import { startCase } from 'lodash';
import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import PlayerSearch from 'src/components/0400_player_search';
import { assignTemporaryManager } from 'src/graphql/mutations/events.graphql';
import {
  IAssignTemporaryManagerMutation,
  IAssignTemporaryManagerMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { IOrganizationRoleEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useEvent from 'src/hooks/events/useEvent';
import { useMutation } from 'urql';

const TemporaryManagers = () => {
  const { eventId } = useParams();
  const { event } = useEvent();
  const { linkToPlayer } = useAppNavigations();
  const [ error, setError ] = useState<string | null>( null );
  const [ mutationResult, mutate ] = useMutation<
    IAssignTemporaryManagerMutation,
    IAssignTemporaryManagerMutationVariables
  >( assignTemporaryManager );

  const handleMutation = useCallback(
    ({ playerId, forRemoval }: { playerId: number; forRemoval?: boolean }) => {
      setError( null );

      mutate({
        eventId: Number( eventId ),
        playerId,
        forRemoval,
      }).then( res => {
        if ( res.data?.assignTemporaryManager?.error ) {
          setError( res.data.assignTemporaryManager.error );
        }
      });
    },
    [ eventId, mutate ],
  );

  return (
    <div className="min-h-[50vh]">
      <div className="grid gap-2">
        <div className="flex justify-between items-center">
          <div className="text-2xl">Temporary Managers</div>
          {mutationResult.fetching && <Loading size="small" />}
        </div>
        <div className="text-juno-gray-200">
          Temporary Managers are Employees or Assistants outside{' '}
          {event?.branch.shorthand} that can help organize this Game. Temporary
          Assistants have the same level of privileges as that in their Home
          Games.
        </div>
        <div>
          <PlayerSearch
            playersOnly
            accessLevelFilters={[
              IOrganizationRoleEnum.Assistant,
              IOrganizationRoleEnum.Employee,
            ]}
            placeholder="Search Assistants or Employees..."
            onSearchResultClick={({ playerId }) =>
              playerId && handleMutation({ playerId })
            }
          />
          {error && <ResponseBox type="error">{error}</ResponseBox>}
        </div>
        <div className="grid">
          {event?.temporaryManagers
            .sort(( a, b ) => a.user.fullName.localeCompare( b.user.fullName ))
            .map( x => (
              <div
                key={x.id}
                className="flex justify-between items-center hover:gray-box transition-all p-1 pr-2"
              >
                <div>
                  <div className="flex gap-2 items-end">
                    <Link
                      className="underline"
                      to={linkToPlayer({ playerId: x.user.id })}
                    >
                      {x.user.fullName}
                    </Link>
                    <span className="text-sm opacity-50">{`#${x.user.id}`}</span>
                  </div>

                  <div className="text-sm opacity-75">
                    {x.user.ownedBranches.length > 0 && (
                      <div>
                        Runs
                        {` `}
                        {x.user.ownedBranches
                          .map( branch => branch.shorthand )
                          .join( ', ' )}
                      </div>
                    )}

                    {x.user.userOrganization?.role ===
                      IOrganizationRoleEnum.Assistant && (
                      <div>
                        {`Assistant in ${x.user.userOrganization.branch.shorthand}`}
                        {Number(
                          x.user.userOrganization?.assistantFlags?.length,
                        ) > 0 && (
                          <span>
                            {`: ${x.user.userOrganization.assistantFlags.map( x => startCase( x )).join( ', ' )}`}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <ConfirmDropdown
                  onConfirm={() =>
                    handleMutation({ playerId: x.user.id, forRemoval: true })
                  }
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TemporaryManagers;
