import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const OrganizationHeader: FC = () => {
  const { organizationSlug } = useParams();
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });

  if ( !canCreateNewPlayer ) return null;

  return (
    <ResponsiveTabGroup label="Actions">
      {canCreateNewPlayer && (
        <Tab
          label={
            <div>
              <FontAwesomeIcon icon={faPlus} className="pr-2" />
              Create New Player
            </div>
          }
          to={`/${organizationSlug}/players/new`}
          highlightMode="responsive"
        />
      )}
    </ResponsiveTabGroup>
  );
};

export default OrganizationHeader;
