import { FC, useCallback } from 'react';
import {
  format,
  formatDistanceToNow,
  isBefore,
  isFuture,
  parseISO,
} from 'date-fns';
import { IXpAudit } from 'src/graphql/types';
import Title from 'src/components/0100_title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

interface IProps {
  data: IXpAudit[];
  header?: string;
}

const Content: FC<IProps> = ({ data, header }) => {
  const { playerId } = useParams();
  const { linkToEvent } = useAppNavigations();

  const displayGameType = useCallback(( x: IXpAudit ) => {
    if (
      ![
        'branch_transfer',
        'home_game',
        'legacy',
        'live',
        'penalty',
        'travel_as_home_game',
        'travel_game',
      ].includes( x.kind )
    ) {
      return '';
    }

    const date =
      x.kind === 'penalty'
        ? ''
        : x.awardedAt
          ? format( parseISO( x.awardedAt ), 'M/d' )
          : '';
    const text = () => {
      if ( x.kind === 'branch_transfer' ) return '<>';
      if ( x.kind === 'penalty' ) return <FontAwesomeIcon icon={faAnglesDown} />;
      if ( !x.homeGameEventsIndex ) return 'T';

      return `${x.kind === 'travel_as_home_game' ? '^' : '#'}${x.homeGameEventsIndex}`;
    };

    return (
      <div className="grid grid-cols-2">
        <div className="opacity-50">{date}</div>
        <div>{text()}</div>
      </div>
    );
  }, []);

  const displayEventName = useCallback(
    ( x: IXpAudit ) => {
      switch ( x.kind ) {
        case 'generic':
        case 'membership':
        case 'referral':
          return (
            <div>
              <div>{x.eventName}</div>
              <div className="opacity-50 text-sm">
                {x.applicableOn && isFuture( parseISO( x.applicableOn ))
                  ? `Unlocks in ${formatDistanceToNow( parseISO( x.applicableOn ))} at ${format( parseISO( x.applicableOn ), 'yyyy MMM dd' )}`
                  : `Applied on ${format( parseISO( x.awardedAt ), 'yyyy MMM dd' )}`}
              </div>
            </div>
          );
        case 'branch_transfer':
          return (
            <div>
              <div>
                Transfer {x.originBranch}
                <FontAwesomeIcon icon={faArrowRight} className="px-2" />
                {x.destinationBranch}
              </div>
              <div className="opacity-50 text-sm">
                {`Recorded on ${format( parseISO( x.awardedAt ), 'yyyy MMM dd' )}`}
              </div>
            </div>
          );
        case 'legacy':
        case 'penalty':
          return x.eventName;
        default:
          return (
            <Link
              to={linkToEvent({
                eventId: Number( x.eventId ),
                section: 'checkin',
                searchParams: [ `player_id=${playerId}` ],
              })}
              className="hover:underline"
            >
              {x.eventName}
            </Link>
          );
      }
    },
    [ linkToEvent, playerId ],
  );

  const displayXp = useCallback(
    ( x: IXpAudit ) =>
      x.xpAddOn
        ? `${( x.xpAwarded ?? 0 ) - ( x.xpAddOn ?? 0 )} + ${x.xpAddOn}`
        : `${x.xpAwarded ?? ''}`,
    [],
  );

  return (
    <div className="w-full grid gap-2">
      {header && (
        <Title variant="heading">
          <div className="flex justify-between">
            <div>{header}</div>
            <div className="text-xl">
              {data
                .map( x => x.xpAwarded )
                .reduce(( a, b ) => ( a ?? 0 ) + ( b ?? 0 ), 0 )}
            </div>
          </div>
        </Title>
      )}
      {data
        .sort(( a, b ) => {
          if ( a.homeGameEventsIndex && b.homeGameEventsIndex ) {
            return b.homeGameEventsIndex - a.homeGameEventsIndex;
          }

          if ( !a.awardedAt && !b.awardedAt ) return 0;
          return isBefore( parseISO( a.awardedAt ), parseISO( b.awardedAt ))
            ? 1
            : -1;
        })
        .map( x => (
          <div
            key={`${x.kind}-${x.eventId ?? x.extraBuildId ?? x.branchTransferId ?? x.eventName}`}
            className="flex justify-between gap-4"
          >
            <div className="w-32 text-right">{displayGameType( x )}</div>
            <div className="w-full text-left">{displayEventName( x )}</div>
            <div className="w-16 text-right">{displayXp( x )}</div>
          </div>
        ))}
    </div>
  );
};

export default Content;
