import { gql } from 'urql';

export const createFellowship = gql`
  mutation CreateFellowship($name: String!, $type: FellowshipKindEnum!) {
    createFellowship(input: { name: $name, kind: $type }) {
      error
      organization {
        id
        fellowships(kind: $type) {
          id
          name
          charactersCount
          removedAt
        }
      }
    }
  }
`;

export const updateFellowship = gql`
  mutation UpdateFellowship(
    $fellowshipId: Int!
    $name: String
    $description: String
    $forRemoval: Boolean
  ) {
    updateFellowship(
      input: {
        fellowshipId: $fellowshipId
        name: $name
        description: $description
        forRemoval: $forRemoval
      }
    ) {
      error
      fellowship {
        id
        name
        description
        charactersCount
        removedAt
      }
    }
  }
`;

export const createCharacterFellowship = gql`
  mutation CreateCharacterFellowship(
    $characterId: Int!
    $fellowshipId: Int!
    $rank: Int
  ) {
    createCharacterFellowship(
      input: {
        characterId: $characterId
        fellowshipId: $fellowshipId
        rank: $rank
      }
    ) {
      error
      fellowship {
        id
        name
        charactersCount
        removedAt
        characterFellowships {
          id
          rank
          removedAt
          character {
            id
            name
          }
        }
      }
    }
  }
`;

export const updateCharacterFellowship = gql`
  mutation UpdateCharacterFellowship(
    $characterFellowshipId: Int!
    $rank: Int
    $forRemoval: Boolean
  ) {
    updateCharacterFellowship(
      input: {
        characterFellowshipId: $characterFellowshipId
        rank: $rank
        forRemoval: $forRemoval
      }
    ) {
      error
      characterFellowship {
        id
        rank
        removedAt
      }
    }
  }
`;
