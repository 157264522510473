import { FC } from 'react';
import TableView from 'src/components/0500_depository/dystopia_rising/TableView';
import useOrganization from 'src/hooks/organizations/useOrganization';

const Tabular: FC = () => {
  const { organization } = useOrganization();

  switch ( organization?.slug ) {
    case 'dystopia-rising':
      return <TableView />;
    default:
      return null;
  }
};

export default Tabular;
