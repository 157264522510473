import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutsideClickRef } from 'rooks';
import clsx from 'clsx';
import { faChevronDown, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownPanel from '../0100_dropdown_panel';
import Tab from '../0200_tab';
import lineageData from './data';

interface IProps {
  isEditable: boolean;
}

const LineageDropdown: FC<IProps> = ({ isEditable }) => {
  const [ isExpanded, setIsExpanded ] = useState( false );
  const { setValue, watch } = useFormContext();
  const { lineageId, strainId } = watch();
  const [ ref ] = useOutsideClickRef(() => {
    setIsExpanded( false );
  });

  const lineage = lineageData.find( x => x.id === lineageId );
  const strain = lineage?.strains.find( x => x.id === strainId );

  return (
    <div ref={ref}>
      <button
        type="button"
        className={clsx(
          'w-full flex justify-between items-center py-1 px-2 rounded dark-box border border-juno-gray-700',
          !isEditable && 'opacity-50',
        )}
        onClick={() => isEditable && setIsExpanded( x => !x )}
      >
        <div>{`${lineage?.name}: ${strain?.name ?? 'Choose Strain'}`}</div>
        <FontAwesomeIcon
          icon={isEditable ? faChevronDown : faLock}
          className={clsx(
            'transition-all duration-300 -mt-0.5',
            isEditable && isExpanded && '-scale-y-100',
          )}
        />
      </button>

      <DropdownPanel
        isExpanded={isExpanded}
        className={clsx(
          'border-juno-gray-700 w-full mt-2',
          isExpanded && 'border',
        )}
      >
        <div className="grid place-self-center grid-cols-1 w-full border-collapse">
          {lineageData
            .filter( x => x.id !== 0 )
            .map(({ id, name, strains, body, mind }) => (
              <div key={id}>
                <div
                  className={clsx(
                    'flex justify-between items-center p-2 px-4 transition-all duration-300',
                    lineageId === id
                      ? 'white-box text-juno-gray-900'
                      : 'midtone-box opacity-75',
                  )}
                >
                  {name}
                  <div className="text-sm text-right">
                    <div>{body} Body</div>
                    <div>{mind} Mind</div>
                  </div>
                </div>
                {strains.map( strain => (
                  <Tab
                    key={strain.id}
                    highlightMode="vertical"
                    isActive={strain.id === strainId}
                    isLocked={!isEditable}
                    label={strain.name}
                    className={clsx(
                      strain.id !== strainId ? 'opacity-50' : 'text-shadow',
                    )}
                    onClick={() => {
                      if ( isEditable ) {
                        setValue( 'lineageId', id, { shouldDirty: true });
                        setValue( 'strainId', strain.id, { shouldDirty: true });
                      }
                    }}
                  />
                ))}
              </div>
            ))}
        </div>
      </DropdownPanel>
    </div>
  );
};

export default LineageDropdown;
