import { useContext, useCallback } from 'react';
import RootUserContext, { TModal, initialState } from 'src/contexts/RootUser';
import {
  destroyAuthTokenMutation,
  generateAuthTokenMutation,
} from 'src/graphql/mutations/auths.graphql';
import {
  IDestroyAuthTokenMutation,
  IDestroyAuthTokenMutationVariables,
  IGenerateAuthTokenMutation,
  IGenerateAuthTokenMutationVariables,
} from 'src/graphql/mutations/auths.graphql.types';

import { useMutation } from 'urql';
import { useNavigate, useSearchParams } from 'react-router-dom';
import graphqlClient from 'src/graphqlClient';
import { getPlayerWithOrganizations } from 'src/graphql/queries/players.graphql';
import {
  IGetPlayerWithOrganizationsQuery,
  IGetPlayerWithOrganizationsQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import {
  IRequestPasswordResetMutation,
  IRequestPasswordResetMutationVariables,
  IResetPasswordMutation,
  IResetPasswordMutationVariables,
} from 'src/graphql/mutations/passwords.graphql.types';
import {
  requestPasswordResetMutation,
  resetPasswordMutation,
} from 'src/graphql/mutations/passwords.graphql';
import { ILoginPayload, IResetPasswordPayload } from './types';

const useAuth = () => {
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const resetPasswordToken = searchParams.get( 'reset_password_token' );

  const { rootUser, setRootUser } = useContext( RootUserContext );

  const [ generateAuthTokenResult, generateAuthToken ] = useMutation<
    IGenerateAuthTokenMutation,
    IGenerateAuthTokenMutationVariables
  >( generateAuthTokenMutation );
  const [ requestPasswordResetResult, requestPasswordReset ] = useMutation<
    IRequestPasswordResetMutation,
    IRequestPasswordResetMutationVariables
  >( requestPasswordResetMutation );
  const [ resetPasswordResult, resetPassword ] = useMutation<
    IResetPasswordMutation,
    IResetPasswordMutationVariables
  >( resetPasswordMutation );
  const [ , destroyAuthToken ] = useMutation<
    IDestroyAuthTokenMutation,
    IDestroyAuthTokenMutationVariables
  >( destroyAuthTokenMutation );

  const { isValidated, activeModal } = rootUser;

  const persistToken = useCallback(
    ({ token, rootUserId }: { token: string; rootUserId: string }) => {
      localStorage.setItem( 'token', token );
      localStorage.setItem( 'rootUserId', rootUserId )
    },
    [],
  );

  const invalidateToken = useCallback(() => {
    localStorage.removeItem( 'token' )
    localStorage.removeItem( 'rootUserId' )
  }, []);

  const revalidateToken = useCallback(() => {
    const token = localStorage.getItem( 'token' ) || ''
    const rootUserId = localStorage.getItem( 'rootUserId' ) || ''

    if ( token.length > 0 && rootUserId.length > 0 ) {
      graphqlClient
        .query<
          IGetPlayerWithOrganizationsQuery,
          IGetPlayerWithOrganizationsQueryVariables
        >(
          getPlayerWithOrganizations,
          { playerId: Number( rootUserId ) },
          { requestPolicy: 'network-only' },
        )
        .toPromise()
        .then( res => {
          const data = res.data?.user;

          if ( data ) {
            setRootUser( x => ({ ...x, ...data, isValidated: true }));
            persistToken({ token, rootUserId });
          } else {
            invalidateToken();
            setRootUser( x => ({
              ...x,
              isValidated: false,
              activeModal: 'login',
            }));
          }
        });
    }
  }, [ invalidateToken, persistToken, setRootUser ]);

  const handleLogin = useCallback(
    ( payload: ILoginPayload ) => {
      generateAuthToken( payload ).then( res => {
        const data = res.data?.generateAuthToken;

        if ( data?.user && data?.token ) {
          persistToken({ token: data.token, rootUserId: String( data.user.id ) });
          setRootUser( x => ({ ...x, ...data.user, isValidated: true }));

          setTimeout(() => {
            setRootUser( x => ({ ...x, activeModal: '', isValidated: true }));
          }, 300 );
        }
      });
    },
    [ generateAuthToken, persistToken, setRootUser ],
  );

  const handleLogout = useCallback(() => {
    destroyAuthToken({});
    invalidateToken();
    setRootUser( initialState );
    navigate( '/' );
  }, [ destroyAuthToken, invalidateToken, setRootUser, navigate ]);

  const handlePasswordRecovery = useCallback(
    ( username: string ) => {
      requestPasswordReset({ username });
    },
    [ requestPasswordReset ],
  );

  const handlePasswordReset = useCallback(
    ( payload: IResetPasswordPayload ) => {
      resetPassword( payload ).then( res => {
        const data = res.data?.resetPassword;

        if ( data?.user && data.token ) {
          persistToken({ token: data.token, rootUserId: String( data.user.id ) });
          setRootUser( x => ({ ...x, ...data.user, isValidated: true }));
          setSearchParams({});

          setTimeout(() => {
            setRootUser( x => ({ ...x, activeModal: '', isValidated: true }));
          }, 300 );
        }
      });
    },
    [ persistToken, resetPassword, setRootUser, setSearchParams ],
  );

  const openModal = useCallback(
    ({ modal, args }: { modal: TModal; args?: Record<string, string> }) =>
      setRootUser( x => ({ ...x, activeModal: modal, modalArgs: args || {}})),
    [ setRootUser ],
  );

  const closeModal = useCallback(() => openModal({ modal: '' }), [ openModal ]);

  const isRootUserValidated = ( localStorage.getItem( 'token' ) || '' ).length > 0 && 
    Number( localStorage.getItem( 'rootUserId' ) || '' ) > 0 && isValidated

  return {
    activeModal,
    isRootUserValidated,
    resetPasswordToken,
    closeModal,
    openModal,
    modalArgs: rootUser.modalArgs,
    generateAuthTokenResult,
    requestPasswordResetResult,
    resetPasswordResult,
    revalidateToken,
    handleLogin,
    handleLogout,
    handlePasswordRecovery,
    handlePasswordReset,
  };
};

export default useAuth;
