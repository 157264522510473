import { addMonths, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import Calendar from 'src/components/0300_calendar';
import useCalendarNavigations from 'src/hooks/calendarNavigations/useCalendarNavigations';
import useEventsWithAttendances from 'src/hooks/events/useEventsWithAttendances';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';

const Attendances: FC = () => {
  const { playerId } = useParams();
  const [ withEvents, setWithEvents ] = useState( true );
  const { start, end, handlePrevious, handleNext } = useCalendarNavigations({
    initStart: addMonths( startOfMonth( new Date()), -11 ),
    initEnd: addMonths( endOfMonth( new Date()), 1 ),
    previousMonthsJump: 12,
  });
  const { events, fetching } = useEventsWithAttendances({
    start,
    end,
    playerId: Number( playerId ),
    withEvents,
  });
  const { data } = usePlayerWithCharactersInOrganization({
    playerId: Number( playerId ),
  });

  return (
    <Calendar
      start={start}
      end={end}
      items={events}
      isFetching={fetching}
      lastMembership={
        data?.user?.lastMembership
          ? parseISO( data?.user?.lastMembership?.endsAt )
          : undefined
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Attendances;
