import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import ResponseBox from 'src/components/0100_response_box';
import ItemKindSelect from 'src/components/0400_item_kind_select';
import { createItem } from 'src/graphql/mutations/items.graphql';
import {
  ICreateItemMutation,
  ICreateItemMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  onCreate: () => void;
}

const NewItem: FC<IProps> = ({ onCreate }) => {
  const { buttonState } = useButtonStates();
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ error, setError ] = useState<string | null>( null );
  const methods = useForm({
    defaultValues: {
      name: '',
      kind: IItemKindEnum.Blueprint,
      grade: IItemGradeEnum.Ungraded,
    },
  });
  const {
    register,
    reset,
    setFocus,
    setValue,
    watch,
    formState: { isValid },
  } = methods;
  const { kind, grade, name } = watch();
  const [ createResult, create ] = useMutation<
    ICreateItemMutation,
    ICreateItemMutationVariables
  >( createItem );

  const handleCreate = useCallback(() => {
    setIsSuccessful( false );
    setError( null );
    create({ name, kind, grade }).then( res => {
      if ( res.data?.createItem?.item ) {
        reset({ kind, name: '', grade });
        setFocus( 'name' );
        setIsSuccessful( true );
        setTimeout(() => setIsSuccessful( false ), 3000 );
        onCreate();
      }

      if ( res.data?.createItem?.error ) {
        setError( res.data.createItem.error );
        setFocus( 'name' );
      }
    });
  }, [ create, grade, kind, name, onCreate, reset, setFocus ]);

  useEffect(() => {
    if ( kind === IItemKindEnum.Blueprint ) {
      setValue( 'grade', IItemGradeEnum.Ungraded );
    }
  }, [ kind, setValue ]);

  return (
    <FormProvider {...methods}>
      <div className="grid gap-2 border-b border-juno-gray-700 mb-2 pb-2">
        <div className="flex items-center gap-2 w-full">
          <div className="w-full">
            <ItemKindSelect
              width="w-full"
              selectedValue={kind}
              {...register( 'kind' )}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Input
            fullWidth
            {...register( 'name', { required: true })}
            placeholder="Item Name"
          />

          <Button
            defaultLabel="Create"
            className="min-w-[128px]"
            state={buttonState({
              isValid,
              isDirty: !isSuccessful,
              isFetching: createResult.fetching,
              isSuccessful,
            })}
            stateLabel={{ loading: 'Creating...', success: 'Created!' }}
            onClick={handleCreate}
          />
        </div>
        {error && <ResponseBox type="error">{error}</ResponseBox>}
      </div>
    </FormProvider>
  );
};

export default NewItem;
