import { FC } from 'react';
import { useParams } from 'react-router-dom';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';
import usePermission from 'src/hooks/permissions/usePermissions';

const Printout: FC = () => {
  const { characterId, playerId } = useParams();
  const { player, character } = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });
  const { isPermitted: canPrint } = usePermission({
    action: 'update_character_data_sensitive',
    characterId: Number( character?.id ),
  });

  if ( !canPrint ) return null;

  return (
    <div className="printout-container">
      <DystopiaRisingPrintout character={character} player={player} />
    </div>
  );
};

export default Printout;
