import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebouncedValue, useIntervalWhen } from 'rooks';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import ResponseBox from 'src/components/0100_response_box';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { decompressLzw } from 'src/utils/jsonCompress';
import { TBuildTemplate } from '../types';
import loreData from '../SkillBuilder/LoreGroup/data';
import { skillNames } from '../SkillBuilder/data';
import { pack, unpack } from '../utils/characterBuildCompression';

const Shareable: FC = () => {
  const [ isLoaded, setIsLoaded ] = useState( false );
  const { reset } = useFormContext();
  const { organizationSlug, characterId, eventId } = useParams();
  const { buttonState } = useButtonStates();
  const [ isCopied, setIsCopied ] = useState( false );
  const [ , setSearchParams ] = useSearchParams();

  const watch = useWatch<TBuildTemplate>();
  const [ debouncedWatch ] = useDebouncedValue( watch, 100 );
  const compressed = useMemo(
    () =>
      pack({
        lineageId: debouncedWatch.lineageId ?? 0,
        strainId: debouncedWatch.strainId ?? 0,
        faithId: debouncedWatch.faithId ?? 0,
        variant: 'standard', // hardcoded for now
        body: debouncedWatch.stats?.body ?? 0,
        mind: debouncedWatch.stats?.mind ?? 0,
        resolve: debouncedWatch.stats?.resolve ?? 0,
        infection: debouncedWatch.stats?.infection ?? 0,
        loreIds: ( debouncedWatch.lores ?? []).map( x => Number( x.id )),
        skills: ( debouncedWatch.skills ?? []).map( x => ({
          id: Number( x.id ),
          level: Number( x.level ),
          positions: x.positions,
        })),
      }),
    [ debouncedWatch ],
  );
  const shareableUrl = useMemo(
    () =>
      [
        window.location.origin,
        organizationSlug,
        `characters?h=${compressed}`,
      ].join( '/' ),
    [ compressed, organizationSlug ],
  );

  const mergeData = useCallback(() => {
    const legacyUrlData = new URL( window.location.toString()).searchParams.get(
      'd',
    );
    const urlData = new URL( window.location.toString()).searchParams.get( 'h' );

    if ( legacyUrlData ) {
      reset( decompressLzw( legacyUrlData ));
    } else if ( urlData ) {
      const unpacked = unpack( urlData );
      reset({
        lineageId: unpacked.lineageId,
        strainId: unpacked.strainId,
        faithId: unpacked.faithId,
        variant: unpacked.variant,
        lores: unpacked.loreIds
          .filter( x => x >= 201 )
          .map( x => ({ id: x, name: loreData.find( y => y.id === x )?.name })),
        skills: unpacked.skills.map( x => ({
          id: x.id,
          name: skillNames[x.id] ?? 'Unknown Skill',
          level: x.level,
          positions: x.positions,
        })),
        stats: {
          body: unpacked.body,
          mind: unpacked.mind,
          resolve: unpacked.resolve,
          infection: unpacked.infection,
        },
        xpEarned: 0,
      });
    }
  }, [ reset ]);

  const copy = useCallback(() => {
    navigator.clipboard.writeText( shareableUrl );
    setIsCopied( true );
  }, [ shareableUrl ]);

  useEffect(() => {
    setIsLoaded( true );
  }, []);

  useEffect(() => {
    if ( isLoaded ) {
      mergeData();
    }
  }, [ isLoaded, mergeData ]);

  useEffect(() => {
    if ( !characterId && !eventId && isLoaded ) {
      const params = new URLSearchParams();
      params.set( 'h', compressed );
      setSearchParams( params, { replace: true });
    }
  }, [ characterId, compressed, eventId, isLoaded, setSearchParams ]);

  useIntervalWhen(() => setIsCopied( false ), 3000, isCopied );

  return (
    <div>
      <div className="w-full flex">
        <Input
          isLocked
          fullWidth
          showLockIcon={false}
          value={shareableUrl}
          className="rounded-r-none"
        />
        <Button
          defaultLabel="Share Build"
          state={buttonState({
            isHighlight: isCopied,
            isValid: true,
            isDirty: true,
            isSuccessful: isCopied,
          })}
          stateLabel={{ success: isCopied ? 'Copied' : 'Share Build' }}
          className="w-32 rounded-l-none rounded-r whitespace-nowrap"
          onClick={copy}
        />
      </div>
      <ResponseBox type="neutral" withIcon={faLightbulb}>
        Pro-tip: you can{' '}
        <button type="button" className="underline" onClick={copy}>
          copy the URL above
        </button>{' '}
        to share your build configuration. Please note that the URL changes as
        you make changes to your character&apos;s build.
      </ResponseBox>
    </div>
  );
};

export default Shareable;
