import { useQuery } from 'urql';
import {
  IGetOrganizationFellowshipsQuery,
  IGetOrganizationFellowshipsQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { getOrganizationFellowships } from 'src/graphql/queries/organizations.graphql';
import { IFellowshipKindEnum } from 'src/graphql/types';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationFellowships = ({
  type,
}: {
  type: IFellowshipKindEnum;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationFellowshipsQuery,
    IGetOrganizationFellowshipsQueryVariables
  >({
    query: getOrganizationFellowships,
    variables: { type },
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    fellowships: data?.organization.fellowships ?? [],
    error,
    fetching,
    stale,
  };
};

export default useOrganizationFellowships;
