import { useParams } from 'react-router-dom';
import { getActivePlayers } from 'src/graphql/queries/branches.graphql';
import {
  IGetActivePlayersQuery,
  IGetActivePlayersQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import useRootUserRevalidation from 'src/hooks/useRootUserRevalidation';
import { useQuery } from 'urql';

const useActivePlayers = () => {
  const { branchId } = useParams();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetActivePlayersQuery,
    IGetActivePlayersQueryVariables
  >({
    query: getActivePlayers,
    variables: { branchId: Number( branchId ) },
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
  };
};

export default useActivePlayers;
