import clsx from 'clsx';
import { FC } from 'react';
import Button from 'src/components/0100_button';

interface IProps {
  label: string;
  value: number;
  isActive: boolean;
  isLoading: boolean;
  displayXp?: boolean;
  onClick: () => void;
}

const CategoryButton: FC<IProps> = ({
  label,
  value,
  isActive,
  isLoading,
  displayXp = true,
  onClick,
}) => (
  <Button
    className="flex items-center"
    defaultLabel={label}
    state={isActive ? 'enabled-highlight' : 'enabled'}
    onClick={onClick}
  >
    <div>{label}</div>
    {displayXp && (
      <div
        className={clsx(
          'border-l ml-2 pl-2',
          isLoading && 'blur-xs',
          isActive ? 'border-juno-gray-50' : 'border-juno-gray-900',
        )}
      >
        {value}
      </div>
    )}
  </Button>
);

export default CategoryButton;
