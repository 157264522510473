import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useOutsideClick } from 'rooks';
import clsx from 'clsx';
import DropdownPanel from '../0100_dropdown_panel';

interface IProps {
  text?: string;
  align?: 'left' | 'right';
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmDropdown: FC<IProps> = ({
  text,
  align = 'right',
  confirmLabel = 'Confirm Deletion',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
}) => {
  const ref = useRef<HTMLDivElement>( null );
  const [ leftShift, setLeftShift ] = useState( 0 );
  const [ isConfirming, setIsConfirming ] = useState( false );

  useOutsideClick( ref, () => setIsConfirming( false ));

  const handleResize = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();

    if ( rect && rect.width > 0 ) {
      setLeftShift( rect.width + 7 - 190 );
    }
  }, [ setLeftShift ]);

  useEffect(() => {
    handleResize();
    window.addEventListener( 'resize', handleResize );
    return () => window.removeEventListener( 'resize', handleResize );
  }, [ handleResize ]);

  return (
    <div ref={ref}>
      <button
        onClick={() => setIsConfirming( true )}
        type="button"
        className="underline"
      >
        {text ?? <FontAwesomeIcon icon={faTrash} />}
      </button>
      <div className="relative h-0 w-0">
        <div
          style={{ marginLeft: align === 'right' ? leftShift : undefined }}
          className={clsx( 'absolute w-[190px] -mt-8' )}
        >
          <DropdownPanel isTransparent isExpanded={isConfirming}>
            <button
              type="button"
              className="rounded-l-md bg-juno-orange-400 text-juno-gray-50 p-2 hover:brightness-125 transition-all duration-300"
              onClick={onConfirm}
            >
              {confirmLabel}
            </button>
            <button
              type="button"
              className="rounded-r-lg bg-juno-gray-200 text-juno-gray-700 p-2 hover:brightness-125 transition-all duration-300"
              onClick={() => {
                setIsConfirming( false );
                onCancel?.();
              }}
            >
              {cancelLabel}
            </button>
          </DropdownPanel>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDropdown;
