import {
  IGetItemBaselinesQuery,
  IGetItemBaselinesQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';
import { getItemBaselines } from 'src/graphql/queries/items.graphql';

const useItemBaselines = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemBaselinesQuery,
    IGetItemBaselinesQueryVariables
  >({
    query: getItemBaselines,
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItemBaselines;
