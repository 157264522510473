import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Textarea from 'src/components/0100_textarea';
import Title from 'src/components/0100_title';
import AugmentedInput from 'src/components/0200_augmented_input';
import { updateCharacter } from 'src/graphql/mutations/characters.graphql';
import {
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import useCharacter from 'src/hooks/characters/useCharacter';
import { useMutation } from 'urql';

interface IProps extends ReturnType<typeof useCharacter> {}

const DystopiaRisingNotes: FC<IProps> = ({
  character,
  canEditSensitiveData,
}) => {
  const [ updatedFields, setUpdatedFields ] = useState<Record<string, boolean>>(
    {},
  );

  const {
    register,
    reset,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      playerNotes: '',
      staffNotes: '',
    },
  });
  const { playerNotes, staffNotes } = watch();
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >( updateCharacter );

  useEffect(() => {
    reset({
      playerNotes: character?.playerNotes ?? '',
      staffNotes: character?.staffNotes ?? '',
    });
  }, [ character, reset ]);

  useEffect(() => {
    setUpdatedFields({});
  }, [ character?.id ]);

  return (
    <div>
      <Title variant="heading" title="Notes" />
      <AugmentedInput
        key="player-notes"
        title="Player Notes"
        isBusy={updateResult.fetching && dirtyFields.playerNotes}
        isUpdated={updatedFields.playerNotes && !dirtyFields.playerNotes}
      >
        <Textarea
          disabled={!canEditSensitiveData}
          value={playerNotes}
          {...register( 'playerNotes' )}
          onBlur={x => {
            setValue( 'playerNotes', x.target.value, { shouldDirty: true });
            setUpdatedFields( x => ({ ...x, playerNotes: false }));
            update({
              characterId: Number( character?.id ),
              playerNotes: x.target.value,
            }).then( res => {
              if ( res.data?.updateCharacterData?.character ) {
                setUpdatedFields( x => ({ ...x, playerNotes: true }));
              }
            });
          }}
        />
      </AugmentedInput>
      {canEditSensitiveData && (
        <AugmentedInput
          key="staff-notes"
          title="Staff Notes"
          subtitle={
            <div className="opacity-50 flex items-center gap-2">
              <FontAwesomeIcon icon={faEyeSlash} /> This field is visible only
              to Staffs
            </div>
          }
          isBusy={updateResult.fetching && dirtyFields.staffNotes}
          isUpdated={updatedFields.staffNotes && !dirtyFields.staffNotes}
        >
          <Textarea
            value={staffNotes}
            {...register( 'staffNotes' )}
            onBlur={x => {
              setValue( 'staffNotes', x.target.value, { shouldDirty: true });
              setUpdatedFields( x => ({ ...x, staffNotes: false }));
              update({
                characterId: Number( character?.id ),
                staffNotes: x.target.value,
              }).then( res => {
                if ( res.data?.updateCharacterData?.character ) {
                  setUpdatedFields( x => ({ ...x, staffNotes: true }));
                }
              });
            }}
          />
        </AugmentedInput>
      )}
    </div>
  );
};

export default DystopiaRisingNotes;
