import { useQuery } from 'urql';
import { getOrganizationMemberships } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationMembershipsQuery,
  IGetOrganizationMembershipsQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationMemberships = ({ on }: { on: Date }) => {
  const { isRootUserValidated } = useAuth();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationMembershipsQuery,
    IGetOrganizationMembershipsQueryVariables
  >({
    query: getOrganizationMemberships,
    variables: { on: on.toDateString() },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    memberships: data?.organization.memberships || [],
  };
};

export default useOrganizationMemberships;
