import { startCase } from 'lodash';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';

const PlayerHeader: FC = () => {
  const { playerId } = useParams();
  const { linkToPlayer, playerSection } = useAppNavigations();
  const { isPermitted: canSeeCorrectiveActions } = usePermission({
    action: 'show_corrective_actions',
    playerId: Number( playerId ),
  });

  if ( playerId === 'new' ) return null;

  return (
    <ResponsiveTabGroup label={startCase( playerSection || 'bio' )}>
      <Tab
        label="Bio"
        to={linkToPlayer({ section: 'bio' })}
        highlightMode="responsive"
        isActive={( playerSection || 'bio' ) === 'bio'}
      />
      <Tab
        label="Attendance"
        to={linkToPlayer({ section: 'attendance' })}
        highlightMode="responsive"
      />
      <Tab
        label="Memberships"
        to={linkToPlayer({ section: 'memberships' })}
        highlightMode="responsive"
      />
      <Tab
        label="Perks"
        to={linkToPlayer({ section: 'perks' })}
        highlightMode="responsive"
      />
      {canSeeCorrectiveActions && (
        <Tab
          label="Corrective Actions"
          to={linkToPlayer({ section: 'corrective_actions' })}
          highlightMode="responsive"
        />
      )}
      <Tab
        label="Referrals"
        to={linkToPlayer({ section: 'referrals' })}
        highlightMode="responsive"
      />
    </ResponsiveTabGroup>
  );
};

export default PlayerHeader;
