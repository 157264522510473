import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { IItemKindEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import ItemView from '../../ItemView';

interface IProps {
  items: {
    id: number;
    name: string;
    kind: IItemKindEnum;
  }[];
}

const ItemList: FC<IProps> = ({ items }) => {
  const { itemId } = useParams();
  const { linkToDepository } = useAppNavigations();
  const [ searchParams ] = useSearchParams();
  const isPrintout = searchParams.get( 'print' ) === 'true';

  return (
    <div>
      {items
        .sort(( a, b ) => a.name.localeCompare( b.name ))
        .map( x => (
          <div key={x.id} id={`item-${x.id}`}>
            <Link
              to={linkToDepository({
                itemId: Number( itemId ) === x.id ? undefined : x.id,
                searchParams: isPrintout ? '?print=true' : '',
              })}
              className={clsx(
                'border-l-4 transition-all px-2 py-1 duration-300 flex justify-between items-center w-full',
                Number( itemId ) === x.id
                  ? 'border-juno-gray-50 hover:border-juno-gray-50 text-juno-gray-50 text-shadow midtone-box'
                  : 'border-transparent hover:border-juno-gray-700 text-juno-gray-200 hover:text-juno-gray-50',
              )}
            >
              <div
                className={clsx(
                  x.kind === IItemKindEnum.Blueprint &&
                    'text-juno-cyan-200 brightness-150 whitespace-nowrap overflow-hidden max-w-[67vw]',
                )}
              >
                {x.name}
              </div>
              <div className="text-sm opacity-75 whitespace-nowrap">
                {startCase( x.kind )}
              </div>
            </Link>
            {Number( itemId ) === x.id && (
              <div className="block lg:hidden pt-2">
                <ItemView />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ItemList;
