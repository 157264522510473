import { FC, useCallback, useState } from 'react';
import Button from 'src/components/0100_button';
import { createItemCrafting } from 'src/graphql/mutations/items.graphql';
import {
  ICreateItemCraftingMutation,
  ICreateItemCraftingMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  blueprintId: number;
}

const NewItemCrafting: FC<IProps> = ({ blueprintId }) => {
  const { buttonState } = useButtonStates();
  const [ , setError ] = useState<string | null>( null );
  const [ createResult, create ] = useMutation<
    ICreateItemCraftingMutation,
    ICreateItemCraftingMutationVariables
  >( createItemCrafting );

  const handleClick = useCallback(() => {
    setError( null );
    create({ blueprintId }).then( res => {
      if ( res.data?.createItemCrafting?.error ) {
        setError( res.data.createItemCrafting.error );
      }
    });
  }, [ blueprintId, create ]);

  return (
    <Button
      className="w-full"
      defaultLabel="Create New Crafting Procedure"
      state={buttonState({
        isFetching: createResult.fetching,
        isDirty: true,
        isValid: !createResult.fetching,
      })}
      stateLabel={{ loading: 'Creating Crafting Procedure...' }}
      onClick={handleClick}
    />
  );
};

export default NewItemCrafting;
