import { gql } from 'urql';

export const getPermission = gql`
  query GetPermission(
    $action: String!
    $branchId: Int
    $eventId: Int
    $playerId: Int
    $characterId: Int
  ) {
    permission(
      action: $action
      branchId: $branchId
      eventId: $eventId
      playerId: $playerId
      characterId: $characterId
    ) {
      entity {
        id
      }
      permitted
    }
  }
`;
