import { getEventPrintouts } from 'src/graphql/queries/events.graphql';
import {
  IGetEventPrintoutsQuery,
  IGetEventPrintoutsQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useEventPrintouts = ({
  eventId,
  paid,
  printRequested,
  homeGame,
  ticketIds,
}: {
  eventId: number;
  paid?: boolean;
  printRequested?: boolean;
  homeGame?: boolean;
  ticketIds?: number[];
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventPrintoutsQuery,
    IGetEventPrintoutsQueryVariables
  >({
    query: getEventPrintouts,
    requestPolicy: 'cache-and-network',
    variables: { eventId, paid, printRequested, homeGame, ticketIds },
    pause: !eventId,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    event: data?.event,
    printouts: data?.event?.printouts ?? [],
  };
};

export default useEventPrintouts;
