import { addDays } from 'date-fns';

interface IOpts {
  withinDays?: number;
}

export const isExpiring = ( date: Date, opts?: IOpts ) =>
  date >= new Date() && date < addDays( new Date(), opts?.withinDays || 30 );

export const hasGoodLifetime = ( date: Date, opts?: IOpts ) =>
  date >= addDays( new Date(), opts?.withinDays || 30 );
