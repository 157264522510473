import { useQuery } from 'urql';
import {
  IResetPasswordTokenStatusQuery,
  IResetPasswordTokenStatusQueryVariables,
} from 'src/graphql/queries/resetPasswordToken.graphql.types';
import { resetPasswordTokenStatus } from 'src/graphql/queries/resetPasswordToken.graphql';
import useAuth from '../auths/useAuth';

const useResetPasswordTokenStatus = () => {
  const { resetPasswordToken } = useAuth();
  const [{ data, error, fetching }] = useQuery<
    IResetPasswordTokenStatusQuery,
    IResetPasswordTokenStatusQueryVariables
  >({
    query: resetPasswordTokenStatus,
    variables: { token: resetPasswordToken! },
    requestPolicy: 'network-only',
    pause: !resetPasswordToken,
  });

  return { fetching, isTokenValid: data?.resetPasswordTokenStatus };
};

export default useResetPasswordTokenStatus;
