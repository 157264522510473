import { gql } from 'urql';

export const createLocation = gql`
  mutation CreateLocation(
    $branchId: Int!
    $name: String!
    $streetAddress: String!
    $city: String!
    $region: String!
    $zipCode: String!
  ) {
    createLocation(
      input: {
        branchId: $branchId
        name: $name
        streetAddress: $streetAddress
        city: $city
        region: $region
        zipCode: $zipCode
      }
    ) {
      error
      branch {
        id
        locations {
          id
          name
          streetAddress
          city
          region
          zipCode
        }
      }
    }
  }
`;

export const destroyLocation = gql`
  mutation DestroyLocation($branchId: Int!, $locationId: Int!) {
    destroyLocation(input: { branchId: $branchId, locationId: $locationId }) {
      error
      location {
        id
      }
    }
  }
`;
export const updateLocation = gql`
  mutation UpdateLocation(
    $locationId: Int!
    $name: String
    $streetAddress: String
    $city: String
    $region: String
    $zipCode: String
  ) {
    updateLocation(
      input: {
        locationId: $locationId
        name: $name
        streetAddress: $streetAddress
        city: $city
        region: $region
        zipCode: $zipCode
      }
    ) {
      error
      location {
        id
        name
        streetAddress
        city
        region
        zipCode
      }
    }
  }
`;
