import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AugmentedInput from 'src/components/0200_augmented_input';
import { format, parseISO } from 'date-fns';
import { useMutation } from 'urql';
import { updateItem } from 'src/graphql/mutations/items.graphql';
import {
  IUpdateItemMutation,
  IUpdateItemMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import useItem from 'src/hooks/items/useItem';
import ItemKindSelect from 'src/components/0400_item_kind_select';
import { IItemKindEnum } from 'src/graphql/types';
import clsx from 'clsx';
import { startCase } from 'lodash';
import CollapsibleSection from 'src/components/0100_collapsible_section';
import Textarea from 'src/components/0100_textarea';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import ItemCrafting from './ItemCrafting';
import NewItemCrafting from './NewItemCrafting';
import ItemClassifications from './ItemClassifications';
import NewItemReproduction from './NewItemReproduction';
import ItemReproduction from './ItemReproduction';
import useItemEditPermission from '../../hooks/useItemEditPermission';

interface IProps {
  onLoad?: () => void;
}

const ItemEditor: FC<IProps> = ({ onLoad }) => {
  const { itemId } = useParams();
  const { canEdit } = useItemEditPermission();
  const [ searchParams ] = useSearchParams();
  const { linkToDepository } = useAppNavigations();
  const itemIdFromSearchParams = searchParams.get( 'item_id' );
  const { item, stale } = useItem({
    itemId: Number( itemId ?? itemIdFromSearchParams ),
  });
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ isBenedictionExpanded, setIsBenedictionExpanded ] = useState( false );
  const [ isBlueprintExpanded, setIsBlueprintExpanded ] = useState( false );
  const [ isTangibilityExpanded, setIsTangibilityExpanded ] = useState( false );
  const [ error, setError ] = useState<string | null>( null );
  const methods = useForm({
    defaultValues: {
      id: 0,
      name: '',
      kind: IItemKindEnum.Gizmo as IItemKindEnum,
      mechanics: null as string | null,
      notes: null as string | null,
      printHeader: null as string | null,
      uses: null as string | null,
      requirementsToUse: null as string | null,
      durationOfEffect: null as string | null,
      validTargetDescription: null as string | null,
      locationOfUse: null as string | null,
      equipmentRequiredForUse: null as string | null,
      durationOfRoleplay: null as string | null,
      descriptionOfRoleplay: null as string | null,
      activationRequirement: null as string | null,
      lifetimeAmount: null as number | null,
      updatedAt: '',
    },
  });
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = methods;
  const {
    name,
    kind,
    printHeader,
    mechanics,
    notes,
    requirementsToUse,
    uses,
    durationOfEffect,
    validTargetDescription,
    locationOfUse,
    equipmentRequiredForUse,
    durationOfRoleplay,
    descriptionOfRoleplay,
    activationRequirement,
    lifetimeAmount,
  } = watch();

  const [ updateResult, update ] = useMutation<
    IUpdateItemMutation,
    IUpdateItemMutationVariables
  >( updateItem );

  const handleSuccess = useCallback(
    (
      item: NonNullable<NonNullable<IUpdateItemMutation['updateItem']>['item']>,
    ) => {
      const {
        id,
        name,
        kind,
        updatedAt,
        lifetimeAmount,
        metadata: {
          printHeader,
          mechanics,
          notes,
          requirementsToUse,
          uses,
          durationOfEffect,
          validTargetDescription,
          locationOfUse,
          equipmentRequiredForUse,
          durationOfRoleplay,
          descriptionOfRoleplay,
          activationRequirement,
        },
      } = item;
      setIsSuccessful( true );
      reset({
        id,
        name,
        kind,
        printHeader,
        mechanics,
        notes,
        uses,
        requirementsToUse,
        lifetimeAmount,
        durationOfEffect,
        validTargetDescription,
        locationOfUse,
        equipmentRequiredForUse,
        durationOfRoleplay,
        descriptionOfRoleplay,
        activationRequirement,
        updatedAt,
      });
      setTimeout(() => setIsSuccessful( false ), 3000 );
    },
    [ reset ],
  );

  const handlePartialUpdate = useCallback(
    ({ field, value }: { field: string; value: unknown }) => {
      setIsSuccessful( false );
      setError( null );
      update({
        itemId: Number( item?.id ),
        [field]: value,
      }).then( res => {
        if ( res.data?.updateItem?.item ) {
          handleSuccess( res.data.updateItem.item );
        }

        if ( res.data?.updateItem?.error ) {
          setError( res.data.updateItem.error );
        }
      });
    },
    [ handleSuccess, item?.id, update ],
  );
  const handleUpdate = useCallback(() => {
    if ( !isDirty ) {
      return;
    }
    setIsSuccessful( false );
    setError( null );
    update({
      itemId: Number( item?.id ),
      name,
      kind,
      printHeader,
      mechanics,
      notes,
      requirementsToUse,
      uses,
      durationOfEffect,
      validTargetDescription,
      locationOfUse,
      equipmentRequiredForUse,
      durationOfRoleplay,
      descriptionOfRoleplay,
      activationRequirement,
      lifetimeAmount: lifetimeAmount ? Number( lifetimeAmount ) : undefined,
    }).then( res => {
      if ( res.data?.updateItem?.item ) {
        handleSuccess( res.data.updateItem.item );
      }

      if ( res.data?.updateItem?.error ) {
        setError( res.data.updateItem.error );
      }
    });
  }, [
    activationRequirement,
    descriptionOfRoleplay,
    durationOfEffect,
    durationOfRoleplay,
    equipmentRequiredForUse,
    handleSuccess,
    isDirty,
    item?.id,
    kind,
    lifetimeAmount,
    locationOfUse,
    mechanics,
    name,
    notes,
    printHeader,
    requirementsToUse,
    update,
    uses,
    validTargetDescription,
  ]);

  useEffect(() => {
    reset({
      id: item?.id,
      name: item?.name,
      kind: item?.kind,
      printHeader: item?.metadata.printHeader,
      mechanics: item?.metadata.mechanics,
      notes: item?.metadata.notes,
      uses: item?.metadata.uses,
      requirementsToUse: item?.metadata.requirementsToUse,
      durationOfEffect: item?.metadata.durationOfEffect,
      validTargetDescription: item?.metadata.validTargetDescription,
      locationOfUse: item?.metadata.locationOfUse,
      equipmentRequiredForUse: item?.metadata.equipmentRequiredForUse,
      durationOfRoleplay: item?.metadata.durationOfRoleplay,
      descriptionOfRoleplay: item?.metadata.descriptionOfRoleplay,
      activationRequirement: item?.metadata.activationRequirement,
      lifetimeAmount: item?.lifetimeAmount,
      updatedAt: item?.updatedAt,
    });
  }, [ item, reset ]);

  useEffect(() => {
    setIsSuccessful( false );
    setError( null );
  }, [ itemId ]);

  useEffect(() => {
    if ( item ) {
      setIsBlueprintExpanded( item.kind === IItemKindEnum.Blueprint );
      setIsBenedictionExpanded(
        item.kind === IItemKindEnum.Blueprint &&
          item.metadata.printHeader === 'Benediction',
      );
      setIsTangibilityExpanded(
        ![ IItemKindEnum.Blueprint, IItemKindEnum.Taxonomy ].includes(
          item.kind,
        ) ||
          ( item.kind === IItemKindEnum.Blueprint &&
            item.metadata.printHeader === 'Benediction' ),
      );

      onLoad?.();
    }
  }, [ item, onLoad ]);

  if ( stale && !item?.id ) return <Loading size="small" />;
  if ( !item?.id ) return <ResponseBox type="error">Item not found</ResponseBox>;

  return (
    <FormProvider {...methods}>
      <div className="min-h-[75vh]">
        <div className="sticky top-0 midtone-box flex items-center justify-between p-2 border-l-4 border-b border-juno-gray-700">
          <div className="text-xl">{item?.name}</div>
          <div className="opacity-75">{startCase( item?.kind )}</div>
        </div>
        <div className="border-b border-juno-gray-700">
          <div
            className={clsx(
              'border-l-4 transition-all duration-500',
              isSuccessful ? 'border-juno-green-400' : 'border-juno-gray-700',
            )}
          >
            <AugmentedInput title="ID" isLocked {...register( 'id' )} />
            <AugmentedInput
              isLocked={!canEdit}
              required
              fullWidth
              title="Name"
              {...register( 'name', { onBlur: handleUpdate })}
            />
            <AugmentedInput required title="Type">
              <ItemKindSelect
                selectedValue={kind}
                defaultLabel="Select Item Type"
                isLocked={!canEdit}
                {...register( 'kind' )}
                onSelectionChange={x =>
                  handlePartialUpdate({ field: 'kind', value: x })
                }
              />
            </AugmentedInput>

            {item.blueprintForCraftings.length > 0 && (
              <AugmentedInput title="Blueprints">
                <div className="grid gap-1 pt-1">
                  {item.blueprintForCraftings.map( x => (
                    <Link
                      key={x.id}
                      to={linkToDepository({ itemId: x.id })}
                      className="text-juno-cyan-200 brightness-150 hover:text-shadow"
                    >
                      {x.name}
                    </Link>
                  ))}
                </div>
              </AugmentedInput>
            )}

            {item.parts.length > 0 && (
              <AugmentedInput title="Used In">
                <div className="grid gap-1 pt-1">
                  {item.parts
                    .sort(( a, b ) =>
                      a.itemCrafting.blueprint.name.localeCompare(
                        b.itemCrafting.blueprint.name,
                      ),
                    )
                    .map( x => (
                      <Link
                        key={x.id}
                        to={linkToDepository({
                          itemId: x.itemCrafting.blueprint.id,
                        })}
                        className="hover:text-shadow"
                      >
                        <span className="text-juno-cyan-200 brightness-150">
                          {x.itemCrafting.blueprint.name}
                        </span>
                        <span>{` (${x.amount})`}</span>
                      </Link>
                    ))}
                </div>
              </AugmentedInput>
            )}

            <CollapsibleSection
              isExpanded={isTangibilityExpanded}
              title="Tangibility"
              onClick={() => setIsTangibilityExpanded( x => !x )}
            >
              <>
                {item.kind === IItemKindEnum.Blueprint &&
                  item.metadata.printHeader !== 'Benediction' && (
                    <ResponseBox type="neutral" withIcon={faLightbulb}>
                      These fields are typically for the items that Blueprints
                      produce, not the Blueprints themselves.
                    </ResponseBox>
                  )}
                {item.kind === IItemKindEnum.Taxonomy && (
                  <ResponseBox type="neutral" withIcon={faLightbulb}>
                    These fields are typically for the items that comprise said
                    Taxonomy, not the Taxonomy itself.
                  </ResponseBox>
                )}

                <AugmentedInput
                  fullWidth
                  isLocked={!canEdit}
                  title="Requirements To Use"
                  disabled={
                    !canEdit ||
                    item.kind === IItemKindEnum.Taxonomy ||
                    ( item.kind === IItemKindEnum.Blueprint &&
                      item.metadata.printHeader !== 'Benediction' )
                  }
                  {...register( 'requirementsToUse', { onBlur: handleUpdate })}
                />

                <AugmentedInput
                  isLocked={!canEdit}
                  title="Uses"
                  disabled={
                    !canEdit ||
                    [ IItemKindEnum.Blueprint, IItemKindEnum.Taxonomy ].includes(
                      item.kind,
                    )
                  }
                  {...register( 'uses', { onBlur: handleUpdate })}
                />
                <AugmentedInput
                  isLocked={!canEdit}
                  title="Lifetime (months)"
                  disabled={
                    !canEdit ||
                    [ IItemKindEnum.Blueprint, IItemKindEnum.Taxonomy ].includes(
                      item.kind,
                    )
                  }
                  footnote="Enter 0 for non-expiring items"
                  {...register( 'lifetimeAmount', { onBlur: handleUpdate })}
                />
                <div className="p-2">
                  <AugmentedInput displayStacked title="Mechanics">
                    <div className="w-full">
                      <Textarea
                        disabled={
                          !canEdit ||
                          item.kind === IItemKindEnum.Taxonomy ||
                          ( item.kind === IItemKindEnum.Blueprint &&
                            item.metadata.printHeader !== 'Benediction' )
                        }
                        height="h-32"
                        width="w-full"
                        {...register( 'mechanics', { onBlur: handleUpdate })}
                      />
                    </div>
                  </AugmentedInput>
                </div>
              </>
            </CollapsibleSection>
            <CollapsibleSection
              isExpanded={isBlueprintExpanded}
              title="Blueprint"
              onClick={() => setIsBlueprintExpanded( x => !x )}
            >
              <>
                {item.kind !== IItemKindEnum.Blueprint && (
                  <ResponseBox type="neutral" withIcon={faLightbulb}>
                    These fields are typically for Blueprints.
                  </ResponseBox>
                )}
                <AugmentedInput
                  title="Print Header"
                  isLocked={!canEdit}
                  {...register( 'printHeader', { onBlur: handleUpdate })}
                />
                <div className="p-2">
                  <AugmentedInput displayStacked fullWidth title="Notes">
                    <div className="w-full">
                      <Textarea
                        height="h-24"
                        width="w-full"
                        disabled={!canEdit}
                        {...register( 'notes', { onBlur: handleUpdate })}
                      />
                    </div>
                  </AugmentedInput>
                </div>
              </>
            </CollapsibleSection>

            <CollapsibleSection
              isExpanded={isBenedictionExpanded}
              title="Benediction"
              onClick={() => setIsBenedictionExpanded( x => !x )}
            >
              <>
                <ResponseBox type="neutral" withIcon={faLightbulb}>
                  These fields are typically for Benediction Blueprints.
                </ResponseBox>
                <AugmentedInput
                  title="Duration of Effect"
                  isLocked={!canEdit}
                  {...register( 'durationOfEffect', { onBlur: handleUpdate })}
                />
                <AugmentedInput
                  fullWidth
                  isLocked={!canEdit}
                  title="Valid Target Description"
                  {...register( 'validTargetDescription', {
                    onBlur: handleUpdate,
                  })}
                />
                <AugmentedInput
                  fullWidth
                  isLocked={!canEdit}
                  title="Location of Use"
                  {...register( 'locationOfUse', { onBlur: handleUpdate })}
                />
                <AugmentedInput
                  fullWidth
                  isLocked={!canEdit}
                  title="Equipment Required for Use"
                  {...register( 'equipmentRequiredForUse', {
                    onBlur: handleUpdate,
                  })}
                />
                <div className="p-2">
                  <AugmentedInput
                    displayStacked
                    title="Mind / Resolve Expenditure to Activate"
                  >
                    <div className="w-full">
                      <Textarea
                        height="h-16"
                        width="w-full"
                        disabled={!canEdit}
                        {...register( 'activationRequirement', {
                          onBlur: handleUpdate,
                        })}
                      />
                    </div>
                  </AugmentedInput>
                </div>
                <AugmentedInput
                  title="Duration of Roleplay"
                  isLocked={!canEdit}
                  {...register( 'durationOfRoleplay', { onBlur: handleUpdate })}
                />
                <div className="p-2">
                  <AugmentedInput
                    displayStacked
                    title="Description of Roleplay"
                  >
                    <div className="w-full">
                      <Textarea
                        height="h-48"
                        width="w-full"
                        disabled={!canEdit}
                        {...register( 'descriptionOfRoleplay', {
                          onBlur: handleUpdate,
                        })}
                      />
                    </div>
                  </AugmentedInput>
                </div>
              </>
            </CollapsibleSection>

            <AugmentedInput title="Last Update">
              <div className="pt-1 flex gap-2 items-center">
                {!updateResult.fetching &&
                  item &&
                  format( parseISO( item?.updatedAt ), 'yyyy-MM-dd HH:mm:ss' )}
                {updateResult.fetching && <Loading size="small" />}
                {isSuccessful && <FontAwesomeIcon icon={faCheck} />}
              </div>
            </AugmentedInput>
            {error && <ResponseBox type="error">{error}</ResponseBox>}
          </div>
        </div>
        {item && item.kind === IItemKindEnum.Blueprint && (
          <div className="grid gap-8 mt-8">
            {item.itemCraftings.map( x => (
              <ItemCrafting key={x.id} {...x} />
            ))}
            {canEdit && (
              <div>
                <NewItemCrafting blueprintId={item.id} />
              </div>
            )}

            {item.itemReproductions.map( x => (
              <ItemReproduction key={x.id} {...x} />
            ))}
            {canEdit && item.itemReproductions.length === 0 && (
              <div>
                <NewItemReproduction blueprintId={item.id} />
              </div>
            )}
          </div>
        )}
        {item && item.kind === IItemKindEnum.Taxonomy && (
          <div className="grid gap-8 mt-8">
            <ItemClassifications
              parentItemId={item.id}
              itemClassifications={item.childItemClassifications}
            />
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default ItemEditor;
