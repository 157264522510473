import { FC } from 'react';
import { partition } from 'lodash';

import Node from '../Node';
import skillData, { TProfession } from '../data';
import useSkillFinder from '../../hooks/useSkillFinder';

interface IProps {
  canEdit: boolean;
  category: string;
  isAberrant?: boolean;
  isEducation?: boolean;
  skills: typeof skillData;
  onBaseSkillMouseOver: ( x: string ) => void;
  onProfessionMouseOver: ( x: string ) => void;
  onNodeMouseOut: () => void;
}

const CategoryGroup: FC<IProps> = ({
  canEdit,
  category,
  isAberrant,
  isEducation,
  skills,
  onBaseSkillMouseOver,
  onProfessionMouseOver,
  onNodeMouseOut,
}) => {
  const { maybeFilterSkill } = useSkillFinder();
  const [ professions, baseSkills ] = partition( skills, x => x.isProfession );
  const filteredBaseSkills = baseSkills.filter( x => maybeFilterSkill( x.name ));

  return (
    <div className="grid grid-cols-12 gap-2 items-center">
      {filteredBaseSkills.length > 0 ? (
        <div className="col-span-5 sm:col-span-6 grid grid-cols-1 gap-2 max-w-[256px]">
          {filteredBaseSkills
            .sort(( a, b ) => a.name.localeCompare( b.name ))
            .map( x => (
              <Node
                key={x.name}
                {...x}
                canEdit={canEdit}
                onMouseOver={x => onBaseSkillMouseOver( x )}
                onMouseOut={onNodeMouseOut}
              />
            ))}
        </div>
      ) : (
        <div className="col-span-12 italic text-juno-gray-700">
          {isEducation && 'All Education and Lores filtered'}
          {!isEducation &&
            `All ${category} ${isAberrant ? 'Aberrant' : ''} Skills filtered`}
        </div>
      )}
      <div className="col-start-7 col-span-6 sm:col-start-7 sm:col-span-6 grid grid-cols-1 gap-2 max-w-[256px] justify-self-end w-full">
        {( professions as TProfession[])
          .filter( x => maybeFilterSkill( x.name ))
          .sort(( a, b ) => a.name.localeCompare( b.name ))
          .map( profession => (
            <div
              key={profession.name}
              className="grid grid-cols gap-2"
              onBlur={() => null}
              onFocus={() => null}
              onMouseOver={() => onProfessionMouseOver( profession.name )}
              onMouseOut={onNodeMouseOut}
            >
              <Node {...profession} canEdit={canEdit} />

              {profession.skills
                .filter( x => maybeFilterSkill( x.name ))
                .map( professionSkill => (
                  <div key={professionSkill.name} className="pl-8">
                    <Node
                      isProfessionSkill
                      canEdit={canEdit}
                      category={category}
                      isAberrant={profession.isAberrant}
                      {...professionSkill}
                    />
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryGroup;
