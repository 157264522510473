import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChessRook,
  faCircleUser,
  faCrown,
  faGear,
  faPenFancy,
  faStapler,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { IAssistantFlagEnum, IOrganizationRoleEnum } from 'src/graphql/types';

type TType = IOrganizationRoleEnum | IAssistantFlagEnum;

interface IProps {
  type?: TType;
  className?: string;
}

const icon = {
  admin: faCrown,
  employee: faChessRook,
  assistant: faCircleUser,
  logistics: faTruck,
  mod_manager: faGear,
  registration: faStapler,
  directorship: faPenFancy,
} as { [key: string]: IconDefinition };

const Badge: FC<IProps> = ({ type, className }) =>
  type && icon[type] ? (
    <FontAwesomeIcon
      icon={icon[type]}
      className={clsx( 'pl-1 fa-fw', className )}
    />
  ) : null;

export default Badge;
