import { useEffect, type FC, type PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/auths/useAuth';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import AuthenticationModal from './AuthenticationModal';
import CheckinEditorModal from '../EventLayer/CheckinEditorModal';

const pathsBypassingModal = [
  '/',
  '/events',
  '/dystopia-rising',
  '/dystopia-rising/branches',
];

const AuthenticationLayer: FC<PropsWithChildren> = ({ children }) => {
  const { closeModal, openModal, revalidateToken, isRootUserValidated } =
    useAuth();
  const { pathname } = useLocation();
  const { isOrganizationCharacterDemoPath } = useAppNavigations();

  useEffect(() => {
    revalidateToken();
  }, [ revalidateToken ]);

  useEffect(() => {
    if ( isRootUserValidated || isOrganizationCharacterDemoPath ) {
      closeModal();
    } else if ( !pathsBypassingModal.includes( pathname )) {
      openModal({ modal: 'login' });
    }
  }, [
    closeModal,
    isOrganizationCharacterDemoPath,
    isRootUserValidated,
    openModal,
    pathname,
  ]);

  return (
    <div>
      <AuthenticationModal />
      <CheckinEditorModal />
      {children}
    </div>
  );
};

export default AuthenticationLayer;
