import { useEffect } from 'react';
import { UseQueryExecute } from 'urql';
import { useParams } from 'react-router-dom';
import useAuth from './auths/useAuth';

interface IProps {
  refetch: UseQueryExecute;
}
const useRootUserRevalidation = ({ refetch }: IProps ) => {
  const { isRootUserValidated } = useAuth();
  const { organizationSlug, eventId, playerId } = useParams();

  useEffect(() => {
    if ( isRootUserValidated ) {
      refetch();
    }
  }, [ isRootUserValidated, refetch, organizationSlug, eventId, playerId ]);
};

export default useRootUserRevalidation;
