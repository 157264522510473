import { format, isBefore, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import useBranchCorrectiveActions from 'src/hooks/organizations/branches/useBranchCorrectiveActions';

import Loading from 'src/components/0100_loading';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

import 'src/basic-table.css';

const CorrectiveActions: FC = () => {
  const { branchId } = useParams();
  const { linkToPlayer } = useAppNavigations();
  const { correctiveActions, fetching } = useBranchCorrectiveActions({
    branchId: Number( branchId ),
  });

  if ( fetching ) return <Loading />;
  return (
    <div className="p-2">
      <table className="basic-table table-fixed">
        <thead>
          <tr className="sticky top-0 midtone-box z-10">
            <th>ID</th>
            <th className="min-w-[128px]">Issued At</th>
            <th className="min-w-[64px]">Player</th>
            <th className="min-w-[128px]">Player Name</th>
            <th className="min-w-[64px]">Issuer</th>
            <th className="min-w-[128px]">Issuer Name</th>
            <th className="min-w-[128px]">Type</th>
            <th className="min-w-[128px]">Starts At</th>
            <th className="min-w-[128px]">Ends At</th>
            <th className="min-w-[320px]">Reason</th>
          </tr>
        </thead>
        <tbody>
          {correctiveActions
            .sort(( a, b ) =>
              isBefore( parseISO( a.createdAt ), parseISO( b.createdAt )) ? 1 : -1,
            )
            .map( x => (
              <tr key={x.id}>
                <td className="text-right">
                  <Link
                    to={linkToPlayer({
                      playerId: x.user.id,
                      section: 'corrective_actions',
                    })}
                    className="underline"
                  >
                    {x.id}
                  </Link>
                </td>
                <td>{format( parseISO( x.createdAt ), 'yyyy-MM-dd' )}</td>
                <td className="text-right">
                  <Link
                    to={linkToPlayer({ playerId: x.user.id })}
                    className="underline"
                  >
                    {x.user.id}
                  </Link>
                </td>
                <td>{x.user.fullName}</td>
                <td className="text-right">
                  <Link
                    to={linkToPlayer({ playerId: x.issuer.id })}
                    className="underline"
                  >
                    {x.issuer.id}
                  </Link>
                </td>
                <td>{x.issuer.fullName}</td>
                <td>{startCase( x.kind )}</td>
                <td>
                  {x.startsAt
                    ? format( parseISO( x.startsAt ), 'yyyy-MM-dd' )
                    : 'Indefinite'}
                </td>
                <td>
                  {x.endsAt
                    ? format( parseISO( x.endsAt ), 'yyyy-MM-dd' )
                    : 'Indefinite'}
                </td>
                <td>{x.reason}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CorrectiveActions;
