import { getItems } from 'src/graphql/queries/items.graphql';
import {
  IGetItemsQuery,
  IGetItemsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';

const useItems = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemsQuery,
    IGetItemsQueryVariables
  >({
    query: getItems,
    requestPolicy: 'cache-and-network',
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItems;
