import { FC } from 'react';
import {
  faBan,
  faPencilRuler,
  faRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatDistanceToNow, isFuture, isPast } from 'date-fns';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useEvent from 'src/hooks/events/useEvent';
import { IEventWithAttendance } from 'src/hooks/events/useEventsWithAttendances';
import { Link } from 'react-router-dom';

interface IProps {
  event: Pick<IEventWithAttendance, 'id' | 'registrationOpensAt'>;
  isExpanded: boolean;
}

const Actions: FC<IProps> = ({ event: propEvent, isExpanded }) => {
  const { linkToEvent } = useAppNavigations();
  const { event: fetchedEvent } = useEvent({
    id: isExpanded ? Number( propEvent.id ) : null,
  });

  const event = { ...propEvent, ...fetchedEvent };
  const availableActions = {
    checkin:
      ( event.registrationOpensAt &&
        isPast( event.registrationOpensAt ) &&
        isFuture( event.endsAt )) ||
      event.isEventRegistrar,
    manage: event.isEventManager,
  };

  return (
    <div className="pt-2">
      <div className="px-2 pb-2 text-juno-gray-50">{event.name}</div>
      {event.registrationOpensAt && isFuture( event.registrationOpensAt ) && (
        <div className="px-2 pb-2">{`Registration opens ${format(
          event.registrationOpensAt,
          'MMM d',
        )} (${formatDistanceToNow( event.registrationOpensAt, {
          addSuffix: true,
        })})`}</div>
      )}
      <div className="flex justify-center items-center gap-2 pb-3">
        {availableActions.manage && (
          <Link
            to={linkToEvent({ eventId: event.id })}
            className="text-juno-gray-50"
          >
            <FontAwesomeIcon icon={faPencilRuler} />
          </Link>
        )}
        {availableActions.checkin && (
          <Link
            to={linkToEvent({ eventId: event.id, section: 'checkin' })}
            className="text-juno-gray-50"
          >
            <FontAwesomeIcon icon={faRightToBracket} />
          </Link>
        )}
        {Object.values( availableActions ).filter( x => x ).length === 0 && (
          <FontAwesomeIcon icon={faBan} />
        )}
      </div>
    </div>
  );
};

export default Actions;
