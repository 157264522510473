import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  className?: string;
  to?: string;
  onClick?: () => void;
}

const LinkOrButton: FC<PropsWithChildren<IProps>> = ({
  className,
  to,
  onClick,
  children,
  ...props
}) => {
  if ( to ) {
    return (
      <Link to={to} className={className} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" className={className} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default LinkOrButton;
