import { getBranchCorrectiveActions } from 'src/graphql/queries/branches.graphql';
import {
  IGetBranchCorrectiveActionsQuery,
  IGetBranchCorrectiveActionsQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import useRootUserRevalidation from 'src/hooks/useRootUserRevalidation';
import { useQuery } from 'urql';

const useBranchCorrectiveActions = ({ branchId }: { branchId: number }) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetBranchCorrectiveActionsQuery,
    IGetBranchCorrectiveActionsQueryVariables
  >({
    query: getBranchCorrectiveActions,
    variables: { branchId },
    requestPolicy: 'cache-and-network',
    pause: branchId < 1,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    correctiveActions: data?.branch?.correctiveActions || [],
  };
};

export default useBranchCorrectiveActions;
