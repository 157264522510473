import { FC, useCallback, useEffect, useState } from 'react';
import { Xwrapper } from 'react-xarrows';
import { groupBy } from 'lodash';
import Title from 'src/components/0100_title';
import { useFormContext } from 'react-hook-form';
import Edge from './Edge';
import skillData, { TProfession } from './data';
import CategoryGroup from './CategoryGroup';
import WeaponSkillGroup from './WeaponSkillGroup';
import LoreGroup from './LoreGroup';
import useSkillFinder from '../hooks/useSkillFinder';

type TLinkPair = {
  identity: { from: string; to: string };
  sibling?: { from: string; to: string };
};

interface IProps {
  canEdit: boolean;
}

const SkillBuilder: FC<IProps> = ({ canEdit }) => {
  const categoryGroup = groupBy( skillData, x => x.category );
  const { setValue, watch } = useFormContext();
  const { showAcquiredOnly } = watch();
  const [ showEdges, setShowEdges ] = useState( false );
  const { maybeFilterSkill } = useSkillFinder();
  const [ highlightLinkPairs, setHighlightLinkPairs ] = useState<
    TLinkPair[] | null
  >( null );
  const edges = skillData.filter( x => !!x.leadsTo );
  const professionEdges = skillData.filter( x => x.isProfession && !!x.skills );
  const shouldHighlightEdge = useCallback(
    ( source: string, target: string ) => {
      if ( !highlightLinkPairs ) return false;

      return highlightLinkPairs.some( highlightLinkPair => {
        const { identity, sibling } = highlightLinkPair;

        return (
          ( identity.from === source && identity.to === target ) ||
          ( sibling && sibling.from === source && sibling.to === target )
        );
      });
    },
    [ highlightLinkPairs ],
  );

  const handleBaseSkillMouseOver = useCallback(( originName: string ) => {
    const originSkill = skillData.find( x => x.name === originName );
    const profession = skillData.find(
      x => x.isProfession && x.name === originSkill?.leadsTo,
    ) as TProfession | undefined;

    if ( profession ) {
      const sibling = skillData.find(
        x => x.leadsTo === profession.name && x.name !== originName,
      );
      setHighlightLinkPairs([
        {
          identity: { from: originName, to: profession.name },
          sibling: sibling
            ? { from: sibling.name, to: profession.name }
            : undefined,
        },
        ...profession.skills.map( professionSkill => ({
          identity: {
            from: `${profession.name}-lock`,
            to: professionSkill.name,
          },
        })),
        ...( originName === 'Education'
          ? [{ identity: { from: 'Education', to: 'loreCount' }}]
          : []),
      ]);
    } else {
      setHighlightLinkPairs( null );
    }
  }, []);

  const handleProfessionMouseOver = useCallback(( professionName: string ) => {
    const baseSkills = skillData.filter( x => x.leadsTo === professionName );
    const profession = skillData.find(
      x => x.isProfession && x.name === professionName,
    ) as TProfession | undefined;

    setHighlightLinkPairs([
      ...( baseSkills && baseSkills.length > 0
        ? [
            {
              identity: { from: baseSkills[0].name, to: professionName },
              sibling: baseSkills[1]
                ? { from: baseSkills[1].name, to: professionName }
                : undefined,
            },
          ]
        : []),
      ...( profession
        ? profession.skills.map( professionSkill => ({
            identity: {
              from: `${professionName}-lock`,
              to: professionSkill.name,
            },
          }))
        : []),
    ]);
  }, []);

  const handleNodeMouseOut = useCallback(() => setHighlightLinkPairs( null ), []);

  useEffect(() => {
    setShowEdges( false );
  }, [ showAcquiredOnly ]);

  useEffect(() => {
    if ( !showEdges ) {
      setTimeout(() => setShowEdges( true ), 250 );
    }
  }, [ showEdges ]);

  return (
    <Xwrapper>
      <div className="flex justify-end w-full mt-8">
        <button
          className="underline"
          type="button"
          onClick={() => {
            setValue( 'showAcquiredOnly', !showAcquiredOnly );
          }}
        >
          {showAcquiredOnly
            ? 'Showing Only Acquired Skills'
            : 'Showing All Skills'}
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <Title variant="heading" title="Default Skills" />
          <WeaponSkillGroup
            canEdit={canEdit}
            skills={categoryGroup.default}
            onProfessionMouseOver={x => handleProfessionMouseOver( x )}
            onNodeMouseOut={handleNodeMouseOut}
          />
        </div>
        <div>
          <Title variant="heading" title="Impact Skills" />
          <div className="grid grid-cols-1 gap-2">
            <CategoryGroup
              canEdit={canEdit}
              category="impact"
              skills={categoryGroup.impact.filter( x => !x.isAberrant )}
              onBaseSkillMouseOver={x => handleBaseSkillMouseOver( x )}
              onProfessionMouseOver={x => handleProfessionMouseOver( x )}
              onNodeMouseOut={handleNodeMouseOut}
            />
            <CategoryGroup
              isAberrant
              canEdit={canEdit}
              category="impact"
              skills={categoryGroup.impact.filter( x => x.isAberrant )}
              onBaseSkillMouseOver={x => handleBaseSkillMouseOver( x )}
              onProfessionMouseOver={x => handleProfessionMouseOver( x )}
              onNodeMouseOut={handleNodeMouseOut}
            />
          </div>
        </div>
        <div>
          <Title variant="heading" title="Development Skills" />
          <div className="grid grid-cols-1 gap-2">
            <CategoryGroup
              isAberrant
              canEdit={canEdit}
              category="development"
              skills={categoryGroup.development.filter( x => x.isAberrant )}
              onBaseSkillMouseOver={x => handleBaseSkillMouseOver( x )}
              onProfessionMouseOver={x => handleProfessionMouseOver( x )}
              onNodeMouseOut={handleNodeMouseOut}
            />
            <CategoryGroup
              canEdit={canEdit}
              category="development"
              skills={categoryGroup.development.filter(
                x => !x.isAberrant && x.name !== 'Education' && !x.isPairwise,
              )}
              onBaseSkillMouseOver={x => handleBaseSkillMouseOver( x )}
              onProfessionMouseOver={x => handleProfessionMouseOver( x )}
              onNodeMouseOut={handleNodeMouseOut}
            />
            <CategoryGroup
              isEducation
              canEdit={canEdit}
              category="development"
              skills={categoryGroup.development.filter(
                x => x.name === 'Education',
              )}
              onBaseSkillMouseOver={x => handleBaseSkillMouseOver( x )}
              onProfessionMouseOver={x => handleProfessionMouseOver( x )}
              onNodeMouseOut={handleNodeMouseOut}
            />
          </div>
        </div>
        {maybeFilterSkill( 'Education' ) && (
          <div className="flex justify-center w-full">
            <LoreGroup canEdit={canEdit} />
          </div>
        )}
      </div>
      {showEdges && (
        <div className="relative">
          {edges
            .filter(
              x => maybeFilterSkill( x.name ) && maybeFilterSkill( x.leadsTo! ),
            )
            .map( x => (
              <Edge
                key={`${x.name}-${x.leadsTo}`}
                start={x.name}
                end={x.leadsTo!}
                {...x}
                isHighlighted={shouldHighlightEdge( x.name, x.leadsTo! )}
              />
            ))}
          <div>
            {( professionEdges as TProfession[])
              .filter( x => maybeFilterSkill( x.name ))
              .map( profession =>
                profession.skills
                  .filter( x => maybeFilterSkill( x.name ))
                  .map( professionSkill => (
                    <Edge
                      key={`${profession.name}-lock-${professionSkill.name}`}
                      start={`${profession.name}-lock`}
                      end={professionSkill.name}
                      startAnchor="bottom"
                      endAnchor="left"
                      {...profession}
                      isHighlighted={shouldHighlightEdge(
                        `${profession.name}-lock`,
                        professionSkill.name,
                      )}
                    />
                  )),
              )}
          </div>
          {[ 'body', 'mind' ]
            .map( stat => categoryGroup.default.map( skill => [ stat, skill.name ]))
            .flat()
            .filter( x => maybeFilterSkill( x[1]))
            .map( x => (
              <Edge
                key={`${x[0]}-${x[1]}`}
                category="default"
                start={x[0]}
                end={x[1]}
                dashness={{ strokeLen: 10, nonStrokeLen: 10 }}
              />
            ))}
          {maybeFilterSkill( 'Education' ) && (
            <Edge
              key="education-lore"
              start="Education"
              end="loreCount"
              endAnchor="top"
              isHighlighted={shouldHighlightEdge( 'Education', 'loreCount' )}
            />
          )}
        </div>
      )}
    </Xwrapper>
  );
};

export default SkillBuilder;
