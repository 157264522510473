import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePermission from 'src/hooks/permissions/usePermissions';
import ByTicket from './ByTicket';

const Tickets: FC = () => {
  const { eventId } = useParams();
  const { isPermitted: canAccessTicketingPage } = usePermission({
    action: 'checkin_character',
    eventId: Number( eventId ),
  });
  const [ displayMode, setDisplayMode ] = useState( 'byTicket' );

  if ( !canAccessTicketingPage ) return null;

  return <ByTicket />;
};

export default Tickets;
