import { FC } from 'react';
import useCharacterBuilder from '../hooks/useCharacterBuilder';
import Node from '../SkillBuilder/Node';
import BidirectionalNode from './BidirectionalNode';

interface IProps {
  canEdit: boolean;
  canEditStartingInfection: boolean;
  isFreeform: boolean;
  showLifecycle?: boolean;
}

const ResolveInfection: FC<IProps> = ({
  canEdit,
  canEditStartingInfection,
  isFreeform,
  showLifecycle,
}) => {
  const { watch } = useCharacterBuilder({});
  const {
    stats: { infection },
    currentLife,
    lifeGain,
    lifeLoss,
    lifeCapacity,
  } = watch();

  return (
    <div className="grid grid-cols-1 gap-4">
      <Node id={-2} name="Resolve" category="stat" canEdit={canEdit} />
      <Node
        id={-1}
        name="Infection"
        category="stat"
        canEdit={canEdit && canEditStartingInfection}
        underlayLevel={lifeCapacity}
      />
      {!showLifecycle && canEdit && !canEditStartingInfection && (
        <div className="text-sm opacity-50">
          Starting Infection cannot be changed once a Character is unstaged.
          Please use Event Checkout to record changes to Infection.
        </div>
      )}
      <BidirectionalNode
        subtract={isFreeform ? 0 : lifeLoss}
        add={isFreeform ? infection + 3 : lifeGain + infection + 3}
        current={isFreeform ? infection + 3 : currentLife}
        max={isFreeform ? infection + 3 : lifeCapacity}
      />
    </div>
  );
};

export default ResolveInfection;
