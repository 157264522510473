import { Outlet } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import useBranch from 'src/hooks/organizations/branches/useBranch';

const Branch = () => {
  const { data } = useBranch({});

  return (
    <div>
      <Title title={data?.branch?.name ?? <Loading />} />
      <Outlet />
    </div>
  );
};

export default Branch;
