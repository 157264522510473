import { FC, PropsWithChildren, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useOutsideClickRef } from 'rooks';
import clsx from 'clsx';
import Tab, { IProps } from '../0200_tab';
import DropdownPanel from '../0100_dropdown_panel';

const ResponsiveTabGroup: FC<PropsWithChildren<IProps>> = ({
  label,
  children,
}) => {
  const [ isExpanded, setIsExpanded ] = useState( false );
  const [ ref ] = useOutsideClickRef(() => setIsExpanded( false ));

  return (
    <>
      <div
        ref={ref}
        role="button"
        tabIndex={0}
        className="block lg:hidden z-10"
        onClick={() => {
          setIsExpanded( x => !x );
        }}
        onKeyDown={() => setIsExpanded( x => !x )}
      >
        <div className="flex justify-end w-48">
          <Tab
            className="justify-end mt-1"
            label={
              <>
                <span className="pr-2">{label}</span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={clsx(
                    '-mt-1 origin-center transition-transform duration-300',
                    isExpanded ? '-scale-y-100' : 'rotate-0',
                  )}
                />
              </>
            }
          />
        </div>

        <DropdownPanel isExpanded={isExpanded} className="w-48">
          {children}
        </DropdownPanel>
      </div>
      <div className="hidden lg:flex">{children}</div>
    </>
  );
};

export default ResponsiveTabGroup;
