import { getCharacterXp } from 'src/graphql/queries/characters.graphql';
import {
  IGetCharacterXpQuery,
  IGetCharacterXpQueryVariables,
} from 'src/graphql/queries/characters.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useCharacterXp = ({
  playerId,
  characterId,
}: {
  playerId: number;
  characterId: number;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetCharacterXpQuery,
    IGetCharacterXpQueryVariables
  >({
    query: getCharacterXp,
    variables: { playerId, characterId },
    requestPolicy: 'cache-and-network',
    pause: !( playerId > 0 && characterId > 0 ),
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    xps: data?.user?.character?.xpAudits || [],
  };
};

export default useCharacterXp;
