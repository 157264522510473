import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'src/components/0100_button';

import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import {
  checkin as checkinMutation,
  rejectCheckin,
  updateCheckin,
} from 'src/graphql/mutations/events.graphql';
import {
  ICheckinMutation,
  ICheckinMutationVariables,
  IDestroyEventAttendeeMutation,
  IDestroyEventAttendeeMutationVariables,
  IUpdateCheckinMutation,
  IUpdateCheckinMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import { useMutation } from 'urql';
import ResponseBox from 'src/components/0100_response_box';
import usePermission from 'src/hooks/permissions/usePermissions';
import Loading from 'src/components/0100_loading';
import useTicketShiftRequirement from '../hooks/useTicketShiftRequirement';
import useCharacterChangeDetection from '../hooks/useCharacterChangeDetection';

const Execution: FC = () => {
  const { event, eventAttendee, player, refetch } = useEventAttendee();
  const [ successMessage, setSuccessMessage ] = useState<string | null>( null );
  const [ failureMessage, setFailureMessage ] = useState<string | null>( null );
  const [ isCancelling, setIsCancelling ] = useState( false );
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number( event?.id ),
  });
  const { buttonState } = useButtonStates();
  const {
    watch,
    formState: { isDirty },
  } = useFormContext();
  const {
    ticketId,
    characterId,
    characterIds,
    buildGrowth,
    printRequestOnCharacterIds,
    shiftIds,
    isPaid,
    isAttending,
  } = watch();
  const { isShiftRequirementsSatisfied } = useTicketShiftRequirement();
  const { activeCharacterIds, hasCharactersChanged } =
    useCharacterChangeDetection();
  const isDirtyWithCharacterChange = isDirty || hasCharactersChanged;

  const [ checkinResult, checkin ] = useMutation<
    ICheckinMutation,
    ICheckinMutationVariables
  >( checkinMutation );
  const [ , reject ] = useMutation<
    IDestroyEventAttendeeMutation,
    IDestroyEventAttendeeMutationVariables
  >( rejectCheckin );

  const [ updateResult, update ] = useMutation<
    IUpdateCheckinMutation,
    IUpdateCheckinMutationVariables
  >( updateCheckin );

  const isValidCheckin =
    ticketId &&
    ( event?.activeMembership ? characterIds.length > 0 : !!characterId ) &&
    isShiftRequirementsSatisfied;

  const checkinParams = useMemo(
    () => ({
      ticketId,
      playerId: Number( player?.id ),
      characters: event?.activeMembership
        ? activeCharacterIds.map( id => ({ id }))
        : [{ id: characterId }],
      printRequestOnCharacterIds: event?.activeMembership
        ? printRequestOnCharacterIds
        : [ characterId ],
      buildGrowth,
      shiftIds,
      isPaid,
      isAttending,
    }),
    [
      activeCharacterIds,
      buildGrowth,
      characterId,
      event?.activeMembership,
      isAttending,
      isPaid,
      player?.id,
      printRequestOnCharacterIds,
      shiftIds,
      ticketId,
    ],
  );

  const handleResponse = useCallback(
    ({
      operation,
      error,
    }: {
      operation: 'update' | 'checkin';
      error?: string | null;
    }) => {
      if ( error ) {
        setFailureMessage( error );
      } else {
        refetch();
        setSuccessMessage(
          operation === 'update' ? 'Checkin Updated' : 'Checkin successful',
        );
      }
    },
    [ refetch ],
  );

  const handleCheckin = useCallback(() => {
    setSuccessMessage( null );
    setFailureMessage( null );

    if ( eventAttendee ) {
      update({ ...checkinParams, eventAttendeeId: eventAttendee.id }).then(
        res =>
          handleResponse({
            operation: 'update',
            error: res.data?.updateEventAttendee?.error,
          }),
      );
    } else {
      checkin( checkinParams ).then( res => {
        handleResponse({
          operation: 'checkin',
          error: res.data?.checkin?.error,
        });
      });
    }
  }, [ checkin, checkinParams, eventAttendee, handleResponse, update ]);

  const handleRejection = useCallback(() => {
    setSuccessMessage( null );
    setFailureMessage( null );
    setIsCancelling( true );

    if ( eventAttendee ) {
      reject({ eventAttendeeId: eventAttendee.id })
        .then( res => {
          if ( res.data?.destroyEventAttendee?.error ) {
            setFailureMessage( res.data.destroyEventAttendee.error );
          } else {
            setSuccessMessage( 'Checkin Cancelled' );
            refetch();
          }
        })
        .finally(() => setIsCancelling( false ));
    }
  }, [ eventAttendee, refetch, reject ]);

  useEffect(() => {
    if ( isDirty ) {
      setSuccessMessage( null );
      setFailureMessage( null );
    }
  }, [ isDirty ]);

  return (
    <div className="grid pb-8 gap-4 min-h-[192px]">
      <div className="flex justify-between items-center gap-4">
        {isCancelling ? (
          <div className="flex items-center gap-4">
            Cancelling Checkin...
            <Loading size="small" />
          </div>
        ) : canAssistCheckin && eventAttendee ? (
          <ConfirmDropdown
            text="Reject Checkin"
            align="left"
            confirmLabel="Reject"
            onConfirm={handleRejection}
          />
        ) : (
          <div />
        )}
        <Button
          className="min-w-[160px] py-2"
          defaultLabel={eventAttendee ? 'Update' : 'Check In'}
          stateLabel={{
            loading: eventAttendee ? 'Updating Checkin...' : 'Checking In...',
          }}
          state={buttonState({
            isHighlight: true,
            isSuccessful: false,
            isDirty: eventAttendee ? isDirtyWithCharacterChange : true,
            isFetching: checkinResult.fetching || updateResult.fetching,
            isValid: isValidCheckin,
          })}
          onClick={handleCheckin}
        />
      </div>
      {successMessage && (
        <div>
          <ResponseBox type="success">{successMessage}</ResponseBox>
        </div>
      )}
      {failureMessage && (
        <div>
          <ResponseBox type="error">{failureMessage}</ResponseBox>
        </div>
      )}
    </div>
  );
};

export default Execution;
