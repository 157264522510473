import { gql } from 'urql';

export const generateAuthTokenMutation = gql`
  mutation GenerateAuthToken($username: String!, $password: String!) {
    generateAuthToken(input: { username: $username, password: $password }) {
      user {
        id
        firstName
        lastName
        fullName
        emailAddress
        organizations {
          id
          name
          role
          slug
          shorthand
          branch {
            id
            name
            shorthand
          }
        }
      }
      token
      error
    }
  }
`;

export const destroyAuthTokenMutation = gql`
  mutation DestroyAuthToken {
    destroyAuthToken(input: {}) {
      error
    }
  }
`;
