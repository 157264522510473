import {
  faCalendarDays,
  faCheck,
  faGavel,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { addMonths, format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Textarea from 'src/components/0100_textarea';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import DatePicker from 'src/components/0200_datepicker';
import BranchSelect from 'src/components/0400_branch_select';
import CorrectiveActionTypeSelect from 'src/components/0400_corrective_action_type_select';
import {
  createPlayerCorrectiveAction,
  destroyPlayerCorrectiveAction,
  updatePlayerCorrectiveAction,
} from 'src/graphql/mutations/players.graphql';
import {
  ICreatePlayerCorrectiveActionMutation,
  ICreatePlayerCorrectiveActionMutationVariables,
  IDestroyPlayerCorrectiveActionMutation,
  IDestroyPlayerCorrectiveActionMutationVariables,
  IUpdatePlayerCorrectiveActionMutation,
  IUpdatePlayerCorrectiveActionMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import { IBranch, ICorrectiveAction, IUser } from 'src/graphql/types';
import useRootUser from 'src/hooks/players/useRootUser';
import { useMutation } from 'urql';

interface IProps {
  correctiveAction?: Pick<
    ICorrectiveAction,
    'id' | 'reason' | 'kind' | 'startsAt' | 'endsAt'
  > & {
    branch: Pick<IBranch, 'id' | 'shorthand'>;
    issuer: Pick<IUser, 'id' | 'fullName'>;
  };
  canCreateAndUpdate?: boolean;
  canDelete?: boolean;
  hasWriteAccess?: boolean;
}

const CorrectiveAction: FC<IProps> = ({
  correctiveAction,
  canCreateAndUpdate,
  canDelete,
  hasWriteAccess,
}) => {
  const { playerId } = useParams();
  const { homeBranch } = useRootUser();
  const [ isDeleted, setIsDeleted ] = useState( false );
  const isActive =
    !correctiveAction?.endsAt || parseISO( correctiveAction.endsAt ) > new Date();
  const methods = useForm({
    defaultValues: {
      correctiveActionId: correctiveAction?.id || 0,
      branchId: correctiveAction?.branch.id || 0,
      kind: correctiveAction?.kind || 'refusal_of_service',
      reason: correctiveAction?.reason || '',
      startsAt: correctiveAction?.startsAt || new Date().toISOString(),
      endsAt: correctiveAction?.endsAt || null,
    },
  });
  const [ createResult, create ] = useMutation<
    ICreatePlayerCorrectiveActionMutation,
    ICreatePlayerCorrectiveActionMutationVariables
  >( createPlayerCorrectiveAction );
  const [ updateResult, update ] = useMutation<
    IUpdatePlayerCorrectiveActionMutation,
    IUpdatePlayerCorrectiveActionMutationVariables
  >( updatePlayerCorrectiveAction );
  const [ destroyResult, destroy ] = useMutation<
    IDestroyPlayerCorrectiveActionMutation,
    IDestroyPlayerCorrectiveActionMutationVariables
  >( destroyPlayerCorrectiveAction );

  const { register, reset, setValue, watch } = methods;

  const { correctiveActionId, branchId, kind, reason, startsAt, endsAt } =
    watch();
  const isNew = correctiveActionId === 0;
  const isValidCreate = branchId > 0 && reason.trim().length > 0;

  const handleCreate = useCallback(() => {
    create({ ...watch(), playerId: Number( playerId ) }).then(() => {
      reset({ ...watch(), reason: '' });
    });
  }, [ create, watch, playerId, reset ]);

  useEffect(() => {
    if ( isNew && homeBranch ) {
      setValue( 'branchId', homeBranch.id );
    }
  }, [ isNew, homeBranch, setValue ]);

  return (
    <AnimatePresence>
      {!isDeleted && (
        <FormProvider {...methods}>
          <motion.tr
            className={clsx(
              'origin-top',
              !isActive && 'bg-juno-gray-700 text-juno-gray-200',
              {
                'bg-juno-orange-200': [
                  'suspension',
                  'refusal_of_service',
                ].includes( correctiveAction?.kind || '' ),
              },
            )}
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0, scaleY: 0 }}
          >
            {correctiveAction ? (
              <>
                <td className="p-1">{startCase( correctiveAction.kind )}</td>
                <td className="p-1">{correctiveAction.branch.shorthand}</td>
                <td className="p-1 whitespace-nowrap">
                  {correctiveAction.startsAt &&
                    format( parseISO( correctiveAction.startsAt ), 'yyyy-MM-dd' )}
                </td>
                {canCreateAndUpdate ? (
                  <td className="p-1 flex items-center">
                    <FontAwesomeIcon
                      icon={endsAt ? faGavel : faCalendarDays}
                      className="cursor-pointer pr-2"
                      onClick={() => {
                        if ( endsAt ) {
                          setValue( 'endsAt', null );
                          update({ correctiveActionId, endsAt: null });
                        } else {
                          setValue( 'endsAt', new Date().toISOString());
                          update({
                            correctiveActionId,
                            endsAt: new Date().toISOString(),
                          });
                        }
                      }}
                    />
                    {endsAt ? (
                      <DatePicker
                        date={parseISO( endsAt )}
                        {...register( 'endsAt' )}
                        onUpdate={date => {
                          if ( date ) {
                            setValue( 'endsAt', date.toISOString());
                            update({
                              correctiveActionId,
                              endsAt: date.toISOString(),
                            });
                          }
                        }}
                      />
                    ) : (
                      <span>Permanent</span>
                    )}
                  </td>
                ) : (
                  <td className="p-1 whitespace-nowrap">
                    {correctiveAction.endsAt
                      ? format( parseISO( correctiveAction.endsAt ), 'yyyy-MM-dd' )
                      : 'Permanent'}
                  </td>
                )}

                <td className="p-1">
                  <div className="flex items-center">
                    <div className="mr-2">{`Issued By #${correctiveAction.issuer.id} ${correctiveAction.issuer.fullName}`}</div>

                    {updateResult.data?.updateCorrectiveAction && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {updateResult.fetching && <Loading size="small" />}
                  </div>
                  {canCreateAndUpdate ? (
                    <Textarea
                      height="h-16"
                      width="w-80"
                      defaultValue={reason}
                      {...register( 'reason' )}
                      onBlur={x => {
                        setValue( 'reason', x.target.value );
                        update({
                          correctiveActionId,
                          reason: x.target.value,
                        });
                      }}
                    />
                  ) : (
                    correctiveAction.reason
                  )}
                </td>

                {hasWriteAccess && (
                  <td className="p-1 pb-0 text-center">
                    {canDelete && (
                      <ConfirmDropdown
                        onConfirm={() => {
                          setIsDeleted( true );
                          destroy({ correctiveActionId });
                        }}
                      />
                    )}
                  </td>
                )}
              </>
            ) : (
              <>
                <td className="p-1">
                  <CorrectiveActionTypeSelect
                    selectedValue={kind}
                    {...register( 'kind' )}
                  />
                </td>
                <td className="p-1">
                  <BranchSelect
                    owned
                    selectedValue={branchId}
                    width="w-20"
                    {...register( 'branchId' )}
                  />
                </td>
                <td className="p-1 pt-1.5">
                  <DatePicker
                    date={parseISO( startsAt )}
                    onUpdate={date =>
                      date && setValue( 'startsAt', date.toISOString())
                    }
                  />
                </td>
                <td className="p-1 pt-1.5 flex items-center">
                  <FontAwesomeIcon
                    icon={endsAt ? faGavel : faCalendarDays}
                    className="cursor-pointer pr-2"
                    onClick={() => {
                      if ( endsAt ) {
                        setValue( 'endsAt', null );
                      } else {
                        setValue(
                          'endsAt',
                          addMonths( new Date(), 1 ).toISOString(),
                        );
                      }
                    }}
                  />
                  {endsAt ? (
                    <DatePicker
                      date={parseISO( endsAt )}
                      onUpdate={date =>
                        date && setValue( 'endsAt', date.toISOString())
                      }
                    />
                  ) : (
                    'Permanent'
                  )}
                </td>
                <td className="p-1 pb-4">
                  <Textarea
                    height="h-16"
                    width="w-80"
                    placeholder="Briefly explain why..."
                    defaultValue={reason}
                    {...register( 'reason' )}
                    onChange={x => setValue( 'reason', x.target.value )}
                  />
                </td>
                <td className="p-1 pb-0 text-center">
                  {createResult.fetching ? (
                    <Loading size="small" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlus}
                      className={clsx(
                        isValidCreate
                          ? 'cursor-pointer'
                          : 'text-juno-gray-700 cursor-not-allowed',
                      )}
                      onClick={handleCreate}
                    />
                  )}
                </td>
              </>
            )}
          </motion.tr>
        </FormProvider>
      )}
    </AnimatePresence>
  );
};

export default CorrectiveAction;
