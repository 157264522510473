import { getItemConsolidatedAttributes } from 'src/graphql/queries/items.graphql';
import {
  IGetItemConsolidatedAttributesQuery,
  IGetItemConsolidatedAttributesQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useItemConsolidatedAttributes = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemConsolidatedAttributesQuery,
    IGetItemConsolidatedAttributesQueryVariables
  >({ query: getItemConsolidatedAttributes, requestPolicy: 'cache-only' });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItemConsolidatedAttributes;
