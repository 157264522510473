import { isBefore, parseISO } from 'date-fns';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import AugmentedInput from 'src/components/0200_augmented_input';
import BranchSelect from 'src/components/0400_branch_select';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useBranchCaps from 'src/hooks/organizations/branches/useBranchCaps';

const headerRow = `${[
  'ID',
  'Recipient ID',
  'Recipient Name',
  'Grantor ID',
  'Grantor Name',
  'Status',
  'Amount',
  'Reason',
  'Created At',
].join( ',' )}\n`;

const Caps: FC = () => {
  const navigate = useNavigate();
  const { branchId } = useParams();
  const { linkToCaps } = useAppNavigations();
  const { caps, fetching } = useBranchCaps({ branchId: Number( branchId ) });
  const methods = useForm({ defaultValues: { branchId: 0 }});
  const { register, watch } = methods;
  const { branchId: selectedBranchId } = watch();

  useEffect(() => {
    if ( selectedBranchId && Number( branchId ) !== Number( selectedBranchId )) {
      navigate( linkToCaps({ branchId: selectedBranchId }));
    }
  }, [ branchId, linkToCaps, navigate, selectedBranchId ]);

  return (
    <FormProvider {...methods}>
      <Title title="CAPS" />
      <AugmentedInput title="Branch">
        <BranchSelect
          owned
          selectedValue={Number( branchId )}
          {...register( 'branchId' )}
        />
      </AugmentedInput>
      {fetching ? (
        <Loading />
      ) : (
        <div className="overflow-auto max-h-[67vh] max-w-full text-juno-gray-200 text-xs select-all">
          <pre>
            {headerRow +
              caps
                .sort(( a, b ) =>
                  isBefore( parseISO( a.createdAt ), parseISO( b.createdAt ))
                    ? -1
                    : 1,
                )
                .map( cap =>
                  [
                    cap.id,
                    cap.user.id,
                    cap.user.fullName,
                    cap.grantor.id,
                    cap.grantor.fullName,
                    cap.state,
                    cap.amount,
                    cap.reason,
                    cap.createdAt,
                  ].join( ',' ),
                )
                .join( '\n' )}
          </pre>
        </div>
      )}
    </FormProvider>
  );
};

export default Caps;
