import { useQuery } from 'urql';
import {
  IGetPlayerPerksQuery,
  IGetPlayerPerksQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { getPlayerPerks } from 'src/graphql/queries/players.graphql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerPerks = ({ playerId }: { playerId: number }) => {
  const { isRootUserValidated } = useAuth();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerPerksQuery,
    IGetPlayerPerksQueryVariables
  >({
    query: getPlayerPerks,
    variables: { playerId },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( playerId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return { error, fetching: fetching || stale, perks: data?.user?.caps || []};
};

export default usePlayerPerks;
