import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface IProps {
  sheetsToPrint: number;
  attendeesToPrint: number;
  hasAttendees: boolean;
}

const popupPrintDialog = () => window.print();

const Summary: FC<IProps> = ({
  sheetsToPrint,
  attendeesToPrint,
  hasAttendees,
}) => {
  const summary =
    sheetsToPrint > 0
      ? `${sheetsToPrint} Sheets from ${attendeesToPrint} Players to print.`
      : hasAttendees
        ? 'No sheets to print. Try adjusting filters.'
        : 'This Game has no printable sheets.';

  return (
    <div className="flex items-center py-2">
      <div className="w-20 flex justify-center">
        <FontAwesomeIcon icon={faPrint} className="text-2xl p-2" />
      </div>
      <div className="w-full">
        <div className="font-bold">{summary}</div>

        {sheetsToPrint > 0 && (
          <div>
            Press{' '}
            <u
              role="button"
              tabIndex={0}
              onClick={popupPrintDialog}
              onKeyDown={popupPrintDialog}
            >
              CTRL+P
            </u>{' '}
            on PC or{' '}
            <u
              role="button"
              tabIndex={0}
              onClick={popupPrintDialog}
              onKeyDown={popupPrintDialog}
            >
              ⌘+P
            </u>{' '}
            on Mac to open Print Dialog.
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
