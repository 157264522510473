import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, InputHTMLAttributes, forwardRef } from 'react';

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  isLocked?: boolean;
  height?: string;
  width?: string;
}

const Textarea: FC<IProps> = forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      disabled,
      isLocked,
      height = 'h-48',
      width = 'w-48 sm:w-72 lg:w-96',
      ...props
    },
    ref,
  ) => (
    <div className="flex items-end relative">
      <textarea
        ref={ref}
        className={clsx(
          'rounded py-1 px-2 dark-box border border-juno-gray-700 resize disabled:opacity-50',
          height,
          width,
        )}
        disabled={disabled || isLocked}
        {...props}
      />
      {( disabled || isLocked ) && (
        <div className="absolute top-2 right-2 opacity-50">
          <FontAwesomeIcon icon={faLock} />
        </div>
      )}
    </div>
  ),
);

export default Textarea;
