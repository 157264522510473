import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useEventPrintouts from 'src/hooks/events/useEventPrintouts';
import usePermission from 'src/hooks/permissions/usePermissions';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import Results from './Results';
import Filters from './Filters';

const Printouts: FC = () => {
  const { eventId } = useParams();
  const [ searchParams ] = useSearchParams();
  const isBlankSheet = searchParams.get( 'blank_sheet' ) === 'true';
  const { event, printouts, stale } = useEventPrintouts({
    eventId: Number( eventId ),
    printRequested:
      searchParams.get( 'print_requested' ) === null
        ? undefined
        : searchParams.get( 'print_requested' ) === 'true',
    paid:
      searchParams.get( 'paid' ) === null
        ? undefined
        : searchParams.get( 'paid' ) === 'true',
    homeGame:
      searchParams.get( 'home_game' ) === null
        ? undefined
        : searchParams.get( 'home_game' ) === 'true',
    ticketIds:
      searchParams.get( 'ticket_ids' ) === null
        ? undefined
        : searchParams
            .get( 'ticket_ids' )
            ?.split( ',' )
            .map( x => Number( x )),
  });
  const { isPermitted: canManageEvent } = usePermission({
    action: 'manage_event',
    eventId: Number( eventId ),
  });

  if ( !canManageEvent ) return null;
  if ( !event ) return null;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div className="col-span-1">
        <Filters />
      </div>
      <div className="col-span-1 lg:col-span-2">
        {isBlankSheet ? (
          <div className="printout-container">
            <DystopiaRisingPrintout event={event} />
          </div>
        ) : (
          <Results event={event} printouts={printouts} fetching={stale} />
        )}
      </div>
    </div>
  );
};

export default Printouts;
