import clsx from 'clsx';
import {
  addMonths,
  differenceInCalendarDays,
  eachMonthOfInterval,
  isAfter,
} from 'date-fns';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import Tooltip from '../0100_tooltip';

type TDot = {
  id: number;
  date: Date;
  content: ReactNode;
  className?: string;
};

interface IProps {
  dots: TDot[];
  startDate?: Date;
  endDate?: Date;
}

const PunchTape: FC<IProps> = ({
  dots,
  startDate = addMonths( new Date(), -6 ),
  endDate = addMonths( new Date(), 1 ),
}) => {
  const { linkToEvent } = useAppNavigations();
  const daysInRange = differenceInCalendarDays( startDate, endDate );
  const months = useMemo(
    () =>
      eachMonthOfInterval({ start: startDate, end: endDate }).filter(
        x => isAfter( x, startDate ) && isAfter( endDate, x ),
      ),
    [ endDate, startDate ],
  );
  const position = useCallback(
    ({ point }: { point: Date }) => {
      const daysFromOrigin = differenceInCalendarDays( startDate, point );
      return daysFromOrigin / daysInRange;
    },
    [ daysInRange, startDate ],
  );

  return (
    <div className="w-full gray-box h-8 relative">
      {dots.map( dot => (
        <Link
          key={dot.id}
          to={linkToEvent({ eventId: dot.id })}
          className={clsx(
            'absolute w-2 h-2 top-[11px] z-10 rounded-full group cursor-pointer',
            dot.className,
          )}
          style={{ left: `${position({ point: dot.date }) * 100}%` }}
        >
          <Tooltip>{dot.content}</Tooltip>
        </Link>
      ))}
      {months.map( month => (
        <div
          key={month.toUTCString()}
          className="absolute h-8 w-[1px] z-0 border-l border-slate-700/90"
          style={{ left: `${position({ point: month }) * 100}%` }}
        />
      ))}
      <div
        className="absolute h-8 w-[1px] border border-juno-cyan-200"
        style={{ left: `${position({ point: new Date() }) * 100}%` }}
      />
    </div>
  );
};

export default PunchTape;
