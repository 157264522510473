import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import { updateCharacter } from 'src/graphql/mutations/characters.graphql';
import {
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { IFracture } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  canEdit?: boolean;
  characterId: number;
  fracture?: Pick<IFracture, 'id' | 'name' | 'createdAt' | 'removedAt'>;
}

const Fracture: FC<IProps> = ({ canEdit, characterId, fracture }) => {
  const {
    register,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm({ defaultValues: { name: '' }});
  const { buttonState } = useButtonStates();
  const isNew = fracture === undefined;
  const { name } = watch();

  const [ updateResult, update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >( updateCharacter );

  const handleCreate = useCallback(() => {
    update({
      characterId,
      fracture: {
        name,
      },
    }).then( res => {
      if ( res.data?.updateCharacterData?.character?.fractures ) {
        reset({ name: '' });
      }
    });
  }, [ characterId, name, reset, update ]);

  const handleUpdate = useCallback(() => {
    if ( !canEdit ) return;
    if ( updateResult.fetching ) return;

    update({
      characterId,
      fracture: {
        id: fracture?.id,
        forRemoval: !fracture?.removedAt,
      },
    });
  }, [ canEdit, characterId, fracture, update, updateResult ]);

  return (
    <div className="flex items-center justify-between w-full gap-2">
      {isNew && canEdit && (
        <Input
          placeholder="New Fracture"
          {...register( 'name', { required: true })}
          onEnter={handleCreate}
        />
      )}
      {fracture && (
        <div className="w-full flex justify-between items-center">
          <div>
            <div>{fracture.name}</div>
            <div className="text-sm opacity-75">
              Recorded {format( parseISO( fracture.createdAt ), 'yyyy MMM dd' )}
              {fracture.removedAt &&
                ` - Removed ${format( parseISO( fracture.removedAt ), 'yyyy MMM dd' )}`}
            </div>
          </div>

          {canEdit && !updateResult.fetching && (
            <button type="button" onClick={handleUpdate}>
              <FontAwesomeIcon icon={fracture.removedAt ? faUndo : faTrash} />
            </button>
          )}
          {updateResult.fetching && <Loading size="small" />}
        </div>
      )}

      {isNew && (
        <Button
          className="whitespace-nowrap"
          defaultLabel="Add"
          state={buttonState({
            isHighlight: true,
            isDirty,
            isValid,
            isFetching: updateResult.fetching,
          })}
          stateLabel={{
            loading: 'Adding...',
          }}
          onClick={handleCreate}
        />
      )}
    </div>
  );
};

export default Fracture;
