import { FC } from 'react';
import { startCase } from 'lodash';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const DepositoryHeader: FC = () => {
  const { depositorySection, linkToDepository } = useAppNavigations();

  return (
    <ResponsiveTabGroup label={startCase( depositorySection )}>
      <Tab
        label="Loupe"
        to={linkToDepository({})}
        highlightMode="responsive"
        isActive={depositorySection === 'loupe'}
      />
      <Tab
        label="Tabular"
        to={linkToDepository({ isTabular: true })}
        highlightMode="responsive"
        isActive={depositorySection === 'tabular'}
      />
    </ResponsiveTabGroup>
  );
};

export default DepositoryHeader;
