import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import LineageDropdown from 'src/components/0200_lineage_dropdown';
import FaithSelect from 'src/components/0400_faith_select';
import LineageVariant from './LineageVariant';

interface IProps {
  canEdit: boolean;
}

const LineageFaith: FC<IProps> = ({ canEdit }) => {
  const { register, watch } = useFormContext();
  const { faithId } = watch();

  return (
    <div>
      <div className="grid grid-cols-1 gap-4">
        <LineageDropdown isEditable={canEdit} />
        <LineageVariant canEdit={canEdit} />

        <FaithSelect
          isLocked={!canEdit}
          selectedValue={faithId}
          width="w-full"
          {...register( 'faithId' )}
        />
      </div>
    </div>
  );
};

export default LineageFaith;
