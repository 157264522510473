import { gql } from 'urql';

export const updateCharacter = gql`
  mutation UpdateCharacter(
    $characterId: Int!
    $name: String
    $strainId: Int
    $faithId: Int
    $variant: String
    $loreIds: [Int!]
    $skills: [CharacterSkillInput!]
    $fracture: FractureInput
    $body: Int
    $mind: Int
    $resolve: Int
    $infection: Int
    $status: CharacterStatusEnum
    $playerNotes: String
    $staffNotes: String
  ) {
    updateCharacterData(
      input: {
        characterId: $characterId
        name: $name
        strainId: $strainId
        variant: $variant
        faithId: $faithId
        loreIds: $loreIds
        skills: $skills
        fracture: $fracture
        body: $body
        mind: $mind
        resolve: $resolve
        infection: $infection
        status: $status
        playerNotes: $playerNotes
        staffNotes: $staffNotes
      }
    ) {
      error
      character {
        id
        name
        buildEarned
        buildUsed
        body
        mind
        resolve
        infection
        currentLife
        lifeCapacity
        faithId
        loreIds
        skills {
          id
          characterSkillId
          level
          positions
        }
        fractures {
          id
          name
          createdAt
          removedAt
        }
        strainId
        lineageId
        variant
        status
        staffNotes
        playerNotes
        staffNotes
        createdAt
        version
      }
    }
  }
`;

export const createCharacter = gql`
  mutation CreateCharacter($playerId: Int!, $name: String!) {
    createCharacter(input: { userId: $playerId, name: $name }) {
      error
      user {
        id
        characters {
          id
          name
          status
        }
      }
    }
  }
`;

export const destroyCharacter = gql`
  mutation DestroyCharacter($playerId: Int!, $characterId: Int!) {
    destroyCharacter(input: { userId: $playerId, characterId: $characterId }) {
      error
      user {
        id
        characters {
          id
          name
          status
        }
      }
    }
  }
`;

export const createCharacterEventLifecycle = gql`
  mutation CreateCharacterEventLifecycle(
    $characterId: Int!
    $eventId: Int!
    $kind: LifecycleKindEnum!
    $description: String
  ) {
    createEventLifecycle(
      input: {
        characterId: $characterId
        eventId: $eventId
        kind: $kind
        description: $description
      }
    ) {
      error
      character {
        id
        buildUsed
        currentLife
        lifeCapacity
        lifeGain
        lifeLoss
        lifecycles {
          id
          kind
          description
          createdAt
          deletedAt

          event {
            id
            name
          }
        }
      }
    }
  }
`;

export const updateCharacterEventLifecycle = gql`
  mutation UpdateCharacterEventLifecycle(
    $lifecycleId: Int!
    $description: String
    $isDeleted: Boolean
  ) {
    updateEventLifecycle(
      input: {
        lifecycleId: $lifecycleId
        description: $description
        isDeleted: $isDeleted
      }
    ) {
      error
      character {
        id
        buildUsed
        currentLife
        lifeCapacity
        lifeGain
        lifeLoss
        lifecycles {
          id
          kind
          description
          createdAt
          deletedAt

          event {
            id
            name
          }
        }
      }
    }
  }
`;

export const copyCharacterBuild = gql`
  mutation CopyCharacterBuild(
    $sourceCharacterId: Int!
    $targetCharacterId: Int!
  ) {
    copyCharacterBuild(
      input: {
        sourceCharacterId: $sourceCharacterId
        targetCharacterId: $targetCharacterId
      }
    ) {
      error
      character {
        id
        buildEarned
        buildUsed
        body
        mind
        resolve
        infection
        currentLife
        lifeCapacity
        faithId
        loreIds
        skills {
          id
          characterSkillId
          level
          positions
        }
        fractures {
          id
          name
          createdAt
          removedAt
        }
        strainId
        lineageId
        variant
      }
    }
  }
`;
