import { FC, useCallback } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';
import clsx from 'clsx';
import Tooltip, { ITooltipProps } from './Tooltip';
import useXpData from './hooks/useXpData';

interface IProps {
  responsivelyHidePie?: boolean;
  displayOverage?: boolean;
}

const XpSunBurst: FC<IProps> = ({
  responsivelyHidePie = false,
  displayOverage = false,
}) => {
  const { costs, donutData } = useXpData();
  const tooltip = useCallback(
    ({ name, value }: ITooltipProps ) => <Tooltip name={name} value={value} />,
    [],
  );

  return (
    <div className="h-full w-full relative flex justify-center">
      <div
        className={clsx(
          'absolute h-full w-full',
          responsivelyHidePie && 'hidden sm:block',
        )}
      >
        <div className="flex h-full w-full justify-center items-center flex-wrap text-shadow">
          <div
            className={clsx(
              'text-center backdrop-blur p-4 rounded-full',
              displayOverage && costs.unspentXp < 0
                ? 'text-juno-orange-200'
                : 'text-juno-gray-50',
            )}
          >
            <div className="text-3xl">
              {costs.isOverSpent ? costs.overspentXp : costs.spentXp}
            </div>

            <div
              className={clsx(
                'italic max-w-[64px] leading-4 text-sm',
                displayOverage && costs.unspentXp < 0
                  ? 'text-juno-orange-200'
                  : 'text-juno-cyan-200',
              )}
            >
              {displayOverage && costs.isOverSpent
                ? 'XP Over'
                : displayOverage
                  ? `/${costs.xpEarned}XP`
                  : 'XP'}
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'h-[calc(100vw-16px)] w-[calc(100vw-16px)] sm:h-full sm:w-full',
          responsivelyHidePie && 'hidden sm:block',
        )}
      >
        <ResponsiveSunburst
          data={donutData}
          enableArcLabels
          colors={{ datum: 'data.color' }}
          arcLabel="value"
          arcLabelsSkipAngle={12}
          arcLabelsTextColor="#eee"
          cornerRadius={4}
          borderWidth={0}
          inheritColorFromParent={false}
          tooltip={params =>
            tooltip({ name: String( params.id ), value: params.value })
          }
        />
      </div>
    </div>
  );
};

export default XpSunBurst;
