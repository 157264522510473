import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useTicketFilter = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const ticketIds = searchParams.get( 'ticket_ids' );
  const arrifiedTicketIds = useMemo(
    () => ( ticketIds === null ? [] : ticketIds.split( ',' ).map( x => Number( x ))),
    [ ticketIds ],
  );
  const handleChange = useCallback(
    ( ticketId: number ) => {
      if ( ticketIds === null ) {
        searchParams.set( 'ticket_ids', String( ticketId ));
      } else {
        const arrified = ticketIds.split( ',' );
        if ( arrified.includes( String( ticketId ))) {
          searchParams.set(
            'ticket_ids',
            arrified.filter( x => x !== String( ticketId )).join( ',' ),
          );
        } else {
          searchParams.set(
            'ticket_ids',
            [ ...arrified, String( ticketId ) ].join( ',' ),
          );
        }
      }

      if ( searchParams.get( 'ticket_ids' ) === '' )
        searchParams.delete( 'ticket_ids' );
      setSearchParams( searchParams );
    },
    [ searchParams, setSearchParams, ticketIds ],
  );
  const handleRemoveFilter = useCallback(() => {
    searchParams.delete( 'ticket_ids' );
    setSearchParams( searchParams );
  }, [ searchParams, setSearchParams ]);

  return {
    ticketIds: arrifiedTicketIds,
    handleChange,
    handleRemoveFilter,
  };
};

export default useTicketFilter;
