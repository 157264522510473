import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DropdownPanel from 'src/components/0100_dropdown_panel';
import useAuth from 'src/hooks/auths/useAuth';
import useRootUser from 'src/hooks/players/useRootUser';
import Tab from 'src/components/0200_tab';
import { useOutsideClickRef } from 'rooks';

const OrganizationalTitle = () => {
  const { organizationSlug } = useParams();
  const { handleLogout } = useAuth();
  const { rootUser } = useRootUser();
  const [ isExpanded, setIsExpanded ] = useState( false );
  const [ ref ] = useOutsideClickRef(() => setIsExpanded( false ));

  const currentOrganization = rootUser.organizations.find(
    x => x.slug === organizationSlug,
  );
  const hasMultipleOrganizations = rootUser.organizations.length > 1;

  return (
    <div ref={ref} className="h-full">
      <button
        type="button"
        className="h-full w-full"
        onClick={() => setIsExpanded( x => !x )}
      >
        <div className="flex justify-between items-center">
          {currentOrganization ? (
            <div className="text-left">
              <div className="uppercase text-xs text-juno-gray-200 -mb-1">
                Organization
              </div>
              <div>{currentOrganization?.name || '???'}</div>
            </div>
          ) : (
            <div>Project Juno</div>
          )}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(
              'transition-transform duration-300 mr-2',
              isExpanded && '-scale-y-100',
            )}
          />
        </div>
      </button>

      <DropdownPanel isExpanded={isExpanded} className="w-60 ml-2" zIndex={20}>
        {hasMultipleOrganizations && (
          <>
            <Tab
              key="-1"
              label="Select Organization"
              to="/"
              highlightMode="vertical"
            />
            <div className="border-t border-juno-gray-200" />
            {rootUser.organizations
              .sort(( a, b ) => a.id - b.id )
              .map( x => (
                <Tab
                  key={x.id}
                  label={x.name}
                  isActive={x.id === currentOrganization?.id}
                  to={`/${x.slug}`}
                  highlightMode="vertical"
                />
              ))}
            <div className="border-t border-collapse border-juno-gray-200" />
          </>
        )}
        <Tab
          key="signout"
          label="Sign Out"
          highlightMode="vertical"
          onClick={handleLogout}
        />
      </DropdownPanel>
    </div>
  );
};

export default OrganizationalTitle;
