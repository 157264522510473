import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format, formatDistanceToNow, isPast, parseISO } from 'date-fns';
import { FC } from 'react';
import { IMembership } from 'src/graphql/types';
import { hasGoodLifetime, isExpiring } from 'src/utils/date';

interface IProps {
  membership?: Pick<IMembership, 'endsAt'> | null;
}

const Membership: FC<IProps> = ({ membership }) => {
  if ( !membership )
    return <div className="text-juno-gray-700">No Memberships Found</div>;

  const endDate = parseISO( membership.endsAt );
  const distance = formatDistanceToNow( endDate, { addSuffix: true });

  return (
    <div
      className={clsx({
        'text-juno-gray-700': isPast( endDate ),
        'text-juno-orange-200': isExpiring( endDate ),
      })}
    >
      <FontAwesomeIcon icon={faTicket} className="pr-1" />
      {isPast( endDate ) && `Exp. ${format( endDate, 'yyyy MMM' )}`}
      {isExpiring( endDate ) && `Exp. ${format( endDate, 'MMM d' )}`}
      {hasGoodLifetime( endDate ) && `Exp. ${format( endDate, 'yyyy MMM' )}`}
      {` (${distance})`}
    </div>
  );
};

export default Membership;
