import { gql } from 'urql';

export const createNewPlayer = gql`
  mutation CreateNewPlayer(
    $branchId: Int!
    $firstName: String
    $lastName: String!
    $preferredName: String
    $emailAddress: String!
  ) {
    createNewPlayer(
      input: {
        branchId: $branchId
        firstName: $firstName
        lastName: $lastName
        preferredName: $preferredName
        emailAddress: $emailAddress
      }
    ) {
      error
      user {
        id
        organizations {
          id
          role
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export const updatePlayerData = gql`
  mutation UpdatePlayerData(
    $playerId: Int!
    $firstName: String
    $lastName: String
    $preferredName: String
    $emailAddress: String
    $branchId: Int
    $role: OrganizationRoleEnum
    $assistantFlags: [AssistantFlagEnum!]
    $notes: String
  ) {
    updatePlayerData(
      input: {
        userId: $playerId
        firstName: $firstName
        lastName: $lastName
        preferredName: $preferredName
        emailAddress: $emailAddress
        branchId: $branchId
        role: $role
        assistantFlags: $assistantFlags
        notes: $notes
      }
    ) {
      error
      user {
        id
        fullName
        firstName
        lastName
        preferredName
        emailAddress
        notes
        userOrganization {
          id
          role
          assistantFlags
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export const updatePlayerMembership = gql`
  mutation UpdatePlayerMembership($membershipId: Int!, $startsAt: String!) {
    updateMembership(
      input: { membershipId: $membershipId, startsAt: $startsAt }
    ) {
      error
      membership {
        id
        grantor {
          id
          fullName
        }
        grantingBranch {
          id
          shorthand
        }
        startsAt
        endsAt
      }
    }
  }
`;

export const updatePlayerBranchOwnership = gql`
  mutation UpdatePlayerBranchOwnership(
    $playerId: Int!
    $branchId: Int!
    $isOwned: Boolean!
  ) {
    updatePlayerBranchOwnership(
      input: { userId: $playerId, branchId: $branchId, isOwned: $isOwned }
    ) {
      error
      user {
        id
        ownedBranches {
          id
          name
        }
      }
    }
  }
`;

export const createPlayerReferral = gql`
  mutation CreatePlayerReferral(
    $playerId: Int!
    $counterPartyId: Int!
    $predicate: String!
  ) {
    createReferral(
      input: {
        userId: $playerId
        counterPartyId: $counterPartyId
        predicate: $predicate
      }
    ) {
      error
      user {
        id
        invitations {
          id
          newbie {
            id
            fullName
          }
          inviter {
            id
            fullName
          }
        }
        referrals {
          id
          newbie {
            id
            fullName
          }
          inviter {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const destroyPlayerReferral = gql`
  mutation DestroyPlayerReferral($playerId: Int!, $referralId: Int!) {
    destroyReferral(input: { userId: $playerId, referralId: $referralId }) {
      error
      user {
        id
        invitations {
          id
          newbie {
            id
            fullName
          }
          inviter {
            id
            fullName
          }
        }
        referrals {
          id
          newbie {
            id
            fullName
          }
          inviter {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const createPlayerPerk = gql`
  mutation CreatePlayerPerk(
    $playerId: Int!
    $branchId: Int!
    $reason: String!
    $amount: Int!
  ) {
    createCap(
      input: {
        userId: $playerId
        branchId: $branchId
        reason: $reason
        amount: $amount
      }
    ) {
      error
      user {
        id
        caps {
          id
          reason
          amount
          state
          createdAt
          grantor {
            id
            fullName
          }
          branch {
            id
            shorthand
          }
        }
      }
    }
  }
`;

export const createPlayerCorrectiveAction = gql`
  mutation CreatePlayerCorrectiveAction(
    $playerId: Int!
    $branchId: Int!
    $kind: String!
    $startsAt: String!
    $endsAt: String
    $reason: String!
  ) {
    createCorrectiveAction(
      input: {
        userId: $playerId
        branchId: $branchId
        kind: $kind
        startsAt: $startsAt
        endsAt: $endsAt
        reason: $reason
      }
    ) {
      error
      user {
        id
        correctiveActions {
          id
          kind
          startsAt
          endsAt
          reason
          issuer {
            id
            fullName
          }
          branch {
            id
            shorthand
          }
        }
      }
    }
  }
`;

export const updatePlayerPerk = gql`
  mutation UpdatePlayerPerk(
    $perkId: Int!
    $reason: String
    $amount: Int
    $state: CapStateEnum
  ) {
    updateCap(
      input: { capId: $perkId, reason: $reason, amount: $amount, state: $state }
    ) {
      error
      cap {
        id
        reason
        amount
        state
      }
    }
  }
`;

export const updatePlayerCorrectiveAction = gql`
  mutation UpdatePlayerCorrectiveAction(
    $correctiveActionId: Int!
    $reason: String
    $endsAt: String
  ) {
    updateCorrectiveAction(
      input: {
        correctiveActionId: $correctiveActionId
        reason: $reason
        endsAt: $endsAt
      }
    ) {
      error
      correctiveAction {
        id
        reason
        endsAt
      }
    }
  }
`;

export const destroyPlayerCorrectiveAction = gql`
  mutation DestroyPlayerCorrectiveAction($correctiveActionId: Int!) {
    destroyCorrectiveAction(
      input: { correctiveActionId: $correctiveActionId }
    ) {
      error
      correctiveAction {
        id
      }
    }
  }
`;

export const createPlayerMembership = gql`
  mutation CreatePlayerMembership($playerId: Int!) {
    createMembership(input: { userId: $playerId }) {
      error
      user {
        id
        memberships {
          id
          grantor {
            id
            fullName
          }
          grantingBranch {
            id
            shorthand
          }
          startsAt
          endsAt
        }
      }
    }
  }
`;

export const destroyPlayerMembership = gql`
  mutation DestroyPlayerMembership($membershipId: Int!) {
    destroyMembership(input: { membershipId: $membershipId }) {
      error
      membership {
        id
      }
    }
  }
`;
