import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import useSearchItems from 'src/hooks/items/useSearchItems';
import { useOutsideClickRef } from 'rooks';
import { startCase } from 'lodash';
import Input from '../0100_input';
import DropdownPanel from '../0100_dropdown_panel';
import Loading from '../0100_loading';

interface IProps {
  blueprint: boolean;
  onSearchResultClick: ({ itemId }: { itemId: number }) => void;
}

const ItemSearch: FC<IProps> = ({ blueprint, onSearchResultClick }) => {
  const [ isFocused, setIsFocused ] = useState( false );
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();

  const { items, fetching } = useSearchItems({ query, blueprint });
  const [ ref ] = useOutsideClickRef(() => setIsFocused( false ));

  const canExecuteSearch = query.trim().length >= 2;
  const hasResults = items.length > 0;

  return (
    <div ref={ref}>
      <div className="flex justify-between items-center">
        <Input
          fullWidth
          placeholder={`Search ${blueprint ? 'Blueprints' : 'Items'}...`}
          onFocus={() => setIsFocused( true )}
          {...register( 'query' )}
        />
      </div>
      <DropdownPanel
        isExpanded={isFocused}
        zIndex={10}
        className={clsx(
          'mt-2 border-juno-gray-700',
          isFocused && fetching && 'border',
        )}
      >
        {isFocused && fetching && (
          <div className="p-2">
            <Loading size="small" />
          </div>
        )}

        {isFocused && !fetching && canExecuteSearch && hasResults && (
          <div className="border border-juno-gray-700 rounded">
            {items.map( x => (
              <button
                key={x.id}
                type="button"
                className="w-full text-left px-2 py-1 border-l-4 border-transparent hover:border-juno-gray-200 transition-all duration-300"
                onClick={() => onSearchResultClick({ itemId: x.id })}
              >
                <div>{x.name}</div>
                <div className="text-sm opacity-75">{startCase( x.kind )}</div>
              </button>
            ))}
          </div>
        )}

        {isFocused && !fetching && !hasResults && (
          <div className="p-2 border border-juno-gray-700 rounded">
            {canExecuteSearch ? 'No Results' : 'Start Typing...'}
          </div>
        )}
      </DropdownPanel>
    </div>
  );
};

export default ItemSearch;
