const loreData = [
  {
    id: 201,
    name: 'Aberrants',
  },
  {
    id: 202,
    name: 'Animals',
  },
  {
    id: 203,
    name: 'Faith - Common',
  },
  {
    id: 204,
    name: 'Faith - Cults',
  },
  {
    id: 205,
    name: 'Lineages',
  },
  {
    id: 206,
    name: 'Medical',
  },
  {
    id: 207,
    name: 'Mutation',
  },
  {
    id: 208,
    name: 'Mortis Amaranthine / Infection',
  },
  {
    id: 209,
    name: 'Nature',
  },
  {
    id: 210,
    name: 'Post-Fall History',
  },
  {
    id: 211,
    name: 'Pre-Fall History',
  },
  {
    id: 212,
    name: 'Raiders',
  },
  {
    id: 213,
    name: 'Shady Operation',
  },
  {
    id: 214,
    name: 'Undead',
  },
  {
    id: 215,
    name: 'Wasteland Science',
  },
];

export default loreData;
