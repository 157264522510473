import { FC, forwardRef } from 'react';
import ToggleGroup, { IToggleGroupProps } from '../0100_toggle_group';

const AssistantFlagsToggle: FC<IToggleGroupProps> = forwardRef<
  HTMLDivElement,
  IToggleGroupProps
>(({ ...props }, ref ) => (
  <ToggleGroup
    {...props}
    flags={[
      { value: 'directorship' },
      { value: 'logistics' },
      { value: 'mod_manager' },
      { value: 'registration' },
    ]}
  />
));

export default AssistantFlagsToggle;
