import { FC } from 'react';
import useAuth from 'src/hooks/auths/useAuth';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import PasswordResetModal from './PasswordResetModal';

const AuthenticationModal: FC = () => {
  const {
    activeModal,
    generateAuthTokenResult,
    requestPasswordResetResult,
    resetPasswordResult,
    isRootUserValidated,
    closeModal,
    handleLogin,
    handlePasswordRecovery,
    handlePasswordReset,
  } = useAuth();

  return (
    <>
      <LoginModal
        isOpen={activeModal === 'login'}
        isValidated={isRootUserValidated}
        isFetching={generateAuthTokenResult.fetching}
        error={generateAuthTokenResult.data?.generateAuthToken?.error}
        onClose={closeModal}
        onLogin={handleLogin}
      />
      <ForgotPasswordModal
        isOpen={activeModal === 'forgotPassword'}
        isFetching={requestPasswordResetResult.fetching}
        isRequestCompleted={!!requestPasswordResetResult.data}
        onPasswordRecovery={handlePasswordRecovery}
      />
      <PasswordResetModal
        isOpen={!isRootUserValidated && activeModal === 'resetPassword'}
        isValidated={isRootUserValidated}
        isFetching={resetPasswordResult.fetching}
        onClose={closeModal}
        onPasswordReset={handlePasswordReset}
      />
    </>
  );
};

export default AuthenticationModal;
