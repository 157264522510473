import { useQuery } from 'urql';
import { format } from 'date-fns';
import {
  IGetPlayerAttendancesQuery,
  IGetPlayerAttendancesQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { getPlayerAttendances } from 'src/graphql/queries/players.graphql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

interface IProps {
  start: Date;
  end: Date;
  playerId: number;
}

const usePlayerAttendances = ({ start, end, playerId }: IProps ) => {
  const { isRootUserValidated } = useAuth();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerAttendancesQuery,
    IGetPlayerAttendancesQueryVariables
  >({
    query: getPlayerAttendances,
    variables: {
      from: format( start, 'yyyy-MM-dd' ),
      upto: format( end, 'yyyy-MM-dd' ),
      playerId,
    },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( playerId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    attendances: data?.user?.eventAttendances || [],
    error,
    fetching: fetching || stale,
  };
};

export default usePlayerAttendances;
