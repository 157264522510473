import { FC } from 'react';
import EmbossAndReproduction from '../EmbossAndReproduction';

import '../index.css';

interface IProps {
  id: number;
  name: string;
  updatedAt: string;
  metadata: {
    printHeader?: string | null;
    mechanics?: string | null;
    notes?: string | null;
    requirementsToUse?: string | null;
    durationOfEffect?: string | null;
    validTargetDescription?: string | null;
    locationOfUse?: string | null;
    equipmentRequiredForUse?: string | null;
    durationOfRoleplay?: string | null;
    descriptionOfRoleplay?: string | null;
    activationRequirement?: string | null;
  };
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const Benediction: FC<IProps> = ({ ...benediction }) => (
  <div className="printout benediction border border-juno-gray-700 overflow-hidden">
    <div className="border-b border-juno-gray-700">
      <div className="text-center text-3xl py-2">
        {benediction.metadata.printHeader}
      </div>
    </div>
    <div className="border-b border-juno-gray-700 text-xl p-2">
      <table className="header">
        <tbody>
          <tr>
            <td>Benediction Name</td>
            <td>{benediction.name}</td>
          </tr>
          <tr>
            <td>Requirements To Use</td>
            <td>{benediction.metadata.requirementsToUse}</td>
          </tr>
          <tr>
            <td>Duration of Effect</td>
            <td>{benediction.metadata.durationOfEffect}</td>
          </tr>
          <tr>
            <td>Valid Target Description</td>
            <td>{benediction.metadata.validTargetDescription}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="border-b border-juno-gray-700 p-2 grid gap-2">
      <table className="header">
        <tbody>
          <tr>
            <td>Location of Use</td>
            <td>{benediction.metadata.locationOfUse}</td>
          </tr>
          <tr>
            <td>Equipment Required for Use</td>
            <td>{benediction.metadata.equipmentRequiredForUse}</td>
          </tr>
          <tr>
            <td>Resource Expended in Use</td>
            <td>{benediction.metadata.equipmentRequiredForUse}</td>
          </tr>
          <tr>
            <td>Duration of Role-play</td>
            <td>{benediction.metadata.durationOfRoleplay}</td>
          </tr>
          <tr>
            <td>Mind/Resolve to Activate</td>
            <td>{benediction.metadata.activationRequirement}</td>
          </tr>
        </tbody>
      </table>

      <div className="grid gap-2 pb-4">
        <div>
          <div className="pl-2 font-bold">Description of Role-play</div>
          <div className="pl-2 font-normal text-sm min-h-[128px]">
            {benediction.metadata.descriptionOfRoleplay}
          </div>
        </div>
        <div>
          <div className="pl-2 font-bold">Mechanics</div>
          <div className="pl-2 font-normal text-sm min-h-[128px]">
            {benediction.metadata.mechanics}
          </div>
        </div>
        <div>
          <div className="pl-2 font-bold">Special Notes</div>
          <div className="pl-2 font-normal text-sm min-h-[96px]">
            {benediction.metadata.notes}
          </div>
        </div>
      </div>
    </div>
    <EmbossAndReproduction
      updatedAt={benediction.updatedAt}
      itemReproduction={benediction.itemReproduction}
    />
  </div>
);

export default Benediction;
