import { forwardRef } from 'react';

// @ts-ignore
const LeanInput = forwardRef(({ value, onClick }, ref ) => (
  <button
    type="button"
    className="underline underline-offset-4 decoration-dashed hover:brightness-125"
    onClick={onClick}
  >
    {value}
  </button>
));

export default LeanInput;
