import { FC } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import AugmentedInput from 'src/components/0200_augmented_input';
import BranchSelect from 'src/components/0400_branch_select';

interface IProps {
  canUpdatePlayerData: boolean;
  canUpdatePlayerDataSensitive: boolean;
  isNewPlayer: boolean;
  isUpdating: boolean;
  isUpdated: { [key: string]: boolean };
  isDirty: {
    firstName: boolean;
    lastName: boolean;
    preferredName: boolean;
    emailAddress: boolean;
    branchId: boolean;
  };
  errors?: {
    firstName?: FieldError;
    lastName?: FieldError;
    preferredName?: FieldError;
    emailAddress?: FieldError;
    branchId?: FieldError;
  };
  onUpdate: () => void;
}

const BaseSection: FC<IProps> = ({
  canUpdatePlayerData = false,
  canUpdatePlayerDataSensitive = false,
  isNewPlayer = false,
  isUpdating = false,
  isDirty = {},
  isUpdated = {},
  errors,
  onUpdate,
}) => {
  const { register, watch } = useFormContext();

  return (
    <div>
      {!isNewPlayer && (
        <AugmentedInput title="ID" isLocked {...register( 'id' )} />
      )}
      <AugmentedInput
        title="First Name"
        isBusy={!isNewPlayer && isUpdating && isDirty.firstName}
        isUpdated={isUpdated.firstName && !isDirty.firstName}
        isLocked={!canUpdatePlayerData && !isNewPlayer}
        onEnter={onUpdate}
        {...register( 'firstName', { onBlur: onUpdate })}
      />
      <AugmentedInput
        required
        title="Last Name"
        footnote="This should be your legal name, i.e. how it would appear on your Government-issued ID and Bank Statements. Game runners use this to verify payments."
        isBusy={!isNewPlayer && isUpdating && isDirty.lastName}
        isUpdated={isUpdated.lastName && !isDirty.lastName}
        isLocked={!canUpdatePlayerData && !isNewPlayer}
        error={errors?.lastName?.message?.toString()}
        onEnter={onUpdate}
        {...register( 'lastName', {
          required: { value: true, message: 'Cannot be blank' },
          onBlur: onUpdate,
        })}
      />
      <AugmentedInput
        title="Preferred Name"
        footnote="If supplied, Database and Character Printouts will use this instead of the legal name above."
        isBusy={!isNewPlayer && isUpdating && isDirty.preferredName}
        isUpdated={isUpdated.preferredName && !isDirty.preferredName}
        isLocked={!canUpdatePlayerData && !isNewPlayer}
        error={errors?.preferredName?.message?.toString()}
        onEnter={onUpdate}
        {...register( 'preferredName', { onBlur: onUpdate })}
      />
      <AugmentedInput
        required
        title="Email Address"
        isBusy={!isNewPlayer && isUpdating && isDirty.emailAddress}
        isUpdated={isUpdated.emailAddress && !isDirty.emailAddress}
        isLocked={!canUpdatePlayerData && !isNewPlayer}
        error={errors?.emailAddress?.message?.toString()}
        onEnter={onUpdate}
        {...register( 'emailAddress', {
          required: { value: true, message: 'Cannot be blank' },
          onBlur: onUpdate,
        })}
      />
      <AugmentedInput
        title="Home Branch"
        isBusy={!isNewPlayer && isUpdating && isDirty.branchId}
        isUpdated={isUpdated.branchId && !isDirty.branchId}
      >
        <BranchSelect
          owned
          selectedValue={watch( 'branchId' )}
          isLocked={!canUpdatePlayerDataSensitive && !isNewPlayer}
          defaultLabel="Select Home Branch"
          onUpdate={onUpdate}
          {...register( 'branchId', {
            min: { value: 1, message: 'Must select a branch' },
          })}
        />
      </AugmentedInput>
    </div>
  );
};

export default BaseSection;
