import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  faDollarSign,
  faHiking,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import { IEventAttendee, IUser } from 'src/graphql/types';
import { useMutation } from 'urql';
import {
  IUpdateEventAttendeeMutation,
  IUpdateEventAttendeeMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { updateEventAttendee } from 'src/graphql/mutations/events.graphql';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  attendee: Pick<
    IEventAttendee,
    'id' | 'kind' | 'paid' | 'attending' | 'buildGrowth'
  > & {
    user: Pick<IUser, 'id' | 'fullName'>;
    attendeeCharacters: {
      id: number;
      character: {
        id: number;
        name: string;
      };
    }[];
  };
}

const Attendee: FC<IProps> = ({ attendee }) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState( false );
  const [ , update ] = useMutation<
    IUpdateEventAttendeeMutation,
    IUpdateEventAttendeeMutationVariables
  >( updateEventAttendee );
  const { reset, setValue, watch } = useForm({
    defaultValues: {
      isPaid: false,
      isAttending: false,
    },
  });

  const { isPaid, isAttending } = watch();

  const handleUpdate = useCallback(
    ({ field, value }: { field: 'isPaid' | 'isAttending'; value: boolean }) => {
      update({
        eventAttendeeId: attendee.id,
        [field]: value,
      }).then( res => {
        if ( res.data?.updateEventAttendee?.eventAttendee ) {
          setIsUpdateSuccessful( true );
          setTimeout(() => setIsUpdateSuccessful( false ), 1000 );
        }
      });

      setValue( field, value );
      if ( attendee.kind === 'home_game' ) {
        if ( field === 'isPaid' ) {
          setValue( 'isAttending', value );
        }
      } else if ( field === 'isPaid' && value === false ) {
        setValue( 'isAttending', value );
      } else if ( field === 'isAttending' && value === true ) {
        setValue( 'isPaid', value );
      }
    },
    [ update, setValue, attendee ],
  );

  useEffect(() => {
    reset({
      isPaid: attendee.paid,
      isAttending: attendee.attending,
    });
  }, [ attendee, reset ]);

  return (
    <div
      className={clsx(
        'transition-all ease-in-out',
        isUpdateSuccessful ? 'bg-lime-600/75' : 'bg-transparent',
      )}
    >
      <div className="flex items-start justify-between gap-4 border-b border-juno-gray-700">
        <div>
          <button
            type="button"
            className="w-16 flex-shrink-0 text-right underline"
            onClick={() => {
              searchParams.set( 'player_id', String( attendee.user.id ));
              setSearchParams( searchParams );
            }}
          >
            {attendee.user.id}
          </button>
          {attendee.buildGrowth > 0 && (
            <div className="text-right">{`+${attendee.buildGrowth}XP`}</div>
          )}
        </div>
        <div className="w-full">
          <div>{attendee.user.fullName}</div>
          <div className="border-l-4 pl-2 mb-2 border-juno-gray-700">
            {attendee.attendeeCharacters.map( ac => (
              <div key={ac.id}>{ac.character.name}</div>
            ))}
          </div>
        </div>
        <div className="w-16 flex-shrink-0 self-center text-center">
          <div>
            <Toggle
              checked={isPaid}
              icons={{
                checked: (
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    className="text-sm -mt-0.5 "
                  />
                ),
                unchecked: (
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    className="text-sm -mt-0.5 opacity-50"
                  />
                ),
              }}
              onChange={x =>
                handleUpdate({ field: 'isPaid', value: x.target.checked })
              }
            />
          </div>
          <div>
            {attendee.kind === 'home_game' ? (
              <FontAwesomeIcon icon={faHome} className="pb-2" />
            ) : (
              <Toggle
                checked={isAttending}
                icons={{
                  checked: (
                    <FontAwesomeIcon
                      icon={faHiking}
                      className="text-sm -mt-0.5 "
                    />
                  ),
                  unchecked: (
                    <FontAwesomeIcon
                      icon={faHiking}
                      className="text-sm -mt-0.5 opacity-50"
                    />
                  ),
                }}
                onChange={x =>
                  handleUpdate({
                    field: 'isAttending',
                    value: x.target.checked,
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendee;
