import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useOutsideClickRef } from 'rooks';
import DropdownPanel from 'src/components/0100_dropdown_panel';
import ResponseBox from 'src/components/0100_response_box';
import Tab from 'src/components/0200_tab';
import {
  destroyCharacter,
  updateCharacter,
} from 'src/graphql/mutations/characters.graphql';
import {
  IDestroyCharacterMutation,
  IDestroyCharacterMutationVariables,
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { ICharacter, ICharacterStatusEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import { useMutation } from 'urql';

interface IProps {
  character: Pick<
    ICharacter,
    'id' | 'name' | 'status' | 'buildEarned' | 'buildUsed'
  >;
  stale: boolean;
  canDestroyCharacter?: boolean;
  canChangeCharacterStatus?: boolean;
  canActivate: boolean;
}

const CharacterRow: FC<IProps> = ({
  character,
  stale,
  canDestroyCharacter,
  canChangeCharacterStatus,
  canActivate,
}) => {
  const { playerId } = useParams();
  const { linkToCharacter } = useAppNavigations();
  const [ isDestroying, setIsDestroying ] = useState( false );
  const [ isActionsExpanded, setIsActionsExpanded ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null );
  const [ ref ] = useOutsideClickRef(() => setIsActionsExpanded( false ));
  const [ , destroy ] = useMutation<
    IDestroyCharacterMutation,
    IDestroyCharacterMutationVariables
  >( destroyCharacter );
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >( updateCharacter );
  const shouldDisplayActions = useMemo(() => {
    switch ( character.status ) {
      case ICharacterStatusEnum.Active:
        return canChangeCharacterStatus;
      case ICharacterStatusEnum.Staged:
        return true;
      default:
        return false;
    }
  }, [ canChangeCharacterStatus, character.status ]);

  if ( isDestroying ) return null;

  return (
    <div ref={ref}>
      <div
        className={clsx(
          'flex justify-between items-center gray-box-angled p-2 border border-juno-gray-700 rounded hover:border-juno-gray-200 hover:text-shadow transition-all ',
          ( stale || updateResult.fetching ) && 'blur-xs',
        )}
      >
        <Link
          className="w-full"
          to={linkToCharacter({
            playerId: Number( playerId ),
            characterId: character.id,
          })}
        >
          <div>
            <div>{character.name}</div>
            <div>
              XP: {character.buildUsed}/{character.buildEarned}
            </div>
          </div>
        </Link>
        {shouldDisplayActions && (
          <div className="flex items-center gap-2 w-36">
            <div className="w-full">
              <button
                type="button"
                className="w-full flex justify-end items-center gap-2 text-right"
                onClick={e => {
                  e.preventDefault();
                  setIsActionsExpanded( x => !x );
                }}
              >
                Actions
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={clsx(
                    'transition-all duration-300',
                    isActionsExpanded && '-scale-y-100',
                  )}
                />
              </button>
              <DropdownPanel isExpanded={isActionsExpanded} className="mt-2">
                <div className="border border-juno-gray-700 rounded-none text-right grid">
                  {canChangeCharacterStatus &&
                    character.status === ICharacterStatusEnum.Active && (
                      <>
                        <Tab
                          highlightMode="vertical"
                          label="Stage"
                          onClick={() => {
                            setErrorMessage( null );
                            update({
                              characterId: character.id,
                              status: ICharacterStatusEnum.Staged,
                            }).then( res => {
                              if ( res.data?.updateCharacterData?.error ) {
                                setErrorMessage(
                                  res.data.updateCharacterData.error,
                                );
                              }
                            });
                          }}
                        />

                        <Tab isLocked highlightMode="vertical" label="Retire" />
                      </>
                    )}
                  {character.status === ICharacterStatusEnum.Staged && (
                    <>
                      <Tab
                        isLocked={!canActivate}
                        highlightMode="vertical"
                        label="Activate"
                        onClick={() => {
                          setErrorMessage( null );
                          update({
                            characterId: character.id,
                            status: ICharacterStatusEnum.Active,
                          }).then( res => {
                            if ( res.data?.updateCharacterData?.error ) {
                              setErrorMessage(
                                res.data.updateCharacterData.error,
                              );
                            }
                          });
                        }}
                      />
                      {canDestroyCharacter && (
                        <Tab
                          isLocked={character.buildEarned > 0}
                          highlightMode="vertical"
                          label="Delete"
                          onClick={() => {
                            destroy({
                              playerId: Number( playerId ),
                              characterId: character.id,
                            });
                            setIsDestroying( true );
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </DropdownPanel>
            </div>
          </div>
        )}
      </div>
      {errorMessage && <ResponseBox type="error">{errorMessage}</ResponseBox>}
    </div>
  );
};

export default CharacterRow;
