export type TViewPackKeys =
  | 'benediction'
  | 'itemCraftingFinalProducts'
  | 'itemCraftings'
  | 'itemReproductions'
  | 'mechanics'
  | 'notes'
  | 'requirementsToUse';

type TViewPack = {
  id: TViewPackKeys;
  name: string;
  columns: { id: string; label: string }[];
};

const viewPacks: TViewPack[] = [
  {
    id: 'benediction',
    name: 'Benediction',
    columns: [
      { id: 'benedictionDuration', label: 'Duration' },
      { id: 'benedictionTarget', label: 'Target' },
      { id: 'benedictionLocation', label: 'Location of Use' },
      { id: 'benedictionEquipmentsForUse', label: 'Equipments for Use' },
      {
        id: 'benedictionActivationRequirement',
        label: 'Activation Requirement',
      },
      { id: 'benedictionRoleplayDuration', label: 'Roleplay Duration' },
      { id: 'benedictionRoleplayDescription', label: 'Roleplay Description' },
    ],
  },
  {
    id: 'itemCraftingFinalProducts',
    name: 'Output',
    columns: [
      { id: 'stack', label: 'Stack' },
      { id: 'kind', label: 'Output Type' },
      { id: 'outputName', label: 'Output Name' },
      { id: 'lifetimeAmount', label: 'Output Lifetime (mo)' },
      { id: 'uses', label: 'Uses' },
    ],
  },
  {
    id: 'requirementsToUse',
    name: 'Requirements to Use',
    columns: [{ id: 'requirementsToUse', label: 'Requirements to Use' }],
  },
  {
    id: 'itemCraftings',
    name: 'Production',
    columns: [
      { id: 'craftingTime', label: 'Time (mins)' },
      { id: 'craftingMindCost', label: 'Mind' },
      { id: 'craftingSkills', label: 'Production Skills' },
      { id: 'craftingZone', label: 'Production Zone' },
      { id: 'craftingComponents', label: 'Production Materials' },
    ],
  },
  {
    id: 'mechanics',
    name: 'Mechanics',
    columns: [{ id: 'mechanics', label: 'Mechanics' }],
  },
  { id: 'notes', name: 'Notes', columns: [{ id: 'notes', label: 'Notes' }]},
  {
    id: 'itemReproductions',
    name: 'Reproduction',
    columns: [
      { id: 'reproductionTime', label: 'Time (min)' },
      { id: 'reproductionCost', label: 'Mind' },
      { id: 'reproductionSkills', label: 'Reproduction Skills' },
      { id: 'reproductionMaterials', label: 'Reproduction Materials' },
    ],
  },
];

export default viewPacks;
