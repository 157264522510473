import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import DystopiaRisingXpAudit from 'src/components/0500_xp_audits/dystopia_rising';
import useCharacterXp from 'src/hooks/characters/useCharacterXp';

const XpAudit: FC = () => {
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );
  const characterXp = useCharacterXp({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  return <DystopiaRisingXpAudit {...characterXp} />;
};

export default XpAudit;
