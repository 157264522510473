import { FC, useMemo } from 'react';
import { IXpAudit } from 'src/graphql/types';
import { groupBy } from 'lodash';
import { format, getMonth, parseISO } from 'date-fns';
import Content from './Content';

interface IProps {
  data: IXpAudit[];
  splitByMonths: boolean;
}

const Viewer: FC<IProps> = ({ data, splitByMonths }) => {
  const monthGroups = useMemo(() => {
    if ( !splitByMonths ) return null;

    return groupBy( data, x => getMonth( parseISO( x.awardedAt )));
  }, [ data, splitByMonths ]);

  if ( !data || data.length === 0 ) {
    return <div>No data in this category</div>;
  }

  if ( !monthGroups ) return <Content data={data} />;
  return (
    <div>
      {Object.keys( monthGroups )
        .sort(( a, b ) => Number( b ) - Number( a ))
        .map( x => (
          <Content
            key={x}
            data={monthGroups[x]}
            header={format( new Date( 2024, Number( x ), 1 ), 'MMMM' )}
          />
        ))}
    </div>
  );
};

export default Viewer;
