import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';
import useRootUser from 'src/hooks/players/useRootUser';
import SidebarLink from 'src/components/0100_sidebar_link';

const OrganizationSidebar: FC = () => {
  const {
    linkToOrganization,
    linkToBranch,
    linkToCaps,
    linkToCorrectiveActions,
    isOrganizationPath,
  } = useAppNavigations();
  const { organizationSection } = useAppNavigations();
  const [ isExpanded, setIsExpanded ] = useState( false );
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });
  const { homeBranch } = useRootUser();

  useEffect(() => {
    if ( isOrganizationPath ) {
      setIsExpanded( true );
    }
  }, [ isOrganizationPath ]);

  return (
    <div className="gray-box-angled">
      <button
        type="button"
        className="flex justify-between w-full px-2 py-1 text-left font-bold"
        onClick={() => setIsExpanded( x => !x )}
      >
        <div>Network Tools</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'px-2 mt-1 transition-transform duration-300',
            isExpanded && '-scale-y-100',
          )}
        />
      </button>
      <div
        className={clsx(
          'gray-box-angled grid gap-2 transition-all duration-300',
          isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className="overflow-hidden grid">
          <SidebarLink
            label="Branches Overview"
            url={linkToBranch({})}
            isActive={organizationSection === 'caps'}
          />

          <SidebarLink
            label="CAPS"
            url={linkToCaps({ branchId: homeBranch?.id })}
            isActive={organizationSection === 'caps'}
          />

          <SidebarLink
            label="Character Retirements"
            url={linkToOrganization({ section: 'character_retirements' })}
            isActive={organizationSection === 'character_retirements'}
          />

          <SidebarLink
            label="Corrective Actions"
            url={linkToCorrectiveActions({ branchId: homeBranch?.id })}
            isActive={organizationSection === 'corrective_actions'}
          />

          <SidebarLink
            label="Depository"
            url={linkToOrganization({ section: 'depository' })}
            isActive={organizationSection === 'depository'}
          />

          <SidebarLink
            label="Diseases"
            url={linkToOrganization({ section: 'diseases' })}
            isActive={organizationSection === 'diseases'}
          />

          {canManageOrganization && (
            <>
              <SidebarLink
                label="Invoices"
                url={linkToOrganization({ section: 'invoice' })}
                isActive={organizationSection === 'invoice'}
              />
              <SidebarLink
                label="Memberships"
                url={linkToOrganization({ section: 'memberships' })}
                isActive={organizationSection === 'memberships'}
              />
            </>
          )}

          <SidebarLink
            label="Societies"
            url={linkToOrganization({ section: 'societies' })}
            isActive={organizationSection === 'societies'}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationSidebar;
