import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Calendar from 'src/components/0300_calendar';
import useCalendarNavigations from 'src/hooks/calendarNavigations/useCalendarNavigations';
import useEventsWithAttendances from 'src/hooks/events/useEventsWithAttendances';
import useRootUser from 'src/hooks/players/useRootUser';

const Event: FC = () => {
  const { playerId } = useParams();
  const { rootUserId } = useRootUser();
  const { start, end, handlePrevious, handleNext } = useCalendarNavigations({});
  const { events, fetching } = useEventsWithAttendances({
    start,
    end,
    playerId: Number( playerId || rootUserId ),
    withEvents: true,
  });

  return (
    <Calendar
      start={start}
      end={end}
      items={events}
      isFetching={fetching}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Event;
