import { FC, useEffect } from 'react';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import BaseSelect from 'src/components/0300_base_select';
import { useFormContext } from 'react-hook-form';
import Loading from 'src/components/0100_loading';
import usePermission from 'src/hooks/permissions/usePermissions';
import MembershipReminder from './MembershipReminder';
import useCharacterChangeDetection from '../hooks/useCharacterChangeDetection';
import CharacterChangeDetection from './CharacterChangeDetection';

const CharacterSelector: FC = () => {
  const { event, eventAttendee, characters } = useEventAttendee();
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number( event?.id ),
  });
  const { register, setValue, watch } = useFormContext();
  const { characterId, printRequestOnCharacterIds } = watch();
  const { activeCharacterIds } = useCharacterChangeDetection();

  useEffect(() => {
    // if player has _not_ checked in
    if ( !eventAttendee && characters ) {
      setValue( 'printRequestOnCharacterIds', activeCharacterIds );
      if ( event?.activeMembership ) {
        setValue( 'characterIds', activeCharacterIds );
      }
    }

    if ( eventAttendee ) {
      setValue(
        'printRequestOnCharacterIds',
        eventAttendee.attendeeCharacters
          .filter( x => x.printRequestStatus === 'print_requested' )
          .map( x => x.character.id ) ?? [],
      );
      setValue(
        'characterIds',
        eventAttendee.attendeeCharacters.length === 0
          ? activeCharacterIds
          : eventAttendee.attendeeCharacters.map( x => x.character.id ),
      );

      if ( event?.activeMembership ) {
        setValue( 'characterId', null );
      } else if ( eventAttendee.attendeeCharacters[0]) {
        setValue(
          'characterId',
          eventAttendee.attendeeCharacters[0].character.id,
        );
      }
    }
  }, [ activeCharacterIds, characters, event, eventAttendee, setValue ]);

  if ( !event ) return <Loading />;

  if ( event.activeMembership )
    return (
      <div>
        <div className="grid gap-2">
          {characters
            .filter( x => x.status === 'active' )
            .sort(( a, b ) => a.name.localeCompare( b.name ))
            .map( character => (
              <div
                key={character.id}
                className="flex items-center justify-between"
              >
                <div>{character.name}</div>
                <Toggle
                  icons={{
                    checked: (
                      <FontAwesomeIcon icon={faPrint} className="-mt-1" />
                    ),
                  }}
                  checked={printRequestOnCharacterIds.includes( character.id )}
                  onChange={x =>
                    setValue(
                      'printRequestOnCharacterIds',
                      x.target.checked
                        ? [ ...printRequestOnCharacterIds, character.id ]
                        : printRequestOnCharacterIds.filter(
                            ( y: number ) => y !== character.id,
                          ),
                      { shouldDirty: true },
                    )
                  }
                />
              </div>
            ))}
        </div>
        <div className="flex justify-end border-r border-juno-gray-200 pt-5 pb-2 pr-3 mr-4">
          <div className="text-right max-w-[256px] md:max-w-[420px] text-juno-gray-200 text-sm">
            <div>
              Help us save the trees by printing{' '}
              <span className="inline-block">
                only Characters you want to play.
              </span>
            </div>
            <div>You can change this later.</div>
          </div>
        </div>
        <MembershipReminder />
        <CharacterChangeDetection />
      </div>
    );

  return (
    <>
      <BaseSelect
        isLocked={!!eventAttendee && !canAssistCheckin}
        defaultLabel="Select a Character To Checkin"
        selectedValue={characterId}
        width="w-full"
        options={characters
          .filter( x => x.status === 'active' )
          .map( x => ({
            label: x.name,
            value: x.id,
          }))}
        {...register( 'characterId', { required: true })}
      />
      <CharacterChangeDetection />
    </>
  );
};

export default CharacterSelector;
