import { FC } from 'react';
import { IBranch, IUserOrganization } from 'src/graphql/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Badge from '../0100_badge';

export type TUserOrganization =
  | ( Pick<IUserOrganization, 'role' | 'assistantFlags'> & {
      branch?: Pick<IBranch, 'shorthand'>;
    })
  | null;

interface IProps {
  withBranch?: boolean;
  userOrganization?: TUserOrganization;
}

const PlayerBadges: FC<IProps> = ({ withBranch, userOrganization }) =>
  userOrganization ? (
    <>
      {withBranch &&
        userOrganization.branch &&
        ` (${userOrganization.branch.shorthand})`}

      <Badge type={userOrganization?.role} />
      {userOrganization.assistantFlags.map( x => (
        <Badge key={x} type={x} />
      ))}
    </>
  ) : (
    <FontAwesomeIcon icon={faTimes} className="pl-2 text-juno-orange-200" />
  );

export default PlayerBadges;
