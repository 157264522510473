import { FC } from 'react';
import Character, { TCharacter } from './Character';
import Player, { TPlayer } from './Player';
import Event, { TEvent } from './Event';
import { TShift, TTicket } from './TicketAndShifts';
import './index.css';

interface IProps {
  character?: TCharacter;
  player?: TPlayer;
  event?: TEvent;
  shifts?: TShift[];
  ticket?: TTicket;
}

const DystopiaRisingPrintout: FC<IProps> = ({
  character,
  event,
  player,
  shifts,
  ticket,
}) => (
  <div className="printout grid gap-2">
    <div className="flex justify-between text-center">
      <Player player={player} />
      <Event event={event} totalCapsForEvent={player?.totalCapsForEvent} />
    </div>
    <Character character={character} shifts={shifts} ticket={ticket} />
  </div>
);

export default DystopiaRisingPrintout;
