import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBefore, parseISO } from 'date-fns';
import { FC } from 'react';
import Button from 'src/components/0100_button';
import Loading from 'src/components/0100_loading';
import Table from 'src/components/0100_table';
import usePlayerMemberships from 'src/hooks/players/usePlayerMemberships';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useMutation } from 'urql';
import {
  ICreatePlayerMembershipMutation,
  ICreatePlayerMembershipMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import { createPlayerMembership } from 'src/graphql/mutations/players.graphql';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import ResponseBox from 'src/components/0100_response_box';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import Membership from './Membership';

const Memberships: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const { buttonState } = useButtonStates();
  const { fetching, memberships, refetch } = usePlayerMemberships({
    playerId: Number( playerId ),
  });
  const { isPermitted: canUpdateMembership } = usePermission({
    action: 'update_player_data_sensitive',
    playerId: Number( playerId ),
  });
  const [ createMembershipResult, createMembership ] = useMutation<
    ICreatePlayerMembershipMutation,
    ICreatePlayerMembershipMutationVariables
  >( createPlayerMembership );

  return (
    <Table
      headers={[
        { content: '', className: 'max-w-[16px]' },
        { content: 'Branch', className: 'w-16' },
        { content: 'Starts' },
        { content: 'Ends' },
        { content: 'Staff' },
        canUpdateMembership
          ? {
              content: <FontAwesomeIcon icon={faTrash} />,
              className: 'text-center',
            }
          : undefined,
      ]}
    >
      {canUpdateMembership && (
        <tr>
          <td />
          <td colSpan={5} className="p-1 pt-2 pb-1">
            <div className="flex items-center">
              <Button
                defaultLabel="Add Membership"
                state={buttonState({
                  isFetching: createMembershipResult.fetching,
                  isValid: true,
                  isDirty: true,
                })}
                stateLabel={{
                  loading: 'Adding Membership...',
                }}
                onClick={() => createMembership({ playerId: Number( playerId ) })}
              />
              {( fetching || createMembershipResult.fetching ) && (
                <Loading size="small" className="ml-2" />
              )}
            </div>
            {createMembershipResult.data?.createMembership?.error && (
              <ResponseBox type="error">
                {createMembershipResult.data.createMembership.error}
              </ResponseBox>
            )}
          </td>
        </tr>
      )}
      {memberships.length === 0 && (
        <tr>
          <td />
          <td colSpan={5} className="p-1 pb-0">
            No Memberships Found
          </td>
        </tr>
      )}
      {memberships
        .sort(( a, b ) =>
          isBefore( parseISO( a.startsAt ), parseISO( b.startsAt )) ? 1 : -1,
        )
        .map(( membership, index ) => (
          <Membership
            key={membership.id}
            canUpdateMembership={canUpdateMembership}
            membership={membership}
            index={index}
            onDelete={refetch}
          />
        ))}
    </Table>
  );
};

export default Memberships;
