import { useParams, useSearchParams } from 'react-router-dom';

const usePlayerIdFromUrl = () => {
  const { playerId: playerIdFromPathname } = useParams();
  const [ searchParams ] = useSearchParams();
  const playerIdFromSearchParams = searchParams.get( 'player_id' );

  return {
    playerId: playerIdFromSearchParams ?? playerIdFromPathname,
  };
};

export default usePlayerIdFromUrl;
