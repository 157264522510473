import { faCheck, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import BranchSelect from 'src/components/0400_branch_select';
import {
  createPlayerPerk,
  updatePlayerPerk,
} from 'src/graphql/mutations/players.graphql';
import {
  ICreatePlayerPerkMutation,
  ICreatePlayerPerkMutationVariables,
  IUpdatePlayerPerkMutation,
  IUpdatePlayerPerkMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import { IBranch, ICap, ICapStateEnum, IUser } from 'src/graphql/types';
import useRootUser from 'src/hooks/players/useRootUser';
import { useMutation } from 'urql';

interface IProps {
  perk?: Pick<ICap, 'id' | 'reason' | 'amount' | 'state' | 'createdAt'> & {
    branch: Pick<IBranch, 'id' | 'shorthand'>;
    grantor: Pick<IUser, 'id' | 'fullName'>;
  };
  canUpdatePerks?: boolean;
}

const Perk: FC<IProps> = ({ perk, canUpdatePerks }) => {
  const { playerId } = useParams();
  const { homeBranch } = useRootUser();
  const methods = useForm({
    defaultValues: {
      perkId: perk?.id || 0,
      branchId: perk?.branch.id || 0,
      reason: perk?.reason || '',
      amount: perk?.amount || 0,
      state: perk?.state || ICapStateEnum.Active,
    },
  });

  const {
    register,
    reset,
    setFocus,
    setValue,
    watch,
    formState: { isDirty },
  } = methods;

  const { perkId, branchId, reason, amount, state } = watch();

  const [ updateResult, update ] = useMutation<
    IUpdatePlayerPerkMutation,
    IUpdatePlayerPerkMutationVariables
  >( updatePlayerPerk );

  const [ createResult, create ] = useMutation<
    ICreatePlayerPerkMutation,
    ICreatePlayerPerkMutationVariables
  >( createPlayerPerk );

  const isDisabled = state === ICapStateEnum.Removed;
  const isNegative = amount < 0;
  const isValidCreate =
    branchId > 0 && reason.trim().length > 0 && amount !== 0;

  const handleBlur = useCallback(() => {
    if ( isDirty && perkId ) {
      update( watch());
    }
  }, [ isDirty, perkId, update, watch ]);

  const handleCreate = useCallback(() => {
    if ( isValidCreate && canUpdatePerks ) {
      create({ ...watch(), playerId: Number( playerId ) }).then(() => {
        reset({
          reason: '',
          amount: 0,
          branchId,
          state: ICapStateEnum.Active,
        });
        setTimeout(() => {
          setFocus( 'reason' );
        }, 100 );
      });
    }
  }, [
    branchId,
    canUpdatePerks,
    create,
    isValidCreate,
    playerId,
    reset,
    setFocus,
    watch,
  ]);

  useEffect(() => {
    if ( perk ) {
      reset({
        perkId: perk.id,
        reason: perk.reason,
        amount: perk.amount,
        state: perk.state,
      });
    }
  }, [ perk, reset ]);

  useEffect(() => {
    if ( !perk && homeBranch ) {
      setValue( 'branchId', homeBranch.id );
    }
  }, [ perk, homeBranch, setValue ]);

  return (
    <AnimatePresence>
      <FormProvider {...methods}>
        <motion.tr
          className={clsx(
            'align-middle',
            isDisabled && 'opacity-50',
            isNegative && 'text-juno-orange-200',
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: isDisabled ? 0.5 : 1 }}
        >
          {perk ? (
            <>
              <td className="p-1">{perk.branch.shorthand}</td>
              <td className="p-1">
                <div className="flex justify-between items-center">
                  <div>{`#${perk.grantor.id} ${perk.grantor.fullName}`}</div>
                  {updateResult.fetching && <Loading size="small" />}
                  {updateResult.data?.updateCap?.error === null && (
                    <FontAwesomeIcon icon={faCheck} className="fa-fw" />
                  )}
                </div>
              </td>
            </>
          ) : (
            <td colSpan={2}>
              <BranchSelect
                owned
                selectedValue={branchId}
                {...register( 'branchId' )}
              />
            </td>
          )}

          <td className="p-1">
            {canUpdatePerks ? (
              <div>
                <Input
                  fullWidth
                  {...register( 'reason' )}
                  className="text-juno-gray-50"
                  onBlur={handleBlur}
                />
              </div>
            ) : (
              perk?.reason
            )}
          </td>
          <td className="p-1 text-right">
            {canUpdatePerks ? (
              <Input
                fullWidth
                type="number"
                {...register( 'amount', { valueAsNumber: true })}
                className="text-right text-juno-gray-50"
                onBlur={handleBlur}
                onEnter={() => !perk && handleCreate()}
              />
            ) : (
              perk?.amount
            )}
          </td>
          {perk ? (
            <>
              <td className="p-1">
                {perk && format( parseISO( perk.createdAt ), 'yyyy-MM-dd' )}
              </td>
              {canUpdatePerks && (
                <td className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      setValue(
                        'state',
                        state === ICapStateEnum.Active
                          ? ICapStateEnum.Removed
                          : ICapStateEnum.Active,
                        { shouldDirty: true },
                      );
                      update( watch());
                    }}
                  >
                    <FontAwesomeIcon
                      icon={isDisabled ? faUndo : faTrash}
                      className="fa-fw"
                    />
                  </button>
                </td>
              )}
            </>
          ) : (
            <td colSpan={2}>
              <Button
                className="w-full"
                defaultLabel="Add Perk"
                state={
                  createResult.fetching
                    ? 'loading'
                    : isValidCreate
                      ? 'enabled'
                      : 'disabled'
                }
                stateLabel={{ loading: 'Adding' }}
                onClick={handleCreate}
              />
            </td>
          )}
        </motion.tr>
      </FormProvider>
    </AnimatePresence>
  );
};

export default Perk;
