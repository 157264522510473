import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';
import usePermission from 'src/hooks/permissions/usePermissions';

const Printout: FC = () => {
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );
  const { isPermitted: canPrint } = usePermission({
    action: 'update_character_data_sensitive',
    characterId: Number( characterId ),
  });

  const character = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  if ( !canPrint ) return null;

  return (
    <div className="printout-container">
      <DystopiaRisingPrintout {...character} />
    </div>
  );
};

export default Printout;
