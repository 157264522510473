import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { getEventInfo } from 'src/graphql/queries/events.graphql';
import {
  IGetEventQuery,
  IGetEventQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import useRootUserRevalidation from '../useRootUserRevalidation';

type TUseEvent = { id: number | null };

const useEvent = ( prop?: TUseEvent ) => {
  const { eventId: eventIdFromParams } = useParams();

  const eventId = prop?.id ?? eventIdFromParams;

  const [{ data, error, fetching }, refetch ] = useQuery<
    IGetEventQuery,
    IGetEventQueryVariables
  >({
    query: getEventInfo,
    variables: { eventId: Number( eventId ) },
    requestPolicy: 'cache-and-network',
    pause: !eventId || Number( eventId ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return { event: data?.event, error, fetching };
};

export default useEvent;
