import { FC, forwardRef } from 'react';
import useBranch from 'src/hooks/organizations/branches/useBranch';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {
  branchId: number;
}

const LocationSelect: FC<IProps> = forwardRef(({ branchId, ...props }, ref ) => {
  const { locations } = useBranch({ overrideBranchId: branchId });
  const location = locations.find( x => x.id === props.selectedValue );

  return (
    <div className="grid grid-cols-1 gap-2">
      <BaseSelect
        withSearch
        options={locations.map( x => ({ value: x.id, label: x.name }))}
        {...props}
      />
      {location && (
        <div>
          <div>{location.streetAddress}</div>
          <div>{`${location.city}, ${location.region}`}</div>
        </div>
      )}
    </div>
  );
});

export default LocationSelect;
