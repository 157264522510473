import { FC } from 'react';
import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Link, Outlet, useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import { IEventKindEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useEvent from 'src/hooks/events/useEvent';
import usePermission from 'src/hooks/permissions/usePermissions';
import { format, parseISO } from 'date-fns';

const Event: FC = () => {
  const { organizationSlug, eventId } = useParams();
  const { event, fetching } = useEvent();
  const { eventSection, linkToEvent } = useAppNavigations();
  const { isPermitted: canCreateEvent } = usePermission({
    action: 'create_event_ui',
  });

  return (
    <div>
      <Title>
        <div className="flex justify-between items-center px-4">
          <Link
            className={clsx(
              'hover:scale-125 transition-transform text-2xl',
              !event?.prevEvent && 'invisible pointer-events-none',
            )}
            to={linkToEvent({
              eventId: event?.prevEvent?.id,
              section: eventSection,
            })}
          >
            <FontAwesomeIcon className="fa-fw" icon={faChevronLeft} />
          </Link>
          {fetching ? (
            <Loading />
          ) : (
            <div>
              <div className="text-2xl">
                {eventId === 'new'
                  ? 'Create New Event'
                  : ( event?.name ?? '...' )}
              </div>
              {event?.location && (
                <div>
                  <div>{event.location.name}</div>
                  <div>
                    {[
                      event.location.streetAddress,
                      event.location.city,
                      event.location.region,
                      event.location.zipCode,
                    ].join( ', ' )}
                  </div>
                </div>
              )}
              {event?.startsAt && event.endsAt && (
                <div>
                  {`${format( parseISO( event?.startsAt ), 'MMM d, eee hh:mm a' )} - ${format( parseISO( event?.endsAt ), 'eee hh:mm a' )}`}
                </div>
              )}
              <div>
                {event && event.kind !== IEventKindEnum.Live && (
                  <div className="flex gap-2 items-center justify-center text-juno-orange-200">
                    <FontAwesomeIcon
                      className="fa-fw"
                      icon={faExclamationTriangle}
                    />
                    Legacy DR 3.0 Game
                  </div>
                )}
              </div>
            </div>
          )}
          {!event?.nextEvent && canCreateEvent && eventId !== 'new' ? (
            <Link
              className={clsx( 'hover:scale-125 transition-transform text-2xl' )}
              to={`/${organizationSlug}/events/new`}
            >
              <FontAwesomeIcon className="fa-fw" icon={faPlus} />
            </Link>
          ) : (
            <Link
              className={clsx(
                'hover:scale-125 transition-transform text-2xl',
                !event?.nextEvent && 'invisible pointer-events-none',
              )}
              to={linkToEvent({
                eventId: event?.nextEvent?.id,
                section: eventSection,
              })}
            >
              <FontAwesomeIcon className="fa-fw" icon={faChevronRight} />
            </Link>
          )}
        </div>
      </Title>
      <Outlet />
    </div>
  );
};

export default Event;
