import {
  isValid,
  parseISO,
  startOfMonth,
  addMonths,
  endOfMonth,
  format,
} from 'date-fns';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  initStart?: Date;
  initEnd?: Date;
  previousMonthsJump?: number;
  nextMonthsJump?: number;
}

const useCalendarNavigations = ({
  initStart = addMonths( startOfMonth( new Date()), -1 ),
  initEnd = addMonths( endOfMonth( new Date()), 2 ),
  previousMonthsJump = 1,
  nextMonthsJump = 1,
}: IProps ) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ start, setStart ] = useState(
    searchParams.get( 'start' ) && isValid( parseISO( searchParams.get( 'start' )! ))
      ? startOfMonth( parseISO( searchParams.get( 'start' )! ))
      : initStart,
  );
  const [ end, setEnd ] = useState(
    searchParams.get( 'end' ) && isValid( parseISO( searchParams.get( 'end' )! ))
      ? endOfMonth( parseISO( searchParams.get( 'end' )! ))
      : initEnd,
  );

  const handlePrevious = useCallback(() => {
    const nextStart = addMonths( start, previousMonthsJump * -1 );

    setStart( nextStart );
    setSearchParams( x => {
      x.set( 'start', format( nextStart, 'yyyy-MM-dd' ));
      return x;
    });
  }, [ previousMonthsJump, setSearchParams, start ]);

  const handleNext = useCallback(() => {
    const nextEnd = addMonths( end, nextMonthsJump );

    setEnd( nextEnd );
    setSearchParams( x => {
      x.set( 'end', format( nextEnd, 'yyyy-MM-dd' ));
      return x;
    });
  }, [ end, nextMonthsJump, setSearchParams ]);

  return {
    end,
    handleNext,
    handlePrevious,
    searchParams,
    setEnd,
    setSearchParams,
    setStart,
    start,
  };
};

export default useCalendarNavigations;
