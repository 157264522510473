import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useMath from './useMath';
import useSkillFinder from './useSkillFinder';

const useXp = () => {
  const { bodyMindFunction, linearFunction, skillFunction } = useMath();
  const { watch } = useFormContext();
  const { stats, xpEarned, lifeCapacity } = watch();
  const { body, mind, resolve, infection } = stats;
  const { skillsFinder, professionSkillsFinder, professionsFinder } =
    useSkillFinder();

  const bodyCost = useMemo(
    () => bodyMindFunction( body ),
    [ body, bodyMindFunction ],
  );
  const mindCost = useMemo(
    () => bodyMindFunction( mind ),
    [ mind, bodyMindFunction ],
  );

  const resolveCost = useMemo(
    () => linearFunction({ level: resolve, multiplier: 2, stacked: true }),
    [ resolve, linearFunction ],
  );

  const infectionCost = useMemo(
    () =>
      linearFunction({
        level: lifeCapacity > 0 ? lifeCapacity - 3 : infection,
        multiplier: 2,
        stacked: true,
      }),
    [ infection, lifeCapacity, linearFunction ],
  );

  const impactSkillsCost = useMemo(
    () =>
      skillsFinder({
        category: 'impact',
        isAberrant: false,
      })
        .map( x => skillFunction( x.level ))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ skillFunction, skillsFinder ],
  );

  const impactSkillsCostAberrant = useMemo(
    () =>
      skillsFinder({
        category: 'impact',
        isAberrant: true,
      })
        .map( x => skillFunction( x.level ))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ skillFunction, skillsFinder ],
  );

  const developmentSkillsCost = useMemo(
    () =>
      skillsFinder({
        category: 'development',
        isAberrant: false,
      })
        .map( x => skillFunction( x.level ))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ skillFunction, skillsFinder ],
  );

  const developmentSkillsCostAberrant = useMemo(
    () =>
      skillsFinder({
        category: 'development',
        isAberrant: true,
      })
        .map( x => skillFunction( x.level ))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ skillFunction, skillsFinder ],
  );

  const impactProfessionSkillsCost = useMemo(
    () =>
      professionSkillsFinder({
        category: 'impact',
        isAberrant: false,
      })
        .map( x => linearFunction({ level: x.level, stacked: true }))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ linearFunction, professionSkillsFinder ],
  );

  const impactProfessionSkillsCostAberrant = useMemo(
    () =>
      professionSkillsFinder({
        category: 'impact',
        isAberrant: true,
      })
        .map( x => linearFunction({ level: x.level, stacked: true }))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ linearFunction, professionSkillsFinder ],
  );

  const developmentProfessionSkillsCost = useMemo(
    () =>
      professionSkillsFinder({
        category: 'development',
        isAberrant: false,
      })
        .map( x => linearFunction({ level: x.level, stacked: true }))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ linearFunction, professionSkillsFinder ],
  );

  const developmentProfessionSkillsCostAberrant = useMemo(
    () =>
      professionSkillsFinder({
        category: 'development',
        isAberrant: true,
      })
        .map( x => linearFunction({ level: x.level, stacked: true }))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ linearFunction, professionSkillsFinder ],
  );

  const weaponProfessionSkillsCost = useMemo(
    () =>
      professionSkillsFinder({
        category: 'default',
        isAberrant: false,
      })
        .map( x => linearFunction({ level: x.level, stacked: true }))
        .reduce(( a: number, b: number ) => a + b, 0 ),
    [ linearFunction, professionSkillsFinder ],
  );

  const professionsCost = useMemo(
    () => linearFunction({ level: professionsFinder().length, stacked: true }),
    [ linearFunction, professionsFinder ],
  );

  const statsCost = useMemo(
    () => bodyCost + mindCost + resolveCost + infectionCost,
    [ bodyCost, infectionCost, mindCost, resolveCost ],
  );

  const impactsCost = useMemo(
    () => impactSkillsCost + impactProfessionSkillsCost,
    [ impactProfessionSkillsCost, impactSkillsCost ],
  );

  const developmentsCost = useMemo(
    () => developmentSkillsCost + developmentProfessionSkillsCost,
    [ developmentProfessionSkillsCost, developmentSkillsCost ],
  );

  const aberrantsCost = useMemo(
    () =>
      impactSkillsCostAberrant +
      impactProfessionSkillsCostAberrant +
      developmentSkillsCostAberrant +
      developmentProfessionSkillsCostAberrant,
    [
      developmentProfessionSkillsCostAberrant,
      developmentSkillsCostAberrant,
      impactProfessionSkillsCostAberrant,
      impactSkillsCostAberrant,
    ],
  );

  const basicSkillsCost = useMemo(
    () => ({
      impact: impactSkillsCost + impactProfessionSkillsCost,
      development: developmentSkillsCost + developmentProfessionSkillsCost,
    }),

    [
      developmentProfessionSkillsCost,
      developmentSkillsCost,
      impactProfessionSkillsCost,
      impactSkillsCost,
    ],
  );

  const professionSkillsCost = useMemo(
    () =>
      impactProfessionSkillsCost +
      developmentProfessionSkillsCost +
      developmentProfessionSkillsCost,
    [ developmentProfessionSkillsCost, impactProfessionSkillsCost ],
  );

  const xpExpenditure = useMemo(
    () => ({
      statsCost,
      weaponProfessionSkillsCost,
      professionsCost,
      impactsCost,
      developmentsCost,
      aberrantsCost,
    }),
    [
      statsCost,
      weaponProfessionSkillsCost,
      professionsCost,
      impactsCost,
      developmentsCost,
      aberrantsCost,
    ],
  );

  const spentXp = useMemo(
    () => Object.values( xpExpenditure ).reduce(( a, b ) => a + b, 0 ),
    [ xpExpenditure ],
  );

  const unspentXp = useMemo(() => xpEarned - spentXp, [ xpEarned, spentXp ]);

  const isOverSpent = useMemo(() => spentXp > xpEarned, [ spentXp, xpEarned ]);

  const overspentXp = useMemo(() => spentXp - xpEarned, [ spentXp, xpEarned ]);

  return {
    ...xpExpenditure,
    basicSkillsCost,
    professionSkillsCost,
    isOverSpent,
    overspentXp,
    spentXp,
    unspentXp,
    xpEarned,
  };
};

export default useXp;
