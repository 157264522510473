import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const EventCalendarSidebar: FC = () => {
  const { linkToEvents, isEventsPath } = useAppNavigations();

  return (
    <Link
      to={linkToEvents}
      className={clsx(
        'border-l-4 hover:border-juno-gray-200 overflow-x-hidden p-2 block w-full font-semibold',
        isEventsPath ? 'border-juno-gray-200' : 'border-transparent',
      )}
    >
      <FontAwesomeIcon icon={faCalendar} className="ml-1 mr-0.5 fa-fw" /> Event
      Calendar
    </Link>
  );
};

export default EventCalendarSidebar;
