import { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import AugmentedInput from 'src/components/0200_augmented_input';
import {
  requestPasswordResetMutation,
  updatePasswordMutation,
} from 'src/graphql/mutations/passwords.graphql';
import {
  IRequestPasswordResetMutation,
  IRequestPasswordResetMutationVariables,
  IUpdatePasswordMutation,
  IUpdatePasswordMutationVariables,
} from 'src/graphql/mutations/passwords.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useRootUser from 'src/hooks/players/useRootUser';
import { useMutation } from 'urql';

const SecuritySection: FC = () => {
  const { playerId } = useParams();
  const { buttonState } = useButtonStates();
  const {
    rootUser: { id: rootUserId },
  } = useRootUser();
  const {
    getValues,
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const [ updatePasswordResult, updatePassword ] = useMutation<
    IUpdatePasswordMutation,
    IUpdatePasswordMutationVariables
  >( updatePasswordMutation );

  const [ requestPasswordResetResult, requestPasswordreset ] = useMutation<
    IRequestPasswordResetMutation,
    IRequestPasswordResetMutationVariables
  >( requestPasswordResetMutation );

  const watchedOldPassword = watch( 'oldPassword' );
  const watchedNewPassword = watch( 'newPassword' );
  const watchedConfirmNewPassword = watch( 'confirmNewPassword' );
  const passwordConfirmMismatch = useMemo(() => {
    if ( !watchedNewPassword || !watchedConfirmNewPassword ) return null;

    return watchedNewPassword !== watchedConfirmNewPassword;
  }, [ watchedNewPassword, watchedConfirmNewPassword ]);

  const isPasswordUpdateable = useMemo(
    () =>
      watchedOldPassword &&
      watchedNewPassword &&
      watchedConfirmNewPassword &&
      watchedOldPassword !== watchedNewPassword &&
      watchedNewPassword === watchedConfirmNewPassword,
    [ watchedConfirmNewPassword, watchedNewPassword, watchedOldPassword ],
  );

  const handleUpdatePassword = useCallback(() => {
    updatePassword({
      oldPassword: getValues( 'oldPassword' ),
      newPassword: getValues( 'newPassword' ),
    }).then( res => {
      if ( !res.data?.updatePassword?.error ) {
        reset();
      }
    });
  }, [ getValues, reset, updatePassword ]);

  const handleRequestPasswordReset = useCallback(() => {
    requestPasswordreset({ username: String( playerId ) });
  }, [ playerId, requestPasswordreset ]);

  if ( Number( playerId ) !== rootUserId ) {
    return (
      <AugmentedInput title="Password">
        <Button
          defaultLabel="Send Password Reset Link"
          state={buttonState({
            isFetching: requestPasswordResetResult.fetching,
            isSuccessful:
              requestPasswordResetResult.data &&
              !requestPasswordResetResult.data.requestPasswordReset?.error,
            isValid: true,
            isDirty: true,
          })}
          onClick={handleRequestPasswordReset}
          stateLabel={{
            loading: 'Sending Reset Link...',
            success: 'Reset Link Sent',
          }}
        />
      </AugmentedInput>
    );
  }

  return (
    <AugmentedInput title="Password">
      <div>
        <Input
          className="mb-2"
          placeholder="Old Password"
          type="password"
          {...register( 'oldPassword' )}
        />
        <Input
          className="mb-2"
          placeholder="New Password"
          type="password"
          {...register( 'newPassword' )}
        />
        <Input
          className="mb-2"
          placeholder="Confirm New Password"
          type="password"
          onEnter={handleUpdatePassword}
          {...register( 'confirmNewPassword' )}
        />
        {passwordConfirmMismatch && (
          <div className="text-juno-orange-200 mb-2">
            New passwords do not match
          </div>
        )}
        {updatePasswordResult.data?.updatePassword?.error && (
          <div className="text-juno-orange-200 mb-2">
            {updatePasswordResult.data?.updatePassword?.error}
          </div>
        )}
        <Button
          defaultLabel="Update Password"
          state={buttonState({
            isHighlight: true,
            isDirty,
            isFetching: updatePasswordResult.fetching,
            isValid: !!isPasswordUpdateable,
            isSuccessful:
              updatePasswordResult.data &&
              !updatePasswordResult.data.updatePassword?.error,
          })}
          stateLabel={{
            loading: 'Updating password...',
            success: 'Password updated',
          }}
          onClick={handleUpdatePassword}
        />
      </div>
    </AugmentedInput>
  );
};

export default SecuritySection;
