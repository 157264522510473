import { FC, useCallback, useMemo, useState } from 'react';
import useCharacter from 'src/hooks/characters/useCharacter';
import useOrganizationFellowships from 'src/hooks/events/useOrganizationFellowships';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from 'urql';
import {
  ICreateCharacterFellowshipMutation,
  ICreateCharacterFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import { createCharacterFellowship } from 'src/graphql/mutations/fellowships.graphql';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import Input from 'src/components/0100_input';
import { IFellowshipKindEnum } from 'src/graphql/types';
import CharacterFellowship from './CharacterFellowship';

interface IProps extends ReturnType<typeof useCharacter> {
  type: IFellowshipKindEnum;
  onFellowshipAssignment: () => void;
}

const title = {
  [IFellowshipKindEnum.Disease]: 'Diseases',
  [IFellowshipKindEnum.Society]: 'Society Memberships',
};

const DystopiaRisingFellowships: FC<IProps> = ({
  character,
  canEditSensitiveData,
  type,
  onFellowshipAssignment,
}) => {
  const { fellowships } = useOrganizationFellowships({ type });
  const [ isExpanded, setIsExpanded ] = useState( false );
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const { register, watch } = useForm({
    defaultValues: { fellowshipQuery: '' },
  });
  const { fellowshipQuery } = watch();
  const filteredFellowships = useMemo(() => {
    const exlucdedFellowships = fellowships.filter(
      x =>
        !character?.characterFellowships
          .map( y => y.fellowship.id )
          .includes( x.id ),
    );
    if ( fellowshipQuery.trim().length === 0 ) return exlucdedFellowships;

    return exlucdedFellowships.filter( x =>
      x.name.toLowerCase().includes( fellowshipQuery.trim().toLowerCase()),
    );
  }, [ character?.characterFellowships, fellowshipQuery, fellowships ]);
  const [ assignmentResult, assign ] = useMutation<
    ICreateCharacterFellowshipMutation,
    ICreateCharacterFellowshipMutationVariables
  >( createCharacterFellowship );

  const handleAssignment = useCallback(
    ({ fellowshipId }: { fellowshipId: number }) => {
      setIsSuccessful( false );
      assign({ characterId: Number( character?.id ), fellowshipId }).then( res => {
        if ( res.data?.createCharacterFellowship?.fellowship?.id ) {
          onFellowshipAssignment();
          setIsSuccessful( true );
        }
      });
    },
    [ assign, character?.id, onFellowshipAssignment ],
  );

  if ( !character ) return null;

  return (
    <div>
      <Title variant="heading">
        <div className="flex justify-between items-center">
          <div className="text-2xl">{title[type]}</div>
          <div>
            {assignmentResult.fetching && <Loading size="small" />}
            {isSuccessful && <FontAwesomeIcon icon={faCheck} />}
          </div>
        </div>
      </Title>
      <div className="grid gap-2">
        {character.characterFellowships.length === 0 && (
          <div className="opacity-50">No {title[type]} found</div>
        )}
        {character.characterFellowships
          .filter( x => x.fellowship.kind === type )
          .sort(( a, b ) => a.fellowship.name.localeCompare( b.fellowship.name ))
          .map( x => (
            <CharacterFellowship
              key={x.id}
              {...x}
              type={type}
              canEditSensitiveData={canEditSensitiveData}
            />
          ))}
      </div>
      {canEditSensitiveData && (
        <div className="grid gap-2 pt-4">
          <button
            type="button"
            className="w-full flex justify-between items-center border-b border-juno-gray-200"
            onClick={() => setIsExpanded( x => !x )}
          >
            <div className="text-lg">Assign {title[type]}</div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={clsx(
                'transition-all duration-300',
                isExpanded && '-scale-y-100',
              )}
            />
          </button>
          <div
            className={clsx(
              'grid gap-2 transition-all duration-300',
              isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
            )}
          >
            <div className="overflow-hidden grid gap-2">
              <div className="pl-2 py-1 pr-1">
                <Input
                  fullWidth
                  placeholder="Search Societies..."
                  {...register( 'fellowshipQuery' )}
                />
              </div>
              <motion.div layout className="grid gap-1">
                {filteredFellowships
                  .sort(( a, b ) => a.name.localeCompare( b.name ))
                  .map( x => (
                    <button
                      type="button"
                      key={x.id}
                      className={clsx(
                        'w-full text-left border-l-4 border-transparent pl-2 py-1 hover:border-juno-gray-200 hover:dark-box transition-all duration-300',
                      )}
                      onClick={() => handleAssignment({ fellowshipId: x.id })}
                    >
                      {x.name}
                    </button>
                  ))}
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DystopiaRisingFellowships;
