import { FC, useState } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import Title from 'src/components/0100_title';
import StreamingProgress from './StreamingProgress';

const Invoice: FC = () => {
  const [ operationInProgress, setOperationInProgress ] =
    useState<string>( 'idle' );
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });

  if ( !canManageOrganization ) return null;

  return (
    <div className="grid grid-cols-1 gap-4">
      <Title title="Invoicing System" />
      <StreamingProgress
        isBusy={![ 'idle', 'snapshot' ].includes( operationInProgress )}
        endpoint="event_attendances"
        name="Snapshot"
        onBusy={isBusy => setOperationInProgress( isBusy ? 'snapshot' : 'idle' )}
      />
      <StreamingProgress
        isBusy={![ 'idle', 'diff' ].includes( operationInProgress )}
        endpoint="diff"
        name="Diff"
        onBusy={isBusy => setOperationInProgress( isBusy ? 'diff' : 'idle' )}
      />
      <StreamingProgress
        hasOutput
        isBusy={![ 'idle', 'invoice' ].includes( operationInProgress )}
        endpoint="invoice"
        name="Invoice"
        onBusy={isBusy => setOperationInProgress( isBusy ? 'invoice' : 'idle' )}
      />
    </div>
  );
};

export default Invoice;
