import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import SidebarLink from 'src/components/0100_sidebar_link';

const BranchesSidebar: FC = () => {
  const { branchId } = useParams();
  const { linkToBranch, branchSection, isBranchManagementPath } =
    useAppNavigations();
  const { branches } = useOrganizationBranches({ owned: true });
  const [ isExpanded, setIsExpanded ] = useState( false );

  useEffect(() => {
    if ( isBranchManagementPath ) {
      setIsExpanded( true );
    }
  }, [ isBranchManagementPath ]);

  if ( branches.length < 1 ) return null;

  return (
    <div className="gray-box-angled">
      <button
        type="button"
        className="flex justify-between w-full px-2 py-1 text-left font-bold"
        onClick={() => setIsExpanded( x => !x )}
      >
        <div>Branches Management</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'px-2 mt-1 transition-transform duration-300',
            isExpanded && '-scale-y-100',
          )}
        />
      </button>
      <div
        className={clsx(
          'gray-box-angled grid gap-2 transition-all duration-300',
          isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className="overflow-hidden grid">
          {branches
            .sort(( a, b ) => a.name.localeCompare( b.name ))
            .map( branch => (
              <SidebarLink
                key={branch.id}
                label={branch.name}
                url={linkToBranch({
                  branchId: branch.id,
                  section: branchSection ?? '',
                })}
                isActive={Number( branchId ) === branch.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default BranchesSidebar;
