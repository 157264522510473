import { useQuery } from 'urql';
import { useParams } from 'react-router-dom';
import {
  IGetEventShiftsQuery,
  IGetEventShiftsQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { getEventShifts } from 'src/graphql/queries/events.graphql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useEventShifts = () => {
  const { eventId } = useParams();
  const { isRootUserValidated } = useAuth();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventShiftsQuery,
    IGetEventShiftsQueryVariables
  >({
    query: getEventShifts,
    variables: { eventId: Number( eventId ) },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( eventId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    shiftKinds: data?.event?.shiftKinds || [],
    shifts: data?.event?.shifts || [],
  };
};

export default useEventShifts;
