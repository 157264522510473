import { TButtonState } from 'src/components/0100_button';

interface IProps {
  isFetching?: boolean;
  isDirty?: boolean;
  isValid?: boolean;
  isSuccessful?: boolean; // terminal state
  isHighlight?: boolean;
  isWarning?: boolean;
}

const useButtonStates = () => {
  const buttonState = ({
    isFetching,
    isDirty,
    isValid,
    isSuccessful,
    isHighlight,
    isWarning,
  }: IProps ): TButtonState => {
    if ( isSuccessful ) return 'success';
    if ( isFetching ) return 'loading';
    if ( isDirty && isValid ) {
      if ( isHighlight ) return 'enabled-highlight';
      if ( isWarning ) return 'enabled-warning';
      return 'enabled';
    }

    return 'disabled';
  };

  return { buttonState };
};

export default useButtonStates;
