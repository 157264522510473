import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import AugmentedInput from 'src/components/0200_augmented_input';
import { useMutation } from 'urql';
import {
  ICreateItemCraftingComponentMutation,
  ICreateItemCraftingComponentMutationVariables,
  ICreateItemCraftingFinalProductMutation,
  ICreateItemCraftingFinalProductMutationVariables,
  IDestroyItemCraftingMutation,
  IDestroyItemCraftingMutationVariables,
  IUpdateItemCraftingMutation,
  IUpdateItemCraftingMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import {
  createItemCraftingComponent,
  createItemCraftingFinalProduct,
  destroyItemCrafting,
  updateItemCrafting,
} from 'src/graphql/mutations/items.graphql';
import clsx from 'clsx';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import ItemSearch from 'src/components/0400_item_search';
import ResponseBox from 'src/components/0100_response_box';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import ItemComponent, { ICraftingComponent } from './ItemComponent';
import ItemFinalProduct from './ItemFinalProduct';
import useItemEditPermission from '../../../hooks/useItemEditPermission';

interface IProps {
  id?: number;
  craftingMindCost?: number | null;
  craftingSkills?: string | null;
  craftingTimeInMinute?: number | null;
  craftingZone?: string | null;
  craftingComponents?: ICraftingComponent[];
  craftingFinalProducts?: {
    id: number;
    stack: number;
    finalProduct: {
      id: number;
      name: string;
      grade: IItemGradeEnum;
      kind: IItemKindEnum;
    };
  }[];
}

const ItemCrafting: FC<IProps> = ({ ...props }) => {
  const { canEdit } = useItemEditPermission();
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ isDeleting, setIsDeleting ] = useState( false );
  const [ addComponentError, setAddComponentError ] = useState<string | null>(
    null,
  );
  const [ addFinalProductError, setAddFinalProductError ] = useState<
    string | null
  >( null );
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      id: 0,
      craftingMindCost: props.craftingMindCost,
      craftingTimeInMinute: props.craftingTimeInMinute,
      craftingZone: props.craftingZone,
      craftingSkills: props.craftingSkills,
    },
  });
  const {
    craftingMindCost,
    craftingTimeInMinute,
    craftingZone,
    craftingSkills,
  } = watch();

  const [ , destroy ] = useMutation<
    IDestroyItemCraftingMutation,
    IDestroyItemCraftingMutationVariables
  >( destroyItemCrafting );

  const [ , update ] = useMutation<
    IUpdateItemCraftingMutation,
    IUpdateItemCraftingMutationVariables
  >( updateItemCrafting );

  const [ , addComponent ] = useMutation<
    ICreateItemCraftingComponentMutation,
    ICreateItemCraftingComponentMutationVariables
  >( createItemCraftingComponent );

  const [ , addFinalProduct ] = useMutation<
    ICreateItemCraftingFinalProductMutation,
    ICreateItemCraftingFinalProductMutationVariables
  >( createItemCraftingFinalProduct );

  const handleUpdate = useCallback(() => {
    if ( !isDirty ) return;
    update({
      itemCraftingId: Number( props.id ),
      craftingMindCost: Number( craftingMindCost ),
      craftingTimeInMinute: Number( craftingTimeInMinute ),
      craftingZone,
      craftingSkills,
    }).then( res => {
      if ( res.data?.updateItemCrafting?.itemCrafting ) {
        const {
          id,
          craftingTimeInMinute,
          craftingMindCost,
          craftingZone,
          craftingSkills,
        } = res.data.updateItemCrafting.itemCrafting;
        setIsSuccessful( true );
        setTimeout(() => setIsSuccessful( false ), 3000 );
        reset({
          id,
          craftingMindCost,
          craftingSkills,
          craftingTimeInMinute,
          craftingZone,
        });
      }
    });
  }, [
    craftingMindCost,
    craftingSkills,
    craftingTimeInMinute,
    craftingZone,
    isDirty,
    props.id,
    reset,
    update,
  ]);

  const handleDestroy = useCallback(() => {
    if ( !props.id ) return;
    destroy({ itemCraftingId: props.id });
    setIsDeleting( true );
  }, [ destroy, props.id ]);

  if ( isDeleting ) return null;

  return (
    <div>
      <div className="flex justify-between items-center border-l-4 border-b border-juno-gray-700 midtone-box p-2">
        <div>Crafting Procedure</div>
        {canEdit && <ConfirmDropdown onConfirm={handleDestroy} />}
      </div>

      <div>
        <div
          className={clsx(
            'border-l-4 border-b border-juno-gray-700 transition-all duration-500',
            isSuccessful && 'border-l-juno-green-400',
          )}
        >
          <AugmentedInput
            required
            isLocked={!canEdit}
            title="Time (mins)"
            {...register( 'craftingTimeInMinute', { onBlur: handleUpdate })}
          />
          <AugmentedInput
            required
            isLocked={!canEdit}
            title="Mind Cost"
            {...register( 'craftingMindCost', { onBlur: handleUpdate })}
          />
          <AugmentedInput
            fullWidth
            isLocked={!canEdit}
            title="Production Zone"
            {...register( 'craftingZone', { onBlur: handleUpdate })}
          />
          <AugmentedInput
            fullWidth
            isLocked={!canEdit}
            title="Skills"
            {...register( 'craftingSkills', { onBlur: handleUpdate })}
          />
        </div>

        <div className="border-b border-juno-gray-700">
          <div className="border-l-4 border-l-juno-gray-700 glass-pane text-center p-2 border-b border-juno-cyan-400">
            Input Components
          </div>
          {props.craftingComponents
            ?.sort(( a, b ) => a.component.name.localeCompare( b.component.name ))
            .map( x => <ItemComponent key={x.id} {...x} /> )}
          {canEdit && (
            <div className="flex items-center gap-4 pl-2 py-2 border-l-4 border-juno-gray-700">
              <div className="whitespace-nowrap w-24">Add Input</div>
              <div className="w-full">
                <ItemSearch
                  blueprint={false}
                  onSearchResultClick={({ itemId }) => {
                    setAddComponentError( null );
                    addComponent({
                      itemCraftingId: Number( props.id ),
                      componentId: itemId,
                    }).then( res => {
                      if ( res.data?.createItemCraftingComponent?.error ) {
                        setAddComponentError(
                          res.data.createItemCraftingComponent.error,
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {addComponentError && (
            <ResponseBox type="error">{addComponentError}</ResponseBox>
          )}
        </div>

        <div className="border-b border-juno-gray-700">
          <div className="border-l-4 border-l-juno-gray-700 glass-pane text-center p-2 border-b border-juno-purple-400">
            Output Products
          </div>
          {props.craftingFinalProducts
            ?.sort(( a, b ) =>
              a.finalProduct.name.localeCompare( b.finalProduct.name ),
            )
            .map( x => <ItemFinalProduct key={x.id} {...x} /> )}
          {canEdit && (
            <div className="flex items-center gap-4 pl-2 py-2 border-l-4 border-juno-gray-700">
              <div className="whitespace-nowrap w-24">Add Output</div>
              <div className="w-full">
                <ItemSearch
                  blueprint={false}
                  onSearchResultClick={({ itemId }) => {
                    setAddFinalProductError( null );
                    addFinalProduct({
                      itemCraftingId: Number( props.id ),
                      finalProductId: itemId,
                    }).then( res => {
                      if ( res.data?.createItemCraftingFinalProduct?.error ) {
                        setAddFinalProductError(
                          res.data.createItemCraftingFinalProduct.error,
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {addFinalProductError && (
            <ResponseBox type="error">{addFinalProductError}</ResponseBox>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCrafting;
