import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { clamp, pick } from 'lodash';
import lineageData from 'src/components/0200_lineage_dropdown/data';
import { TLore, TSkill } from '../types';
import useSkillFinder from './useSkillFinder';
import skillData, { TProfession } from '../SkillBuilder/data';
import useMath from './useMath';

interface IProps {
  canEdit?: boolean;
  levelCap?: number;
}

const useCharacterBuilder = ({ canEdit = false, levelCap = 1 }: IProps ) => {
  const { skillFunction, linearFunction } = useMath();
  const formContext = useFormContext();
  const { setValue, watch } = formContext;
  const { skills, stats, lineageId, lores } = watch();
  const { body, mind, resolve, infection } = stats;
  const {
    getSkill,
    getSkillLevel,
    getProfession,
    isBasicSkill,
    isProfession,
    isProfessionSkill,
  } = useSkillFinder();

  const innateStats = useMemo(
    () =>
      pick( lineageData.find( x => x.id === lineageId ) || { body: 0, mind: 0 }, [
        'body',
        'mind',
      ]),
    [ lineageId ],
  );

  const getSkillXp = useCallback(
    ( skillName: string, level: number ) => {
      if ( isBasicSkill( skillName )) {
        return skillFunction( level );
      }

      if ( isProfessionSkill( skillName )) {
        return linearFunction({
          level,
          stacked: true,
        });
      }

      return 0;
    },
    [ isBasicSkill, isProfessionSkill, linearFunction, skillFunction ],
  );

  const maxPurchaseableStats = useMemo(() => {
    const { body: innateBody, mind: innateMind } = innateStats;

    return {
      body: 100 - innateBody,
      mind: 100 - innateMind,
    };
  }, [ innateStats ]);

  const hasProfession = useCallback(
    ( professionName: string ) =>
      skillData.find( x => x.name === professionName )?.isProfession &&
      getSkillLevel( professionName ) > 0,
    [ getSkillLevel ],
  );

  const educationLevel = useMemo(
    () => getSkillLevel( 'Education' ),
    [ getSkillLevel ],
  );
  const maxLores = useMemo(() => educationLevel * 3, [ educationLevel ]);
  const loreCount = useMemo(() => lores.length, [ lores ]);
  const isLoreOverLimit = useMemo(
    () => loreCount > maxLores,
    [ loreCount, maxLores ],
  );

  const professionDependentLevelsRequirement = useCallback(
    ( professionName: string ) => {
      const profession = skillData.find( x => x.name === professionName );

      if ( !profession ) return 0;
      switch ( profession.category ) {
        case 'impact':
          return 5;
        case 'development':
          return 6;
        case 'default':
          return 30;
        default:
          return 0;
      }
    },
    [],
  );

  const professionCount = useMemo(
    () =>
      ( skills as TSkill[])
        .map(
          acquiredSkill =>
            skillData.find( data => data.name === acquiredSkill.name )
              ?.isProfession && acquiredSkill.level > 0,
        )
        .reduce(( a, b ) => a + ( b ? 1 : 0 ), 0 ),
    [ skills ],
  );

  const isAtProfessionLimit = useMemo(
    () => professionCount >= 3,
    [ professionCount ],
  );

  const professionUnlockProgress = useCallback(
    ( professionName: string ) => {
      const profession = skillData.find( x => x.name === professionName );

      if ( !profession ) return 0;
      switch ( profession.category ) {
        case 'default':
          return mind + body;
        default:
          return skillData
            .filter( x => x.leadsTo === professionName )
            .map( dep => getSkillLevel( dep.name ))
            .reduce(( a, b ) => a + b, 0 );
      }
    },
    [ body, getSkillLevel, mind ],
  );

  const canIncrementSkillLevel = useCallback(
    ( skillName: string ) =>
      canEdit && isBasicSkill( skillName ) && getSkillLevel( skillName ) < levelCap,
    [ canEdit, getSkillLevel, isBasicSkill, levelCap ],
  );

  const hasDefaultProfession = useMemo(
    () =>
      skillData
        .filter( x => x.category === 'default' )
        .filter( profession => getSkillLevel( profession.name ) > 0 ).length > 0,
    [ getSkillLevel ],
  );

  const siblingStatValue = useCallback(
    ( stat: 'body' | 'mind' ) => ( stat === 'body' ? mind : body ),
    [ body, mind ],
  );

  const lowerClampedValue = useCallback(
    ( stat: 'body' | 'mind' ) =>
      hasDefaultProfession ? 30 - siblingStatValue( stat ) : 0,
    [ hasDefaultProfession, siblingStatValue ],
  );

  const upperClampedValue = useCallback(
    ( stat: 'body' | 'mind' ) => {
      const { body: innateBody, mind: innateMind } = innateStats;

      return 100 - ( stat === 'body' ? innateBody : innateMind );
    },
    [ innateStats ],
  );

  const clampValue = useCallback(
    ( stat: 'body' | 'mind', value: number ) =>
      clamp( value, lowerClampedValue( stat ), upperClampedValue( stat )),
    [ lowerClampedValue, upperClampedValue ],
  );

  const canIncrementStat = useCallback(
    ( stat: string ) => {
      if ( !canEdit ) return false;

      switch ( stat ) {
        case 'Resolve':
          return resolve < 5;
        case 'Infection':
          return infection < 5;
        case 'Body':
          return clampValue( 'body', body + 1 ) > body;
        case 'Mind':
          return clampValue( 'mind', mind + 1 ) > mind;
        default:
          return false;
      }
    },
    [ body, canEdit, clampValue, infection, mind, resolve ],
  );

  const canDecrementSkillOrStat = useCallback(
    ( entityName: string ) => {
      if ( !canEdit ) return false;
      if ([ 'Resolve', 'Infection', 'Body', 'Mind' ].includes( entityName )) {
        switch ( entityName ) {
          case 'Resolve':
            return resolve > 0;
          case 'Infection':
            return infection > 0;
          case 'Body':
            return clampValue( 'body', body - 1 ) < body;
          case 'Mind':
            return clampValue( 'mind', mind - 1 ) < mind;
          default:
            return false;
        }
      }

      // handle Profession Skills
      if ( isProfessionSkill( entityName )) {
        return getSkillLevel( entityName ) > 0;
      }

      // Basic Skills OR Profession
      const skillInfo = skillData.find( x => x.name === entityName );

      if ( !skillInfo ) return false;
      if ( getSkillLevel( entityName ) === 0 ) return false;

      const profession = skillData.find(
        x => x.isProfession && x.name === entityName,
      );

      // handle non-zero Profession
      if ( profession ) {
        const professionSkills = ( profession as TProfession ).skills.filter(
          x => getSkillLevel( x.name ) > 0,
        );

        return professionSkills.length === 0;
      }

      // handle Basic SKills
      const professionViaBasicSkill = skillData.find(
        x => x.isProfession && x.name === skillInfo.leadsTo,
      );

      if ( !professionViaBasicSkill ) return getSkillLevel( entityName ) > 0;
      if ( getSkillLevel( professionViaBasicSkill.name ) === 0 ) return true;

      const sibling = skillData.find(
        x =>
          x.leadsTo === professionViaBasicSkill.name && x.name !== entityName,
      );
      if ( !sibling ) return false;

      const levelRequirement = professionDependentLevelsRequirement(
        professionViaBasicSkill.name,
      );

      const postDecrementSkillLevels = [
        Math.max( getSkillLevel( entityName ) - 1, 0 ),
        getSkillLevel( sibling.name ),
      ];

      const postDecrementLevels = postDecrementSkillLevels.reduce(
        ( a, b ) => a + b,
        0,
      );

      return postDecrementLevels >= levelRequirement;
    },
    [
      body,
      canEdit,
      clampValue,
      getSkillLevel,
      infection,
      isProfessionSkill,
      mind,
      professionDependentLevelsRequirement,
      resolve,
    ],
  );

  const canUnlockProfession = useCallback(
    ( professionName: string ) => {
      if ( !canEdit ) return false;
      if ( isAtProfessionLimit ) return false;

      const profession = skillData.find( x => x.name === professionName );

      if ( !profession ) return false;

      const dependentLevels = professionUnlockProgress( professionName );

      return (
        dependentLevels >= professionDependentLevelsRequirement( professionName )
      );
    },
    [
      canEdit,
      isAtProfessionLimit,
      professionDependentLevelsRequirement,
      professionUnlockProgress,
    ],
  );

  const canIncrementProfessionSkillLevel = useCallback(
    ( professionSkillName: string ) => {
      if ( !canEdit ) return false;
      const profession = getProfession( professionSkillName );

      if ( !profession ) return false;

      return (
        ( getSkillLevel( professionSkillName ) > 0 || hasProfession( profession )) &&
        getSkillLevel( professionSkillName ) < levelCap
      );
    },
    [ canEdit, getProfession, getSkillLevel, hasProfession, levelCap ],
  );

  const setStat = useCallback(
    ({
      attribute,
      value,
    }: {
      attribute: 'body' | 'mind' | 'resolve' | 'infection';
      value: number;
    }) => {
      if ( attribute === 'resolve' || attribute === 'infection' ) {
        setValue(
          'stats',
          {
            ...stats,
            [attribute]: Math.max( Math.min( value, 5 ), 0 ),
          },
          { shouldDirty: true },
        );
        return;
      }

      setValue(
        'stats',
        {
          ...stats,
          [attribute]: clampValue( attribute, value ),
        },
        { shouldDirty: true },
      );
    },
    [ clampValue, setValue, stats ],
  );

  const updateSkillOrStatLevel = useCallback(
    ({
      id,
      skillName,
      amount,
    }: {
      id: number;
      skillName: string;
      amount: number;
    }) => {
      if ( skillName === 'Resolve' || skillName === 'Infection' ) {
        const attribute = skillName.toLowerCase() as 'resolve' | 'infection';
        setStat({
          attribute,
          value: stats[attribute] + amount,
        });
      }
      const skill = getSkill( skillName );
      const currentSkills = skills as TSkill[];

      if ( skill ) {
        setValue(
          'skills',
          currentSkills
            .filter( x => x.id !== id )
            .concat({
              id,
              name: skillName,
              level: clamp( skill.level + amount, 0, levelCap ),
            }),
          { shouldDirty: true },
        );
      } else {
        setValue(
          'skills',
          currentSkills.concat({
            id,
            name: skillName,
            level: clamp( amount, 0, levelCap ),
          }),
          { shouldDirty: true },
        );
      }
    },
    [ getSkill, levelCap, setStat, setValue, skills, stats ],
  );

  const toggleLore = useCallback(
    ({ id, name }: { id: number; name: string }) => {
      if ( !canEdit ) return;
      if ( lores.find(( x: TLore ) => x.id === id )) {
        setValue(
          'lores',
          ( lores as TLore[]).filter( x => x.id !== id ),
          { shouldDirty: true },
        );
      } else if ( loreCount < maxLores ) {
        setValue( 'lores', lores.concat({ id, name }), { shouldDirty: true });
      }
    },
    [ canEdit, loreCount, lores, maxLores, setValue ],
  );

  return {
    ...formContext,
    canDecrementSkillOrStat,
    canIncrementProfessionSkillLevel,
    canIncrementSkillLevel,
    canIncrementStat,
    canUnlockProfession,
    getSkillLevel,
    getSkillXp,
    hasProfession,
    innateStats,
    isAtProfessionLimit,
    isProfession,
    isBasicSkill,
    isProfessionSkill,
    maxPurchaseableStats,
    professionDependentLevelsRequirement,
    professionUnlockProgress,
    setStat,
    updateSkillOrStatLevel,
    isLoreOverLimit,
    loreCount,
    maxLores,
    toggleLore,
  };
};

export default useCharacterBuilder;
