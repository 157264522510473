import { FC } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import useEvent from 'src/hooks/events/useEvent';
import useEmbossPrintTime from '../hooks/useEmbossPrintTime';

interface IProps {
  updatedAt: string;
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const EmbossAndReproduction: FC<IProps> = ({ updatedAt, itemReproduction }) => {
  const { rootUser } = useRootUser();
  const { printTime, crc } = useEmbossPrintTime();
  const { event } = useEvent();

  return (
    <div className="flex justify-between">
      <div className="w-2/5 flex justify-center py-[10px]">
        <div className="w-[192px] h-[192px] border border-juno-gray-700 rounded-full flex items-center justify-center text-center -rotate-45 text-xl">
          Emboss
          <br />
          Here
        </div>
      </div>
      <div className="w-3/5 flex items-end pb-1.5">
        <div className="w-full">
          <div className="text-xs security">
            <div>Blueprint Timestamp: {updatedAt}</div>
            <div>
              Printed At {printTime}
              {event && ` during ${event.name} (ID: #${event.id})`}
              by {`#${rootUser.id} ${rootUser.fullName}`}
            </div>
            <div>Authenticity Token: {crc}</div>
          </div>
          <div className="grid text-sm">
            <div className="flex items-center gap-2">
              <div className="w-2/5">Print Reproduction Skills</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionSkills}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5">Print Reproduction Time</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionTimeInMinute} minutes
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5">Print Reproduction Cost</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionMindCost} Mind
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5">Print Reproduction Resource</div>
              <div className="w-3/5 flex gap-2">
                {itemReproduction?.itemReproductionComponents?.map( x => (
                  <div key={x.id} className="flex items-center gap-1">
                    <div>{x.amount}</div>
                    <div>{x.item.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5">Print Reproduction Notes</div>
              <div className="w-3/5">{itemReproduction?.metadata.notes}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbossAndReproduction;
