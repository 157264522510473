import { format, isBefore, parseISO } from 'date-fns';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import AugmentedInput from 'src/components/0200_augmented_input';
import BranchSelect from 'src/components/0400_branch_select';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useBranchCorrectiveActions from 'src/hooks/organizations/branches/useBranchCorrectiveActions';

const headerRow = `${[
  'ID',
  'Issued At',
  'Player ID',
  'Player Name',
  'Issuer ID',
  'Issuer Name',
  'Type',
  'Starts At',
  'Ends At',
  'Reason',
].join( ',' )}\n`;

const OrganizationCorrectiveActions: FC = () => {
  const navigate = useNavigate();
  const { branchId } = useParams();
  const { linkToCorrectiveActions } = useAppNavigations();
  const { correctiveActions, fetching } = useBranchCorrectiveActions({
    branchId: Number( branchId ),
  });
  const methods = useForm({ defaultValues: { branchId: 0 }});
  const { register, watch } = methods;
  const { branchId: selectedBranchId } = watch();

  useEffect(() => {
    if ( selectedBranchId && Number( branchId ) !== Number( selectedBranchId )) {
      navigate( linkToCorrectiveActions({ branchId: selectedBranchId }));
    }
  }, [ branchId, linkToCorrectiveActions, navigate, selectedBranchId ]);

  return (
    <FormProvider {...methods}>
      <Title title="Corrective Actions" />
      <AugmentedInput title="Branch">
        <BranchSelect
          owned
          selectedValue={Number( branchId )}
          {...register( 'branchId' )}
        />
      </AugmentedInput>
      {fetching ? (
        <Loading />
      ) : (
        <div className="overflow-auto max-h-[67vh] max-w-full text-juno-gray-200 text-xs select-all">
          <pre>
            {headerRow +
              correctiveActions
                .sort(( a, b ) =>
                  isBefore( parseISO( a.createdAt ), parseISO( b.createdAt ))
                    ? 1
                    : -1,
                )
                .map( x =>
                  [
                    x.id,
                    format( parseISO( x.createdAt ), 'yyyy-MM-dd' ),
                    x.user.id,
                    x.user.fullName,
                    x.issuer.id,
                    x.issuer.fullName,
                    x.kind,
                    x.startsAt
                      ? format( parseISO( x.startsAt ), 'yyyy-MM-dd' )
                      : 'Indefinite',
                    x.endsAt
                      ? format( parseISO( x.startsAt ), 'yyyy-MM-dd' )
                      : 'Indefinite',
                    x.reason,
                  ].join( ',' ),
                )
                .join( '\n' )}
          </pre>
        </div>
      )}
    </FormProvider>
  );
};

export default OrganizationCorrectiveActions;
