import { getItemReproductions } from 'src/graphql/queries/items.graphql';
import {
  IGetItemReproductionsQuery,
  IGetItemReproductionsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';

const useItemReproductions = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemReproductionsQuery,
    IGetItemReproductionsQueryVariables
  >({
    query: getItemReproductions,
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItemReproductions;
