import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DystopiaRisingBuild from 'src/components/0500_character_builders/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Build: FC = () => {
  const { eventId } = useParams();
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );

  const character = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  return (
    <DystopiaRisingBuild
      showLifecycle
      {...character}
      canEdit
      eventId={Number( eventId )}
      playerId={Number( playerId )}
    />
  );
};

export default Build;
