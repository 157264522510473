import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  type: 'success' | 'error' | 'info' | 'neutral';
  withIcon?: IconDefinition;
}

const ResponseBox: FC<PropsWithChildren<IProps>> = ({
  type,
  children,
  withIcon,
}) => (
  <div
    className={clsx( 'p-2 my-2', {
      'bg-juno-cyan-400': type === 'success',
      'bg-juno-orange-400': type === 'error',
      'bg-juno-green-400': type === 'info',
      'bg-juno-gray-700': type === 'neutral',
    })}
  >
    <div className="flex gap-2 items-center">
      {withIcon && (
        <FontAwesomeIcon icon={withIcon} className="text-2xl pl-2 py-2" />
      )}
      <div className={clsx( withIcon && 'p-2' )}>{children}</div>
    </div>
  </div>
);

export default ResponseBox;
