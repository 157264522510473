import { useQuery } from 'urql';
import { getOrganizationRoles } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationRolesQuery,
  IGetOrganizationRolesQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';

const useOrganizationRoles = () => {
  const [{ data, error, fetching }] = useQuery<
    IGetOrganizationRolesQuery,
    IGetOrganizationRolesQueryVariables
  >({
    query: getOrganizationRoles,
  });

  return { data, error, fetching, roles: data?.organizationRoles || []};
};

export default useOrganizationRoles;
