import {
  faDollarSign,
  faHiking,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Toggle from 'react-toggle';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import usePermission from 'src/hooks/permissions/usePermissions';

const Attendance: FC = () => {
  const { event } = useEventAttendee();
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number( event?.id ),
  });
  const { setValue, watch } = useFormContext();
  const { isAttending, isPaid } = watch();

  if ( !canAssistCheckin ) return null;

  return (
    <div className="grid grid-cols-2 glass-pane py-8 justify-items-center">
      <div className="flex items-center gap-4">
        <Toggle
          checked={isPaid}
          icons={{
            checked: (
              <FontAwesomeIcon
                icon={faDollarSign}
                className="text-sm -mt-0.5"
              />
            ),
          }}
          onChange={x => {
            setValue( 'isPaid', x.target.checked, { shouldDirty: true });
            if ( !x.target.checked ) setValue( 'isAttending', false );
          }}
        />
        <div className="w-12">{isPaid ? 'Paid' : 'Unpaid'}</div>
      </div>

      {event?.isHomeGame ? (
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faHome} />
          Home Game
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <Toggle
            checked={isAttending}
            icons={{
              checked: (
                <FontAwesomeIcon icon={faHiking} className="text-sm -mt-0.5" />
              ),
              unchecked: (
                <FontAwesomeIcon
                  icon={faHiking}
                  className="text-sm -mt-0.5 opacity-50"
                />
              ),
            }}
            onChange={x => {
              setValue( 'isAttending', x.target.checked, { shouldDirty: true });
              if ( x.target.checked ) setValue( 'isPaid', true );
            }}
          />
          <div className="w-16">{isAttending ? 'Attending' : 'Absent'}</div>
        </div>
      )}
    </div>
  );
};

export default Attendance;
