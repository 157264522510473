import {
  IGetPlayerReferralsQuery,
  IGetPlayerReferralsQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { getPlayerReferrals } from 'src/graphql/queries/players.graphql';
import { useQuery } from 'urql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerReferrals = ({ playerId }: { playerId: number }) => {
  const { isRootUserValidated } = useAuth();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerReferralsQuery,
    IGetPlayerReferralsQueryVariables
  >({
    query: getPlayerReferrals,
    variables: { playerId },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( playerId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching: fetching || stale,
    referrals: data?.user?.referrals || [],
    invitations: data?.user?.invitations || [],
  };
};

export default usePlayerReferrals;
