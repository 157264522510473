import { getBranchCaps } from 'src/graphql/queries/branches.graphql';
import {
  IGetBranchCapsQuery,
  IGetBranchCapsQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import useRootUserRevalidation from 'src/hooks/useRootUserRevalidation';
import { useQuery } from 'urql';

const useBranchCaps = ({ branchId }: { branchId: number }) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetBranchCapsQuery,
    IGetBranchCapsQueryVariables
  >({
    query: getBranchCaps,
    variables: {
      branchId,
    },
    requestPolicy: 'cache-and-network',
    pause: branchId < 1,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    caps: data?.branch?.caps || [],
  };
};

export default useBranchCaps;
