import { gql } from 'urql';

export const getLocations = gql`
  query GetLocations {
    locations {
      id
      name
      streetAddress
      city
      region
      zipCode
    }
  }
`;
