import { getItemCraftingComponents } from 'src/graphql/queries/items.graphql';
import {
  IGetItemCraftingComponentsQuery,
  IGetItemCraftingComponentsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';

const useItemCraftingComponents = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemCraftingComponentsQuery,
    IGetItemCraftingComponentsQueryVariables
  >({
    query: getItemCraftingComponents,
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItemCraftingComponents;
