import { useMemo } from 'react';

interface IProps {
  category: string;
  isAberrant: boolean;
  isProfession: boolean;
  isProfessionSkill: boolean;
}

const useNode = ({
  category,
  isAberrant,
  isProfession,
  isProfessionSkill,
}: IProps ) => {
  const levelCap = useMemo(() => {
    if ( category === 'stat' ) return 8;
    if ( isProfession && !isProfessionSkill ) return 1;

    switch ( category ) {
      case 'development':
        return isProfessionSkill ? 1 : 3;
      default:
        return isProfessionSkill ? 5 : 10;
    }
  }, [ category, isProfession, isProfessionSkill ]);

  const levelClassName = useMemo(() => {
    if ( category === 'stat' ) return 'grid-cols-8';

    switch ( levelCap ) {
      case 3:
        return 'grid-cols-3';
      case 5:
        return 'grid-cols-5';
      case 10:
        return 'grid-cols-10';
      default:
        return 'grid-cols-1';
    }
  }, [ category, levelCap ]);

  const nodeClassName = useMemo(() => {
    switch ( category ) {
      case 'impact':
        return isAberrant ? 'cyan-box' : 'orange-box';
      case 'development':
        return isAberrant ? 'cyan-box' : 'purple-box';
      case 'stat':
      case 'default':
        return 'green-box';
      default:
        return 'midtone-box';
    }
  }, [ category, isAberrant ]);

  return {
    levelCap,
    levelClassName,
    nodeClassName,
  };
};

export default useNode;
