import { groupBy } from 'lodash';
import { FC } from 'react';
import { IShift, ITicket } from 'src/graphql/types';

export type TShift = Pick<IShift, 'label' | 'kind'>;
export type TTicket = Pick<ITicket, 'label'>;

interface IProps {
  shifts?: TShift[];
  ticket?: TTicket;
}

const TicketAndShifts: FC<IProps> = ({ shifts, ticket }) => {
  const shiftGroups = groupBy( shifts, 'kind' );

  if ( !shifts || !ticket ) return null;
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Ticket and Shifts</th>
        </tr>
      </thead>
      <tbody className="grid-1-2">
        <tr>
          <td>Ticket</td>
          <td>{ticket.label}</td>
        </tr>
        {shiftGroups.casting?.map(( shift, i ) => (
          <tr key={shift.label}>
            <td>Shift {i + 1}</td>
            <td>{shift.label}</td>
          </tr>
        ))}
        {shiftGroups.special?.map( shift => (
          <tr key={shift.label}>
            <td>Shift</td>
            <td>{shift.label}</td>
          </tr>
        ))}
        {shiftGroups.cleanup?.map( shift => (
          <tr key={shift.label}>
            <td>Cleanup</td>
            <td>{shift.label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TicketAndShifts;
