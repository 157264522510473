import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';

interface IProps {
  title: string | ReactNode;
}

const CollapsibleCard: FC<PropsWithChildren<IProps>> = ({
  title,
  children,
}) => {
  const [ isExpanded, setIsExpanded ] = useState( false );

  return (
    <div
      className={clsx(
        'border border-juno-gray-700 rounded p-4 gray-box',
        'grid',
        'hover:brightness-125 transition-all duration-300',
      )}
    >
      <button
        type="button"
        className="w-full flex justify-between items-center gap-2"
        onClick={() => setIsExpanded( x => !x )}
      >
        <div className="overflow-hidden whitespace-nowrap text-xl">{title}</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'transition-all duration-300',
            isExpanded && '-scale-y-100',
          )}
        />
      </button>
      <div
        className={clsx(
          'grid gap-2 transition-all duration-300',
          isExpanded ? 'grid-rows-[1fr] mt-4' : 'grid-rows-[0fr]',
        )}
      >
        <div className="overflow-hidden grid">{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleCard;
