import { FC, useMemo } from 'react';
import Title from 'src/components/0100_title';
import useOrganizationFellowships from 'src/hooks/events/useOrganizationFellowships';
import { Link, useParams } from 'react-router-dom';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import Input from 'src/components/0100_input';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { startCase } from 'lodash';
import { IFellowshipKindEnum } from 'src/graphql/types';
import NewFellowship from './NewFellowship';
import Fellowship from './Fellowship';
import Details from './Details';

interface IProps {
  type: IFellowshipKindEnum;
}

const title: Record<IFellowshipKindEnum, string> = {
  disease: 'Diseases',
  society: 'Societies',
};

const Fellowships: FC<IProps> = ({ type }) => {
  const { fellowshipId } = useParams();
  const { linkToFellowship } = useAppNavigations();
  const { fellowships } = useOrganizationFellowships({ type });
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();

  const filteredFellowships = useMemo(() => {
    if ( query.length === 0 ) return fellowships;

    return fellowships.filter( x =>
      x.name.toLowerCase().includes( query.toLowerCase()),
    );
  }, [ fellowships, query ]);

  return (
    <div>
      <Title title={title[type]} />
      <div className="flex justify-between items-start gap-8">
        <div className="grid gap-2 w-full">
          <Input
            fullWidth
            placeholder={`Search ${startCase( type )}...`}
            {...register( 'query' )}
          />
          <motion.div layout className="grid gap-2">
            {filteredFellowships
              .sort(( a, b ) => a.name.localeCompare( b.name ))
              .map( x => (
                <Link
                  to={linkToFellowship({
                    fellowshipId: x.id,
                    type,
                  })}
                  key={x.id}
                >
                  <Fellowship
                    fellowship={x}
                    isExpanded={
                      fellowshipId ? x.id === Number( fellowshipId ) : false
                    }
                    type={type}
                  />
                </Link>
              ))}
          </motion.div>
          <div>
            <NewFellowship type={type} />
          </div>
        </div>
        <div className="w-full hidden lg:block">
          {fellowshipId && <Details type={type} />}
        </div>
      </div>
    </div>
  );
};

export default Fellowships;
