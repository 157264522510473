import { FC, forwardRef } from 'react';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import ToggleGroup, { IToggleGroupProps } from '../0100_toggle_group';

interface IProps extends IToggleGroupProps {
  shorthand?: boolean;
}

const BranchesToggle: FC<IProps> = forwardRef<HTMLDivElement, IProps>(
  ({ shorthand, ...props }, ref ) => {
    const { branches } = useOrganizationBranches({});

    return (
      <ToggleGroup
        {...props}
        flags={branches
          .sort(( a, b ) =>
            shorthand
              ? a.shorthand.localeCompare( b.shorthand )
              : a.name.localeCompare( b.name ),
          )
          .map( x => ({ value: x.id, label: shorthand ? x.shorthand : x.name }))}
      />
    );
  },
);

export default BranchesToggle;
