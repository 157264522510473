import { useCallback, useMemo } from 'react';
import { format, parseISO, previousMonday } from 'date-fns';
import { IEventAttendeeKindEnum, IEventKindEnum } from 'src/graphql/types';
import usePlayerAttendances from '../players/usePlayerAttendances';
import useEvents from './useEvents';

export interface IEventWithAttendance {
  id: number;
  name: string;
  keyMonday: string;
  startsAt: Date;
  endsAt: Date;
  registrationOpensAt?: Date;
  kind: IEventKindEnum;
  isVirtual: boolean;
  branch: {
    id: number;
    shorthand: string;
    organization: {
      id: number;
      slug: string;
    };
  };
  attendance?: {
    id: number;
    buildAwarded: boolean;
    buildGrowth: number;
    checkinApproved: boolean;
    kind: IEventAttendeeKindEnum;
    attendeeCharacters: {
      id: number;
      buildGrowth: number;
      character: {
        id: number;
        name: string;
      };
    }[];
  };
}

interface IProps {
  start: Date;
  end: Date;
  playerId?: number;
  organizationIds?: number[];
  withEvents?: boolean;
}

const useEventsWithAttendances = ({
  start,
  end,
  playerId = 0,
  organizationIds,
  withEvents,
}: IProps ) => {
  const { events, fetching: isFetchingEvents } = useEvents({
    start,
    end,
    organizationIds,
    pause: !withEvents,
  });
  const { attendances, fetching: isFetchingAttendances } = usePlayerAttendances(
    { start, end, playerId },
  );

  const carbonDating = useCallback(
    ( event: {
      startsAt: string;
      endsAt: string;
      registrationOpensAt?: string;
    }) => ({
      keyMonday: format( previousMonday( parseISO( event.startsAt )), 'yyyy-MM-dd' ),
      startsAt: parseISO( event.startsAt ),
      endsAt: parseISO( event.endsAt ),
      registrationOpensAt: event.registrationOpensAt
        ? parseISO( event.registrationOpensAt )
        : undefined,
    }),
    [],
  );

  const eventsWithAttendances: IEventWithAttendance[] = useMemo(() => {
    if ( withEvents ) {
      return events.map( event => ({
        ...event,
        ...carbonDating( event ),
        attendance:
          playerId > 0
            ? attendances.find( atd => atd.event.id === event.id )
            : undefined,
      }));
    }

    return attendances.map( atd => ({
      ...carbonDating( atd.event ),
      id: atd.event.id,
      name: atd.event.name,
      branch: atd.event.branch,
      kind: atd.event.kind,
      isVirtual: atd.event.isVirtual,
      attendance: atd,
    }));
  }, [ withEvents, attendances, events, carbonDating, playerId ]);

  return {
    events: eventsWithAttendances,
    fetching: isFetchingEvents || isFetchingAttendances,
  };
};

export default useEventsWithAttendances;
