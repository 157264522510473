import { startCase } from 'lodash';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';

const BranchHeader: FC = () => {
  const { branchSection, linkToBranch } = useAppNavigations();
  const { branchId } = useParams();
  const { isPermitted } = usePermission({
    action: 'manage_branch',
    branchId: Number( branchId ),
  });

  if ( !isPermitted ) return null;

  return (
    <ResponsiveTabGroup label={startCase( branchSection || 'basics' )}>
      <Tab
        label="Basics"
        to={linkToBranch({ branchId: Number( branchId ), section: 'basics' })}
        highlightMode="responsive"
        isActive={( branchSection || 'basics' ) === 'basics'}
      />
      <Tab
        label="Locations"
        to={linkToBranch({ branchId: Number( branchId ), section: 'locations' })}
        highlightMode="responsive"
        isActive={branchSection === 'locations'}
      />
      <Tab
        label="Attendance"
        to={linkToBranch({ branchId: Number( branchId ), section: 'attendance' })}
        highlightMode="responsive"
        isActive={branchSection === 'attendance'}
      />
      <Tab
        label="CAPS"
        to={linkToBranch({ branchId: Number( branchId ), section: 'caps' })}
        highlightMode="responsive"
        isActive={branchSection === 'caps'}
      />
      <Tab
        label="Corrective Actions"
        to={linkToBranch({
          branchId: Number( branchId ),
          section: 'corrective_actions',
        })}
        highlightMode="responsive"
        isActive={branchSection === 'corrective_actions'}
      />
    </ResponsiveTabGroup>
  );
};

export default BranchHeader;
