import { FC } from 'react';
import clsx from 'clsx';
import useXpData from '../XpSunBurst/hooks/useXpData';

interface IProps {
  displayOverage?: boolean;
}

const XpBar: FC<IProps> = ({ displayOverage }) => {
  const { costs } = useXpData();
  const frac =
    costs.xpEarned === 0 ? 0 : ( costs.spentXp * 100 ) / costs.xpEarned;

  return (
    <div className="relative">
      <div className="w-[160px] h-10 midtone-box rounded">&nbsp;</div>
      <div
        className={clsx(
          '-mt-10 h-10 rounded z-10',
          displayOverage && costs.isOverSpent
            ? 'orange-box'
            : 'midtone-box brightness-200',
        )}
        style={{ width: `${frac}%`, maxWidth: '100%' }}
      >
        &nbsp;
      </div>
      <div className="-mt-10 w-full flex items-center p-2 z-20 relative text-shadow">
        {displayOverage
          ? costs.isOverSpent
            ? `${costs.overspentXp}XP Over`
            : `${costs.spentXp}/${costs.xpEarned}XP`
          : `${costs.spentXp}XP`}
      </div>
    </div>
  );
};

export default XpBar;
