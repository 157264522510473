import { useQuery } from 'urql';
import { getPlayerCorrectiveActions } from 'src/graphql/queries/players.graphql';
import {
  IGetPlayerCorrectiveActionsQuery,
  IGetPlayerCorrectiveActionsQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerCorrectiveActions = ({ playerId }: { playerId: number }) => {
  const { isRootUserValidated } = useAuth();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerCorrectiveActionsQuery,
    IGetPlayerCorrectiveActionsQueryVariables
  >({
    query: getPlayerCorrectiveActions,
    variables: { playerId },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( playerId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching: fetching || stale,
    correctiveActions: data?.user?.correctiveActions || [],
  };
};

export default usePlayerCorrectiveActions;
