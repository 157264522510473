import { FC } from 'react';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import { startCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import EmbossAndReproduction from '../EmbossAndReproduction';

import '../index.css';

interface IProps {
  id: number;
  name: string;
  kind: IItemKindEnum;
  grade: IItemGradeEnum;
  updatedAt: string;
  lifetimeAmount?: number | null;
  metadata: {
    printHeader?: string | null;
    mechanics?: string | null;
    notes?: string | null;
    requirementsToUse?: string | null;
    uses?: string | null;
  };
  itemCrafting: {
    id: number;
    craftingTimeInMinute?: number | null;
    craftingMindCost?: number | null;
    craftingZone?: string | null;
    craftingSkills?: string | null;
    craftingComponents: {
      id: number;
      component: {
        id: number;
        name: string;
      };
      amount: number;
    }[];
    craftingFinalProducts: {
      id: number;
      stack: number;
      finalProduct?: {
        id: number;
        name: string;
        grade: IItemGradeEnum;
        kind: IItemKindEnum;
        lifetimeAmount?: number | null;
        childItemClassifications: {
          id: number;
          childItem: {
            id: number;
            name: string;
            kind: IItemKindEnum;
            metadata: {
              mechanics?: string | null;
            };
          };
        }[];
        metadata: {
          printHeader?: string | null;
          mechanics?: string | null;
          notes?: string | null;
          requirementsToUse?: string | null;
          uses?: string | null;
        };
      };
    }[];
  };
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const ItemCrafting: FC<IProps> = ({ ...blueprint }) => {
  const output = blueprint.itemCrafting.craftingFinalProducts[0]?.finalProduct;
  const outputKind =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications
          .map( x => startCase( x.childItem.kind ))
          .join( ' or ' )
      : startCase( output?.kind );
  const outputMechanics =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications.map(
          x =>
            `${startCase( x.childItem.kind )}: ${x.childItem.metadata.mechanics}`,
        )
      : [ output?.metadata.mechanics ];
  const { itemCrafting } = blueprint;

  return (
    <div className="printout blueprint border border-juno-gray-700 overflow-hidden">
      <div className="border-b border-juno-gray-700">
        <div className="text-center text-3xl py-2">
          {blueprint.metadata.printHeader}
        </div>
      </div>
      <div className="border-b border-juno-gray-700 text-xl p-2">
        <table>
          <tbody>
            <tr>
              <td>Item Name</td>
              <td>{output?.name}</td>
            </tr>
            <tr>
              <td>Item Type</td>
              <td colSpan={3}>{outputKind}</td>
            </tr>
            <tr>
              <td>Requirements To Use</td>
              <td>{output?.metadata.requirementsToUse}</td>
            </tr>
            <tr>
              <td>Expiration</td>
              <td>{output?.lifetimeAmount} months</td>
              <td className="w-2/5">
                <span>Number of Uses: </span>
                {output?.metadata.uses || 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="border-b border-juno-gray-700 p-2">
        <div className="flex justify-between w-full">
          <div className="w-full">
            <table>
              <tbody>
                <tr>
                  <td>Production Skills</td>
                  <td>{itemCrafting.craftingSkills}</td>
                </tr>
                <tr>
                  <td>Production Time</td>
                  <td>{itemCrafting.craftingTimeInMinute} minutes</td>
                </tr>
                <tr>
                  <td>Production Cost</td>
                  <td>{itemCrafting.craftingMindCost} Mind</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    Production Resources
                    <div className="font-normal">
                      {Array.from({ length: 9 }).map(( _, i ) => {
                        const craftingComponent =
                          blueprint.itemCrafting.craftingComponents[i];
                        if ( !craftingComponent )
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={i} className="text-transparent">
                              .
                            </div>
                          );
                        return (
                          <div
                            key={craftingComponent.id}
                            className="flex items-center gap-2 w-full"
                          >
                            <div className="w-12 text-right">
                              {craftingComponent.amount}
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="pl-1 text-sm"
                              />
                            </div>
                            <div>{craftingComponent.component.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Production Zone</td>
                    <td className="w-3/5">{itemCrafting.craftingZone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="pl-2 font-bold">Item Mechanics</div>
          <div className="pl-2 font-normal min-h-[128px]">
            {outputMechanics.map(( x, i ) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                {x}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="pl-2 font-bold">Special Notes</div>
          <div className="pl-2 font-normal min-h-[128px]">
            {blueprint.metadata.notes}
          </div>
        </div>
      </div>
      <EmbossAndReproduction
        updatedAt={blueprint.updatedAt}
        itemReproduction={blueprint.itemReproduction}
      />
    </div>
  );
};

export default ItemCrafting;
