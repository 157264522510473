import { getLocations } from 'src/graphql/queries/locations.graphql';
import {
  IGetLocationsQuery,
  IGetLocationsQueryVariables,
} from 'src/graphql/queries/locations.graphql.types';
import { useQuery } from 'urql';

const useLocations = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetLocationsQuery,
    IGetLocationsQueryVariables
  >({
    query: getLocations,
    requestPolicy: 'cache-and-network',
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    locations: data?.locations ?? [],
  };
};

export default useLocations;
