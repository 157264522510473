import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import { FC, forwardRef } from 'react';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {
  owned?: boolean;
}

const BranchSelect: FC<IProps> = forwardRef(
  ({ owned = false, ...props }, ref ) => {
    const { branches } = useOrganizationBranches({ owned });

    return (
      <BaseSelect
        withSearch
        options={branches.map( x => ({ value: x.id, label: x.shorthand }))}
        {...props}
      />
    );
  },
);

export default BranchSelect;
