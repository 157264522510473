import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import {
  IGetEventAttendancesQuery,
  IGetEventAttendancesQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { getEventAttendances } from 'src/graphql/queries/events.graphql';

const useEventAttendees = ({ ticketId }: { ticketId?: number }) => {
  const { eventId } = useParams();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventAttendancesQuery,
    IGetEventAttendancesQueryVariables
  >({
    query: getEventAttendances,
    variables: { eventId: Number( eventId ), ticketId },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  return {
    error,
    fetching,
    stale,
    refetch,
    attendees: data?.event?.eventAttendees || [],
  };
};

export default useEventAttendees;
