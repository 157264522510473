import {
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatDistanceStrict } from 'date-fns';
import Slider from 'rc-slider';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/0100_button';
import ResponseBox from 'src/components/0100_response_box';
import Textarea from 'src/components/0100_textarea';
import AugmentedInput from 'src/components/0200_augmented_input';
import DatePicker from 'src/components/0200_datepicker';
import BranchSelect from 'src/components/0400_branch_select';
import LocationSelect from 'src/components/0400_location_select';
import {
  createNewEvent,
  updateEvent,
} from 'src/graphql/mutations/events.graphql';
import {
  ICreateNewEventMutation,
  ICreateNewEventMutationVariables,
  IUpdateEventMutation,
  IUpdateEventMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useEvent from 'src/hooks/events/useEvent';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import usePermission from 'src/hooks/permissions/usePermissions';
import useRootUser from 'src/hooks/players/useRootUser';
import { useMutation } from 'urql';
import Toggle from 'react-toggle';
import TemporaryManagers from './TemporaryManagers';

const defaultValues = {
  branchId: 0,
  locationId: 0,
  registrationOpensAt: null as Date | null,
  startsAt: null as Date | null,
  endsAt: null as Date | null,
  name: '',
  description: '',
  baseBuild: 0,
  maxCurveXp: 0,
  extraXpCost: 0,
  maxExtraXp: 0,
  maxExtraXpLimit: 0,
  considersTravelersLocal: false,
};

const Basics: FC = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { linkToEvent } = useAppNavigations();
  const isNewEvent = eventId === 'new';
  const { homeBranch } = useRootUser();
  const { buttonState } = useButtonStates();
  const { branches } = useOrganizationBranches({});
  const { event } = useEvent();
  const { isPermitted: canManageEvent } = usePermission({
    action: 'manage_event',
    eventId: Number( eventId ),
  });
  const { isPermitted: canCreateEvent } = usePermission({
    action: 'create_event_ui',
  });
  const canAccessPage = ( isNewEvent && canCreateEvent ) || canManageEvent;

  const [ shouldDisplayResponse, setShouldDisplayResponse ] = useState( false );
  const [ updatedFields, setUpdatedFields ] = useState<{
    [key: string]: boolean;
  }>({});
  const methods = useForm({ defaultValues });
  const {
    getValues,
    register,
    reset,
    resetField,
    setError,
    setFocus,
    setValue,
    watch,
    formState: { dirtyFields, errors, touchedFields, isValid },
  } = methods;
  const {
    branchId,
    locationId,
    registrationOpensAt,
    startsAt,
    endsAt,
    name,
    description,
    baseBuild,
    maxCurveXp,
    extraXpCost,
    maxExtraXp,
    maxExtraXpLimit,
    considersTravelersLocal,
  } = watch();

  const defaultEventName = useMemo(() => {
    if ( !startsAt ) return null;

    const branchShorthand = branches.find( x => x.id === branchId )?.shorthand;
    const startDate = format( startsAt, 'yyyy MMM' );

    return [ branchShorthand, startDate ].join( ' ' );
  }, [ branchId, branches, startsAt ]);

  const registrationText = useMemo(() => {
    if ( !registrationOpensAt )
      return { type: 'warning', message: 'Game is invisible to Players' };
    if ( !startsAt )
      return { type: 'warning', message: 'Start Date is required' };

    return {
      type: 'ok',
      message: `${formatDistanceStrict(
        registrationOpensAt,
        startsAt,
      )} to register`,
    };
  }, [ registrationOpensAt, startsAt ]);

  const gameText = useMemo(() => {
    if ( startsAt && endsAt ) {
      return {
        type: 'ok',
        message: `${formatDistanceStrict( startsAt, endsAt, {
          unit: 'hour',
        })} in-game`,
      };
    }

    return { type: 'warning', message: 'Start and End Dates are required' };
  }, [ endsAt, startsAt ]);

  const [ createResult, create ] = useMutation<
    ICreateNewEventMutation,
    ICreateNewEventMutationVariables
  >( createNewEvent );

  const [ updateResult, update ] = useMutation<
    IUpdateEventMutation,
    IUpdateEventMutationVariables
  >( updateEvent );

  const createEvent = useCallback(() => {
    if ( !isNewEvent ) return;
    if ( !startsAt || !endsAt || !name ) return;

    setShouldDisplayResponse( true );

    create({
      branchId: Number( branchId ),
      description,
      endsAt: endsAt?.toISOString(),
      locationId: Number( locationId ),
      registrationOpensAt: registrationOpensAt?.toISOString(),
      startsAt: startsAt?.toISOString(),
      name,
    }).then( res => {
      if ( res.data?.createEvent?.event ) {
        navigate( linkToEvent({ eventId: res.data.createEvent.event.id }));
      }
    });
  }, [
    branchId,
    create,
    description,
    endsAt,
    isNewEvent,
    linkToEvent,
    locationId,
    name,
    navigate,
    registrationOpensAt,
    startsAt,
  ]);

  const partialUpdateEvent = useCallback(() => {
    if ( isNewEvent ) return;

    const dirtyField = Object.keys( dirtyFields )[0] as keyof typeof dirtyFields;
    setUpdatedFields( prev => ({ ...prev, [dirtyField]: false }));
    update({
      eventId: Number( eventId ),
      [dirtyField]: getValues( dirtyField ),
    }).then( res => {
      if ( res.data?.updateEvent?.error ) {
        resetField( dirtyField );
        setError( dirtyField, { message: res.data.updateEvent.error });
        setFocus( dirtyField );
      } else if ( dirtyField ) {
        setUpdatedFields( prev => ({ ...prev, [dirtyField]: true }));
      }
    });
  }, [
    dirtyFields,
    eventId,
    getValues,
    isNewEvent,
    resetField,
    setError,
    setFocus,
    update,
  ]);

  useEffect(() => {
    if ( homeBranch ) {
      setValue( 'branchId', homeBranch.id );
    }
  }, [ homeBranch, setValue ]);

  useEffect(() => {
    if ( isNewEvent && defaultEventName && !touchedFields.name )
      setValue( 'name', defaultEventName, { shouldDirty: false });
  }, [
    branchId,
    defaultEventName,
    isNewEvent,
    setValue,
    startsAt,
    touchedFields.name,
  ]);

  useEffect(() => {
    if ( event?.id ) {
      reset({
        branchId: event.branch.id,
        locationId: event.location.id,
        registrationOpensAt: event.registrationOpensAt
          ? new Date( event.registrationOpensAt )
          : null,
        startsAt: new Date( event.startsAt ),
        endsAt: new Date( event.endsAt ),
        name: event.name,
        description: event.description || '',
        baseBuild: event.config.baseBuild,
        maxCurveXp: event.config.maxCurveXp,
        extraXpCost: event.config.extraXpCost,
        maxExtraXp: event.config.maxExtraXp,
        maxExtraXpLimit: event.config.maxExtraXpLimit,
        considersTravelersLocal: event.config.considersTravelersLocal,
      });
    } else {
      setValue( 'name', '' );
      setValue( 'description', '' );
      setValue( 'registrationOpensAt', null );
      setValue( 'startsAt', null );
      setValue( 'endsAt', null );
      setShouldDisplayResponse( false );
    }
  }, [ event, reset, setValue ]);

  if ( !canAccessPage ) return null;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-wrap justify-center w-full pb-8">
        <div className="max-w-[640px] w-full grid grid-cols-1 gap-4">
          <AugmentedInput required title="Branch">
            <BranchSelect
              owned
              isLocked={!isNewEvent}
              {...register( 'branchId', { required: true })}
              selectedValue={branchId}
            />
          </AugmentedInput>
          <AugmentedInput required title="Location">
            <LocationSelect
              branchId={branchId}
              {...register( 'locationId', { required: true })}
              selectedValue={locationId}
              isUpdating={
                !isNewEvent && updateResult.fetching && dirtyFields.locationId
              }
              isUpdated={updatedFields.locationId && !dirtyFields.locationId}
              onUpdate={() => {
                if ( !isNewEvent ) partialUpdateEvent();
              }}
            />
          </AugmentedInput>

          <AugmentedInput title="Registration Date">
            <div className="grid grid-cols-1 gap-4">
              <DatePicker
                complex
                showTimeSelect
                isClearable
                date={registrationOpensAt ?? undefined}
                dateFormat="yyyy MMM d hh:mm a"
                maxDate={startsAt ?? undefined}
                {...register( 'registrationOpensAt' )}
                isUpdating={
                  !isNewEvent &&
                  updateResult.fetching &&
                  dirtyFields.registrationOpensAt
                }
                isUpdated={
                  updatedFields.registrationOpensAt &&
                  !dirtyFields.registrationOpensAt
                }
                error={errors.registrationOpensAt?.message?.toString()}
                onBlur={() => null}
                onUpdate={date => {
                  setValue( 'registrationOpensAt', date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  if ( !isNewEvent ) partialUpdateEvent();
                }}
              />
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon
                  className="fa-fw"
                  icon={
                    registrationText.type === 'warning'
                      ? faExclamationTriangle
                      : faCheck
                  }
                />
                {registrationText.message}
              </div>
            </div>
          </AugmentedInput>
          <AugmentedInput required title="Start Date">
            <div className="grid grid-cols-1 gap-4">
              <DatePicker
                complex
                showTimeSelect
                isClearable={isNewEvent}
                date={startsAt ?? undefined}
                dateFormat="yyyy MMM d hh:mm a"
                placeholder="Starts At"
                minDate={registrationOpensAt ?? undefined}
                maxDate={endsAt ?? undefined}
                {...register( 'startsAt', { required: true })}
                isUpdating={
                  !isNewEvent && updateResult.fetching && dirtyFields.startsAt
                }
                isUpdated={updatedFields.startsAt && !dirtyFields.startsAt}
                error={errors.startsAt?.message?.toString()}
                onBlur={() => null}
                onUpdate={date => {
                  setValue( 'startsAt', date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  if ( !isNewEvent && date ) partialUpdateEvent();
                }}
              />
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon
                  className="fa-fw"
                  icon={
                    gameText.type === 'warning'
                      ? faExclamationTriangle
                      : faCheck
                  }
                />
                {gameText.message}
              </div>{' '}
            </div>
          </AugmentedInput>
          <AugmentedInput required title="End Date">
            <DatePicker
              complex
              showTimeSelect
              isClearable={isNewEvent}
              date={endsAt ?? undefined}
              dateFormat="yyyy MMM d hh:mm a"
              placeholder="Ends At"
              minDate={startsAt ?? undefined}
              {...register( 'endsAt', { required: true })}
              isUpdating={
                !isNewEvent && updateResult.fetching && dirtyFields.endsAt
              }
              isUpdated={updatedFields.endsAt && !dirtyFields.endsAt}
              error={errors.endsAt?.message?.toString()}
              onBlur={() => null}
              onUpdate={date => {
                setValue( 'endsAt', date, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                if ( !isNewEvent && date ) partialUpdateEvent();
              }}
            />
          </AugmentedInput>

          <div className="w-full border-t border-juno-gray-700" />
          <AugmentedInput
            required
            title="Name"
            isBusy={!isNewEvent && updateResult.fetching && dirtyFields.name}
            isUpdated={updatedFields.name && !dirtyFields.name}
            error={errors.name?.message?.toString()}
            {...register( 'name', {
              required: true,
              onBlur: partialUpdateEvent,
            })}
          />
          <AugmentedInput
            title="Description"
            isBusy={
              !isNewEvent && updateResult.fetching && dirtyFields.description
            }
            isUpdated={updatedFields.description && !dirtyFields.description}
            error={errors.description?.message?.toString()}
            placeholder="Tell the world how awesome your Game is..."
          >
            <Textarea
              {...register( 'description', { onBlur: partialUpdateEvent })}
            />
          </AugmentedInput>
          {isNewEvent && (
            <>
              <div className="w-full border-t border-juno-gray-700" />
              {createResult.data?.createEvent?.error && (
                <ResponseBox type="error" withIcon={faExclamationTriangle}>
                  {createResult.data.createEvent.error}
                </ResponseBox>
              )}
              <Button
                defaultLabel="Create Event"
                state={buttonState({
                  isValid,
                  isDirty: isNewEvent,
                  isFetching: createResult.fetching,
                  isHighlight: isNewEvent && isValid && !createResult.fetching,
                })}
                stateLabel={{
                  loading: 'Creating Event...',
                }}
                onClick={createEvent}
              />
            </>
          )}
          {shouldDisplayResponse && createResult.data?.createEvent?.event && (
            <ResponseBox type="success" withIcon={faCheck}>
              Event created successfully.
            </ResponseBox>
          )}
          {!isNewEvent && (
            <>
              <div className="w-full border-t border-juno-gray-700" />
              <ResponseBox type="success" withIcon={faExclamationTriangle}>
                Please make sure XP settings are set in stone prior to opening
                your Game for Checkins. Changes to these settings will not
                automatically reflect existing Checkins.
              </ResponseBox>
              <AugmentedInput
                title="Base XP"
                footnote="This adds XP on top of normal Player's XP accrual. Typically, only Network-wide Games or Games running longer than 3 days need to adjust this."
                isBusy={
                  !isNewEvent && updateResult.fetching && dirtyFields.baseBuild
                }
                isUpdated={updatedFields.baseBuild && !dirtyFields.baseBuild}
                error={errors.baseBuild?.message?.toString()}
              >
                <div className="flex flex-wrap items-center gap-4 pl-1.5 pt-1">
                  <div style={{ width: `${maxCurveXp * 16}px` }}>
                    <Slider
                      dots
                      max={maxCurveXp}
                      value={baseBuild}
                      onChange={x =>
                        setValue( 'baseBuild', Number( x ), { shouldDirty: true })
                      }
                      onChangeComplete={partialUpdateEvent}
                    />
                  </div>
                  <div>{`+${baseBuild}`}</div>
                </div>
              </AugmentedInput>
              <AugmentedInput
                title="Purchasable"
                isBusy={
                  !isNewEvent && updateResult.fetching && dirtyFields.maxExtraXp
                }
                isUpdated={updatedFields.maxExtraXp && !dirtyFields.maxExtraXp}
                error={errors.maxExtraXp?.message?.toString()}
              >
                <div className="flex flex-wrap items-center gap-4 pl-1.5 pt-1">
                  <div style={{ width: `${maxExtraXpLimit * 16}px` }}>
                    <Slider
                      dots
                      max={maxExtraXpLimit}
                      value={maxExtraXp}
                      onChange={x =>
                        setValue( 'maxExtraXp', Number( x ), {
                          shouldDirty: true,
                        })
                      }
                      onChangeComplete={partialUpdateEvent}
                    />
                  </div>
                  <div>{`${maxExtraXp}XP @$${extraXpCost}`}</div>
                </div>
              </AugmentedInput>
              <AugmentedInput
                title="Travelers are Local"
                isBusy={
                  !isNewEvent &&
                  updateResult.fetching &&
                  dirtyFields.considersTravelersLocal
                }
                isUpdated={
                  updatedFields.considersTravelersLocal &&
                  !dirtyFields.considersTravelersLocal
                }
                footnote={`Travelers ${considersTravelersLocal ? 'can' : 'cannot'} purchase Non-Attending Tickets. You should only turn this on when you are running Network-wide Games.`}
              >
                <Toggle
                  checked={considersTravelersLocal}
                  className="mt-1 -ml-1"
                  onChange={x => {
                    setValue( 'considersTravelersLocal', x.target.checked, {
                      shouldDirty: true,
                    });
                    partialUpdateEvent();
                  }}
                />
              </AugmentedInput>
              <div className="w-full border-t border-juno-gray-700" />
              <TemporaryManagers />
            </>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default Basics;
