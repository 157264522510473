import { faBan, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Toggle from 'react-toggle';
import Input from 'src/components/0100_input';
import { IShift } from 'src/graphql/types';
import { useMutation } from 'urql';
import {
  IDestroyEventShiftMutation,
  IDestroyEventShiftMutationVariables,
  IUpdateEventShiftMutation,
  IUpdateEventShiftMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import {
  destroyEventShift,
  updateEventShift,
} from 'src/graphql/mutations/events.graphql';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import { identifierToDay } from 'src/utils/shiftTime';
import AttendeeShift, { TAttendeeShift } from './AttendeeShift';

export type TShift = Pick<
  IShift,
  | 'id'
  | 'label'
  | 'playersCount'
  | 'limit'
  | 'baseRatio'
  | 'adjustableExpansionStrategy'
  | 'autoExpand'
  | 'identifier'
  | 'isEnabled'
> & {
  attendeeShifts?: TAttendeeShift[] | null;
};

interface IProps {
  canManageShift?: boolean;
  shift: TShift;
  isExpanded: boolean;
  onExpand: () => void;
}

const Shift: FC<IProps> = ({ canManageShift, shift, isExpanded, onExpand }) => {
  const [ isEditingCap, setIsEditingCap ] = useState( false );
  const [ isDestroying, setIsDestroying ] = useState( false );
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState( false );
  const {
    register,
    reset,
    setFocus,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      label: '',
      limit: undefined as string | undefined,
      baseRatio: undefined as string | undefined,
      isEnabled: false,
      autoExpand: false,
    },
  });
  const { label, limit, baseRatio, isEnabled } = watch();
  const isFull = shift.playersCount === shift.limit;
  const isOver = ( shift.playersCount ?? 0 ) > ( shift.limit ?? 0 );

  const [ , update ] = useMutation<
    IUpdateEventShiftMutation,
    IUpdateEventShiftMutationVariables
  >( updateEventShift );

  const [ , destroy ] = useMutation<
    IDestroyEventShiftMutation,
    IDestroyEventShiftMutationVariables
  >( destroyEventShift );

  const handleUpdateShift = useCallback(
    ( res: Awaited<ReturnType<typeof update>> ) => {
      if ( res.data?.updateEventShift?.shift ) {
        setIsUpdateSuccessful( true );
        setTimeout(() => setIsUpdateSuccessful( false ), 1000 );
      }
    },
    [],
  );

  const handleChange = useCallback(() => {
    if ( Object.keys( dirtyFields ).length === 0 ) return;

    update({
      shiftId: shift.id,
      label,
      limit: Number( limit ),
      baseRatio: Number( baseRatio ) ?? 0,
    }).then( handleUpdateShift );
  }, [
    baseRatio,
    dirtyFields,
    handleUpdateShift,
    label,
    limit,
    shift.id,
    update,
  ]);

  useEffect(() => {
    if ( shift ) {
      reset({
        label: shift.label ?? '',
        limit: shift.limit ? String( shift.limit ) : undefined,
        isEnabled: shift.isEnabled,
        baseRatio: String( shift.baseRatio ),
      });
    }
  }, [ reset, shift ]);

  if ( isDestroying ) return null;

  return (
    <>
      <tr
        className={clsx(
          'transition-all ease-in-out',
          isUpdateSuccessful ? 'bg-lime-600/75' : 'bg-transparent',
        )}
      >
        <td className="pt-2">
          <Toggle
            checked={isEnabled}
            disabled={!canManageShift}
            onChange={x => {
              setValue( 'isEnabled', x.target.checked, { shouldDirty: true });
              update({ shiftId: shift.id, isEnabled: x.target.checked }).then(
                handleUpdateShift,
              );
            }}
          />
        </td>
        <td className="pb-2">
          <div className="flex">
            <Input
              fullWidth
              disabled={!canManageShift}
              placeholder="Shift Name"
              {...register( 'label' )}
              onBlur={handleChange}
              onEnter={handleChange}
            />
            {shift.identifier ? (
              <div className="relative">
                <div className="absolute mt-[6px] -ml-[110px] w-[102px] opacity-50 text-right text-sm whitespace-nowrap">
                  {`Legacy ${identifierToDay( shift.identifier )}`}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </td>
        <td className="pb-2 pl-2">
          {isEditingCap ? (
            <Input
              fullWidth
              disabled={!canManageShift}
              type="number"
              min={0}
              step={0}
              className="text-right"
              {...register( 'limit' )}
              onBlur={() => {
                setIsEditingCap( false );
                handleChange();
              }}
              onEnter={() => {
                setIsEditingCap( false );
                handleChange();
              }}
            />
          ) : (
            <div className="relative">
              <button
                type="button"
                disabled={!canManageShift}
                className={clsx(
                  'text-right w-full dark-box border rounded border-juno-gray-700 py-1 px-2',
                  !canManageShift && 'opacity-50',
                )}
                onClick={() => {
                  if ( canManageShift ) {
                    setIsEditingCap( true );
                    setTimeout(() => setFocus( 'limit' ), 100 );
                  }
                }}
              >
                {shift.playersCount}/{shift.limit}
              </button>
              <div className="w-[calc(100%-1px)]">
                <div
                  style={{
                    width: `${isOver ? '100%' : (( shift.playersCount ?? 0 ) * 100 ) / ( shift.limit ?? 0 )}%`,
                  }}
                  className={clsx(
                    'bg-red-500 h-1 rounded-bl -mt-1 ml-[1px]',
                    isFull && 'rounded-br',
                    isOver ? 'orange-box' : 'white-box',
                  )}
                />
              </div>
            </div>
          )}
        </td>
        <td className="pb-2 pl-2">
          {shift.adjustableExpansionStrategy ? (
            <div>
              <Input
                fullWidth
                disabled={!canManageShift}
                className="text-right"
                min={0}
                step={0}
                {...register( 'baseRatio' )}
                onBlur={handleChange}
                onEnter={handleChange}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={faBan}
              className="pt-2 text-right opacity-50 w-full"
            />
          )}
        </td>
        <td className="pt-1 pl-2">
          {( shift.playersCount ?? 0 ) > 0 ? (
            <button type="button" onClick={() => onExpand()}>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx( 'transition-all', isExpanded && '-scale-y-100' )}
              />
            </button>
          ) : (
            <div>
              {canManageShift && (
                <ConfirmDropdown
                  onConfirm={() => {
                    destroy({
                      shiftId: shift.id,
                    });
                    setIsDestroying( true );
                  }}
                />
              )}
            </div>
          )}
        </td>
      </tr>
      <tr>
        <td />
        <td colSpan={4}>
          <div
            className={clsx(
              'grid transition-all duration-300',
              isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
            )}
          >
            <div className="overflow-hidden">
              {shift.attendeeShifts
                ?.sort(( a, b ) => a.player.id - b.player.id )
                .map( x => <AttendeeShift key={x.id} attendeeShift={x} /> )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default Shift;
