import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import LinkOrButton from '../0100_link_or_button';

interface IProps {
  title: string;
  shorthand?: string;
  faIcon?: IconProp;
  to?: string;
  onClick?: () => void;
}

const Card: FC<IProps> = ({ title, shorthand, faIcon, to, onClick }) => (
  <LinkOrButton
    onClick={onClick}
    to={to}
    className="border rounded-md border-juno-gray-700 hover:brightness-150 w-48 text-center"
  >
    <div className="flex justify-center p-4">
      {faIcon ? (
        <FontAwesomeIcon
          icon={faIcon}
          className="text-5xl w-24 py-6 text-juno-gray-200"
        />
      ) : (
        <div className="rounded-full text-5xl bg-juno-gray-200 text-juno-gray-700 w-24 py-6">
          {shorthand}
        </div>
      )}
    </div>
    <div className="text-center border-t border-juno-gray-700 p-2">{title}</div>
  </LinkOrButton>
);

export default Card;
