import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Modal from 'src/components/0300_modal';
import DystopiaRisingCheckinForm from 'src/components/0500_checkin_forms/dystopia_rising';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const CheckinEditorModal: FC = () => {
  const { eventSection } = useAppNavigations();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isOpen, setIsOpen ] = useState( false );
  const playerId = searchParams.get( 'player_id' );
  const closeModal = useCallback(() => {
    searchParams.delete( 'player_id' );
    setSearchParams( searchParams );
  }, [ searchParams, setSearchParams ]);

  useEffect(() => {
    if ( eventSection === 'tickets' ) {
      setIsOpen( Number( playerId ) > 0 );
    }
  }, [ eventSection, playerId ]);

  return (
    <Modal
      withCloseButton
      title="Edit Checkin"
      type="actionable"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className="overflow-auto max-h-[66vh] px-2 md:px-8">
        <DystopiaRisingCheckinForm forceSingleLine />
      </div>
    </Modal>
  );
};

export default CheckinEditorModal;
