import { faCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import Slider from 'rc-slider';
import { FC, useCallback, useState } from 'react';
import Loading from 'src/components/0100_loading';
import { updateCharacterFellowship } from 'src/graphql/mutations/fellowships.graphql';
import {
  IUpdateCharacterFellowshipMutation,
  IUpdateCharacterFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import { IFellowshipKindEnum } from 'src/graphql/types';
import { useMutation } from 'urql';

interface IProps {
  id: number;
  rank: number;
  removedAt?: string;
  fellowship: {
    id: number;
    name: string;
  };
  type: IFellowshipKindEnum;
  canEditSensitiveData?: boolean;
}

const maxRank = {
  [IFellowshipKindEnum.Disease]: 4,
  [IFellowshipKindEnum.Society]: 2,
};

const CharacterFellowship: FC<IProps> = ({
  id,
  rank,
  removedAt,
  fellowship,
  type,
  canEditSensitiveData,
}) => {
  const [ isUpdated, setIsUpdated ] = useState( false );
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterFellowshipMutation,
    IUpdateCharacterFellowshipMutationVariables
  >( updateCharacterFellowship );

  const handleUpdate = useCallback(
    ({ rank, forRemoval }: { rank?: number; forRemoval?: boolean }) => {
      setIsUpdated( false );
      update({
        characterFellowshipId: id,
        rank,
        forRemoval,
      }).then( res => {
        if ( res.data?.updateCharacterFellowship?.characterFellowship?.id ) {
          setIsUpdated( true );
        }
      });
    },
    [ id, update ],
  );

  return (
    <div key={id} className={clsx( removedAt && 'opacity-50' )}>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1">
          {fellowship.name}
          {updateResult.fetching && <Loading size="small" />}
          {isUpdated && <FontAwesomeIcon icon={faCheck} />}
          {canEditSensitiveData && !removedAt && (
            <FontAwesomeIcon
              icon={faUserSlash}
              className="cursor-pointer"
              onClick={() => handleUpdate({ forRemoval: true })}
            />
          )}
        </div>
        <div className="min-w-[128px] pr-2">
          <Slider
            dots
            disabled={!canEditSensitiveData || !!removedAt}
            min={0}
            max={maxRank[type]}
            value={rank}
            onChange={x => handleUpdate({ rank: Number( x ) })}
          />
        </div>
      </div>
      {removedAt && (
        <div className="flex gap-1">
          {`Revoked on ${format( parseISO( removedAt ), 'yyyy/M/d' )}.`}
          {canEditSensitiveData && (
            <button
              type="button"
              className="cursor-pointer underline"
              onClick={() => handleUpdate({ forRemoval: false })}
            >
              Undo
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CharacterFellowship;
