import { FC, useEffect, useMemo, useRef, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import Button from 'src/components/0100_button';
import ProgressBar from 'src/components/0100_progress_bar';
import useDigest from 'src/hooks/useDigest';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useOrganization from 'src/hooks/organizations/useOrganization';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

type TSnapshot = {
  completed?: number | boolean;
  entity?: string;
  size?: number;
  header?: string;
  payload?: string;
};

interface IProps {
  isBusy: boolean;
  hasOutput?: boolean;
  endpoint: string;
  name: string;
  onBusy: ( busy: boolean ) => void;
}

const StreamingProgress: FC<IProps> = ({
  isBusy,
  endpoint,
  hasOutput,
  name,
  onBusy,
}) => {
  const { buttonState } = useButtonStates();
  const { computeDigest } = useDigest();
  const [ isFetching, setIsFetching ] = useState( false );
  const [ progress, setProgress ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ message, setMessage ] = useState( '' );
  const [ content, setContent ] = useState<string[]>([]);
  const [ isError, setIsError ] = useState( false );
  const { organization } = useOrganization();
  const sse = useRef<EventSource | null>( null );
  const visibilityRef = useRef<HTMLDivElement | null>( null );
  const lastExecution = useMemo(() => {
    switch ( endpoint ) {
      case 'event_attendances':
        return organization?.lastEventAttendanceSnapshotOn;
      case 'diff':
        return organization?.lastEventDiffOn;
      default:
        return null;
    }
  }, [ endpoint, organization ]);

  const closeSse = () => {
    if ( sse.current ) {
      sse.current.close();
    }

    setIsFetching( false );
    onBusy( false );
  };

  const handleData = ( e: MessageEvent ) => {
    const data = camelcaseKeys( JSON.parse( e.data ).data ) as TSnapshot;
    if ( data.entity ) {
      setProgress( Number( data.completed ));
      setMessage( `Executed ${data.entity}...` );

      if ( data.payload ) {
        setContent( prev => prev.concat( String( data.payload ).split( '\n' )));
        visibilityRef.current?.scrollIntoView();
      }
    }

    if ( data.size ) {
      setTotal( Number( data.size ));
      setMessage( 'Initializing...' );
    }

    if ( data.completed === true ) {
      closeSse();
      setMessage( `Operation completed at ${format( new Date(), 'yyyy-MM-dd' )}` );
    }

    if ( data.header ) {
      setContent([ data.header.trim() ]);
    }
  };

  const execute = async () => {
    if ( isFetching ) {
      setMessage( 'Operation interrupted' );
      closeSse();
    } else {
      const urlEncoded = await computeDigest({});
      sse.current = new EventSource(
        `${import.meta.env.VITE_HOST}/api/snapshots/${endpoint}?${urlEncoded}`,
      );

      sse.current.onopen = () => setIsFetching( true );
      sse.current.addEventListener( endpoint, handleData );
      sse.current.onerror = () => setIsError( true );
      onBusy( true );
    }
  };

  useEffect(() => {
    if ( lastExecution && total === 0 ) {
      setMessage(
        `Last executed on ${format(
          parseISO( lastExecution ),
          'yyyy-MM-dd',
        )} (${formatDistanceToNow( parseISO( lastExecution ), {
          addSuffix: true,
        })})`,
      );
    }
  }, [ lastExecution, total ]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <div className="col-span-1">
          <Button
            defaultLabel={name}
            state={buttonState({
              isFetching: isBusy,
              isValid: true,
              isDirty: true,
            })}
            stateLabel={{ enabled: isFetching ? 'Cancel' : `${name}` }}
            onClick={execute}
          />
        </div>
        {!isError && (
          <div className="sm:col-span-3">
            <div className="flex justify-between">
              <div>{message}</div>
              {total > 0 && <div>{`${progress}/${total}`}</div>}
            </div>
            {total > 0 && (
              <ProgressBar
                limit={total}
                nominal={progress}
                highlightClassName="border-juno-gray-50"
                mutedClassName="border-juno-gray-700"
              />
            )}
          </div>
        )}
        {isError && (
          <div className="sm:col-span-3">
            Unable to authenticate. Try re-login. If that does not work, please
            contact support.
          </div>
        )}
      </div>
      {hasOutput && (
        <div className="overflow-auto max-h-[67vh] max-w-full text-juno-gray-200 text-xs select-all">
          <pre>{content.filter( x => x ).join( '\n' )}</pre>
          <div ref={visibilityRef} className="h-1" />
        </div>
      )}
    </div>
  );
};

export default StreamingProgress;
