import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PlayerSearch from 'src/components/0400_player_search';
import { createCharacterFellowship } from 'src/graphql/mutations/fellowships.graphql';
import {
  ICreateCharacterFellowshipMutation,
  ICreateCharacterFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import { useMutation } from 'urql';
import ResponseBox from 'src/components/0100_response_box';
import Title from 'src/components/0100_title';
import Loading from 'src/components/0100_loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSortAsc,
  faSortDesc,
} from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { IFellowshipKindEnum } from 'src/graphql/types';
import Member, { TCharacterFellowship } from './Member';

interface IProps {
  fellowship: {
    characterFellowships: TCharacterFellowship[];
    charactersCount: number;
  };
  type: IFellowshipKindEnum;
}

const Members: FC<IProps> = ({ fellowship, type }) => {
  const { fellowshipId } = useParams();
  const [ filterQuery, setFilterQuery ] = useState( '' );
  const [ error, setError ] = useState<string | null>( null );
  const [ sortBy, setSortBy ] = useState<'id' | 'player' | 'character' | 'rank'>(
    'id',
  );
  const [ isSortAscending, setIsSortAscending ] = useState( false );
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ assignmentResult, assign ] = useMutation<
    ICreateCharacterFellowshipMutation,
    ICreateCharacterFellowshipMutationVariables
  >( createCharacterFellowship );
  const handleAssignment = useCallback(
    ({ characterId }: { characterId: number }) => {
      setIsSuccessful( false );
      setError( null );
      assign({ characterId, fellowshipId: Number( fellowshipId ) }).then( res => {
        if ( res.data?.createCharacterFellowship?.fellowship?.id ) {
          setIsSuccessful( true );
          setTimeout(() => setIsSuccessful( false ), 3000 );
        }

        if ( res.data?.createCharacterFellowship?.error ) {
          setError( res.data.createCharacterFellowship.error );
        }
      });
    },
    [ assign, fellowshipId ],
  );

  const filteredMembers = useMemo(() => {
    if ( filterQuery.trim().length === 0 ) return fellowship.characterFellowships;

    const query = filterQuery.trim().toLowerCase();

    return fellowship.characterFellowships.filter(
      characterFellowship =>
        characterFellowship.character.name.toLowerCase().includes( query ) ||
        characterFellowship.character.user.fullName
          .toLowerCase()
          .includes( query ),
    );
  }, [ fellowship.characterFellowships, filterQuery ]);

  const handleSortChange = useCallback(
    ( by: 'id' | 'player' | 'character' | 'rank' ) => {
      if ( by === sortBy ) {
        setIsSortAscending( x => !x );
      } else {
        setSortBy( by );
        setIsSortAscending( false );
      }
    },
    [ sortBy ],
  );

  const sortLabel = useCallback(
    ( by: 'id' | 'player' | 'character' | 'rank' ) => {
      const label = by === 'id' ? 'ID' : startCase( by );

      return (
        <div className="flex items-center">
          <div className={clsx( by !== sortBy && 'text-juno-gray-700' )}>
            {label}
          </div>
          <div>
            {sortBy === by && (
              <FontAwesomeIcon
                icon={isSortAscending ? faSortAsc : faSortDesc}
              />
            )}
          </div>
        </div>
      );
    },
    [ isSortAscending, sortBy ],
  );

  return (
    <div>
      <div>
        <Title variant="heading">
          <div className="flex justify-between items-center">
            <div>Members</div>

            <div className="flex gap-2 items-center">
              {assignmentResult.fetching && <Loading size="small" />}
              {isSuccessful && <FontAwesomeIcon icon={faCheck} />}
              <div>{fellowship.charactersCount}</div>
            </div>
          </div>
        </Title>
      </div>
      <div className="min-h-[57vh]">
        <div className="pb-2 sticky top-0 gray-box z-10">
          <PlayerSearch
            withCharacters
            placeholder="Search or Add Members..."
            onSearchResultClick={({ characterId }) =>
              characterId && handleAssignment({ characterId })
            }
            onQueryChange={x => setFilterQuery( x )}
          />
          {error && <ResponseBox type="error">{error}</ResponseBox>}
        </div>
        {filteredMembers.length === 0 ? (
          <div>No members found</div>
        ) : (
          <div>
            <div className="flex justify-end border-b border-juno-gray-200 gap-2 mb-4 mt-2">
              <div>Sort By:</div>
              <div className="flex gap-2">
                <button type="button" onClick={() => handleSortChange( 'id' )}>
                  {sortLabel( 'id' )}
                </button>
                <button
                  type="button"
                  onClick={() => handleSortChange( 'player' )}
                >
                  {sortLabel( 'player' )}
                </button>
                <button
                  type="button"
                  onClick={() => handleSortChange( 'character' )}
                >
                  {sortLabel( 'character' )}
                </button>
                <button type="button" onClick={() => handleSortChange( 'rank' )}>
                  {sortLabel( 'rank' )}
                </button>
              </div>
            </div>
            <motion.div layout className="grid gap-2">
              {filteredMembers
                .sort(( a, b ) => {
                  if ( !isSortAscending ) {
                    switch ( sortBy ) {
                      case 'player':
                        return a.character.user.fullName.localeCompare(
                          b.character.user.fullName,
                        );
                      case 'character':
                        return a.character.name.localeCompare( b.character.name );
                      case 'rank':
                        return a.rank - b.rank;
                      default:
                        return a.character.user.id - b.character.user.id;
                    }
                  } else {
                    switch ( sortBy ) {
                      case 'player':
                        return b.character.user.fullName.localeCompare(
                          a.character.user.fullName,
                        );
                      case 'character':
                        return b.character.name.localeCompare( a.character.name );
                      case 'rank':
                        return b.rank - a.rank;
                      default:
                        return b.character.user.id - a.character.user.id;
                    }
                  }
                })
                .map( characterFellowship => (
                  <Member
                    key={characterFellowship.id}
                    characterFellowship={characterFellowship}
                    type={type}
                  />
                ))}
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Members;
