import { FC, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import useEventAttendees from 'src/hooks/events/useEventAttendees';
import Loading from 'src/components/0100_loading';
import { useFormContext } from 'react-hook-form';
import Attendee from './Attendee';

export type TOnFilterChange = {
  length: number;
  hasSearchQuery: boolean;
  identifier?: string;
};

interface IProps {
  ticketId: number;
  isExpanded: boolean;
  onFilterChange?: ({ length, hasSearchQuery }: TOnFilterChange ) => void;
}

const TicketAttendees: FC<IProps> = ({
  ticketId,
  isExpanded,
  onFilterChange,
}) => {
  const { attendees, fetching, refetch } = useEventAttendees({ ticketId });
  const { watch } = useFormContext();
  const { searchQuery } = watch();
  const hasSearchQuery = useMemo(
    () => searchQuery.trim().length > 0,
    [ searchQuery ],
  );
  const filteredAttendees = useMemo(
    () =>
      hasSearchQuery
        ? attendees.filter(
            atd =>
              atd.user.fullName.match( new RegExp( searchQuery, 'i' )) ||
              String( atd.user.id ).match( new RegExp( searchQuery, 'i' )) ||
              atd.attendeeCharacters.some( x =>
                x.character.name.match( new RegExp( searchQuery, 'i' )),
              ),
          )
        : attendees,
    [ attendees, hasSearchQuery, searchQuery ],
  );

  useEffect(() => {
    if ( isExpanded ) {
      refetch();
    }
  }, [ isExpanded, refetch, ticketId ]);

  useEffect(() => {
    onFilterChange?.({ length: filteredAttendees.length, hasSearchQuery });
  }, [ filteredAttendees.length, hasSearchQuery, onFilterChange ]);

  return (
    <div
      className={clsx(
        'grid transition-all duration-300 overflow-auto',
        isExpanded
          ? 'max-h-[calc(100vh-128px)] sm:max-h-[67vh] grid-rows-[1fr] pb-2'
          : 'max-h-0 grid-rows-[0fr] pb-0',
      )}
    >
      {fetching ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-1 gap-2">
          {filteredAttendees
            .sort(( a, b ) => a.user.id - b.user.id )
            .map( x => (
              <Attendee key={x.id} attendee={x} />
            ))}
        </div>
      )}
    </div>
  );
};

export default TicketAttendees;
