import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const EventsHeader: FC = () => {
  const { organizationSlug } = useParams();
  const { isPermitted: canCreateEvent } = usePermission({
    action: 'create_event_ui',
  });

  if ( !canCreateEvent ) return null;

  return (
    <ResponsiveTabGroup label="Actions">
      {canCreateEvent && (
        <Tab
          label={
            <div>
              <FontAwesomeIcon icon={faPlus} className="pr-2" />
              Create New Event
            </div>
          }
          to={`/${organizationSlug}/events/new`}
          highlightMode="responsive"
        />
      )}
    </ResponsiveTabGroup>
  );
};

export default EventsHeader;
