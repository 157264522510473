import { useCallback, useEffect } from 'react';
import { updateItem } from 'src/graphql/mutations/items.graphql';
import {
  IUpdateItemMutation,
  IUpdateItemMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { IPropsWithOnUpdate } from '../types';

interface IProps extends IPropsWithOnUpdate {
  itemId: number;
  isNumeric?: boolean;
  field: string;
  initialValue: unknown;
}

const useItemUpdate = ({
  field,
  isNumeric = false,
  itemId,
  initialValue,
  onUpdate,
}: IProps ) => {
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      [field]: initialValue ?? null,
    },
  });

  const [ , update ] = useMutation<
    IUpdateItemMutation,
    IUpdateItemMutationVariables
  >( updateItem );

  const handleUpdate = useCallback(() => {
    if ( !isDirty ) return;

    onUpdate({ status: 'busy' });
    update({
      itemId,
      [field]: isNumeric ? Number( watch()[field]) : watch()[field],
    }).then( res => {
      if ( res.data?.updateItem?.item ) {
        onUpdate({ status: 'success' });
      }

      if ( res.data?.updateItem?.error ) {
        onUpdate({ status: 'error' });
      }
    });
  }, [ field, isDirty, isNumeric, itemId, onUpdate, update, watch ]);

  useEffect(() => {
    reset({ [field]: initialValue ?? null });
  }, [ field, initialValue, reset ]);

  return {
    handleUpdate,
    register,
  };
};

export default useItemUpdate;
