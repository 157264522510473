import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode } from 'react';

type THeader =
  | {
      content: ReactNode;
      className?: string;
      key?: string;
      onClick?: () => void;
    }
  | undefined;
interface IProps {
  headers: THeader[];
  className?: string;
  width?: string;
}

const Table: FC<PropsWithChildren<IProps>> = ({
  headers,
  className,
  children,
  width,
}) => (
  <div
    className={clsx(
      'max-w-[calc(100vw-8px)] sm:max-w-none overflow-auto',
      width ?? 'min-h-[100vh] sm:min-h-none',
    )}
  >
    <table className={clsx( className || 'w-full min-w-[480px]' )}>
      <thead>
        <tr className="text-left">
          {headers
            .filter( x => x )
            .map( x => (
              <th
                key={x?.key ?? x?.content?.toString()}
                className={clsx(
                  'p-1 pt-2 gray-box-vertical border-b border-juno-gray-700',
                  x?.className,
                )}
                onClick={() => x?.onClick?.()}
              >
                {x?.content}
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="align-top">{children}</tbody>
    </table>
  </div>
);

export default Table;
