import { gql } from 'urql';

export const getItems = gql`
  query GetItems {
    organization {
      id
      items {
        id
        name
        kind
        grade
      }
    }
  }
`;

export const getItemKinds = gql`
  query GetItemKinds {
    organization {
      id
      itemKinds
    }
  }
`;

export const getItemGrades = gql`
  query GetItemGrades {
    organization {
      id
      itemGrades
    }
  }
`;

export const getItemConsolidatedAttributes = gql`
  query GetItemConsolidatedAttributes {
    organization {
      id
      items {
        id
        name
        kind
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
          craftingComponents {
            id
            component {
              id
              name
              grade
              kind
            }
            amount
          }
          craftingFinalProducts {
            id
            stack
            finalProduct {
              id
              name
              kind
              lifetimeAmount
              metadata {
                mechanics
                requirementsToUse
                uses
              }
              childItemClassifications {
                id
                childItem {
                  id
                  name
                  kind
                  lifetimeAmount
                  metadata {
                    mechanics
                    requirementsToUse
                    uses
                  }
                }
              }
            }
          }
        }
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
          itemReproductionComponents {
            id
            item {
              id
              name
            }
            amount
          }
        }
      }
    }
  }
`;

export const getItemBaselines = gql`
  query GetItemBaselines {
    organization {
      id
      items(blueprintOnly: true) {
        id
        name
        kind
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
      }
    }
  }
`;

export const getItemCraftingComponents = gql`
  query GetItemCraftingComponents {
    organization {
      id
      items {
        id
        name
        kind
        metadata {
          notes
          printHeader
        }
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
          craftingComponents {
            id
            component {
              id
              name
              grade
              kind
            }
            amount
          }
        }
      }
    }
  }
`;

export const getItemCraftingFinalProducts = gql`
  query GetItemCraftingFinalProducts {
    organization {
      id
      items {
        id
        name
        itemCraftings {
          id
          craftingFinalProducts {
            id
            stack
            finalProduct {
              id
              name
              kind
              lifetimeAmount
              metadata {
                mechanics
                requirementsToUse
                uses
              }
              childItemClassifications {
                id
                childItem {
                  id
                  name
                  kind
                  lifetimeAmount
                  metadata {
                    mechanics
                    requirementsToUse
                    uses
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getItemReproductions = gql`
  query GetItemReproductions {
    organization {
      id
      items {
        id
        name
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
          itemReproductionComponents {
            id
            item {
              id
              name
            }
            amount
          }
        }
      }
    }
  }
`;

export const getItem = gql`
  query GetItem($itemId: Int!) {
    organization {
      id
      item(id: $itemId) {
        id
        name
        kind
        grade
        updatedAt
        lifetimeAmount
        lifetimeUnit
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
        blueprintForCraftings {
          id
          name
        }
        childItemClassifications {
          id
          childItem {
            id
            kind
            name
          }
        }
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
          craftingComponents {
            id
            component {
              id
              name
              grade
              kind
            }
            amount
          }
          craftingFinalProducts {
            id
            stack
            finalProduct {
              id
              name
              grade
              kind
              lifetimeAmount
              lifetimeUnit
              childItemClassifications {
                id
                childItem {
                  id
                  name
                  kind
                  metadata {
                    mechanics
                  }
                }
              }
              metadata {
                mechanics
                notes
                printHeader
                requirementsToUse
                uses
                durationOfEffect
                validTargetDescription
                locationOfUse
                equipmentRequiredForUse
                durationOfRoleplay
                descriptionOfRoleplay
                activationRequirement
              }
            }
          }
        }
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
          itemReproductionComponents {
            id
            item {
              id
              name
              grade
              kind
            }
            amount
          }
        }
        parts {
          id
          amount
          itemCrafting {
            id
            blueprint {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const searchItems = gql`
  query SearchItems($query: String!, $blueprint: Boolean!) {
    organization {
      id
      searchItems(query: $query, blueprint: $blueprint) {
        id
        name
        kind
        grade
      }
    }
  }
`;
