import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import lineageData from 'src/components/0200_lineage_dropdown/data';

const useLineageVariant = () => {
  const { watch } = useFormContext();
  const { lineageId, variant } = watch();

  const variantProperties = useMemo(() => {
    const variants = lineageData.find( x => x.id === lineageId )?.variants;

    if ( !variants ) return { advantage: null, disadvantage: null };
    if ( ![ 'civilized', 'wastelander' ].includes( variant ))
      return { advantage: null, disadvantage: null };

    return variants[variant as 'civilized' | 'wastelander'];
  }, [ lineageId, variant ]);

  return variantProperties;
};

export default useLineageVariant;
