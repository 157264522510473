import { FC, useCallback, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { uniq } from 'lodash';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import useCharacterBuilder from '../../../hooks/useCharacterBuilder';
import useSkillFinder from '../../../hooks/useSkillFinder';
import { TSkill } from '../../../types';
import skillData from '../../data';

interface IProps {
  name: string;
  pairs: string[];
}

const Pairwise: FC<IProps> = ({ name, pairs }) => {
  const { getSkillLevel } = useCharacterBuilder({
    levelCap: 1,
  });
  const { setValue, watch } = useFormContext();
  const skills = watch().skills as TSkill[];
  const { getSkill } = useSkillFinder();
  const skillLevel = useMemo(() => getSkillLevel( name ), [ getSkillLevel, name ]);
  const skillDataA = useMemo(
    () => skillData.find( x => x.name === pairs[0]),
    [ pairs ],
  );
  const skillDataB = useMemo(
    () => skillData.find( x => x.name === pairs[1]),
    [ pairs ],
  );
  const pairA = useMemo(() => getSkill( pairs[0]), [ getSkill, pairs ]);
  const pairB = useMemo(() => getSkill( pairs[1]), [ getSkill, pairs ]);
  const pairAPositions = useMemo(
    () => ( pairA?.positions ?? []).sort().slice( 0, skillLevel ),
    [ pairA?.positions, skillLevel ],
  );
  const pairBPositions = useMemo(
    () => ( pairB?.positions ?? []).sort().slice( 0, skillLevel ),
    [ pairB?.positions, skillLevel ],
  );

  const pairText = useCallback(
    ( i: number ) => {
      if ( pairAPositions.includes( i )) return String( skillDataA?.name );
      if ( pairBPositions.includes( i )) return String( skillDataB?.name );
      return 'Select';
    },
    [ pairAPositions, pairBPositions, skillDataA, skillDataB ],
  );

  const handleToggle = useCallback(
    ( i: number ) => {
      const current = pairText( i );

      setValue(
        'skills',
        skills
          .filter( x => ![ skillDataA?.id, skillDataB?.id ].includes( x.id ))
          .concat(
            {
              id: Number( skillDataA?.id ),
              name: String( skillDataA?.name ),
              level: 1,
              positions:
                current === String( skillDataA?.name )
                  ? pairAPositions.filter( x => x !== i )
                  : uniq( pairAPositions.concat( i )),
            },
            {
              id: Number( skillDataB?.id ),
              name: String( skillDataB?.name ),
              level: 1,
              positions:
                current === String( skillDataA?.name )
                  ? uniq( pairBPositions.concat( i ))
                  : pairBPositions.filter( x => x !== i ),
            },
          ),
        { shouldDirty: true },
      );
    },
    [
      pairAPositions,
      pairBPositions,
      pairText,
      setValue,
      skillDataA,
      skillDataB,
      skills,
    ],
  );

  return (
    <AnimatePresence>
      {getSkillLevel( name ) > 0 && (
        <motion.div
          layout
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 32 }}
          exit={{ opacity: 0, height: 0 }}
          className="grid grid-cols-3 items-center gap-0.5"
        >
          {Array.from({ length: getSkillLevel( name ) }).map(( _, i ) => (
            <button
              type="button"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={clsx(
                'text-xs leading-3 bg-gradient-to-br bg-clip-text text-transparent py-2 brightness-150 transition-all',
                pairText( i ) === String( skillDataA?.name ) &&
                  'from-[#c66d3a] to-[#3391a6]',
                pairText( i ) === String( skillDataB?.name ) &&
                  'from-[#7a3b91] to-[#c66d3a]',
                pairText( i ) === 'Select' && 'bg-juno-gray-50',
              )}
              onClick={() => handleToggle( i )}
            >
              {pairText( i )}
            </button>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Pairwise;
