import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IItemKindEnum } from 'src/graphql/types';
import useItem from 'src/hooks/items/useItem';
import ItemCrafting from './ItemCrafting';
import Benediction from './Benediction';

const ItemPrintout: FC = () => {
  const { itemId } = useParams();
  const { item } = useItem({
    itemId: Number( itemId ),
  });

  if ( !item ) return null;

  return (
    <div className="overflow-auto max-w-[calc(100vw-16px)]">
      {item.kind !== IItemKindEnum.Blueprint ? (
        'This Item is not a Blueprint'
      ) : (
        <div className="printout-container">
          {item.itemCraftings.length > 0 ? (
            item.itemCraftings.map( itemCrafting => (
              <ItemCrafting
                key={itemCrafting.id}
                {...item}
                itemCrafting={itemCrafting}
                itemReproduction={item.itemReproductions[0]}
              />
            ))
          ) : (
            <Benediction
              {...item}
              itemReproduction={item.itemReproductions[0]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemPrintout;
