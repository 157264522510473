import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import { IBranch, IEvent, ILocation } from 'src/graphql/types';

export type TEvent = Pick<IEvent, 'id' | 'name' | 'startsAt'> & {
  location: Pick<ILocation, 'id' | 'name'>;
  branch: Pick<IBranch, 'id' | 'shorthand'>;
};

interface IProps {
  event?: TEvent;
  totalCapsForEvent?: number;
}

const Event: FC<IProps> = ({ event, totalCapsForEvent }) => (
  <div className="text-right">
    <div className={clsx( event && 'text-2xl font-bold' )}>
      {event?.name ?? 'Freeform Print'}
    </div>
    {event && (
      <div>
        {event.location.name} - {format( event.startsAt, 'yyyy MMM dd' )}
      </div>
    )}
    <div>
      Generated {format( new Date(), 'yyyy MMM dd - HH:mm' )}
      {totalCapsForEvent
        ? ` | ${event?.branch.shorthand} CAPS: ${totalCapsForEvent}`
        : ''}
    </div>
  </div>
);

export default Event;
