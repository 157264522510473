import { FC } from 'react';
import Xarrow, { xarrowPropsType } from 'react-xarrows';

// IProps extends props of Xarrow

interface IProps extends xarrowPropsType {
  start: string;
  end: string;
  category?: string;
  isAberrant?: boolean;
  isHighlighted?: boolean;
}

const Edge: FC<IProps> = ({
  start,
  end,
  category,
  isAberrant,
  isHighlighted,
  ...props
}) => (
  <Xarrow
    start={start}
    end={end}
    startAnchor="right"
    endAnchor="left"
    curveness={0.5}
    path="grid"
    animateDrawing={0.25}
    strokeWidth={isHighlighted ? 2 : 1}
    headSize={isHighlighted ? 4 : 0}
    zIndex={isHighlighted ? 9 : -1}
    color={
      isHighlighted
        ? isAberrant
          ? '#298397'
          : category === 'impact'
            ? '#d07a49'
            : category === 'default'
              ? '#629134'
              : '#85479b'
        : '#444'
    }
    {...props}
  />
);

export default Edge;
