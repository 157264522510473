import clsx from 'clsx';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import ResponseBox from 'src/components/0100_response_box';

import AugmentedInput from 'src/components/0200_augmented_input';
import {
  createLocation,
  updateLocation,
} from 'src/graphql/mutations/locations.graphql';
import {
  ICreateLocationMutation,
  ICreateLocationMutationVariables,
  IUpdateLocationMutation,
  IUpdateLocationMutationVariables,
} from 'src/graphql/mutations/locations.graphql.types';
import { IRegionEnum } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import EventsInLocation from './EventsInLocation';

interface IProps {
  branchId: number;
  id: number | null;
  name?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  region?: IRegionEnum | null;
  zipCode?: string | null;
  existingEventsSize?: number | null;
}

const Location: FC<IProps> = ({ branchId, ...props }) => {
  const isNew = !props.id;
  const [ isSuccessful, setIsSuccessful ] = useState( false );
  const [ isExpanded, setIsExpanded ] = useState( isNew );
  const { buttonState } = useButtonStates();
  const [ error, setError ] = useState<string | null>( null );
  const {
    register,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      name: props.name,
      streetAddress: props.streetAddress,
      city: props.city,
      region: props.region,
      zipCode: props.zipCode,
    },
  });

  const { name, streetAddress, city, region, zipCode } = watch();

  const [ , update ] = useMutation<
    IUpdateLocationMutation,
    IUpdateLocationMutationVariables
  >( updateLocation );

  const [ createResult, create ] = useMutation<
    ICreateLocationMutation,
    ICreateLocationMutationVariables
  >( createLocation );

  const handleUpdate = useCallback(() => {
    if ( isNew ) return;
    if ( !isDirty ) return;

    setIsSuccessful( false );
    setError( null );

    update({
      locationId: props.id ?? 0,
      name,
      streetAddress,
      city,
      region,
      zipCode,
    }).then( res => {
      if ( res.data?.updateLocation?.location ) {
        setIsSuccessful( true );
        setTimeout(() => setIsSuccessful( false ), 3000 );
      }

      if ( res.data?.updateLocation?.error ) {
        setError( res.data.updateLocation.error );
      }
    });
  }, [
    isNew,
    props.id,
    update,
    name,
    streetAddress,
    city,
    region,
    zipCode,
    isDirty,
  ]);

  const handleCreate = useCallback(() => {
    if ( !isNew ) return;
    if ( !isValid ) return;
    if ( !( name && streetAddress && city && region && zipCode )) return;

    setError( null );

    create({
      branchId,
      name,
      streetAddress,
      city,
      region,
      zipCode,
    }).then( res => {
      setIsSuccessful( false );
      setError( null );
      if ( res.data?.createLocation?.branch ) {
        setIsSuccessful( true );
        setTimeout(() => setIsSuccessful( false ), 3000 );
        reset({
          name: '',
          streetAddress: '',
          city: '',
          region: null,
          zipCode: '',
        });
      }

      if ( res.data?.createLocation?.error ) {
        setError( res.data.createLocation.error );
      }
    });
  }, [
    create,
    reset,
    isNew,
    branchId,
    name,
    streetAddress,
    city,
    region,
    zipCode,
    isValid,
  ]);

  useEffect(() => {
    if ( !isNew ) {
      reset({
        name: props.name,
        streetAddress: props.streetAddress,
        city: props.city,
        region: props.region,
        zipCode: props.zipCode,
      });
    }
  }, [
    props.name,
    props.streetAddress,
    props.city,
    props.region,
    props.zipCode,
    isNew,
    reset,
  ]);

  return (
    <div
      className={clsx(
        'border border-l-4 transition-all duration-300 grid gap-2',
        isSuccessful
          ? 'border-juno-green-200 hover:border-juno-green-200'
          : 'border-juno-gray-700 hover:border-juno-gray-200',
      )}
    >
      {isNew && <div className="dark-box p-2">Create New Location</div>}
      <div className="p-2 grid gap-2">
        <div className="flex items-end gap-2">
          <div className="w-full">
            <AugmentedInput
              required
              isCompact
              fullWidth
              displayStacked
              title="Venue Name"
              isLocked={props.region === 'VIRTUAL'}
              placeholder="Ex: Skid Row Mall"
              {...register( 'name', { onBlur: handleUpdate, required: true })}
            />
          </div>
          {!isNew && (
            <button
              type="button"
              className="px-2 pb-1"
              onClick={() => setIsExpanded( x => !x )}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx(
                  'transition-all duration-300',
                  isExpanded && '-scale-y-100',
                )}
              />
            </button>
          )}
        </div>
        <div
          className={clsx(
            'grid transition-all duration-300',
            isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
          )}
        >
          <div className="overflow-hidden grid gap-2">
            <div className="w-full">
              <AugmentedInput
                required
                isCompact
                fullWidth
                displayStacked
                title="Address"
                isLocked={props.region === 'VIRTUAL'}
                placeholder="Ex: 500 Elm St"
                {...register( 'streetAddress', {
                  onBlur: handleUpdate,
                  required: true,
                })}
              />
            </div>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 md:col-span-6">
                <AugmentedInput
                  required
                  isCompact
                  fullWidth
                  displayStacked
                  title="City"
                  isLocked={props.region === 'VIRTUAL'}
                  placeholder="Ex: Los Angeles"
                  {...register( 'city', {
                    onBlur: handleUpdate,
                    required: true,
                  })}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <AugmentedInput
                  required
                  isCompact
                  fullWidth
                  displayStacked
                  title="State"
                  isLocked={props.region === 'VIRTUAL'}
                  placeholder="Ex: CA"
                  {...register( 'region', {
                    onBlur: handleUpdate,
                    required: true,
                  })}
                />
              </div>
              <div className="col-span-6 md:col-span-3">
                <AugmentedInput
                  required
                  isCompact
                  fullWidth
                  displayStacked
                  title="Zip Code"
                  isLocked={props.region === 'VIRTUAL'}
                  placeholder="Ex: 90003"
                  {...register( 'zipCode', {
                    onBlur: handleUpdate,
                    required: true,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        {!isNew && (
          <EventsInLocation
            locationId={Number( props.id )}
            existingEventsSize={Number( props.existingEventsSize )}
          />
        )}
        {error && <ResponseBox type="error">{error}</ResponseBox>}
        {isNew && (
          <div className="flex justify-end">
            <Button
              state={buttonState({
                isFetching: createResult.fetching,
                isDirty: isValid,
                isValid,
                isHighlight: isSuccessful,
              })}
              stateLabel={{
                loading: 'Creating New Location...',
                success: 'Location Created!',
              }}
              defaultLabel="Create New Location"
              onClick={handleCreate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Location;
