import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ITicketPassEnum, ITicketRestrictionEnum } from 'src/graphql/types';
import useEventAttendee from 'src/hooks/events/useEventAttendee';

const useTicketShiftRequirement = () => {
  const { event } = useEventAttendee();
  const { watch } = useFormContext();

  const { ticketId, shiftIds } = watch();
  const selectedTicket = event?.tickets.find( x => x.id === ticketId );
  const shiftResponsibilities =
    event?.tickets.find( x => x.id === ticketId )?.shiftResponsibilities ?? 0;

  const shifts = useMemo(() => event?.shifts ?? [], [ event ]);
  const isNonAttending =
    selectedTicket?.passType === ITicketPassEnum.NonAttendingPass;
  const isAssistantTicket =
    selectedTicket?.restrictionType ===
    ITicketRestrictionEnum.AssistantRestricted;
  const isEmployeeTicket =
    selectedTicket?.restrictionType ===
    ITicketRestrictionEnum.EmployeeRestricted;

  const requiresCleanupShift = !(
    isNonAttending ||
    isAssistantTicket ||
    isEmployeeTicket ||
    ( selectedTicket?.identifier ?? 0 ) >= 500
  );
  const selectedShifts = useMemo(
    () => shifts.filter( x => shiftIds.includes( x.id )),
    [ shifts, shiftIds ],
  );

  const selectedCastingShifts = useMemo(
    () => selectedShifts.filter( x => x.kind === 'casting' ),
    [ selectedShifts ],
  );
  const selectedCleanupShifts = useMemo(
    () => selectedShifts.filter( x => x.kind === 'cleanup' ),
    [ selectedShifts ],
  );
  const selectedSpecialShifts = useMemo(
    () => selectedShifts.filter( x => x.kind === 'special' ),
    [ selectedShifts ],
  );

  const castingShiftResponsibilities =
    selectedSpecialShifts.length > 0 ? 0 : shiftResponsibilities;

  const isCastingOrSpecialShiftSatisfied =
    selectedSpecialShifts.length > 0 ||
    selectedCastingShifts.length === castingShiftResponsibilities;
  const isCleanupShiftSatisfied =
    !requiresCleanupShift || selectedCleanupShifts.length > 0;
  const isShiftRequirementsSatisfied =
    isCastingOrSpecialShiftSatisfied && isCleanupShiftSatisfied;

  return {
    castingShiftResponsibilities,
    isNonAttending,
    isEmployeeTicket,
    isShiftRequirementsSatisfied,
    requiresCleanupShift,
    selectedCastingShifts,
    selectedCleanupShifts,
    selectedShifts,
    selectedSpecialShifts,
    ticketId,
  };
};

export default useTicketShiftRequirement;
