import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PlayerSearch from 'src/components/0400_player_search';
import DystopiaRisingCheckinForm from 'src/components/0500_checkin_forms/dystopia_rising';
import usePermission from 'src/hooks/permissions/usePermissions';

const Checkin: FC = () => {
  const { eventId } = useParams();
  const [ , setSearchParams ] = useSearchParams();
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number( eventId ),
  });

  return (
    <div className="grid gap-4">
      {canAssistCheckin && (
        <div className="flex justify-center w-full">
          <div className="w-[256px]">
            <PlayerSearch
              playersOnly
              placeholder="Search Players..."
              onSearchResultClick={({ playerId }) =>
                setSearchParams({ player_id: String( playerId ) })
              }
            />
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <DystopiaRisingCheckinForm />
      </div>
    </div>
  );
};

export default Checkin;
