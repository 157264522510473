import { FC, useState } from 'react';
import Table from 'src/components/0100_table';
import useCharacterLifecycles from 'src/hooks/characters/useCharacterLifecycles';
import Title from 'src/components/0100_title';
import { isBefore, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Loading from 'src/components/0100_loading';
import NewLifecycle from './NewLifecycle';
import Lifecycle from './Lifecycle';

interface IProps {
  canEdit?: boolean;
  playerId?: number;
  characterId?: number;
  eventId?: number;
  currentLife?: number;
  lifeCapacity?: number;
}

const LifecyleEditor: FC<IProps> = ({
  canEdit,
  playerId,
  characterId,
  eventId,
  currentLife = 0,
  lifeCapacity = 0,
}) => {
  const [ isExpanded, setIsExpanded ] = useState( false );
  const { lifecycles, fetching } = useCharacterLifecycles({
    playerId: playerId ?? 0,
    characterId: characterId ?? 0,
  });
  const canGain = currentLife < lifeCapacity;
  const canPurchase = lifeCapacity < 8;
  const canLose = currentLife > 0;

  if ( !playerId && !characterId ) return null;

  return (
    <div>
      <Title variant="heading">
        <button
          type="button"
          className="w-full flex justify-between text-2xl"
          onClick={() => setIsExpanded( x => !x )}
        >
          Infection Changes
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(
              'transition-all duration-300',
              isExpanded && '-scale-y-100',
            )}
          />
        </button>
      </Title>
      <div
        className={clsx(
          'grid transition-all duration-300',
          isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        {fetching ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div className="overflow-hidden">
            <Table
              width="min-h-[224px]"
              headers={[
                { content: 'Type', className: 'min-w-[80px]' },
                { content: 'Description', className: 'w-full' },
                { content: 'Event', className: 'min-w-[128px]' },
                { content: '', className: 'min-w-[24px]' },
              ]}
            >
              {eventId && playerId && characterId && canEdit && (
                <NewLifecycle
                  playerId={playerId}
                  characterId={characterId}
                  eventId={eventId}
                  canGain={canGain}
                  canPurchase={canPurchase}
                  canLose={canLose}
                />
              )}
              {lifecycles
                ?.sort(( a, b ) =>
                  isBefore( parseISO( a.createdAt ), parseISO( b.createdAt ))
                    ? 1
                    : -1,
                )
                .filter( x => ( canEdit ? x : !x.deletedAt ))
                .map( lifecycle => (
                  <Lifecycle
                    key={lifecycle.id}
                    canEdit={canEdit}
                    lifecycle={lifecycle}
                    playerId={playerId}
                    characterId={characterId}
                  />
                ))}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default LifecyleEditor;
