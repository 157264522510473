import {
  addDays,
  addMonths,
  eachWeekOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isFriday,
  isThisWeek,
  nextFriday,
  startOfMonth,
} from 'date-fns';
import { groupBy } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { IEventWithAttendance } from 'src/hooks/events/useEventsWithAttendances';
import Loading from '../0100_loading';
import EventCell from '../0200_event_cell';

interface IProps {
  start?: Date;
  end?: Date;
  sort?: 'asc' | 'desc';
  items: IEventWithAttendance[];
  isFetching?: boolean;
  lastMembership?: Date;
  onPrevious?: () => void;
  onNext?: () => void;
}

const Calendar: FC<IProps> = ({
  start = addMonths( startOfMonth( new Date()), -1 ),
  end = addMonths( endOfMonth( new Date()), 2 ),
  sort = 'asc',
  items,
  isFetching,
  lastMembership,
  onPrevious,
  onNext,
}) => {
  const sortFunctor = useCallback(
    ( a: string, b: string ) => a.localeCompare( b ) * ( sort === 'asc' ? 1 : -1 ),
    [ sort ],
  );

  const weeklyStruct = useMemo(
    () =>
      eachWeekOfInterval(
        { start: isFriday( start ) ? start : nextFriday( start ), end },
        { weekStartsOn: 5 },
      )
        .map( week => ({
          year: week.getFullYear(),
          month: format( week, 'MMMM' ),
          keyMonday: format( addDays( week, -4 ), 'yyyy-MM-dd' ),
          friday: week,
        }))
        .sort(( a, b ) => sortFunctor( a.keyMonday, b.keyMonday )),
    [ start, end, sortFunctor ],
  );

  const yearlyGroup = useMemo(
    () => groupBy( weeklyStruct, x => x.year ),
    [ weeklyStruct ],
  );

  const [ activeCell, setActiveCell ] = useState<{
    year: number;
    month: string | null;
    weekIndex: number;
    cellIndex: number;
  }>({ year: 0, month: null, weekIndex: 0, cellIndex: 0 });

  return (
    <div>
      {Object.keys( yearlyGroup )
        .sort( sortFunctor )
        .map(( kYear, yearIndex ) => (
          <div key={`year-${kYear}`}>
            <div className="text-center font-bold p-2 sticky top-0 border-b border-juno-gray-700 z-[20]">
              {kYear}
            </div>
            {Object.values( groupBy( yearlyGroup[kYear], x => x.month )).map(
              ( months, monthIndex ) => (
                <div key={`${kYear}-${months[0].month}`}>
                  <div className="p-2 border-b border-juno-gray-700 sticky top-0 gray-box flex justify-between z-[19]">
                    <div>{months[0].month}</div>
                    {yearIndex === 0 &&
                      monthIndex === 0 &&
                      ( isFetching ? (
                        <Loading size="small" />
                      ) : (
                        <button
                          type="button"
                          className="hover:text-shadow"
                          onClick={() =>
                            sort === 'asc' ? onPrevious?.() : onNext?.()
                          }
                        >
                          {sort === 'asc' ? 'Previous' : 'Next'}
                        </button>
                      ))}
                  </div>
                  {months.map(( week, weekIndex ) => (
                    <div
                      key={week.keyMonday}
                      className={clsx(
                        'border-b border-juno-gray-700 flex items-center',
                        isThisWeek( week.friday, { weekStartsOn: 1 }) &&
                          'midtone-box',
                      )}
                    >
                      <div
                        className={clsx( 'p-2 text-right w-16', {
                          'text-juno-gray-700':
                            items.filter( x => x.keyMonday === week.keyMonday )
                              .length === 0,
                        })}
                      >
                        {format( week.friday, 'd' )}
                      </div>
                      <div className="pt-2 pl-2 flex w-[calc(100%-64px)] min-h-[40px] flex-wrap border-l border-juno-gray-700">
                        {lastMembership &&
                          isAfter( lastMembership, addDays( week.friday, -4 )) &&
                          isBefore( lastMembership, addDays( week.friday, 3 )) && (
                            <div className="orange-box px-4 py-1 mr-2 mb-2">
                              Membership Expires{' '}
                              {format( lastMembership, 'yyyy-MM-dd' )}
                            </div>
                          )}
                        {items
                          .filter( x => x.keyMonday === week.keyMonday )
                          .sort(( a, b ) =>
                            a.name
                              .toLowerCase()
                              .localeCompare( b.name.toLowerCase()),
                          )
                          .map(( x, cellIndex ) => (
                            <motion.div
                              key={x.id}
                              initial={{ opacity: 0, scale: 0 }}
                              animate={{
                                opacity: 1,
                                scale: 1,
                                transition: {
                                  delay: weekIndex * 0.05 + cellIndex * 0.075,
                                },
                              }}
                              style={{
                                zIndex:
                                  19 +
                                  ( week.year === activeCell.year &&
                                  week.month === activeCell.month &&
                                  weekIndex === activeCell.weekIndex &&
                                  cellIndex === activeCell.cellIndex
                                    ? 2
                                    : 0 ),
                              }}
                              onClick={() =>
                                setActiveCell({
                                  year: week.year,
                                  month: week.month,
                                  weekIndex,
                                  cellIndex,
                                })
                              }
                            >
                              <EventCell event={x} />
                            </motion.div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              ),
            )}
          </div>
        ))}
      <div className="text-right p-2 glass-pane">
        {isFetching ? (
          <Loading size="small" />
        ) : (
          <button
            type="button"
            className="hover:text-shadow"
            onClick={() => ( sort === 'asc' ? onNext?.() : onPrevious?.())}
          >
            {sort === 'asc' ? 'Next' : 'Previous'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Calendar;
