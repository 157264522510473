import { faCircle, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Title from 'src/components/0100_title';

interface IProps {
  filterKey: string;
  title: string;
  trueLabel: string;
  falseLabel: string;
}

const Boolean: FC<IProps> = ({ filterKey, title, trueLabel, falseLabel }) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const valueFromUrl = searchParams.get( filterKey );
  const isDefined = valueFromUrl !== null;
  const isTrue = valueFromUrl === 'true';
  const isFalse = valueFromUrl === 'false';

  const handleClick = useCallback(
    ( value: boolean | null ) => {
      if ( value === null ) {
        searchParams.delete( filterKey );
      } else {
        searchParams.set( filterKey, String( value ));
      }
      setSearchParams( searchParams );
    },
    [ filterKey, searchParams, setSearchParams ],
  );

  return (
    <div>
      <Title variant="heading">{title}</Title>
      <div className="grid gap-2">
        <button
          type="button"
          className={clsx(
            'text-left',
            !isDefined ? 'text-shadow' : 'opacity-50',
          )}
          onClick={() => handleClick( null )}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={isDefined ? faCircle : faCircleDot}
          />
          All
        </button>
        <button
          type="button"
          className={clsx( 'text-left', isTrue ? 'text-shadow' : 'opacity-50' )}
          onClick={() => handleClick( true )}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={isTrue ? faCircleDot : faCircle}
          />
          {trueLabel}
        </button>
        <button
          type="button"
          className={clsx( 'text-left', isFalse ? 'text-shadow' : 'opacity-50' )}
          onClick={() => handleClick( false )}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={isFalse ? faCircleDot : faCircle}
          />
          {falseLabel}
        </button>
      </div>
    </div>
  );
};

export default Boolean;
