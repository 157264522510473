import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface IProps extends Omit<LinkProps, 'to'> {
  isActive?: boolean;
  url: string;
  label?: string;
  className?: string;
}

const SidebarLink: FC<PropsWithChildren<IProps>> = ({
  isActive,
  label,
  children,
  url,
  className,
  ...props
}) => (
  <div
    className={clsx(
      'border-l-4 transition-all duration-300 ease-in-out overflow-hidden',
      isActive
        ? 'border-juno-gray-50 text-juno-gray-50 text-shadow'
        : 'border-transparent hover:border-juno-gray-200 text-juno-gray-200 hover:text-juno-gray-50 hover:text-shadow',
    )}
  >
    <Link
      className={clsx( 'block px-2 py-1 text-left w-full', className )}
      to={url}
      {...props}
    >
      {children || label}
    </Link>
  </div>
);
export default SidebarLink;
