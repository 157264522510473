import { create } from 'zustand';

interface ICharacterEditorState {
  isSafetyLockActive: boolean;
  isSaving: boolean;
  isSaved: boolean;
  error: string | null;
  actions: {
    isSaving: () => void;
    isSaved: () => void;
    isErrored: ( error: string ) => void;
    lock: () => void;
    reset: () => void;
    toggleLock: () => void;
  };
}

const useCharacterBuilderStore = create<ICharacterEditorState>( set => ({
  actions: {
    isSaving: () =>
      set(() => ({ isSaving: true, isSaved: false, error: null })),
    isSaved: () => set(() => ({ isSaving: false, isSaved: true, error: null })),
    isErrored: error => set(() => ({ isSaving: false, isSaved: false, error })),
    lock: () => set(() => ({ isSafetyLockActive: true })),
    reset: () => set(() => ({ isSaving: false, isSaved: false, error: null })),
    toggleLock: () =>
      set( state => ({ isSafetyLockActive: !state.isSafetyLockActive })),
  },
  isSafetyLockActive: true,
  isSaving: false,
  isSaved: false,
  error: null,
}));

export default useCharacterBuilderStore;
