import { searchPlayers } from 'src/graphql/queries/players.graphql';
import {
  ISearchPlayerQuery,
  ISearchPlayerQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { IOrganizationRoleEnum } from 'src/graphql/types';
import { useQuery } from 'urql';

const useSearchPlayers = ({
  query,
  playersOnly,
  accessLevelFilters,
  branchId,
}: {
  query: string;
  playersOnly?: boolean;
  accessLevelFilters?: IOrganizationRoleEnum[];
  branchId?: number;
}) => {
  const [{ data, error, fetching }, executeSearch ] = useQuery<
    ISearchPlayerQuery,
    ISearchPlayerQueryVariables
  >({
    query: searchPlayers,
    variables: { query, playersOnly, accessLevelFilters, branchId },
    requestPolicy: 'network-only',
    pause: true,
  });

  return { data, error, fetching, executeSearch };
};

export default useSearchPlayers;
