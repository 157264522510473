import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Toggle from 'react-toggle';
import ResponseBox from 'src/components/0100_response_box';
import { IShift, IShiftKindEnum } from 'src/graphql/types';
import {
  hasDayInLabel,
  identifierToDay,
  sortFunctor,
} from 'src/utils/shiftTime';

interface IProps {
  canEditShift: boolean;
  showAllShifts: boolean;
  shifts: Pick<
    IShift,
    'id' | 'identifier' | 'label' | 'isEnabled' | 'isFullyBooked'
  >[];
  kind: IShiftKindEnum;
  onActivate?: ( shiftId: number ) => void;
}
const ShiftList: FC<IProps> = ({
  canEditShift,
  showAllShifts,
  shifts,
  kind,
  onActivate,
}) => {
  const { setValue, watch } = useFormContext();
  const { shiftIds } = watch();

  return (
    <motion.div layout>
      {kind === IShiftKindEnum.Special && (
        <ResponseBox type="neutral">
          Special Shifts are substitute for Casting Shifts. Please arrange with
          your Game Runners prior to checking in.
        </ResponseBox>
      )}
      <div>
        {shifts
          .filter( x => x.isEnabled )
          .filter( x => ( showAllShifts ? true : shiftIds.includes( x.id )))
          .sort(( a, b ) => sortFunctor( a, b, kind ))
          .map( shift => (
            <div
              key={shift.id}
              className={clsx(
                'flex justify-start items-start gap-2 hover:text-shadow transition-all',
                !( canEditShift && !shift.isFullyBooked ) &&
                  'opacity-50 pointer-events-none',
              )}
            >
              <div>
                <Toggle
                  id={`shift-${shift.id}`}
                  disabled={!( canEditShift && !shift.isFullyBooked )}
                  checked={shiftIds.includes( shift.id )}
                  onChange={x => {
                    if ( onActivate && x.target.checked ) {
                      onActivate( shift.id );
                    } else {
                      setValue(
                        'shiftIds',
                        x.target.checked
                          ? [ ...shiftIds, shift.id ]
                          : shiftIds.filter(( y: number ) => y !== shift.id ),
                        { shouldDirty: true },
                      );
                    }
                  }}
                />
              </div>
              <label className="cursor-pointer" htmlFor={`shift-${shift.id}`}>
                {kind === IShiftKindEnum.Casting &&
                !hasDayInLabel( shift.label ?? '' )
                  ? `${identifierToDay( shift.identifier )} `
                  : ''}
                {shift.label}
                {shift.isFullyBooked && ' [Full]'}
              </label>
            </div>
          ))}
      </div>
    </motion.div>
  );
};

export default ShiftList;
