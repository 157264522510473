import { gql } from 'urql';

export const getEventAttendee = gql`
  query GetEventAttendee($eventId: Int!, $playerId: Int!) {
    event(eventId: $eventId) {
      id
      registrationOpensAt
      startsAt
      endsAt
      activeMembership(playerId: $playerId)
      maximumCheckinableCharacters(playerId: $playerId)
      isHomeGame(playerId: $playerId)
      canSelfCheckin(playerId: $playerId)
      activeCorrectiveActions(playerId: $playerId) {
        id
        issuer {
          id
          fullName
        }
        branch {
          id
          shorthand
        }
        kind
        reason
      }
      config {
        baseBuild
        maxExtraXp
        extraXpCost
        shiftUnit
        shiftMultiplier
        shiftKinds
      }
      eventAttendee(playerId: $playerId) {
        id
        buildAwarded
        buildGrowth
        checkinApproved
        kind
        paid
        attending
        shifts {
          id
          label
        }
        ticket {
          id
          cost
          identifier
          label
          passType
          shiftResponsibilities
        }
        attendeeCharacters {
          id
          printRequestStatus
          character {
            id
            name
            status
          }
        }
      }
      tickets(playerId: $playerId) {
        id
        cost
        discountType
        fullyBooked
        identifier
        label
        passType
        restrictionType
        shiftResponsibilities
      }
      shifts {
        id
        label
        kind
        identifier
        isEnabled
        isFullyBooked
      }
    }
  }
`;
