import { gql } from 'urql';

export const getCharacter = gql`
  query GetCharacter($playerId: Int!, $characterId: Int!) {
    user(userId: $playerId) {
      id
      fullName
      roleInOrganization
      userOrganization {
        id
        branch {
          id
          shorthand
        }
      }
      character(characterId: $characterId) {
        id
        name
        buildEarned
        buildUsed
        body
        mind
        resolve
        infection
        currentLife
        lifeCapacity
        lifeGain
        lifeLoss
        latestLifecycleGainsThisYear
        faithId
        loreIds
        skills {
          id
          characterSkillId
          level
          positions
        }
        fractures {
          id
          name
          createdAt
          removedAt
        }
        characterFellowships {
          id
          rank
          removedAt
          fellowship {
            id
            kind
            name
          }
        }
        strainId
        lineageId
        variant
        status
        staffNotes
        playerNotes
        createdAt
        version
      }
    }
  }
`;

export const getCharacterXp = gql`
  query GetCharacterXp($playerId: Int!, $characterId: Int!) {
    user(userId: $playerId) {
      id
      character(characterId: $characterId) {
        id
        xpAudits {
          eventId
          eventName
          xpAwarded
          xpAddOn
          homeGameEventsIndex
          kind
          awardedAt
          branchTransferId
          originBranch
          destinationBranch
          extraBuildId
          applicableOn
        }
      }
    }
  }
`;

export const getCharacterLifecycles = gql`
  query GetCharacterLifecycles($playerId: Int!, $characterId: Int!) {
    user(userId: $playerId) {
      id
      character(characterId: $characterId) {
        id
        currentLife
        lifeCapacity
        lifeGain
        lifeLoss
        lifecycles {
          id
          kind
          description
          createdAt
          deletedAt

          event {
            id
            name
          }
        }
      }
    }
  }
`;
