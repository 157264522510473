import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { IFellowship, IFellowshipKindEnum } from 'src/graphql/types';
import Details from '../Details';

interface IProps {
  fellowship: Pick<
    IFellowship,
    'id' | 'name' | 'charactersCount' | 'removedAt'
  >;
  type: IFellowshipKindEnum;
  isExpanded: boolean;
}
const Fellowship: FC<IProps> = ({ fellowship, isExpanded, type }) => (
  <div>
    <button
      type="button"
      className={clsx(
        'w-full flex gap-2 items-center border-l-4 px-2 py-1 hover:border-juno-gray-200 transition-all duration-300',
        isExpanded
          ? 'border-juno-gray-50 glass-pane text-juno-gray-50'
          : 'border-transparent text-juno-gray-200',
      )}
    >
      <div className="w-full text-left">{fellowship.name}</div>
      <div className="flex gap-2 items-center">
        <div className="text-right">{fellowship.charactersCount}</div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={clsx(
            'rotate-0 transition-all duration-300 hidden lg:block',
            isExpanded && '-scale-y-100',
          )}
        />
      </div>
    </button>
    <div
      className={clsx(
        'grid transition-all duration-300',
        isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
      )}
    >
      <div className="w-full block lg:hidden overflow-hidden">
        <Details isEmbedded type={type} />
      </div>
    </div>
  </div>
);

export default Fellowship;
