import { FC, useCallback, useEffect, useState } from 'react';

interface IProps {
  activeHeaders: ({ id: string; label: string } | undefined )[];
  widths: Record<string, number>;
  onDrag: ({ id, width }: { id: string; width: number }) => void;
}

const TableHeader: FC<IProps> = ({ activeHeaders, widths, onDrag }) => {
  const [ originX, setOriginX ] = useState<number>( 0 );
  const [ targetHeader, setTargetHeader ] = useState<string | null>( null );
  const [ targetOriginalWidth, setTargetOriginalWidth ] = useState<number | null>(
    null,
  );
  const [ isDragging, setIsDragging ] = useState<boolean>( false );

  const handleMouseMove = useCallback(
    ( e: MouseEvent ) => {
      if ( isDragging && targetHeader && targetOriginalWidth ) {
        e.preventDefault();
        const deltaX = e.clientX - originX;

        onDrag({ id: targetHeader, width: targetOriginalWidth + deltaX });
      }
    },
    [ isDragging, onDrag, originX, targetHeader, targetOriginalWidth ],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging( false );
  }, []);

  useEffect(() => {
    window.addEventListener( 'mousemove', handleMouseMove );
    window.addEventListener( 'mouseup', handleMouseUp );

    return () => {
      window.removeEventListener( 'mousemove', handleMouseMove );
      window.removeEventListener( 'mouseup', handleMouseUp );
    };
  }, [ handleMouseMove, handleMouseUp, isDragging ]);

  return activeHeaders.map( x => (
    <th
      key={x?.id}
      style={{
        width: widths[x!.id],
        minWidth: widths[x!.id],
        maxWidth: widths[x!.id],
      }}
      className="group cursor-ew-resize overflow-hidden"
      onMouseDown={e => {
        e.preventDefault();
        const target = widths[typeof x === 'string' ? x : x!.id];

        if ( target ) {
          setOriginX( e.clientX );
          setIsDragging( true );
          setTargetHeader( typeof x === 'string' ? x : x!.id );
          setTargetOriginalWidth( widths[typeof x === 'string' ? x : x!.id]);
        }
      }}
      onMouseUp={handleMouseUp}
    >
      {x?.label}
    </th>
  ));
};

export default TableHeader;
