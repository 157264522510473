import { FC } from 'react';
import { startCase } from 'lodash';
import ResponseBox from 'src/components/0100_response_box';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const CheckinBlocker: FC = () => {
  const { event } = useEventAttendee();

  if ( event?.canSelfCheckin ) return null;

  return (
    <ResponseBox type="error" withIcon={faTriangleExclamation}>
      <div className="p-2 grid">
        <div className="pb-4">
          {event?.canSelfCheckin ? (
            <p>
              This Player has Active Corrective Actions. These do not block them
              from self-checkin.
            </p>
          ) : (
            <p>
              This Player is unable to perform self-checkin due to active
              Suspension and/or Refusal of Service below. Your privilege enables
              you to bypass this restriction at your own discretion.
            </p>
          )}
        </div>
        {event?.activeCorrectiveActions.map( ca => (
          <div
            key={ca.id}
            className="border-t border-juno-gray-50 flex items-center gap-4 py-2 last-of-type:pb-0"
          >
            <div className="border rounded bg-juno-gray-50 text-juno-gray-700 text-xl w-12 text-center">
              {ca.branch.shorthand}
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="border rounded px-2 bg-juno-gray-50 text-juno-gray-700 inline-flex">
                  {startCase( ca.kind )}
                </div>
                <div>
                  Issued by #{ca.issuer.id} {ca.issuer.fullName}
                </div>
              </div>
              {ca.reason}
            </div>
          </div>
        ))}
      </div>
    </ResponseBox>
  );
};

export default CheckinBlocker;
