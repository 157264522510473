import { format } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Loading from 'src/components/0100_loading';
import PunchTape from 'src/components/0200_punch_tape';
import { IAssistantFlagEnum, IOrganizationRoleEnum } from 'src/graphql/types';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

import Badge from 'src/components/0100_badge';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

type TEventAttendance = {
  id: number;
  event: {
    id: number;
    startsAt: Date;
    name: string;
    branchId: number;
  };
};

type TPlayer = {
  id: number;
  name: string;
  eventAttendances: TEventAttendance[];
  flags?: IAssistantFlagEnum[];
  originBranch?: string | null;
  role?: IOrganizationRoleEnum | null;
};

interface IProps {
  className?: string;
  players: TPlayer[];
  isFetching: boolean;
  deletionLock: boolean;
  deletingPlayerId?: number | null;
  emptyMessage: string;
  branchId: number;
  sticky: boolean;
  onUpdate?: ({
    playerId,
    flags,
  }: {
    playerId: number;
    flags: IAssistantFlagEnum[];
  }) => void;
  onDelete?: ({ playerId }: { playerId: number }) => void;
}

const flags = [
  IAssistantFlagEnum.Directorship,
  IAssistantFlagEnum.Logistics,
  IAssistantFlagEnum.ModManager,
  IAssistantFlagEnum.Registration,
];

const PunchTable: FC<IProps> = ({
  className,
  branchId,
  players,
  isFetching,
  deletionLock,
  deletingPlayerId,
  emptyMessage,
  sticky,
  onUpdate,
  onDelete,
}) => {
  const { linkToPlayer } = useAppNavigations();
  const filteredPlayers = players.filter( x => x.eventAttendances.length > 0 );

  if ( filteredPlayers.length === 0 ) {
    return (
      <tr>
        <td colSpan={3}>{emptyMessage}</td>
      </tr>
    );
  }

  return isFetching ? (
    <tr>
      <td colSpan={7}>
        <Loading size="small" />
      </td>
    </tr>
  ) : (
    players
      .filter( x => deletingPlayerId !== x.id )
      .map( player => (
        <tr key={player.id}>
          <td
            className={clsx(
              'player-id -left-14',
              sticky && 'sticky z-10',
              className,
            )}
            colSpan={player.flags || player.role || player.originBranch ? 1 : 2}
          >
            <Link
              to={linkToPlayer({ playerId: player.id })}
              className="min-w-[128px] flex items-center gap-2 w-full flex-shrink hover:text-shadow"
            >
              <div className="min-w-[48px] text-right underline">
                {player.id}
              </div>
              <div className="whitespace-nowrap overflow-hidden">
                <div>{player.name}</div>
              </div>
            </Link>
          </td>

          {( player.role || player.originBranch ) && (
            <td>
              <div className="flex items-center justify-end opacity-50 text-sm">
                {player.role === IOrganizationRoleEnum.Assistant && (
                  <Badge type={IOrganizationRoleEnum.Assistant} />
                )}
                {player.role === IOrganizationRoleEnum.Employee && (
                  <Badge type={IOrganizationRoleEnum.Employee} />
                )}
                {player.originBranch && <div>{player.originBranch}</div>}
              </div>
            </td>
          )}

          {player.flags && (
            <td
              className={clsx(
                'text-center badge relative',
                sticky ? 'z-0' : '-z-10',
              )}
            >
              <div className="flex items-center">
                {flags.map( flag => (
                  <div key={flag}>
                    <button
                      type="button"
                      onClick={() =>
                        onUpdate?.({
                          playerId: player.id,
                          flags: player.flags?.includes( flag )
                            ? player.flags.filter( x => x !== flag )
                            : ( player.flags ?? []).concat( flag ),
                        })
                      }
                    >
                      <Badge
                        type={flag}
                        className={clsx(
                          'transition-all text-sm',
                          player.flags?.includes( flag ) ? '' : 'opacity-25',
                        )}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </td>
          )}

          <td className="no-padding flex items-center group/row">
            <PunchTape
              dots={player.eventAttendances.map( x => ({
                id: x.event.id,
                date: x.event.startsAt,
                content: (
                  <div>
                    <div className="whitespace-nowrap overflow-hidden">
                      {x.event.name}
                    </div>
                    <div>{format( x.event.startsAt, 'MMM d' )}</div>
                  </div>
                ),
                className:
                  x.event.branchId === branchId
                    ? 'bg-juno-gray-50'
                    : 'bg-juno-gray-700',
              }))}
            />
            {!deletionLock && (
              <div className="w-0 h-0 relative">
                <div
                  className={clsx(
                    'transition-all duration-300',
                    'absolute -mt-[10px] -ml-[24px]',
                    'cursor-pointer',
                    'pointer-events-none group-hover/row:pointer-events-auto',
                    'opacity-0 group-hover/row:opacity-90',
                  )}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => {
                      onDelete?.({ playerId: player.id });
                    }}
                  />
                </div>
              </div>
            )}
          </td>
        </tr>
      ))
  );
};

export default PunchTable;
