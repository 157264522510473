import { fromPairs } from 'lodash';

type TBaseType = {
  id: number;
  name: string;
  category: 'default' | 'impact' | 'development';
  isAberrant: boolean;
  isPairwise?: boolean;
  isProfession: boolean;
  pairwiseTo?: string[];
};

type TBasicSkill = TBaseType & { isProfession: false; leadsTo: null };
type TBasicSkillWithProfession = TBaseType & {
  isProfession: false;
  leadsTo: string;
};
export type TProfession = TBaseType & {
  isProfession: true;
  skills: { id: number; name: string }[];
  leadsTo?: undefined;
};

type TSkillData = TBasicSkill | TBasicSkillWithProfession | TProfession;

export const deCompressionMapping: Record<string, number> = {
  AA: 201, // AA -> AT: Impact skills level 1 to 20
  BA: 202,
  CA: 210,
  DA: 211,
  EA: 212,
  FA: 213,
  GA: 214,
  HA: 215,
  IA: 216,
  JA: 217,
  KA: 218,
  LA: 219,
  MA: 220,
  NA: 221,
  OA: 222,
  PA: 223,
  QA: 224,
  RA: 225,
  SA: 226,
  TA: 227,
  UA: 228,
  VA: 229,
  WA: 230,
  XA: 231,
  YA: 232,
  ZA: 233,
  AU: 234, // AU -> AZ: Development skills level 1 to 5
  BU: 235,
  CU: 236,
  DU: 237,
  EU: 238,
  FU: 239,
  GU: 240,
  HU: 241,
  IU: 242,
  JU: 243,
  KU: 244,
  LU: 245,
  MU: 246,
  NU: 247,
  kA: 298, // kA -> kZ: Pairwise skills
  ka: 299, // ka -> kz: Pairwise skills
  zA: 301, // z*: Professions, 1-level only
  zB: 302,
  zC: 303,
  zD: 304,
  zE: 305,
  zF: 306,
  zG: 307,
  zH: 308,
  zI: 309,
  zJ: 310,
  zK: 311,
  zL: 312,
  zM: 313,
  zN: 314,
  zO: 315,
  zP: 316,
  zQ: 317,
  zR: 318,
  zS: 319,
  zT: 320,
  zU: 321,
  zV: 322,
  zW: 323,
  y0: 401, // y0 -> y9: Profession Skills
  yA: 402, // q* -> y*
  yK: 403,
  ya: 404,
  yk: 405,
  x0: 406,
  xA: 407,
  xK: 408,
  xa: 409,
  xk: 410,
  w0: 411,
  wA: 412,
  wK: 413,
  wa: 414,
  wk: 415,
  v0: 416,
  vA: 417,
  vK: 418,
  va: 419,
  vk: 420,
  u0: 421,
  uA: 422,
  uK: 423,
  ua: 424,
  uk: 425,
  t0: 426,
  tA: 427,
  tK: 428,
  ta: 429,
  tk: 430,
  s0: 431,
  sA: 432,
  sK: 433,
  sa: 434,
  sk: 435,
  r0: 436,
  rA: 437,
  rK: 438,
  ra: 439,
  rk: 440,
  q0: 441,
  qA: 442,
  qK: 443,
  qa: 444,
  qk: 445,
  p0: 446,
};

export const compressionMapping = fromPairs(
  Object.entries( deCompressionMapping ).map(([ key, val ]) => [ val, key ]),
);

export const skillNames: Record<number, string> = {
  201: 'Malicious Discussion',
  202: 'Mental Endurance',
  210: 'Avoid',
  211: 'Awareness',
  212: 'Balance',
  213: 'Blinding',
  214: 'Break',
  215: 'Choking Blow',
  216: 'Double-Tap',
  217: 'Enhanced Movement',
  218: 'First Aid',
  219: 'Interfere',
  220: 'Knockout',
  221: 'Pickpocket',
  222: 'Piercing Strike',
  223: 'Salvaging',
  224: 'Scatter Shot',
  225: 'Stealth',
  226: 'Takedown',
  227: 'Trap Dis/Arming',
  228: 'Biogenetics',
  229: 'Necrogenesis',
  230: 'Precognition',
  231: 'Psychic Propulsion',
  232: 'Pyrokinetics',
  233: 'Telekinesis',
  234: 'Biokinesis',
  235: 'Faithful Miracles',
  236: 'Faithful Will',
  237: 'Necrokinetics',
  238: 'Agricultural',
  239: 'Artisan',
  240: 'Criminal Influence',
  241: 'Culinary',
  242: 'Financial Manipulation',
  243: 'Foraging',
  244: 'Lockpicking & Security',
  245: 'Medical',
  246: 'Travel',
  247: 'Education',
  298: 'Black Market',
  299: 'Murder Inc',
  301: 'Battle Wall',
  302: 'Shock Trooper',
  303: 'Violence Technician',
  304: 'Assassin',
  305: 'Combat Medic',
  306: 'Nightcap',
  307: 'Reaper',
  308: 'Scavenger',
  309: 'Shadow',
  310: 'Sharpshooter',
  311: 'Skirmisher',
  312: 'Squad Leader',
  313: 'Flame Whisperer',
  314: 'Infection Bender',
  315: 'Life Anchor',
  316: 'Saint',
  317: 'Heldiver',
  318: 'Caravaneer',
  319: 'Corpse Harvester',
  320: 'Jones',
  321: 'Mortis Practitioner',
  322: 'Ruin Engineer',
  323: 'Mind Killer',
  401: 'Mangle',
  402: 'Shield Master',
  403: 'Ground And Pound',
  404: 'Momentum',
  405: 'Dead To Rights',
  406: 'Trade Cycled Equipment',
  407: 'Bleeding Mark',
  408: 'Murder',
  409: 'Drag Handle',
  410: "I've Seen Worse",
  411: 'Cutthroat',
  412: 'Ground Shatter',
  413: 'Death Stalk',
  414: "Hunter's Mark",
  415: 'Cowards Way Out',
  416: 'Gimme Your Kicks',
  417: 'Never Here',
  418: 'The a la menthe',
  419: 'Equipment Awareness',
  420: 'Trick Shot',
  421: 'Agile Movement',
  422: 'Riposte',
  423: 'Dead Trigger',
  424: 'Rally Call',
  425: 'Living Flame',
  426: 'Spoilers',
  427: 'Paralysis',
  428: 'Wrong Way',
  429: 'Imprint Shaker',
  430: 'Life For A Life',
  431: 'Price of the Martyr',
  432: 'Stand For The Holy',
  433: 'The Grand Design',
  434: 'The Last Watch',
  435: 'I Belong Here',
  436: 'The Wheels of Commerce',
  437: 'Dead Market Harvest',
  438: 'Farming Unto Death',
  439: 'I Forgot More Than You Know',
  440: 'One For The Books',
  441: 'Mortuary Morsel',
  442: 'The Living Experiment',
  443: 'Digging Deeper',
  444: 'The Organic Machine',
  445: 'Aberrant Horror',
  446: 'Aberrant Suffering',
};

const skillData: TSkillData[] = [
  {
    id: 201,
    name: skillNames[201],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[323],
  },
  {
    id: 202,
    name: skillNames[202],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[323],
  },
  {
    id: 210,
    name: skillNames[210],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[311],
  },
  {
    id: 211,
    name: skillNames[211],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[305],
  },
  {
    id: 212,
    name: skillNames[212],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[311],
  },
  {
    id: 213,
    name: skillNames[213],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[304],
  },
  {
    id: 214,
    name: skillNames[214],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[310],
  },
  {
    id: 215,
    name: skillNames[215],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[306],
  },
  {
    id: 216,
    name: skillNames[216],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[312],
  },
  {
    id: 217,
    name: skillNames[217],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[307],
  },
  {
    id: 218,
    name: skillNames[218],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[305],
  },
  {
    id: 219,
    name: skillNames[219],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[312],
  },
  {
    id: 220,
    name: skillNames[220],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[306],
  },
  {
    id: 221,
    name: skillNames[221],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[308],
  },
  {
    id: 222,
    name: skillNames[222],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[304],
  },
  {
    id: 223,
    name: skillNames[223],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[308],
  },
  {
    id: 224,
    name: skillNames[224],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[310],
  },
  {
    id: 225,
    name: skillNames[225],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[309],
  },
  {
    id: 226,
    name: skillNames[226],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[307],
  },
  {
    id: 227,
    name: skillNames[227],
    category: 'impact',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[309],
  },
  {
    id: 228,
    name: skillNames[228],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[315],
  },
  {
    id: 229,
    name: skillNames[229],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[315],
  },
  {
    id: 230,
    name: skillNames[230],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[313],
  },
  {
    id: 231,
    name: skillNames[231],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[314],
  },
  {
    id: 232,
    name: skillNames[232],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[313],
  },
  {
    id: 233,
    name: skillNames[233],
    category: 'impact',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[314],
  },
  {
    id: 234,
    name: skillNames[234],
    category: 'development',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[317],
  },
  {
    id: 235,
    name: skillNames[235],
    category: 'development',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[316],
  },
  {
    id: 236,
    name: skillNames[236],
    category: 'development',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[316],
  },
  {
    id: 237,
    name: skillNames[237],
    category: 'development',
    isAberrant: true,
    isProfession: false,
    leadsTo: skillNames[317],
  },
  {
    id: 238,
    name: skillNames[238],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[319],
  },
  {
    id: 239,
    name: skillNames[239],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[322],
  },
  {
    id: 240,
    name: skillNames[240],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[319],
    pairwiseTo: [ 'Black Market', 'Murder Inc' ],
  },
  {
    id: 241,
    name: skillNames[241],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[321],
  },
  {
    id: 242,
    name: skillNames[242],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[318],
  },
  {
    id: 243,
    name: skillNames[243],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[322],
  },
  {
    id: 244,
    name: skillNames[244],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[320],
  },
  {
    id: 245,
    name: skillNames[245],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[321],
  },
  {
    id: 246,
    name: skillNames[246],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[318],
  },
  {
    id: 298,
    name: skillNames[298],
    category: 'development',
    isAberrant: false,
    isPairwise: true,
    isProfession: false,
    leadsTo: null,
  },
  {
    id: 299,
    name: skillNames[299],
    category: 'development',
    isAberrant: false,
    isPairwise: true,
    isProfession: false,
    leadsTo: null,
  },
  {
    id: 301,
    name: skillNames[301],
    category: 'default',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 401, name: skillNames[401] },
      { id: 402, name: skillNames[402] },
    ],
  },
  {
    id: 302,
    name: skillNames[302],
    category: 'default',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 403, name: skillNames[403] },
      { id: 404, name: skillNames[404] },
    ],
  },
  {
    id: 303,
    name: skillNames[303],
    category: 'default',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 405, name: skillNames[405] },
      { id: 406, name: skillNames[406] },
    ],
  },
  {
    id: 304,
    name: skillNames[304],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 407, name: skillNames[407] },
      { id: 408, name: skillNames[408] },
    ],
  },
  {
    id: 305,
    name: skillNames[305],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 409, name: skillNames[409] },
      { id: 410, name: skillNames[410] },
    ],
  },
  {
    id: 306,
    name: skillNames[306],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 411, name: skillNames[411] },
      { id: 412, name: skillNames[412] },
    ],
  },
  {
    id: 307,
    name: skillNames[307],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 413, name: skillNames[413] },
      { id: 414, name: skillNames[414] },
    ],
  },
  {
    id: 308,
    name: skillNames[308],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 415, name: skillNames[415] },
      { id: 416, name: skillNames[416] },
    ],
  },
  {
    id: 309,
    name: skillNames[309],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 417, name: skillNames[417] },
      { id: 418, name: skillNames[418] },
    ],
  },
  {
    id: 310,
    name: skillNames[310],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 419, name: skillNames[419] },
      { id: 420, name: skillNames[420] },
    ],
  },
  {
    id: 311,
    name: skillNames[311],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 421, name: skillNames[421] },
      { id: 422, name: skillNames[422] },
    ],
  },
  {
    id: 312,
    name: skillNames[312],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 423, name: skillNames[423] },
      { id: 424, name: skillNames[424] },
    ],
  },
  {
    id: 313,
    name: skillNames[313],
    category: 'impact',
    isProfession: true,
    isAberrant: true,
    skills: [
      { id: 425, name: skillNames[425] },
      { id: 426, name: skillNames[426] },
    ],
  },
  {
    id: 314,
    name: skillNames[314],
    category: 'impact',
    isProfession: true,
    isAberrant: true,
    skills: [
      { id: 427, name: skillNames[427] },
      { id: 428, name: skillNames[428] },
    ],
  },
  {
    id: 315,
    name: skillNames[315],
    category: 'impact',
    isProfession: true,
    isAberrant: true,
    skills: [
      { id: 429, name: skillNames[429] },
      { id: 430, name: skillNames[430] },
    ],
  },
  {
    id: 316,
    name: skillNames[316],
    category: 'development',
    isProfession: true,
    isAberrant: true,
    skills: [
      { id: 431, name: skillNames[431] },
      { id: 432, name: skillNames[432] },
    ],
  },
  {
    id: 317,
    name: skillNames[317],
    category: 'development',
    isProfession: true,
    isAberrant: true,
    skills: [
      { id: 433, name: skillNames[433] },
      { id: 434, name: skillNames[434] },
    ],
  },
  {
    id: 318,
    name: skillNames[318],
    category: 'development',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 435, name: skillNames[435] },
      { id: 436, name: skillNames[436] },
    ],
  },
  {
    id: 319,
    name: skillNames[319],
    category: 'development',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 437, name: skillNames[437] },
      { id: 438, name: skillNames[438] },
    ],
  },
  {
    id: 320,
    name: skillNames[320],
    category: 'development',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 439, name: skillNames[439] },
      { id: 440, name: skillNames[440] },
    ],
  },
  {
    id: 321,
    name: skillNames[321],
    category: 'development',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 441, name: skillNames[441] },
      { id: 442, name: skillNames[442] },
    ],
  },
  {
    id: 322,
    name: skillNames[322],
    category: 'development',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 443, name: skillNames[443] },
      { id: 444, name: skillNames[444] },
    ],
  },
  {
    id: 323,
    name: skillNames[323],
    category: 'impact',
    isProfession: true,
    isAberrant: false,
    skills: [
      { id: 445, name: skillNames[445] },
      { id: 446, name: skillNames[446] },
    ],
  },
  {
    id: 247,
    name: skillNames[247],
    category: 'development',
    isAberrant: false,
    isProfession: false,
    leadsTo: skillNames[320],
  },
];

export default skillData;
