import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import Title from 'src/components/0100_title';
import useEventTickets from 'src/hooks/events/useEventTickets';
import useTicketFilter from '../../hooks/useTicketFilter';

const TicketSelect: FC = () => {
  const { tickets } = useEventTickets({ editMode: true });
  const { ticketIds, handleChange, handleRemoveFilter } = useTicketFilter();

  const isActive = useCallback(
    ( ticketId: number ) => ticketIds.includes( ticketId ),
    [ ticketIds ],
  );

  return (
    <div>
      <Title variant="heading">Tickets</Title>
      <div className="grid">
        <button
          type="button"
          className={clsx(
            'w-full text-left pb-1',
            ticketIds.length === 0 ? 'text-shadow' : 'opacity-50',
          )}
          onClick={handleRemoveFilter}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={ticketIds.length === 0 ? faCheckSquare : faSquare}
          />
          All Tickets
        </button>
        {tickets
          .filter( x => ( x.eventAttendeesCount ?? 0 ) > 0 )
          .sort(( a, b ) => a.id - b.id )
          .map( ticket => (
            <button
              key={ticket.id}
              className={clsx(
                'text-left flex overflow-hidden items-center justify-between gap-2 border-l-2 ml-2 pl-4 py-1',
                isActive( ticket.id )
                  ? 'border-juno-gray-200'
                  : 'border-juno-gray-700 opacity-50',
              )}
              type="button"
              onClick={() => handleChange( ticket.id )}
            >
              <div
                className={clsx(
                  'flex w-full items-center gap-2 whitespace-nowrap overflow-hidden',
                  isActive( ticket.id ) && 'text-shadow',
                )}
              >
                <FontAwesomeIcon
                  icon={isActive( ticket.id ) ? faCheckSquare : faSquare}
                />
                <div>{ticket.label}</div>
              </div>
              <div className="text-right w-8">{ticket.eventAttendeesCount}</div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default TicketSelect;
