import { FC, useCallback, useMemo, useState } from 'react';
import Loading from 'src/components/0100_loading';
import { IAssistantFlagEnum, IOrganizationRoleEnum } from 'src/graphql/types';
import useBranchStaffs from 'src/hooks/organizations/branches/useBranchStaffs';

import { useMutation } from 'urql';
import {
  IUpdatePlayerDataMutation,
  IUpdatePlayerDataMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import { updatePlayerData } from 'src/graphql/mutations/players.graphql';
import PlayerSearch from 'src/components/0400_player_search';
import clsx from 'clsx';
import {
  IAssignBranchOwnershipMutation,
  IAssignBranchOwnershipMutationVariables,
} from 'src/graphql/mutations/branches.graphql.types';
import { assignBranchOwnership } from 'src/graphql/mutations/branches.graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import useActivePlayers from 'src/hooks/organizations/branches/useActivePlayers';
import usePermission from 'src/hooks/permissions/usePermissions';
import PunchTable from './PunchTable';

import './index.css';

const Attendance: FC = () => {
  const { data, fetching, refetch } = useBranchStaffs();
  const { data: activePlayersData, fetching: fetchingActivePlayers } =
    useActivePlayers();
  const { isPermitted } = usePermission({
    action: 'manage_branch',
    branchId: data?.branch?.id,
  });
  const [ stickyHeaders, setStickyHeaders ] = useState( true );
  const [ deletionLock, setDeletionLock ] = useState( true );
  const [ deletingPlayerId, setDeletingPlayerId ] = useState<number | null>( null );
  const [ sortPlayersBy, setSortPlayersBy ] = useState<
    'id' | 'name' | 'branch' | 'activity'
  >( 'id' );
  const [ , update ] = useMutation<
    IUpdatePlayerDataMutation,
    IUpdatePlayerDataMutationVariables
  >( updatePlayerData );
  const handleUpdate = useCallback(
    async ({
      playerId,
      assistantFlags,
      role,
    }: {
      playerId: number;
      assistantFlags?: IAssistantFlagEnum[];
      role?: IOrganizationRoleEnum;
    }) => {
      await update({
        playerId,
        assistantFlags,
        role,
      });
    },
    [ update ],
  );
  const [ assignRoleResult, assignRole ] = useMutation<
    IUpdatePlayerDataMutation,
    IUpdatePlayerDataMutationVariables
  >( updatePlayerData );
  const [ assignOwnershipResult, assignOwnership ] = useMutation<
    IAssignBranchOwnershipMutation,
    IAssignBranchOwnershipMutationVariables
  >( assignBranchOwnership );

  const handleAddAssistant = useCallback(
    ({ playerId, role }: { playerId: number; role: IOrganizationRoleEnum }) => {
      assignRole({
        playerId,
        role,
      }).then(() => refetch());
    },
    [ assignRole, refetch ],
  );

  const handleAddOwner = useCallback(
    ({ playerId }: { playerId: number }) => {
      assignOwnership({ playerId, branchId: Number( data?.branch?.id ) });
    },
    [ assignOwnership, data?.branch?.id ],
  );

  const handleDelete = useCallback(
    async ({ playerId }: { playerId: number }) => {
      setDeletingPlayerId( playerId );
      await handleUpdate({ playerId, role: IOrganizationRoleEnum.Regular });
      await refetch();
      setTimeout(() => setDeletingPlayerId( null ), 1000 );
    },
    [ handleUpdate, refetch ],
  );

  const branchStaffIds = useMemo(() => {
    if ( !data?.branch ) return [];

    return data.branch.branchAssistants
      .map( x => x.user.id )
      .concat( data.branch.branchOwners.map( x => x.user.id ));
  }, [ data?.branch ]);

  if ( !isPermitted ) return null;

  return (
    <div>
      <div className="px-2 pb-8">
        <table className="branch-staffs">
          <thead>
            <tr>
              <th className="min-w-[224px] max-w-[360px]" colSpan={2}>
                Player
              </th>
              <th className="min-w-[512px]">Attendances</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-juno-purple-200">
              <td colSpan={2}>Employees</td>
              <td className={clsx( 'text-right opacity-90' )}>
                <FontAwesomeIcon
                  className="cursor-pointer fa-fw"
                  icon={deletionLock ? faLock : faUnlock}
                  onClick={() => setDeletionLock( x => !x )}
                />
              </td>
            </tr>
            <PunchTable
              className="employee"
              isFetching={fetching}
              branchId={data?.branch?.id ?? 0}
              sticky={stickyHeaders}
              deletionLock={deletionLock}
              deletingPlayerId={deletingPlayerId}
              emptyMessage={`No Employees Assigned to ${data?.branch?.name}`}
              players={( data?.branch?.branchOwners ?? [])
                .sort(( a, b ) => a.user.id - b.user.id )
                .map( x => ({
                  id: x.user.id,
                  name: x.user.fullName,
                  eventAttendances: x.user.recentEventAttendances.map( ea => ({
                    id: ea.id,
                    event: {
                      id: ea.event.id,
                      startsAt: ea.event.startsAt,
                      name: ea.event.name,
                      branchId: ea.event.branch.id,
                    },
                  })),
                }))}
              onDelete={({ playerId }) => handleDelete({ playerId })}
            />
            <tr>
              <td colSpan={2} className="employee">
                <PlayerSearch
                  playersOnly
                  placeholder="Add Employee"
                  accessLevelFilters={[
                    IOrganizationRoleEnum.Regular,
                    IOrganizationRoleEnum.Assistant,
                  ]}
                  onBlur={() => setStickyHeaders( true )}
                  onFocus={() => setStickyHeaders( false )}
                  onSearchResultClick={x =>
                    handleAddOwner({
                      playerId: Number( x.playerId ),
                    })
                  }
                />
              </td>
              <td>
                {assignOwnershipResult.fetching && <Loading size="small" />}
              </td>
            </tr>
            <tr className="bg-juno-orange-200">
              <td colSpan={2}>Assistant</td>
              <td className={clsx( 'text-right opacity-90' )}>
                <FontAwesomeIcon
                  className="cursor-pointer fa-fw"
                  icon={deletionLock ? faLock : faUnlock}
                  onClick={() => setDeletionLock( x => !x )}
                />
              </td>
            </tr>
            <PunchTable
              className="assistant"
              branchId={data?.branch?.id ?? 0}
              isFetching={fetching}
              sticky={stickyHeaders}
              deletionLock={deletionLock}
              deletingPlayerId={deletingPlayerId}
              emptyMessage={`No Assistants Assigned to ${data?.branch?.name}`}
              players={( data?.branch?.branchAssistants ?? [])
                .sort(( a, b ) => a.user.id - b.user.id )
                .map( x => ({
                  id: x.user.id,
                  name: x.user.fullName,
                  flags: x.assistantFlags,
                  eventAttendances: x.user.recentEventAttendances.map( ea => ({
                    id: ea.id,
                    event: {
                      id: ea.event.id,
                      startsAt: ea.event.startsAt,
                      name: ea.event.name,
                      branchId: ea.event.branch.id,
                    },
                  })),
                }))}
              onUpdate={({ playerId, flags }) =>
                handleUpdate({ playerId, assistantFlags: flags })
              }
              onDelete={({ playerId }) => handleDelete({ playerId })}
            />
            <tr>
              <td colSpan={2} className="assistant">
                <PlayerSearch
                  playersOnly
                  placeholder="Add Assistant"
                  branchId={data?.branch?.id}
                  onBlur={() => setStickyHeaders( true )}
                  onFocus={() => setStickyHeaders( false )}
                  onSearchResultClick={x => {
                    if ( x.playerId ) {
                      handleAddAssistant({
                        playerId: x.playerId,
                        role: IOrganizationRoleEnum.Assistant,
                      });
                    }
                  }}
                />
              </td>
              <td>{assignRoleResult.fetching && <Loading size="small" />}</td>
            </tr>
            <tr className="bg-juno-cyan-200">
              <td colSpan={2}>Active Players</td>
              <td>
                Sort By:{' '}
                <button
                  type="button"
                  className={clsx(
                    'px-1 transition-all duration-300',
                    sortPlayersBy === 'id'
                      ? 'pointer-events-none cursor-default'
                      : 'underline cursor-pointer opacity-50',
                  )}
                  onClick={() => setSortPlayersBy( 'id' )}
                >
                  ID
                </button>
                <button
                  type="button"
                  className={clsx(
                    'px-1 transition-all duration-300',
                    sortPlayersBy === 'name'
                      ? 'pointer-events-none cursor-default'
                      : 'underline cursor-pointer opacity-50',
                  )}
                  onClick={() => setSortPlayersBy( 'name' )}
                >
                  Name
                </button>
                <button
                  type="button"
                  className={clsx(
                    'px-1 transition-all duration-300',
                    sortPlayersBy === 'branch'
                      ? 'pointer-events-none cursor-default'
                      : 'underline cursor-pointer opacity-50',
                  )}
                  onClick={() => setSortPlayersBy( 'branch' )}
                >
                  Branch
                </button>
                <button
                  type="button"
                  className={clsx(
                    'px-1 transition-all duration-300',
                    sortPlayersBy === 'activity'
                      ? 'pointer-events-none cursor-default'
                      : 'underline cursor-pointer opacity-50',
                  )}
                  onClick={() => setSortPlayersBy( 'activity' )}
                >
                  Activity
                </button>
              </td>
            </tr>
            <PunchTable
              className="active-player"
              deletionLock
              branchId={data?.branch?.id ?? 0}
              isFetching={fetchingActivePlayers}
              sticky={stickyHeaders}
              emptyMessage="No Players active within the past 6 months"
              players={( activePlayersData?.branch?.activePlayers ?? [])
                .filter( x => !branchStaffIds.includes( x.user.id ))
                .sort(( a, b ) => {
                  if ( sortPlayersBy === 'id' ) return a.user.id - b.user.id;
                  if ( sortPlayersBy === 'name' )
                    return a.user.fullName.localeCompare( b.user.fullName );
                  if ( sortPlayersBy === 'activity' ) {
                    const aLength = a.user.recentEventAttendances.length;
                    const bLength = b.user.recentEventAttendances.length;

                    if ( aLength === bLength ) return a.user.id - b.user.id;
                    return bLength - aLength;
                  }

                  if (
                    a.branch.id === data?.branch?.id &&
                    b.branch.id !== data?.branch?.id
                  )
                    return -1;
                  if (
                    a.branch.id !== data?.branch?.id &&
                    b.branch.id === data?.branch?.id
                  )
                    return 1;
                  if (
                    a.branch.id === data?.branch?.id &&
                    b.branch.id === data?.branch?.id
                  ) {
                    if ( a.role === b.role ) {
                      return a.user.id - b.user.id;
                    }

                    return a.role.localeCompare( b.role );
                  }

                  return a.branch.shorthand.localeCompare( b.branch.shorthand );
                })
                .map( x => ({
                  id: x.user.id,
                  name: x.user.fullName,
                  originBranch:
                    x.branch.id === data?.branch?.id
                      ? null
                      : x.branch.shorthand,
                  role: x.role,
                  eventAttendances: x.user.recentEventAttendances.map( ea => ({
                    id: ea.id,
                    event: {
                      id: ea.event.id,
                      startsAt: ea.event.startsAt,
                      name: ea.event.name,
                      branchId: ea.event.branch.id,
                    },
                  })),
                }))}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Attendance;
