import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  isExpanded: boolean;
  title: string;
  onClick: () => void;
}

const CollapsibleSection: FC<PropsWithChildren<IProps>> = ({
  isExpanded,
  title,
  children,
  onClick,
}) => (
  <div>
    <button
      type="button"
      className="flex justify-between items-center w-full gap-4 pr-2 text-juno-gray-200 transition-all duration-300 py-2"
      onClick={onClick}
    >
      <div className="border-t border-juno-gray-700 w-full" />
      <div className="text-sm whitespace-nowrap">{title}</div>
      <FontAwesomeIcon
        icon={faChevronDown}
        className={clsx(
          'transition-all duration-300',
          isExpanded && '-scale-y-100',
        )}
      />
    </button>
    <div
      className={clsx(
        'grid gap-2 transition-all duration-300',
        isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
      )}
    >
      <div className="overflow-hidden grid">{children}</div>
    </div>
  </div>
);

export default CollapsibleSection;
