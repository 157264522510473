import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/auths/useAuth';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import OrganizationalTitle from './OrganizationalTitle';

const SidebarTitle: FC = () => {
  const { isRootUserValidated } = useAuth();
  const { isEventPath } = useAppNavigations();
  const { organizationSlug } = useParams();

  return (
    <div className="px-2 h-12 gray-box">
      {isRootUserValidated && ( organizationSlug || isEventPath ) ? (
        <OrganizationalTitle />
      ) : (
        <Link to="/" className="flex h-full items-center">
          Project Juno
        </Link>
      )}
    </div>
  );
};

export default SidebarTitle;
