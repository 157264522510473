import {
  faCircleNodes,
  faEarthAmericas,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Title from 'src/components/0100_title';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useAuth from 'src/hooks/auths/useAuth';

const GuestWelcome: FC = () => {
  const { openModal, resetPasswordToken } = useAuth();
  const { publicLink } = useAppNavigations();

  useEffect(() => {
    if ( resetPasswordToken ) {
      openModal({ modal: 'resetPassword' });
    }
  }, [ openModal, resetPasswordToken ]);

  return (
    <div className="grid gap-8 py-6">
      <Title title="Project Juno" />
      <div>
        Welcome to Advanced LARP Management System. Focus on your world building
        and leave the technical complexities to us:
        <ul className="list-disc pl-6">
          <li>
            Character Builder. Try our supported games below. No sign-ups
            needed.
          </li>
          <li>
            Event Management. Check out our{' '}
            <Link to="/events" className="underline">
              Calendar
            </Link>
            .
          </li>
          <li>Ticketing and Accounting System.</li>
          <li>In-Game Inventory Management.</li>
        </ul>
      </div>
      <button
        type="button"
        className="flex justify-center text-left hover:brightness-125 transition-all duration-300"
        onClick={() => openModal({ modal: 'login' })}
      >
        <div className="gray-box w-full max-w-[640px] flex justify-between items-center gap-2 border border-juno-gray-700 rounded p-4">
          <div>
            <div className="text-lg lg:text-2xl">Already have an account?</div>
            <div className="text-sm opacity-75">
              If you have previously attended Dystopia Rising games, your
              account will work as is.
            </div>
          </div>
          <div className="px-4 py-1 whitespace-nowrap bg-juno-cyan-200 rounded-xl">
            Sign In
          </div>
        </div>
      </button>
      {/* <Title title="Character Builder" variant="heading" /> */}
      <Title title="Our Featured LARP" />
      <Title title="Dystopia Rising" variant="heading" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Link
          to={publicLink({
            organizationSlug: 'dystopia-rising',
            section: 'characters',
          })}
          className="border border-juno-gray-700 rounded p-4 text-xl gray-box flex justify-between hover:brightness-150 hover:text-shadow transition-all duration-300 overflow-hidden"
        >
          Character Builder
          <div className="relative">
            <FontAwesomeIcon
              icon={faCircleNodes}
              className="absolute right-4 top-0 scale-[2.2] rotate-30 text-3xl overflow-hidden opacity-25"
            />
          </div>
        </Link>
        <Link
          to={publicLink({
            organizationSlug: 'dystopia-rising',
            section: 'branches',
          })}
          className="border border-juno-gray-700 rounded p-4 text-xl gray-box flex justify-between hover:brightness-150 hover:text-shadow transition-all duration-300 overflow-hidden"
        >
          Find A Branch Near You
          <div className="relative">
            <FontAwesomeIcon
              icon={faEarthAmericas}
              className="absolute right-4 top-0 scale-[2.2] text-3xl overflow-hidden opacity-25"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default GuestWelcome;
