import { FC, forwardRef } from 'react';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {}

export const faiths = [
  { value: 0, label: 'Faithless' },
  { value: 1, label: 'The Telling Visionaries' },
  { value: 2, label: 'Sainthood of Ashes' },
  { value: 3, label: 'The Nuclear Family' },
  { value: 4, label: 'Church of Darwin' },
  { value: 5, label: 'Cult of The Fallow Hopes' },
  { value: 6, label: 'Final Knights' },
  { value: 7, label: 'Kings and Queens' },
  { value: 8, label: 'Tribes of The Seasons' },
  { value: 9, label: 'Cults' },
  { value: 10, label: 'Light of The Hedon' },
];

const FaithSelect: FC<IProps> = forwardRef(({ ...props }, ref ) => (
  <BaseSelect withSearch options={faiths} {...props} />
));

export default FaithSelect;
