import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ResponseBox from 'src/components/0100_response_box';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import useRootUser from 'src/hooks/players/useRootUser';

const MembershipReminder: FC = () => {
  const { linkToPlayer } = useAppNavigations();
  const { rootUserId } = useRootUser();
  const { event, player } = useEventAttendee();
  const [ searchParams ] = useSearchParams();
  const isSelfCheckin =
    searchParams.get( 'player_id' ) &&
    Number( searchParams.get( 'player_id' )) === rootUserId;

  if ( !event?.activeMembership ) return null;

  return (
    <ResponseBox type="info" withIcon={faLightbulb}>
      <div>
        Active Membership: All Active Characters are eligible for check-in.
      </div>
      {isSelfCheckin && (
        <div>
          Missing Characters?{' '}
          {player?.id && (
            <Link
              className="underline"
              to={linkToPlayer({
                playerId: player.id,
                section: 'characters',
              })}
            >
              {' '}
              Manage your Characters.
            </Link>
          )}
        </div>
      )}
    </ResponseBox>
  );
};

export default MembershipReminder;
