import { searchItems } from 'src/graphql/queries/items.graphql';
import {
  ISearchItemsQuery,
  ISearchItemsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';

const useSearchItems = ({
  query,
  blueprint,
}: {
  query: string;
  blueprint: boolean;
}) => {
  const [{ data, error, fetching, stale }] = useQuery<
    ISearchItemsQuery,
    ISearchItemsQueryVariables
  >({
    query: searchItems,
    variables: {
      query,
      blueprint,
    },
    requestPolicy: 'cache-and-network',
    pause: query.trim().length < 3,
  });

  return {
    data,
    error,
    fetching,
    stale,
    items: data?.organization.searchItems ?? [],
  };
};

export default useSearchItems;
