import { FC } from 'react';
import Slider from 'rc-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import skillData, { TProfession } from '../data';
import useCharacterBuilder from '../../hooks/useCharacterBuilder';
import Node from '../Node';
import useSkillFinder from '../../hooks/useSkillFinder';

interface IProps {
  canEdit: boolean;
  skills: typeof skillData;
  onProfessionMouseOver: ( x: string ) => void;
  onNodeMouseOut: () => void;
}

const WeaponSkillGroup: FC<IProps> = ({
  canEdit,
  skills: professions,
  onProfessionMouseOver,
  onNodeMouseOut,
}) => {
  const { maybeFilterSkill } = useSkillFinder();
  const {
    innateStats: { body: innateBody, mind: innateMind },
    maxPurchaseableStats: { body: maxBody, mind: maxMind },
    setStat,
    canDecrementSkillOrStat,
    canIncrementStat,
    watch,
  } = useCharacterBuilder({ canEdit });
  const {
    stats: { body, mind },
  } = watch();

  return (
    <div className="grid grid-cols-12 justify-center gap-2 items-center">
      <div className="col-span-5 sm:col-span-6 grid grid-cols-1 gap-4 max-w-[256px] sm:max-w-[256px] w-[40vw]">
        <div id="body" className="midtone-box rounded p-2 px-4 ">
          <div className="flex justify-between items-end flex-wrap sm:flex-nowrap">
            <div>Body</div>
            <div className="flex items-end gap-1 justify-end w-full">
              <div>{innateBody}</div>
              <div>+</div>
              <div className="font-semibold text-2xl -mb-0.5">{body}</div>
              <div>=</div>
              <div>{innateBody + body}</div>
            </div>
          </div>
          <div className="flex items-center gap-4 pt-2">
            <button
              type="button"
              disabled={!canDecrementSkillOrStat( 'Body' )}
              className={clsx(
                'transition-all',
                canDecrementSkillOrStat( 'Body' )
                  ? 'hover:scale-150'
                  : 'opacity-25',
              )}
              onClick={() => setStat({ attribute: 'body', value: body - 1 })}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <Slider
              min={0}
              max={maxBody}
              value={body}
              disabled={!canEdit}
              onChange={x => setStat({ attribute: 'body', value: Number( x ) })}
            />
            <button
              type="button"
              disabled={!canIncrementStat( 'Body' )}
              className={clsx(
                'transition-all',
                canIncrementStat( 'Body' ) ? 'hover:scale-150' : 'opacity-25',
              )}
              onClick={() => setStat({ attribute: 'body', value: body + 1 })}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <div id="mind" className="midtone-box rounded p-2 px-4">
          <div className="flex justify-between items-end flex-wrap sm:flex-nowrap">
            <div>Mind</div>
            <div className="flex items-end gap-1 justify-end w-full">
              <div>{innateMind}</div>
              <div>+</div>
              <div className="font-semibold text-2xl -mb-0.5">{mind}</div>
              <div>=</div>
              <div>{innateMind + mind}</div>
            </div>
          </div>
          <div className="flex items-center gap-4 pt-2">
            <button
              type="button"
              disabled={!canDecrementSkillOrStat( 'Mind' )}
              className={clsx(
                'transition-all',
                canDecrementSkillOrStat( 'Mind' )
                  ? 'hover:scale-150'
                  : 'opacity-25',
              )}
              onClick={() => setStat({ attribute: 'mind', value: mind - 1 })}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <Slider
              min={0}
              max={maxMind}
              value={mind}
              disabled={!canEdit}
              onChange={x => setStat({ attribute: 'mind', value: Number( x ) })}
            />
            <button
              type="button"
              disabled={!canIncrementStat( 'Mind' )}
              className={clsx(
                'transition-all',
                canIncrementStat( 'Mind' ) ? 'hover:scale-150' : 'opacity-25',
              )}
              onClick={() => setStat({ attribute: 'mind', value: mind + 1 })}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-start-7 col-span-6 sm:col-start-7 sm:col-span-6 grid grid-cols-1 gap-2 max-w-[256px] justify-self-end w-full">
        {( professions as TProfession[])
          .filter( x => maybeFilterSkill( x.name ))
          .map( profession => (
            <div
              key={profession.name}
              className="grid grid-cols gap-2"
              onBlur={() => null}
              onFocus={() => null}
              onMouseOver={() => onProfessionMouseOver( profession.name )}
              onMouseOut={onNodeMouseOut}
            >
              <Node {...profession} canEdit={canEdit} />

              {profession.skills
                .filter( x => maybeFilterSkill( x.name ))
                .map( professionSkill => (
                  <div key={professionSkill.name} className="pl-8">
                    <Node
                      isProfessionSkill
                      canEdit={canEdit}
                      category="default"
                      isAberrant={profession.isAberrant}
                      {...professionSkill}
                    />
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default WeaponSkillGroup;
