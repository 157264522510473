import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import Bio from 'src/routes/Organization/Player/Bio';
import CorrectiveActions from 'src/routes/Organization/Player/CorrectiveActions';
import Perks from 'src/routes/Organization/Player/Perks';
import Memberships from 'src/routes/Organization/Player/Memberships';
import Referrals from 'src/routes/Organization/Player/Referrals';
import Build from './Build';
import Notes from './Notes';
import Printout from './Printout';
import XpAudit from './XpAudit';

const Checkout: FC = () => {
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );
  const section = searchParams.get( 'section' );

  if ( playerId && characterId ) {
    return (
      <>
        {( !section || section === 'build' ) && <Build />}
        {section === 'notes' && <Notes />}
        {section === 'printout' && <Printout />}
        {section === 'xp_audit' && <XpAudit />}
      </>
    );
  }

  if ( playerId ) {
    return (
      <>
        {( !section || section === 'bio' ) && <Bio />}
        {section === 'memberships' && <Memberships />}
        {section === 'corrective_actions' && <CorrectiveActions />}
        {section === 'perks' && <Perks />}
        {section === 'referrals' && <Referrals />}
      </>
    );
  }

  return (
    <div className="text-center">
      Please Select a Character from the Sidebar
    </div>
  );
};

export default Checkout;
