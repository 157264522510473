import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import useItems from 'src/hooks/items/useItems';
import { IItemKindEnum } from 'src/graphql/types';
import { groupBy, startCase } from 'lodash';
import Button from 'src/components/0100_button';
import NewItem from './NewItem';
import ItemList from './ItemList';
import ItemView from '../ItemView';

const filters: IItemKindEnum[] = [
  IItemKindEnum.Blueprint,
  IItemKindEnum.Taxonomy,
];

export type TLoupeViewProps = {
  components?: {
    withTitle: boolean;
    withItemCreation: boolean;
  };
};

interface IProps extends TLoupeViewProps {}

const LoupeView: FC<IProps> = ({ components = {}}) => {
  const { itemId } = useParams();
  const [ activeFilter, setActiveFilter ] = useState<IItemKindEnum | null>(
    IItemKindEnum.Blueprint,
  );

  const { stale, items, refetch } = useItems();
  const itemKindGroups = groupBy( items, 'kind' );
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const queryFilteredItems =
    query.trim().length === 0
      ? items
      : items.filter(
          x =>
            x.name.toLowerCase().includes( query.trim().toLowerCase()) ||
            x.kind.toLowerCase().includes( query.trim().toLowerCase()),
        );
  const filteredItems = queryFilteredItems.filter( x =>
    activeFilter ? x.kind === activeFilter : !filters.includes( x.kind ),
  );
  const matchingItem = useMemo(
    () => items.find( x => x.id === Number( itemId )),
    [ itemId, items ],
  );
  const itemExists = !!matchingItem;

  useEffect(() => {
    if ( !stale && itemExists ) {
      setTimeout(() => {
        const element = document.getElementById( `item-${itemId}` );
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 250 );
    }
  }, [ stale, itemExists, itemId ]);

  useEffect(() => {
    if ( matchingItem?.kind ) {
      if ( filters.includes( matchingItem.kind )) {
        setActiveFilter( matchingItem.kind );
      } else {
        setActiveFilter( null );
      }
    }
  }, [ matchingItem?.kind ]);

  return (
    <div>
      {components.withTitle && <Title title="Depository" />}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-start">
        <div className="grid">
          {components.withItemCreation && (
            <div className="z-30">
              <NewItem onCreate={refetch} />
            </div>
          )}
          <div className="w-full sticky top-0 gray-box-angled z-20">
            <div className="flex justify-between items-center gap-2">
              <Input
                fullWidth
                placeholder="Search Items..."
                {...register( 'query' )}
              />
            </div>
            <div className="flex flex-wrap items-center gap-2 pt-2">
              {filters.map( kind => (
                <Button
                  key={kind}
                  defaultLabel={kind}
                  state={
                    activeFilter === kind ? 'enabled-highlight' : 'enabled'
                  }
                  onClick={() =>
                    setActiveFilter( prev => ( prev === kind ? null : kind ))
                  }
                >
                  <div className="flex gap-1 items-center">
                    <div>{startCase( kind )}</div>
                    <div className="opacity-75">|</div>
                    <div>{itemKindGroups[kind]?.length ?? 0}</div>
                  </div>
                </Button>
              ))}
              <Button
                defaultLabel="All"
                state={activeFilter ? 'enabled' : 'enabled-highlight'}
                onClick={() => setActiveFilter( null )}
              >
                <div className="flex gap-1 items-center">
                  <div>Others</div>
                  <div className="opacity-75">|</div>
                  <div>
                    {items.length -
                      items.filter( x => filters.includes( x.kind )).length}
                  </div>
                </div>
              </Button>
            </div>
          </div>
          {stale && items.length === 0 && (
            <div>
              <Loading size="small" />
            </div>
          )}
          <ItemList items={filteredItems} />
        </div>
        <div className="hidden lg:block sticky top-0">
          {itemId && <ItemView />}
        </div>
      </div>
    </div>
  );
};

export default LoupeView;
