import { FC, useCallback, useEffect } from 'react';
import { IItemKindEnum } from 'src/graphql/types';
import { startCase } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import {
  IUpdateItemCraftingFinalProductMutation,
  IUpdateItemCraftingFinalProductMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { updateItemCraftingFinalProduct } from 'src/graphql/mutations/items.graphql';
import clsx from 'clsx';
import { IPropsWithOnUpdate } from '../../types';
import SingleItemEditor from '../SingleItemEditor';

export type TOutput = {
  id: number;
  name: string;
  kind?: IItemKindEnum;
  lifetimeAmount?: number;
  uses?: string;
  metadata: {
    mechanics?: string | null;
    requirementsToUse?: string | null;
    uses?: string | null;
  };
};

export type TItemCraftingOutput = {
  id: number;
  craftingFinalProducts?: {
    id: number;
    stack?: number;
    finalProduct: TOutput & {
      childItemClassifications: {
        id: number;
        childItem: TOutput;
      }[];
    };
  }[];
};

interface IProps extends IPropsWithOnUpdate {
  itemCraftings: TItemCraftingOutput[];
}

const columns: ( 'kind' | 'name' | 'lifetimeAmount' | 'uses' )[] = [
  'kind',
  'name',
  'lifetimeAmount',
  'uses',
];
const ItemCraftingFinalProducts: FC<IProps> = ({ itemCraftings, onUpdate }) => {
  const craftingFinalProducts = itemCraftings
    ? itemCraftings[0]?.craftingFinalProducts
    : null;
  const finalProduct = craftingFinalProducts
    ? craftingFinalProducts[0]?.finalProduct
    : null;
  const actualProducts = finalProduct
    ? finalProduct.childItemClassifications.length > 0
      ? finalProduct.childItemClassifications.map( x => x.childItem )
      : [ finalProduct ]
    : null;
  const sortedProducts = actualProducts
    ? actualProducts.sort(( a, b ) => a.name.localeCompare( b.name ))
    : null;

  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      stack: ( craftingFinalProducts
        ? ( craftingFinalProducts[0]?.stack ?? null )
        : null ) as number | null,
    },
  });
  const { stack } = watch();

  const [ , update ] = useMutation<
    IUpdateItemCraftingFinalProductMutation,
    IUpdateItemCraftingFinalProductMutationVariables
  >( updateItemCraftingFinalProduct );

  const handleUpdate = useCallback(() => {
    if ( !isDirty ) return;
    if ( !craftingFinalProducts ) return;

    onUpdate({ status: 'busy' });
    update({
      itemCraftingFinalProductId: Number( craftingFinalProducts[0].id ),
      stack: Number( stack ),
    }).then( res => {
      if ( res.data?.updateItemCraftingFinalProduct?.craftingFinalProduct ) {
        onUpdate({ status: 'success' });
      }

      if ( res.data?.updateItemCraftingFinalProduct?.error ) {
        onUpdate({ status: 'error' });
      }
    });
  }, [ craftingFinalProducts, isDirty, onUpdate, stack, update ]);

  useEffect(() => {
    reset({
      stack: craftingFinalProducts ? craftingFinalProducts[0]?.stack : null,
    });
  }, [ craftingFinalProducts, reset ]);

  if ( !actualProducts ) return <td colSpan={columns.length + 1} />;

  return (
    <>
      <td>
        <input
          className="text-right border-0 bg-transparent border-b last:border-b-0 border-b-juno-gray-700 w-full p-1"
          type="text"
          {...register( 'stack', { onBlur: handleUpdate })}
        />
      </td>
      {columns.map( column => (
        <td key={column} className={clsx( column === 'kind' && 'midtone-box' )}>
          {sortedProducts?.map( product => (
            <SingleItemEditor
              key={product.id}
              itemId={product.id}
              field={column}
              isNumeric={column === 'lifetimeAmount'}
              isEditable={column !== 'kind'}
              initialValue={
                column === 'kind'
                  ? startCase( product[column])
                  : [ 'mechanics', 'requirementsToUse', 'uses' ].includes( column )
                    ? product.metadata[
                        column as 'mechanics' | 'requirementsToUse' | 'uses'
                      ]
                    : product[column]
              }
              onUpdate={onUpdate}
            />
          ))}
        </td>
      ))}
    </>
  );
};

export default ItemCraftingFinalProducts;
