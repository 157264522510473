import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';

const EventHeader: FC = () => {
  const { eventId } = useParams();
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );
  const checkoutSection = searchParams.get( 'section' );
  const {
    linkToEvent,
    linkToEventCharacterCheckout,
    linkToEventPlayerCheckout,
    eventSection,
  } = useAppNavigations();
  const { isPermitted: canAssistEvent } = usePermission({
    action: 'assists_event',
    eventId: Number( eventId ),
  });
  const { isPermitted: canAssistLogistics } = usePermission({
    action: 'assists_logistics',
    eventId: Number( eventId ),
  });
  const { isPermitted: canAssistRegistration } = usePermission({
    action: 'checkin_character',
    eventId: Number( eventId ),
  });
  const { isPermitted: canManageEvent } = usePermission({
    action: 'manage_event',
    eventId: Number( eventId ),
  });
  // const { isPermitted: canAssistMod } = usePermission({
  //   action: 'assists_mod',
  //   eventId: Number( eventId ),
  // });

  if ( !canAssistEvent ) return null;

  return (
    <ResponsiveTabGroup label={startCase( eventSection || 'basics' )}>
      {canManageEvent && (
        <Tab
          label="Basics"
          to={linkToEvent({ section: 'basics' })}
          highlightMode="responsive"
          isActive={( eventSection || 'basics' ) === 'basics'}
        />
      )}
      {canAssistRegistration && (
        <Tab
          label="Tickets"
          to={linkToEvent({ section: 'tickets' })}
          highlightMode="responsive"
          isActive={eventSection === 'tickets'}
        />
      )}
      {canAssistEvent && (
        <Tab
          label="Shifts"
          to={linkToEvent({ section: 'shifts' })}
          highlightMode="responsive"
          isActive={eventSection === 'shifts'}
        />
      )}
      {canAssistRegistration && (
        <Tab
          label="Checkin"
          to={linkToEvent({ section: 'checkin' })}
          highlightMode="responsive"
          isActive={eventSection === 'checkin'}
        />
      )}
      {canManageEvent && (
        <Tab
          label="Printouts"
          to={linkToEvent({ section: 'printouts' })}
          highlightMode="responsive"
          isActive={eventSection === 'printouts'}
        />
      )}
      {canAssistLogistics && (
        <Tab
          label="Depository"
          to={linkToEvent({ section: 'depository' })}
          highlightMode="responsive"
          isActive={eventSection === 'depository'}
        />
      )}
      {canManageEvent && (
        <>
          <Tab
            label={
              eventSection === 'checkout' && playerId ? (
                <div className="flex items-center gap-2">
                  <div>Checkout</div>
                  <FontAwesomeIcon icon={faCaretRight} />
                </div>
              ) : (
                'Checkout'
              )
            }
            to={linkToEvent({ section: 'checkout' })}
            highlightMode="responsive"
            isActive={eventSection === 'checkout'}
          />
          {eventSection === 'checkout' && playerId && characterId && (
            <>
              <Tab
                label="Build"
                to={linkToEventCharacterCheckout({
                  playerId: Number( playerId ),
                  characterId: Number( characterId ),
                })}
                highlightMode="responsive"
                isActive={!checkoutSection || checkoutSection === 'build'}
              />
              <Tab
                label="Notes"
                to={linkToEventCharacterCheckout({
                  playerId: Number( playerId ),
                  characterId: Number( characterId ),
                  section: 'notes',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'notes'}
              />
              <Tab
                label="XP Audit"
                to={linkToEventCharacterCheckout({
                  playerId: Number( playerId ),
                  characterId: Number( characterId ),
                  section: 'xp_audit',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'xp_audit'}
              />
              <Tab
                label="Printout"
                to={linkToEventCharacterCheckout({
                  playerId: Number( playerId ),
                  characterId: Number( characterId ),
                  section: 'printout',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'printout'}
              />
            </>
          )}
          {eventSection === 'checkout' && playerId && !characterId && (
            <>
              <Tab
                label="Bio"
                to={linkToEventPlayerCheckout({
                  playerId: Number( playerId ),
                })}
                highlightMode="responsive"
                isActive={!checkoutSection || checkoutSection === 'bio'}
              />
              <Tab
                label="Memberships"
                to={linkToEventPlayerCheckout({
                  playerId: Number( playerId ),
                  section: 'memberships',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'memberships'}
              />
              <Tab
                label="Perks"
                to={linkToEventPlayerCheckout({
                  playerId: Number( playerId ),
                  section: 'perks',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'perks'}
              />
              <Tab
                label="Corrective Actions"
                to={linkToEventPlayerCheckout({
                  playerId: Number( playerId ),
                  section: 'corrective_actions',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'corrective_actions'}
              />
              <Tab
                label="Referrals"
                to={linkToEventPlayerCheckout({
                  playerId: Number( playerId ),
                  section: 'referrals',
                })}
                highlightMode="responsive"
                isActive={checkoutSection === 'referrals'}
              />
            </>
          )}
        </>
      )}
    </ResponsiveTabGroup>
  );
};

export default EventHeader;
