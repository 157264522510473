import { type Dispatch, type SetStateAction, createContext } from 'react';
import { IGetPlayerWithOrganizationsQuery } from 'src/graphql/queries/players.graphql.types';

export type TModal = '' | 'login' | 'forgotPassword' | 'resetPassword';

type TRootUserWithValidation = IGetPlayerWithOrganizationsQuery['user'] & {
  isValidated: boolean;
  activeModal: TModal;
  modalArgs: Record<string, string>;
  inspectedPlayerId: number;
};

export type TRootUserContext = {
  rootUser: TRootUserWithValidation;
  setRootUser: Dispatch<SetStateAction<TRootUserWithValidation>>;
};

export const initialState = {
  id: 0,
  fullName: '',
  firstName: '',
  lastName: '',
  preferredName: '',
  emailAddress: '',
  organizations: [],
  isValidated: false,
  activeModal: '' as TModal,
  modalArgs: {} as Record<string, string>,
  inspectedPlayerId: 0,
} as TRootUserWithValidation;

const RootUserContext = createContext<TRootUserContext>({
  rootUser: initialState,
  setRootUser: () => {},
});

export default RootUserContext;
