import { useState, type FC, useMemo } from 'react';
import { Provider } from 'urql';
import graphqlClient from 'src/graphqlClient';
import AuthenticationLayer from 'src/pages/AuthenticationLayer';
import GridLayout from 'src/pages/GridLayout';
import RootUserContext, {
  initialState,
  type TRootUserContext,
} from 'src/contexts/RootUser';

const Root: FC = () => {
  const [ rootUser, setRootUser ] =
    useState<TRootUserContext['rootUser']>( initialState );

  const rootUserContextValue = useMemo(
    () => ({ rootUser, setRootUser }),
    [ rootUser ],
  );

  return (
    <Provider value={graphqlClient}>
      <RootUserContext.Provider value={rootUserContextValue}>
        <AuthenticationLayer>
          <GridLayout />
        </AuthenticationLayer>
      </RootUserContext.Provider>
    </Provider>
  );
};

export default Root;
