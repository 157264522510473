import { FC } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import GuestWelcome from './GuestWelcome';
import UserWelcome from './UserWelcome';

const WelcomePage: FC = () => {
  const { isValidated } = useRootUser();

  return isValidated ? <UserWelcome /> : <GuestWelcome />;
};

export default WelcomePage;
