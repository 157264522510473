import { FC } from 'react';
import { useParams } from 'react-router-dom';
import DystopiaRisingBuild from 'src/components/0500_character_builders/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Build: FC = () => {
  const { playerId, characterId } = useParams();
  const character = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  return <DystopiaRisingBuild {...character} playerId={Number( playerId )} />;
};

export default Build;
