import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

const Tooltip: FC<PropsWithChildren> = ({ children }) => (
  <div
    className={clsx(
      'absolute z-30 -top-14 -left-[62px] py-1 px-2',
      'w-32',
      'midtone-box',
      'rounded',
      'text-center text-sm',
      'invisible group-hover:visible',
      'before:absolute',
      'before:w-0',
      'before:h-0',
      'before:ml-[-4px]',
      'before:bottom-[-14px]',
      'before:z-10',
      'before:border-t-slate-700',
      'before:opacity-75',
      'before:border-transparent',
      'before:border-[6px]',
      'before:border-t-[12px]',
    )}
  >
    {children}
  </div>
);

export default Tooltip;
