import { gql } from 'urql';

export const createNewEvent = gql`
  mutation CreateNewEvent(
    $branchId: Int!
    $locationId: Int!
    $registrationOpensAt: String
    $startsAt: String!
    $endsAt: String!
    $name: String!
    $description: String
  ) {
    createEvent(
      input: {
        branchId: $branchId
        locationId: $locationId
        registrationOpensAt: $registrationOpensAt
        startsAt: $startsAt
        endsAt: $endsAt
        name: $name
        description: $description
        kind: live
      }
    ) {
      error
      event {
        id
        name
        description
        kind
        startsAt
        endsAt
        registrationOpensAt
        branch {
          id
        }
        location {
          id
        }
      }
    }
  }
`;

export const assignTemporaryManager = gql`
  mutation AssignTemporaryManager(
    $eventId: Int!
    $playerId: Int!
    $forRemoval: Boolean
  ) {
    assignTemporaryManager(
      input: { eventId: $eventId, playerId: $playerId, forRemoval: $forRemoval }
    ) {
      error
      event {
        id
        temporaryManagers {
          id
          user {
            id
            fullName
            userOrganization {
              id
              role
              branch {
                id
                shorthand
              }
            }
            ownedBranches {
              id
              shorthand
            }
          }
        }
      }
    }
  }
`;

export const updateEvent = gql`
  mutation UpdateEvent(
    $eventId: Int!
    $locationId: Int
    $registrationOpensAt: String
    $startsAt: String
    $endsAt: String
    $name: String
    $description: String
    $baseBuild: Int
    $maxExtraXp: Int
    $considersTravelersLocal: Boolean
  ) {
    updateEvent(
      input: {
        eventId: $eventId
        locationId: $locationId
        registrationOpensAt: $registrationOpensAt
        startsAt: $startsAt
        endsAt: $endsAt
        name: $name
        description: $description
        baseBuild: $baseBuild
        maxExtraXp: $maxExtraXp
        considersTravelersLocal: $considersTravelersLocal
      }
    ) {
      error
      event {
        id
        name
        description
        startsAt
        endsAt
        registrationOpensAt
        branch {
          id
        }
        location {
          id
        }
        config {
          baseBuild
          maxCurveXp
          extraXpCost
          maxExtraXp
          maxExtraXpLimit
          shiftUnit
          shiftMultiplier
          maxShift
          shiftKinds
          considersTravelersLocal
        }
      }
    }
  }
`;

export const createEventTicket = gql`
  mutation CreateEventTicket(
    $eventId: Int!
    $label: String!
    $cost: Int!
    $limit: Int
    $shiftResponsibilities: Int!
    $restrictionType: TicketRestrictionEnum!
  ) {
    createEventTicket(
      input: {
        eventId: $eventId
        label: $label
        cost: $cost
        limit: $limit
        shiftResponsibilities: $shiftResponsibilities
        restrictionType: $restrictionType
      }
    ) {
      error
      event {
        id
        tickets(editMode: true) {
          id
          cost
          label
          limit
          discountType
          passType
          restrictionType
          shiftResponsibilities
          fixedLimit
          fullyBooked
          eventAttendeesCount
        }
      }
    }
  }
`;

export const createEventShfit = gql`
  mutation CreateEventShift(
    $eventId: Int!
    $label: String!
    $baseRatio: Int!
    $kind: ShiftKindEnum!
  ) {
    createEventShift(
      input: {
        eventId: $eventId
        label: $label
        baseRatio: $baseRatio
        kind: $kind
      }
    ) {
      error
      event {
        id
        shiftKinds
        shifts(withFulfillments: true) {
          id
          label
          baseRatio
          limit
          kind
          isEnabled
          autoExpand
          adjustableExpansionStrategy
          playersCount
          attendeeShifts {
            id
            status
            player {
              id
              fullName
              roleInOrganization
            }
          }
        }
      }
    }
  }
`;

export const destroyEventShift = gql`
  mutation DestroyEventShift($shiftId: Int!) {
    destroyEventShift(input: { shiftId: $shiftId }) {
      error
      event {
        id
        shiftKinds
        shifts(withFulfillments: true) {
          id
          label
          baseRatio
          limit
          kind
          isEnabled
          autoExpand
          adjustableExpansionStrategy
          playersCount
          attendeeShifts {
            id
            status
            player {
              id
              fullName
              roleInOrganization
            }
          }
        }
      }
    }
  }
`;

export const updateEventTicket = gql`
  mutation UpdateEventTicket(
    $ticketId: Int!
    $label: String
    $cost: Int
    $limit: Int
    $unlimited: Boolean
  ) {
    updateEventTicket(
      input: {
        ticketId: $ticketId
        label: $label
        cost: $cost
        limit: $limit
        unlimited: $unlimited
      }
    ) {
      error
      ticket {
        id
        cost
        label
        limit
        eventAttendeesCount
        fullyBooked
      }
    }
  }
`;

export const updateEventShift = gql`
  mutation UpdateEventShift(
    $shiftId: Int!
    $label: String
    $limit: Int
    $baseRatio: Int
    $autoExpand: Boolean
    $isEnabled: Boolean
  ) {
    updateEventShift(
      input: {
        shiftId: $shiftId
        label: $label
        limit: $limit
        baseRatio: $baseRatio
        autoExpand: $autoExpand
        isEnabled: $isEnabled
      }
    ) {
      error
      shift {
        id
        label
        limit
        baseRatio
        autoExpand
        isEnabled
      }
    }
  }
`;

export const fulfillShift = gql`
  mutation FulfillShift($attendeeShiftId: Int!, $isFulfilled: Boolean!) {
    fulfillShift(
      input: { attendeeShiftId: $attendeeShiftId, isFulfilled: $isFulfilled }
    ) {
      error
      attendeeShift {
        id
        status
      }
    }
  }
`;

export const checkin = gql`
  mutation Checkin(
    $ticketId: Int!
    $playerId: Int!
    $shiftIds: [Int!]!
    $characters: [CheckinCharacterInput!]
    $printRequestOnCharacterIds: [Int!]!
    $buildGrowth: Int!
    $isAttending: Boolean
    $isPaid: Boolean
  ) {
    checkin(
      input: {
        ticketId: $ticketId
        playerId: $playerId
        shiftIds: $shiftIds
        characters: $characters
        printRequestOnCharacterIds: $printRequestOnCharacterIds
        buildGrowth: $buildGrowth
        isAttending: $isAttending
        isPaid: $isPaid
      }
    ) {
      error
    }
  }
`;

export const updateCheckin = gql`
  mutation updateCheckin(
    $eventAttendeeId: Int!
    $printRequestOnCharacterIds: [Int!]
    $characters: [CheckinCharacterInput!]
    $buildGrowth: Int
    $ticketId: Int
    $shiftIds: [Int!]
    $isAttending: Boolean
    $isPaid: Boolean
  ) {
    updateEventAttendee(
      input: {
        eventAttendeeId: $eventAttendeeId
        printRequestOnCharacterIds: $printRequestOnCharacterIds
        characters: $characters
        buildGrowth: $buildGrowth
        ticketId: $ticketId
        shiftIds: $shiftIds
        isAttending: $isAttending
        isPaid: $isPaid
      }
    ) {
      error
      eventAttendee {
        id
        buildAwarded
        buildGrowth
        checkinApproved
        kind
        paid
        attending
        shifts {
          id
          label
        }
        ticket {
          id
          cost
          identifier
          label
          passType
          shiftResponsibilities
        }
        attendeeCharacters {
          id
          printRequestStatus
          character {
            id
            name
            status
          }
        }
      }
    }
  }
`;

export const rejectCheckin = gql`
  mutation DestroyEventAttendee($eventAttendeeId: Int!) {
    destroyEventAttendee(input: { eventAttendeeId: $eventAttendeeId }) {
      error
      eventAttendee {
        id
        ticket {
          id
        }
      }
    }
  }
`;

export const updateEventAttendee = gql`
  mutation UpdateEventAttendee(
    $eventAttendeeId: Int!
    $printRequestOnCharacterIds: [Int!]
    $referralInfo: String
    $characters: [CheckinCharacterInput!]
    $buildGrowth: Int
    $ticketId: Int
    $inviterId: Int
    $shiftIds: [Int!]
    $isAttending: Boolean
    $isPaid: Boolean
  ) {
    updateEventAttendee(
      input: {
        eventAttendeeId: $eventAttendeeId
        printRequestOnCharacterIds: $printRequestOnCharacterIds
        referralInfo: $referralInfo
        characters: $characters
        buildGrowth: $buildGrowth
        ticketId: $ticketId
        inviterId: $inviterId
        shiftIds: $shiftIds
        isAttending: $isAttending
        isPaid: $isPaid
      }
    ) {
      error
      eventAttendee {
        id
        buildGrowth
        kind
        paid
        attending
        referralInfo
        user {
          id
          fullName
        }
        attendeeCharacters {
          id
          character {
            id
            name
          }
        }
        ticket {
          id
          label
        }
        shifts {
          id
        }
      }
    }
  }
`;
