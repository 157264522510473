import { getOrganizationRebirthedCharacters } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationRebirthedCharactersQuery,
  IGetOrganizationRebirthedCharactersQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationRebirthedCharacters = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationRebirthedCharactersQuery,
    IGetOrganizationRebirthedCharactersQueryVariables
  >({
    query: getOrganizationRebirthedCharacters,
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    rebirthedCharacters: data?.organization.rebirthedCharacters || [],
  };
};

export default useOrganizationRebirthedCharacters;
