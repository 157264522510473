import { FC, forwardRef } from 'react';
import useItemKinds from 'src/hooks/items/useItemKinds';
import { startCase } from 'lodash';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {}

const ItemKindSelect: FC<IProps> = forwardRef(({ ...props }, ref ) => {
  const { itemKinds } = useItemKinds();

  return (
    <BaseSelect
      withSearch
      options={itemKinds.map( x => ({
        value: x,
        label: startCase( x ),
      }))}
      {...props}
    />
  );
});

export default ItemKindSelect;
