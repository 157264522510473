import { useQuery } from 'urql';
import { getTokenId } from 'src/graphql/queries/token.graphql';
import {
  IGetTokenIdQuery,
  IGetTokenIdQueryVariables,
} from 'src/graphql/queries/token.graphql.types';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useTokenId = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetTokenIdQuery,
    IGetTokenIdQueryVariables
  >({
    query: getTokenId,
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    tokenId: data?.tokenId,
    error,
    fetching,
    stale,
  };
};

export default useTokenId;
