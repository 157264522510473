import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash';
import Loading from 'src/components/0100_loading';
import useEvent from 'src/hooks/events/useEvent';
import useEventTickets from 'src/hooks/events/useEventTickets';
import Table from 'src/components/0100_table';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import TicketGroup from './TicketGroup';
import Search from './Search';
import { TOnFilterChange } from './TicketGroup/Ticket/TicketAttendees';

const restrictionSort: { [key: string]: number } = {
  new_player_restricted: 0,
  unrestricted: 10,
  assistant_restricted: 20,
  employee_restricted: 30,
};

const ByTicket: FC = () => {
  const { event } = useEvent();
  const methods = useForm({
    defaultValues: {
      searchQuery: '',
    },
  });
  const { watch } = methods;
  const { searchQuery } = watch();
  const [ expandedTicketIds, setExpandedTicketIds ] = useState<number[]>([]);
  const { tickets, fetching } = useEventTickets({ editMode: true });
  const [ searchMatches, setSearchMatches ] = useState<{ [key: string]: number }>(
    {},
  );
  const restrictionGroups = groupBy( tickets, 'restrictionType' );
  const handleExpand = useCallback(( id: number ) => {
    setExpandedTicketIds( x =>
      x.includes( id ) ? x.filter( y => y !== id ) : [ ...x, id ],
    );
  }, []);
  const handleExpandAll = useCallback(() => {
    if ( expandedTicketIds.length === 0 ) {
      setExpandedTicketIds( tickets.map( x => x.id ));
    } else {
      setExpandedTicketIds([]);
    }
  }, [ expandedTicketIds.length, tickets ]);
  const partialExpanded = expandedTicketIds.length > 0;
  const hasSearchQuery = searchQuery.trim().length > 0;
  const unfilteredAttendancesLength = tickets
    .map( x => x.eventAttendeesCount )
    .reduce(( a, b ) => ( a ?? 0 ) + ( b ?? 0 ), 0 );
  const filteredAttendancesLength = useMemo(
    () => Object.values( searchMatches ).reduce(( a, b ) => a + b, 0 ),
    [ searchMatches ],
  );
  const handleFilterChange = useCallback(
    ({ identifier, length }: TOnFilterChange ) => {
      setSearchMatches( x => ({
        ...x,
        [identifier ?? '']: length,
      }));
    },
    [],
  );

  useEffect(() => {
    if ( hasSearchQuery ) {
      setExpandedTicketIds( tickets.map( x => x.id ));
    }
  }, [ handleExpandAll, hasSearchQuery, tickets ]);

  if ( fetching ) return <Loading />;

  return (
    <div className="grid gap-4">
      <FormProvider {...methods}>
        <Search />

        <div className="flex justify-center">
          <Table
            className="table-fixed w-[calc(100vw-8px)] sm:w-[480px] lg:w-[640px]"
            headers={[
              { content: 'Name' },
              { content: 'Price', className: 'w-[64px] text-center' },
              { content: 'Limit', className: 'w-[64px] text-center' },
              {
                content: (
                  <div className="flex items-center justify-end w-full text-right gap-2">
                    {hasSearchQuery ? (
                      <div className="flex justify-end items-center">
                        {`${filteredAttendancesLength}/${unfilteredAttendancesLength}`}
                      </div>
                    ) : (
                      <div className="flex items-center justify-end gap-1.5">
                        {unfilteredAttendancesLength}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={clsx(
                            'transition-all',
                            partialExpanded && '-scale-y-100',
                          )}
                        />
                      </div>
                    )}
                  </div>
                ),
                className: 'w-[64px] text-right pr-2',
                onClick: handleExpandAll,
              },
            ]}
          >
            {Object.keys( restrictionSort )
              .sort(( a, b ) => restrictionSort[a] - restrictionSort[b])
              .map( restrictionType => (
                <TicketGroup
                  key={restrictionType}
                  name={restrictionType}
                  adjustableShift={[
                    'new_player_restricted',
                    'unrestricted',
                  ].includes( restrictionType )}
                  expandedTicketIds={expandedTicketIds}
                  tickets={restrictionGroups[restrictionType] || []}
                  config={event?.config}
                  onExpand={handleExpand}
                  onFilterChange={handleFilterChange}
                />
              ))}
          </Table>
        </div>
      </FormProvider>
    </div>
  );
};

export default ByTicket;
