import { faEdit, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, isBefore, parseISO } from 'date-fns';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CollapsibleCard from 'src/components/0100_collapsible_card';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import EventCard from 'src/components/0200_event_card';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useEvents from 'src/hooks/events/useEvents';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import usePermission from 'src/hooks/permissions/usePermissions';

const Branches: FC = () => {
  const { branches, fetching } = useOrganizationBranches({ owned: false });
  const { branches: ownedBranches } = useOrganizationBranches({ owned: true });
  const { isPermitted } = usePermission({ action: 'search_player' });
  const { linkToPlayer, linkToBranch } = useAppNavigations();
  const { events } = useEvents({
    start: addDays( new Date(), -3 ),
    end: addDays( new Date(), 90 ),
  });

  const eventsInBranch = useCallback(
    ({ branchId }: { branchId: number }) =>
      events
        .filter( x => x.branch.id === branchId && x.registrationOpensAt )
        .sort(( a, b ) =>
          isBefore( parseISO( a.startsAt ), parseISO( b.startsAt )) ? -1 : 1,
        ),
    [ events ],
  );

  if ( fetching ) return <Loading />;

  return (
    <div className="grid lg:grid-cols-2 gap-4">
      {branches
        .sort(( a, b ) => a.name.localeCompare( b.name ))
        .map( branch => (
          <div
            key={branch.id}
            className="col-span-1 grid gap-2 place-content-start justify-stretch"
          >
            <Title variant="heading">
              <div className="flex justify-between items-center">
                <div className="text-2xl">{branch.name}</div>
                {ownedBranches.some( x => x.id === branch.id ) && (
                  <Link to={linkToBranch({ branchId: branch.id })}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                )}
              </div>
            </Title>
            {branch.website && (
              <a
                href={branch.website}
                target={`_website-${branch.id}`}
                className="border border-juno-gray-700 rounded p-4 gray-box flex justify-between hover:brightness-125 transition-all duration-300 text-xl relative overflow-hidden"
              >
                <div>Website</div>
                <div className="absolute right-10 scale-[4] top-6 text-juno-gray-700 -rotate-12">
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                </div>
              </a>
            )}

            {eventsInBranch({ branchId: branch.id }).length > 0 && (
              <div className="grid gap-2">
                {eventsInBranch({ branchId: branch.id }).map( x => (
                  <EventCard
                    key={x.id}
                    id={x.id}
                    name={x.name}
                    description={x.description}
                    startsAt={parseISO( x.startsAt )}
                    endsAt={parseISO( x.endsAt )}
                    kind={x.kind}
                    location={{
                      name: x.location.name,
                      streetAddress: x.location.streetAddress,
                      city: x.location.city,
                      region: x.location.region,
                      zipCode: x.location.zipCode,
                    }}
                    config={{
                      baseBuild: x.config.baseBuild,
                      maxExtraXp: x.config.maxExtraXp,
                      considersTravelersLocal: x.config.considersTravelersLocal,
                    }}
                  />
                ))}
              </div>
            )}

            {branch.branchOwners.length > 0 ? (
              <CollapsibleCard title="Employees">
                <div className="grid gap-2">
                  {branch.branchOwners
                    .sort(( a, b ) => a.user.id - b.user.id )
                    .map( branchOwner => (
                      <div key={branchOwner.id} className="flex gap-2">
                        <div className="w-14 text-right opacity-75">
                          {isPermitted ? (
                            <Link
                              to={linkToPlayer({
                                playerId: branchOwner.user.id,
                              })}
                              className="underline"
                            >
                              {branchOwner.user.id}
                            </Link>
                          ) : (
                            branchOwner.user.id
                          )}
                        </div>
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
                          <div className="w-full whitespace-nowrap overflow-hidden">
                            {branchOwner.user.fullName}
                          </div>
                          {/* <div className="w-full opacity-75 whitespace-nowrap overflow-hidden select-all">
                        {branchOwner.user.emailAddress}
                      </div> */}
                        </div>
                      </div>
                    ))}
                </div>
              </CollapsibleCard>
            ) : (
              <div className="opacity-50">No Employees</div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Branches;
