import clsx from 'clsx';
import { FC, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemPrintout from './ItemPrintout';
import ItemEditor from './ItemEditor';

const ItemView: FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const isPrintout = searchParams.get( 'print' ) === 'true';
  const ref = useRef<HTMLDivElement>( null );

  const handleOnLoad = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div ref={ref} className="overflow-auto lg:max-h-[calc(100vh-64px)]">
      <div className="flex justify-between border-b border-juno-gray-700 mb-2">
        <button
          type="button"
          className={clsx(
            'w-full p-2 border border-b-0 rounded-t transition-all duration-300',
            !isPrintout
              ? 'text-shadow text-juno-gray-50 dark-box border-juno-gray-700'
              : 'text-juno-gray-700 border-transparent',
          )}
          onClick={() => {
            searchParams.delete( 'print' );
            setSearchParams( searchParams );
          }}
        >
          Data Entry
        </button>
        <button
          type="button"
          className={clsx(
            'w-full p-2 border border-b-0 rounded-t transition-all duration-300',
            isPrintout
              ? 'text-shadow text-juno-gray-50 dark-box border-juno-gray-700'
              : 'text-juno-gray-700 border-transparent',
          )}
          onClick={() => {
            searchParams.set( 'print', 'true' );
            setSearchParams( searchParams );
          }}
        >
          Printout
        </button>
      </div>
      {isPrintout ? <ItemPrintout /> : <ItemEditor onLoad={handleOnLoad} />}
    </div>
  );
};

export default ItemView;
