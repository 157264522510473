import { getOrganizationFellowshipDetails } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationFellowshipDetailsQuery,
  IGetOrganizationFellowshipDetailsQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationFellowship = ({
  fellowshipId,
}: {
  fellowshipId: number;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationFellowshipDetailsQuery,
    IGetOrganizationFellowshipDetailsQueryVariables
  >({
    query: getOrganizationFellowshipDetails,
    variables: { fellowshipId },
    requestPolicy: 'cache-and-network',
    pause: fellowshipId <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    fellowship: data?.organization.fellowship,
    error,
    fetching,
    stale,
  };
};

export default useOrganizationFellowship;
