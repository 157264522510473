import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import DystopiaRisingNotes from 'src/components/0500_character_notes/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Notes: FC = () => {
  const [ searchParams ] = useSearchParams();
  const playerId = searchParams.get( 'player_id' );
  const characterId = searchParams.get( 'character_id' );

  const character = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  return <DystopiaRisingNotes {...character} />;
};

export default Notes;
