import { gql } from 'urql';

export const requestPasswordResetMutation = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordReset(input: { username: $username }) {
      error
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation ResetPassword($resetPasswordToken: String!, $password: String!) {
    resetPassword(
      input: { resetPasswordToken: $resetPasswordToken, password: $password }
    ) {
      error
      token
      user {
        id
        firstName
        lastName
        fullName
        emailAddress
        organizations {
          id
          name
          role
          slug
          shorthand
          branch {
            id
            name
            shorthand
          }
        }
      }
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      error
    }
  }
`;
