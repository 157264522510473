import { useCallback } from 'react';
import useRootUser from '../players/useRootUser';

const usePreferences = () => {
  const { rootUser, setRootUser } = useRootUser();

  const { inspectedPlayerId } = rootUser;
  const setInspectedPlayerId = useCallback(
    ( id: number | null ) => {
      setRootUser( x => ({ ...x, inspectedPlayerId: id || 0 }));
    },
    [ setRootUser ],
  );

  return { inspectedPlayerId, setInspectedPlayerId };
};

export default usePreferences;
