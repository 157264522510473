import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Title from 'src/components/0100_title';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';

const Player: FC = () => {
  const { playerId } = useParams();
  const { data } = usePlayerWithCharactersInOrganization({
    playerId: Number( playerId ) || 0,
  });
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });

  if ( playerId === 'new' && !canCreateNewPlayer ) return null;

  return (
    <div>
      <Title
        title={
          playerId === 'new' ? 'Create New Player' : data?.user?.fullName || ''
        }
      />
      <Outlet />
    </div>
  );
};

export default Player;
