import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ResponseBox from 'src/components/0100_response_box';
import AugmentedInput from 'src/components/0200_augmented_input';
import BranchesToggle from 'src/components/0200_branches_toggle';
import { updatePlayerBranchOwnership } from 'src/graphql/mutations/players.graphql';
import {
  IUpdatePlayerBranchOwnershipMutation,
  IUpdatePlayerBranchOwnershipMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import usePlayerOwnedBranches from 'src/hooks/players/usePlayerOwnedBranches';
import { useMutation } from 'urql';

const EmployeeBranchOwnership: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const isNewPlayer = playerId === 'new';

  const { ownedBranches } = usePlayerOwnedBranches({
    playerId: Number( playerId ),
  });
  const memoizedOwnedBranchIds = useMemo(
    () => ownedBranches.map( x => x.id ),
    [ ownedBranches ],
  );
  const [ updateOwnershipResult, updateOwnership ] = useMutation<
    IUpdatePlayerBranchOwnershipMutation,
    IUpdatePlayerBranchOwnershipMutationVariables
  >( updatePlayerBranchOwnership );

  const methods = useForm({
    defaultValues: {
      ownedBranchIds: [] as number[],
    },
  });
  const { register, setValue, watch } = methods;
  const { ownedBranchIds } = watch();

  const { isPermitted: canAssignBranchOwnership } = usePermission({
    action: 'update_player_branch_ownership',
    pause: isNewPlayer,
  });

  useEffect(() => {
    setValue( 'ownedBranchIds', memoizedOwnedBranchIds );
  }, [ memoizedOwnedBranchIds, setValue ]);

  return (
    <FormProvider {...methods}>
      <AugmentedInput
        key="owned-branches"
        title="Branch Ownerships"
        isBusy={updateOwnershipResult.fetching}
        isUpdated={
          !!updateOwnershipResult.data?.updatePlayerBranchOwnership &&
          !updateOwnershipResult.data?.updatePlayerBranchOwnership?.error
        }
        footnote={
          ownedBranchIds.length === 0 && (
            <ResponseBox type="error" withIcon={faExclamationTriangle}>
              Employee needs at least one Branch Ownership assignment.
            </ResponseBox>
          )
        }
      >
        <div className="pt-0.5">
          <BranchesToggle
            activeFlags={ownedBranchIds || []}
            canToggle={canAssignBranchOwnership}
            className="min-w-[160px]"
            {...register( 'ownedBranchIds' )}
            onToggle={x => {
              updateOwnership({
                playerId: Number( playerId ),
                branchId: Number( x.value ),
                isOwned: x.state,
              });
            }}
          />
        </div>
      </AugmentedInput>
    </FormProvider>
  );
};

export default EmployeeBranchOwnership;
