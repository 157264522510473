import { useState, type FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useOutsideClickRef } from 'rooks';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SidebarTitle from 'src/pages/SidebarTitle';
import Sidebar from 'src/pages/Sidebar';
import Header from 'src/pages/Header';

import 'rc-slider/assets/index.css';

const GridLayout: FC = () => {
  const { pathname } = useLocation();
  const [ isSidebarOpen, setIsSidebarOpen ] = useState( false );
  const [ layout, setLayout ] = useState<'mobile' | 'desktop'>( 'mobile' );
  const [ ref ] = useOutsideClickRef(() => setIsSidebarOpen( false ));
  const unconstrainedWidth =
    pathname === '/dystopia-rising/depository/tabular' ||
    pathname.match( /staffs$/ ) ||
    pathname.match( /branches\/\d+\/(caps|corrective_actions)$/ );

  const handleResize = () => {
    if ( window.innerWidth < 768 ) {
      setLayout( 'mobile' );
    } else {
      setLayout( 'desktop' );
    }
  };

  useEffect(() => {
    window.addEventListener( 'resize', handleResize );
    handleResize();
    return () => window.removeEventListener( 'resize', handleResize );
  }, []);

  return (
    <div>
      {layout === 'desktop' ? (
        <div className="grid grid-cols-[256px_1fr] grid-rows-[48px_1fr]">
          <div style={{ gridArea: '1/1/2/2' }} className="grid items-center">
            <SidebarTitle />
          </div>
          <div style={{ gridArea: '1/2/2/6' }} className="grid gray-box">
            <Header />
          </div>
          <div
            style={{
              gridArea: '2/1/6/2',
              maxHeight: 'calc(100vh - 48px)',
              minHeight: 'calc(100vh - 48px)',
            }}
            className="overflow-auto gray-box"
          >
            <Sidebar />
          </div>
          <div
            style={{ gridArea: '2/2/6/6', maxHeight: 'calc(100vh - 48px)' }}
            className="overflow-auto"
          >
            <div
              className={clsx(
                !unconstrainedWidth && 'flex justify-center p-4 w-full',
              )}
            >
              <div
                className={clsx(
                  !unconstrainedWidth && 'w-[calc(min(100vw,1024px))]',
                )}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-[1fr] grid-rows-[48px_1fr]">
          <div
            ref={ref}
            className={clsx(
              'absolute z-20 w-64 transition-all duration-300 ease-in-out',
              isSidebarOpen ? 'left-0' : '-left-64',
            )}
          >
            <div className="grid grid-cols-[1fr] grid-rows-[48px_1fr]">
              <div
                style={{ gridArea: '1/1/2/2' }}
                className="grid items-center"
              >
                <SidebarTitle />
                <button
                  type="button"
                  className="absolute -right-8"
                  onClick={() => setIsSidebarOpen( x => !x )}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={clsx(
                      'text-3xl mt-0.5 origin-center transition-transform duration-300',
                      isSidebarOpen && '-scale-x-100',
                    )}
                  />
                </button>
              </div>
              <div
                style={{
                  gridArea: '2/1/3/2',
                  maxHeight: 'calc(100vh - 48px)',
                  minHeight: 'calc(100vh - 48px)',
                }}
                className="overflow-auto gray-box"
              >
                <Sidebar />
              </div>
            </div>
          </div>
          <div style={{ gridArea: '1/1/2/2' }} className="grid gray-box">
            <Header />
          </div>
          <div
            style={{ gridArea: '2/1/3/2', maxHeight: 'calc(100vh - 48px)' }}
            className="overflow-auto relative z-0"
          >
            <div className="flex justify-center py-4 px-2 w-full">
              <div className="w-[calc(min(100vw,1024px))]">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridLayout;
