import { createClient, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import type { GraphCacheConfig } from './graphql/types';
import schema from './generatedIntrospection';

const storage = makeDefaultStorage({
  idbName: 'juno',
  maxAge: 7,
});

const cache = cacheExchange<GraphCacheConfig>({
  schema,
  storage,
  keys: {
    Permission: () => null,
    EventConfig: () => null,
    XpAudit: () => null,
    ItemMetadata: () => null,
    ItemReproductionMetadata: () => null,
    CharacterSkill: data => data.characterSkillId ?? '-1',
  },
  updates: {
    Mutation: {
      destroyEventAttendee: (result, args, cache) => {
        cache.invalidate({
          __typename: 'EventAttendee',
          id: args.input.eventAttendeeId,
        });
        cache.invalidate({
          __typename: 'Ticket',
          id: Number(result.destroyEventAttendee?.eventAttendee?.ticket.id),
        });
      },
    },
  },
});

const graphqlClient = createClient({
  url: import.meta.env.VITE_API_ENDPOINT,
  exchanges: [devtoolsExchange, cache, fetchExchange],
  fetchOptions() {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    return token
      ? {
        headers: {
          'X-Auth-Token': token,
          'X-Window-Location': window.location.href,
        },
      }
      : {
        headers: {
          'X-Auth-Token': '0',
          'X-Window-Location': window.location.href,
        }
      };
  },
});

export default graphqlClient;
