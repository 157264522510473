import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { updateItemReproduction } from 'src/graphql/mutations/items.graphql';
import {
  IUpdateItemReproductionMutation,
  IUpdateItemReproductionMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { useMutation } from 'urql';
import { IPropsWithOnUpdate } from '../../types';

export type TItemReproduction = {
  id: number;
  reproductionTimeInMinute?: number | null;
  reproductionSkills?: string | null;
  reproductionMindCost?: number | null;
  metadata: {
    notes?: string | null;
  };
  itemReproductionComponents: {
    id: number;
    item: {
      id: number;
      name: string;
    };
    amount: number;
  }[];
};

interface IProps extends IPropsWithOnUpdate {
  id: number;
  itemReproductions: TItemReproduction[];
}

const ItemReproduction: FC<IProps> = ({ id, itemReproductions, onUpdate }) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const itemReproduction = itemReproductions[0];
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      reproductionTimeInMinute: null as number | null,
      reproductionSkills: null as string | null,
      reproductionMindCost: null as number | null,
    },
  });

  const [ , update ] = useMutation<
    IUpdateItemReproductionMutation,
    IUpdateItemReproductionMutationVariables
  >( updateItemReproduction );

  const handleUpdate = useCallback(() => {
    if ( !isDirty ) return;
    if ( !itemReproduction ) return;

    onUpdate({ status: 'busy' });
    update({
      itemReproductionId: itemReproduction.id,
      reproductionTimeInMinute: watch( 'reproductionTimeInMinute' ),
      reproductionSkills: watch( 'reproductionSkills' ),
      reproductionMindCost: watch( 'reproductionMindCost' ),
    }).then( res => {
      if ( res.data?.updateItemReproduction?.itemReproduction ) {
        onUpdate({ status: 'success' });
      }

      if ( res.data?.updateItemReproduction?.error ) {
        onUpdate({ status: 'error' });
      }
    });
  }, [ isDirty, itemReproduction, onUpdate, update, watch ]);

  useEffect(() => {
    if ( itemReproduction ) {
      reset({
        reproductionTimeInMinute: itemReproduction.reproductionTimeInMinute,
        reproductionSkills: itemReproduction.reproductionSkills,
        reproductionMindCost: itemReproduction.reproductionMindCost,
      });
    }
  }, [ itemReproduction, reset ]);

  if ( !itemReproduction ) return <td colSpan={4} />;

  return (
    <>
      <td>
        <input
          className="text-right border-0 bg-transparent w-full p-1"
          type="number"
          {...register( 'reproductionTimeInMinute', { onBlur: handleUpdate })}
        />
      </td>

      <td>
        <input
          className="text-right border-0 bg-transparent w-full p-1"
          type="number"
          {...register( 'reproductionMindCost', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="border-0 bg-transparent w-full p-1"
          type="text"
          {...register( 'reproductionSkills', { onBlur: handleUpdate })}
        />
      </td>
      <td className="midtone-box">
        <button
          type="button"
          className="w-full p-1"
          onClick={() => {
            searchParams.set( 'item_id', String( id ));
            setSearchParams( searchParams );
          }}
        >
          {itemReproduction.itemReproductionComponents.map(
            reproductionComponent => (
              <div
                key={reproductionComponent.id}
                className="flex items-center gap-1 whitespace-nowrap overflow-hidden"
              >
                <div>{reproductionComponent.amount}</div>
                <div>{reproductionComponent.item.name}</div>
              </div>
            ),
          )}
        </button>
      </td>
    </>
  );
};

export default ItemReproduction;
