import { getOrganization } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationQuery,
  IGetOrganizationQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganization = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationQuery,
    IGetOrganizationQueryVariables
  >({
    query: getOrganization,
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    organization: data?.organization,
  };
};

export default useOrganization;
