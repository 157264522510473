import { useCallback, useEffect, useState } from 'react';

const useEmbossPrintTime = () => {
  const [ printTime, setPrintTime ] = useState( new Date().toISOString());
  const [ crc, setCrc ] = useState( '' );
  const embossPrintTime = useCallback(() => {
    setPrintTime( new Date().toISOString());
    setCrc( window.crypto.randomUUID().toString());
  }, []);

  useEffect(() => {
    window.addEventListener( 'beforeprint', embossPrintTime );
    return () => {
      window.removeEventListener( 'beforeprint', embossPrintTime );
    };
  }, [ embossPrintTime ]);

  return { embossPrintTime, printTime, crc };
};

export default useEmbossPrintTime;
