import { getItem } from 'src/graphql/queries/items.graphql';
import { useQuery } from 'urql';
import {
  IGetItemQuery,
  IGetItemQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useItem = ({ itemId }: { itemId: number }) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemQuery,
    IGetItemQueryVariables
  >({
    query: getItem,
    variables: {
      itemId,
    },
    requestPolicy: 'cache-and-network',
    pause: itemId < 1,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    item: data?.organization.item,
  };
};

export default useItem;
